import { Table, message, Modal, Tag, Tooltip } from "antd";
import React, { useState, useEffect } from "react";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

import axiosClient from "../../Authentication/ApiCall";
import authService from "../../Authentication/authService";
// import { set } from "immer/dist/internal";

const { Column } = Table;

export default function ReportUserList(props) {
  const [data, setdata] = useState([]);
  const [loading, setloading] = useState(false);
  const [DeleteRefresh, setDeleteRefresh] = useState(1);
  const [cameralist, setcameralist] = useState([]);
  const [TableEventList, setTableEventList] = useState([]);
  const [TableUsername, setTableUsername] = useState([]);
  const [TableEmail, setTableEmail] = useState([]);
  const [PageTotal, setPageTotal] = useState(10);
  const [PageNo, setPageNo] = useState(1);

  // removes duplicates for table filter
  const Dupless = TableEmail.filter(
    (ele, ind) =>
      ind ===
      TableEmail.findIndex(
        (elem) => elem.text === ele.text && elem.value === ele.value
      )
  );

  const Duplessuser = TableUsername.filter(
    (ele, ind) =>
      ind ===
      TableUsername.findIndex(
        (elem) => elem.text === ele.text && elem.value === ele.value
      )
  );

  const Duplesscam = cameralist.filter(
    (ele, ind) =>
      ind ===
      cameralist.findIndex(
        (elem) => elem.text === ele.text && elem.value === ele.value
      )
  );

  const Duplessevent = TableEventList.filter(
    (ele, ind) =>
      ind ===
      TableEventList.findIndex(
        (elem) => elem.text === ele.text && elem.value === ele.value
      )
  );

  // delete notification

  const OnDelete = (record) => {
    Modal.confirm({
      title: "Confirm Delete?",

      okText: "Confirm",
      okType: "danger",
      onOk: () => {
        axiosClient
          .put("/mailreport/deleteUsersFromSendingNotification", {
            id: record.id,
            // delete_reason:record
          })
          .then((response) => {
            if (response.data.issuccess === true) {
              message.success(response.data.msg);
              setDeleteRefresh(DeleteRefresh + 1);
            } else {
              // console.log("error");
              message.error(response.data);
            }
          })
          .catch((err) => {
            console.log("errr", err);
            if (err.status === 0) {
              message.error("Server error");
            } else {
              message.error(err.msg);
            }
          });
      },
    });
  };

  // initial fetch and if any deleted
  useEffect(() => {
    axiosClient
      .get(
        "/mailreport/getReportNotificationUserList?page=" +
          "1" +
          "&&size=" +
          "10"
      )
      .then((response) => {
        if (response.data.issuccess === true) {
          setdata(response.data.data);
          console.log(data);
          setPageTotal(response.data.totaldatacount);
          setPageNo(response.data.page);
          setloading(false);
        } else {
        }
      })
      .catch((err) => {
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  }, [props.refresh, DeleteRefresh]);

  // removeDuplicates(TableEmail);
  return (
    <Table
      loading={loading}
      rowKey="sno"
      dataSource={data}
      pagination={{ showSizeChanger: "true" }}
      scroll={{ x: "max-content" }}
    >
      <Column
        title="S.no"
        key="id"
        // sorter={true}
        render={(item, record, index) => {
          ++index;

          return index;
        }}
      />
      {/* <Column title="Username" dataIndex="username" key="username" /> */}
      <Column
        title="Camera Name"
        dataIndex="cameraname"
        key="cameraName"
        filterSearch={true}
        filters={Duplesscam.map((item) => item)}
        onFilter={(value, record) => record.cameraname.indexOf(value) === 0}
      />
      <Column
        title="Event Name"
        dataIndex="eventname"
        key="eventname"
        filterSearch={true}
        filters={Duplessevent.map((item) => item)}
        onFilter={(value, record) => record.eventname.indexOf(value) === 0}
      />
      <Column
        title="User Name"
        dataIndex="username"
        key="username"
        filterSearch={true}
        filters={Duplessuser.map((item) => item)}
        onFilter={(value, record) => record.username.indexOf(value) === 0}
      />
      <Column
        title="Report type"
        dataIndex="mailsendto"
        key="mailsendto"
        filterSearch={true}
        filters={
          // let list = removeDuplicates(TableEmail);
          Dupless.map((item) => item)
          // ()=>{
          //   TableEmail.map((item) => item)

          // }
        }
        onFilter={(value, record) => record.email.indexOf(value) === 0}
      />

      {authService.getCurrentUserRole() === "Admin" ||
      authService.getCurrentUserRole() === "Management" ? (
        <Column
          align="center"
          title="Delete"
          key="action"
          render={(record) => {
            return (
              <>
                {authService.getCurrentUserReadOnly() ? (
                  <Tooltip title="you need access to delete">
                    <DeleteOutlined disabled />
                  </Tooltip>
                ) : (
                  <DeleteOutlined
                    style={{ color: "red" }}
                    onClick={(e) => {
                      //   console.log(record);
                      OnDelete(record);
                      // onDeleteRecord(record);
                      //   setDeleterecord(record);
                      //   setReasonModal(!ReasonModal);
                      // DeleteReason(record);
                    }}
                  />
                )}
              </>
            );
          }}
        />
      ) : (
        ""
      )}
    </Table>
  );
}
