import {
  Card,
  Col,
  Row,
  Typography,
  Image,
  Select,
  Flex,
  Divider,
  Skeleton,
  notification,
} from "antd";
import { useAtom } from "jotai";
import authService from "../../Authentication/authService";
import React, { useEffect, useState } from "react";
// import helmet from "./helmet.png";
import Chart from "react-apexcharts";
import moment from "moment";
import { DatePicker } from "antd";
import { IoIosSearch } from "react-icons/io";
import axiosClient from "../../Authentication/ApiCall";
import total from "../../Icons/total.png";
import fire from "../../Icons/Fire.png";
import object from "../../Icons/Object.png";
import humanVehicle from "../../Icons/human vehicle Zone.png";
import ppe from "../../Icons/ppe.png";
import tressp from "../../Icons/tressp.png";
import month from "../../Icons/graph.png";
import vehicle from "../../Icons/vehicle Zone.png";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import useInterval from "use-interval";
import { useNavigate } from "react-router-dom";
import { DashboardNavData } from "../Utils/StateExports";
const { Title, Text } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;

const DashboardMain = () => {
  const [dashboardNavData, setDashboardNavData] = useAtom(DashboardNavData);
  const [showMessage, setShowMessage] = useState(false);
  const [filter, setFilter] = useState("Weekly wise");
  const [filterCam, setFilterCam] = useState("OverAll");
  const [cam, setCam] = useState(0);
  const [loading, setLoading] = useState(false);
  const [allData, setAllData] = useState([]);
  const [overAll, setOverAll] = useState(0);
  const [monthlyCount, setMonthlyCount] = useState("");
  const [InputSDate, setInputSDate] = useState("");
  const [InputEDate, setInputEDate] = useState("");
  const [chartState, setChartState] = useState({
    options: {
      chart: {
        id: "basic-bar",
      },
      xaxis: {
        categories: [],
      },
      plotOptions: {
        bar: {
          barHeight: "100%",
          distributed: true,
          barWidth: 0.3,
        },
      },
    },
    series: [
      {
        name: "Graph",
        data: [],
      },
    ],
    Total: 0,
  });
  const [selectedDate, setSelectedDate] = useState("");

  const [overAllData, setOverAllData] = useState({
    selectFilter: 2,
    startDate: dayjs().subtract(6, "day").format("YYYY-MM-DD"),
    endDate: dayjs().format("YYYY-MM-DD"),
    Total: 0,
  });
  const initialDate = dayjs().startOf("month").format("YYYY-MM");

  const [selectedMonth, setSelectedMonth] = useState(
    dayjs().startOf("month").format("YYYY-MM")
  );
  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      setDashboardNavData(null); // Resetting the state
    };
  }, [setDashboardNavData]);

  // onclik of these events  sending request to report page
  const Trespassing = (id) => {
    console.log("id44444", id);

    const dataToPass = { id: id, name: "Trespassing", status: "true" };

    // Update the atom with the new data
    setDashboardNavData(dataToPass);

    // Navigate to the Reports page
    navigate("/Admin/Reports");
  };
  const Firedetection = (id) => {
    const dataToPass = { id: id, name: "Fire_Detection" };
    setDashboardNavData(dataToPass);

    navigate("/Admin/Reports");
  };
  const safetyEquipments = (id) => {
    const dataToPass = { id: id, name: "Safety_Equipments_Detection" };
    setDashboardNavData(dataToPass);
    navigate("/Admin/Reports");
  };
  const HumnZone = (id) => {
    const dataToPass = { id: id, name: "Human _Zone_Crossing" };
    setDashboardNavData(dataToPass);
    navigate("/Admin/Reports");
  };
  const Vehiclezone = (id) => {
    const dataToPass = { id: id, name: "Vehicle _Zone_Crossing" };
    setDashboardNavData(dataToPass);
    navigate("/Admin/Reports");
  };

  function handleChange(value) {
    //console.log(`Selected: ${value}`);
    setFilter(value);
  }

  function handleChangeCam(value) {
    setFilterCam(value);
    // console.log(`Selected: ${value}`);

    // Calculate start and end dates based on selected option
    // switch (value) {
    //   case "Weekly-wise":
    //     startDate = moment().subtract(7, "days").format("YYYY-MM-DD");
    //     endDate = moment().format("YYYY-MM-DD");
    //     graphDetails(startDate, endDate, cam);
    //     break;
    //   case "15Days":
    //     startDate = moment().subtract(15, "days").format("YYYY-MM-DD");
    //     endDate = moment().format("YYYY-MM-DD");
    //     graphDetails(startDate, endDate, cam);
    //     break;
    //   case "Monthly-wise":
    //     startDate = moment().startOf("month").format("YYYY-MM-DD");
    //     endDate = moment().endOf("month").format("YYYY-MM-DD");
    //     graphDetails(startDate, endDate, cam);
    //     break;

    //   default:
    //     // For "Select Date" or any other option, you can handle differently
    //     break;
    // }

    // Make API call with calculated start and end dates
    // console.log(startDate,endDate)
  }
  function selectDateChance(dates) {
    setSelectedDate(dates);
    if (dates && dates.length === 2) {
      const [startDate, endDate] = dates;
      const formattedStartDate = startDate.format("YYYY-MM-DD");
      const formattedEndDate = endDate.format("YYYY-MM-DD");
      // Now you have the separate startDate and endDate in the desired format
      //  console.log('Start Date:', formattedStartDate);
      //console.log('End Date:', formattedEndDate);
      graphDetails(formattedStartDate, formattedEndDate, cam);
    } else {
      graphDetails("", "", filter);
    }
  }
  useEffect(() => {
    details(selectedDate, filterCam, cam);
  }, [selectedDate, filterCam, cam, selectedDate]);

  useEffect(() => {
    graphDetails();
  }, [InputSDate, InputEDate, cam, filterCam]);
  useEffect(() => {
    TotalData();
  }, [overAllData.startDate, overAllData.endDate]);
  useEffect(() => {
    mainData();
  }, []);
 
  useEffect(() => {
    axiosClient
      .get("/dashboard1/getcameralist")
      .then((response) => {
        if (response.data.issuccess === true) {
          console.log("Camera Lisrt Events:", response.data.data);
          const Camera = response.data.data.data;
          setCamera(response.data.data);
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((err) => {
        console.error("Error fetching overall events:", err);
      });
    //console.log("List---------------", camera);
  }, []);
  function details(selectedDate, filterCam) {
    if (filterCam == "Weekly-wise") {
      setInputSDate(moment().subtract(7, "days").format("YYYY-MM-DD"));
      setInputEDate(moment().format("YYYY-MM-DD"));
    } else if (filterCam == "15Days") {
      setInputSDate(moment().subtract(15, "days").format("YYYY-MM-DD"));
      setInputEDate(moment().endOf("month").format("YYYY-MM-DD"));
    } else if (filterCam == "Monthly-wise") {
      const startOfMonth = moment().startOf("month").format("YYYY-MM-DD");
      const endOfMonth = moment().endOf("month").format("YYYY-MM-DD");

      setInputSDate(startOfMonth);
      setInputEDate(endOfMonth);
    } else if (filterCam == "OverAll") {
      let startDate = "";
      let endDate = "";
      setInputSDate(startDate);
      setInputEDate(endDate);
      // graphDetails();
    } else if (filterCam == "Select-Date") {
      if (selectedDate == "") {
        setInputSDate("");
        setInputEDate("");
      } else {
        const [startDate, endDate] = selectedDate;
        const formattedStartDate = startDate.format("YYYY-MM-DD");
        const formattedEndDate = endDate.format("YYYY-MM-DD");

        // graphDetails(formattedStartDate, formattedEndDate, );
        setInputSDate(formattedStartDate);
        setInputEDate(formattedEndDate);
      }
    }
  }

  useInterval(() => {
    mainData();
    graphDetails();
    monthlyData();
    TotalData();
  }, 20000);

  function cameraChange(cam) {
    console.log(`Selected: ${cam}`);
    setCam(cam);
    //graphDetails();
  }
  function graphDetails() {
    setLoading(true);
    axiosClient
      .get(
        `/dashboard1/getdashborddetailsforgraph?startDate=${InputSDate}&endDate=${InputEDate}&cameraId=${cam}`
      )
      .then((response) => {
        //console.log(response.data.data)
        if (response.data.issuccess === true) {
          const graphData = response.data.data.data;
          // console.log("TOTAL DECTION UIG",response.data.totalCount)
          const categories = graphData.map((item) => Object.keys(item)[0]);
          const data = graphData.map((item) => Object.values(item)[0]);
          //console.log(response.data.data)

          setChartState((prevState) => ({
            ...prevState,
            options: {
              ...prevState.options,
              xaxis: { categories },
            },
            series: [{ name: "Graph", data }],
            Total: response.data.totalCount > 0 ? response.data.totalCount : 0,
          }));
          //console.log("Graph Data:", graphData);
          setLoading(false);
        } else {
          console.log("Something went wrong");
          setLoading(false);
        }
      })
      .catch((err) => {
        console.error("Error fetching graph data:", err);
        setLoading(false);
      });
  }

  function disabledDate(current) {
    // Disable dates after today
    // return current && current > moment().endOf("day");
    const createdDate = authService.getCreatedDate();
    const today = dayjs();

    return createdDate
      ? current < dayjs(createdDate).startOf("day") ||
          current > today.endOf("day")
      : false;
  }

  console.log("allData7777", allData);

  const mainData = () => {
    axiosClient
      .get("/dashboard1/getdashborddetailsforoverallevents?startDate=")
      .then((response) => {
        if (response.data.issuccess === true) {
          console.log("Overall Events:", response.data.data.data);
          const dataArray = response.data.data.data;

          // Create an array of objects with id and count
          const updatedData = dataArray.map((event) => {
            const eventType = Object.keys(event)[0];
            return {
              id: event.id,
              type: eventType,
              count: event[eventType],
            };
          });

          setAllData(updatedData);
          setOverAll(response.data.data.overallTotal);
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((err) => {
        console.error("Error fetching overall events:", err);
      });
  };

  const onMainFilterChange = (val) => {
    if (val == 1) {
      setOverAllData((prevState) => ({
        ...prevState,
        selectFilter: val,
        startDate: dayjs().format("YYYY-MM-DD"),
        endDate: dayjs().format("YYYY-MM-DD"),
      }));
    } else if (val == 2) {
      setOverAllData((prevState) => ({
        ...prevState,
        selectFilter: val,
        startDate: dayjs().subtract(6, "day").format("YYYY-MM-DD"),
        endDate: dayjs().format("YYYY-MM-DD"),
      }));
    } else if (val == 3) {
      setOverAllData((prevState) => ({
        ...prevState,
        selectFilter: val,
        startDate: dayjs().subtract(14, "day").format("YYYY-MM-DD"),
        endDate: dayjs().format("YYYY-MM-DD"),
      }));
    } else if (val == 4) {
      setOverAllData((prevState) => ({
        ...prevState,
        selectFilter: val,
        startDate: dayjs().subtract(44, "day").format("YYYY-MM-DD"),
        endDate: dayjs().format("YYYY-MM-DD"),
      }));
    }
  };
  const onMainFilterDateChange = (date) => {
    if (date == null || date == "") {
      setOverAllData((prevState) => ({
        ...prevState,
        startDate: dayjs().format("YYYY-MM-DD"),
        endDate: dayjs().format("YYYY-MM-DD"),
      }));
    } else {
      setOverAllData((prevState) => ({
        ...prevState,
        startDate: dayjs(date).format("YYYY-MM-DD"),
        endDate: dayjs(date).format("YYYY-MM-DD"),
      }));
    }
  };

  const TotalData = () => {
    axiosClient
      .get(
        `/dashboard1/getdashborddetailsforoverallevents?startDate=${overAllData.startDate}&endDate=${overAllData.endDate}`
      )
      .then((response) => {
        if (response.data.issuccess === true) {
          console.log("Overall TOTAL DATA:", response.data.data.overallTotal);
          setOverAllData((prev) => ({
            ...prev,
            Total:
              response.data.data.overallTotal > 0
                ? response.data.data.overallTotal
                : 0,
          }));
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((err) => {
        console.error("Error fetching overall events:", err);
      });
  };

  const [camera, setCamera] = useState([]);

  // useEffect(() => {
  //   axiosClient
  //     .get(
  //       "/dashboard1/getdashborddetailsforgraph?startDate=&endDate=&cameraId=0"
  //     )
  //     .then((response) => {
  //       if (response.data.issuccess === true) {
  //         const graphData = response.data.data.data;
  //         const categories = graphData.map((item) => Object.keys(item)[0]);
  //         const data = graphData.map((item) => Object.values(item)[0]);

  //         setChartOptions((prevOptions) => ({
  //           ...prevOptions,
  //           xaxis: { categories },
  //         }));
  //         setChartSeries([{ name: "Graph", data }]);

  //         //console.log("Graph Data:", graphData);
  //       } else {
  //         console.log("Something went wrong");
  //       }
  //     })
  //     .catch((err) => {
  //       console.error("Error fetching graph data:", err);
  //     });
  // }, [2000]);

  useEffect(() => {
    monthlyData();
  }, [selectedMonth]);

  function monthlyData() {
    axiosClient
      .get(`/dashboard1/getmonthlydashborddetails?month=${selectedMonth}`)
      .then((response) => {
        if (response.data.issuccess === true) {
          console.log("Monthly Count----------", response.data.data);
          setMonthlyCount(response.data.data > 0 ? response.data.data : 0);
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((err) => {
        console.error("Error fetching graph data:", err);
      });
  }

  const handleMonthChange = (date) => {
    if (date) {
      // Format the selected month to 'YYYY-MM'
      const formattedDate = date.startOf("month").format("YYYY-MM");
      console.log(formattedDate);
      setSelectedMonth(formattedDate);
    } else {
      setSelectedMonth(initialDate);
    }
  };
  const disabledmonth = (current) => {
    const createdDate = authService.getCreatedDate();
    const today = dayjs();

    return createdDate
      ? current < dayjs(createdDate).startOf("month") ||
          current > today.endOf("month")
      : false;
  };
  // const getCountByType = (type) => {
  //   const event = allData.find(item => item.type === type);
  //   return event ? event.count : 0; // Return 0 if not found
  // };
  const getEventDataByType = (type) => {
    const event = allData.find((item) => item.type === type);
    return event
      ? { count: event.count, id: event.id }
      : { count: 0, id: null };
  };
  const trespassingData = getEventDataByType("Trespassing");
  const Fire_DetectionData = getEventDataByType("Fire_Detection");
  const Human_Zone_Crossingdata = getEventDataByType("Human_Zone_Crossing");
  const Safety_Equipments_Detectiondata = getEventDataByType(
    "Safety_Equipments_Detection"
  );
  const Vehicle_Zone_CrossingData = getEventDataByType("Vehicle_Zone_Crossing");
  return (
    <div>
      <div
        style={{
          padding: "2rem 1rem",
          display: "flex",
          flexDirection: "column",
          gap: "0.85rem",
        }}
      >
        <Row
          gutter={[16, 16]}
          justify="center"
          style={{ display: "flex", flexWrap: "warp" }}
        >
          <Col xs={24} sm={12} md={70} lg={6}>
            <Card
              style={{
                height: "11rem",
                padding: "0.5rem",
                overflow: "hidden",
                position: "relative",
              }}
            >
              <Row align="middle" justify="space-between">
                <Col
                  xs={16}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <Title
                    level={5}
                    type="secondary"
                    style={{
                      margin: 0, // Set margin to zero
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    Monthly Total
                  </Title>
                  <Text
                    style={{
                      color: "black",
                      fontWeight: "900",
                      fontSize: "3rem",
                      lineHeight: 1.2,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      margin: 0, // Set margin to zero
                    }}
                  >
                    {monthlyCount}
                  </Text>
                  <DatePicker
                    picker="month"
                    value={dayjs(selectedMonth, "YYYY-MM")}
                    onChange={(date) => {
                      handleMonthChange(date);
                    }}
                    disabledDate={disabledmonth}
                    className="custom-date-picker"
                    style={{
                      width: "100%",
                      maxWidth: "7rem",
                      margin: 0, // Set margin to zero
                      paddingTop: "0.2rem", // Optional: add slight spacing above the DatePicker
                    }}
                  />
                </Col>

                <Col
                  xs={8}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    overflow: "hidden",
                  }}
                >
                  <Image
                    style={{ width: "3rem", objectFit: "contain" }}
                    src={month}
                    preview={false}
                  />
                </Col>
              </Row>
            </Card>
          </Col>

          <Col xs={24} sm={12} md={70} lg={6}>
            <Card style={{ height: "11rem", padding: "1rem" }}>
              <Row align="middle" justify="space-between">
                <Col
                  xs={16}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Row
                    align="middle"
                    justify="space-between"
                    style={{ width: "100%" }}
                  >
                    <Col>
                      <Title level={5} type="secondary" style={{ margin: 0 }}>
                        {overAllData.selectFilter === 2
                          ? "Weekly Total"
                          : overAllData.selectFilter === 3
                          ? "15 Days Total"
                          : overAllData.selectFilter === 4
                          ? "45 Days Total"
                          : "Single Day Total"}
                      </Title>
                      <span
                        style={{
                          color: "black",
                          fontWeight: "900",
                          fontSize: "3rem",
                          lineHeight: 1.2,
                          margin: 0,
                        }}
                      >
                        {overAllData.Total}
                      </span>
                    </Col>

                    <Select
                      style={{
                        width: "100%",
                        maxWidth: "9rem",
                        margin: 0,
                      }}
                      defaultValue={2}
                      onChange={onMainFilterChange}
                    >
                      <Option value={2}>Weekly</Option>
                      <Option value={3}>Last 15 days</Option>
                      <Option value={4}>Last 45 days</Option>
                      <Option value={1}>Single Day</Option>
                    </Select>
                  </Row>
                </Col>
                <Col
                  xs={8}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Image
                    style={{ width: "4rem", objectFit: "contain" }}
                    src={object}
                    preview={false}
                  />
                </Col>
              </Row>
            </Card>
          </Col>

          <Col xs={24} sm={12} md={70} lg={6}>
            <Card style={{ height: "11rem" }}>
              <Row align="middle" justify="space-between">
                <Col>
                  <Title level={5} type="secondary">
                    Today Total
                  </Title>
                  <Text
                    style={{
                      color: "black",
                      fontWeight: "900",
                      fontSize: "3.5rem",
                    }}
                  >
                    {overAll}
                  </Text>
                </Col>
                <Col>
                  <Image
                    style={{ width: "4rem", objectFit: "contain" }}
                    src={total}
                    preview={false}
                  />
                </Col>
              </Row>
            </Card>
          </Col>

          <Col xs={24} sm={12} md={70} lg={6}>
            <Card
              style={{ height: "11rem" }}
              // onClick={handleClick1}
              onClick={() => {
                if (Fire_DetectionData.count > 0) {
                  Firedetection(Fire_DetectionData.id);
                } else {
                  notification.error({
                    message: "No Data",
                    description:
                      "There is no data available for Fire_Detection.",
                    duration: 2,
                  });
                }
              }}
              // onClick={() => handleClick1(Fire_DetectionData.id)}
            >
              <Row align="middle" justify="space-between">
                <Col>
                  <Title level={5} type="secondary">
                    Fire Detection
                  </Title>
                  <Text
                    style={{
                      color: "black",
                      fontWeight: "900",
                      fontSize: "3.5rem",
                    }}
                  >
                    {Fire_DetectionData.count}
                    {/* {getCountByType("Fire_Detection")} */}
                  </Text>
                </Col>
                <Col>
                  <Image
                    style={{ width: "4rem", objectFit: "contain" }}
                    src={fire}
                    preview={false}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

        <Row gutter={[16, 16]} justify="center">
          <Col xs={24} sm={12} md={70} lg={6}>
            <Card
              style={{ height: "11rem" }}
              // onClick={handleClick2}
              onClick={() => {
                if (Safety_Equipments_Detectiondata.count > 0) {
                  safetyEquipments(Safety_Equipments_Detectiondata.id);
                } else {
                  notification.error({
                    message: "No Data",
                    description:
                      "There is no data available for TrespSafety_Equipments_Detection.",
                    duration: 2,
                  });
                }
              }}
              // onClick={() => handleClick2(Safety_Equipments_Detectiondata.id)}
            >
              <Row align="middle" justify="space-between">
                <Col xs={{ span: 18, offset: 0 }} sm={{ span: 16, offset: 0 }}>
                  <Title
                    level={5}
                    type="secondary"
                    style={{ wordWrap: "break-word" }}
                  >
                    Safety Equipments Detection
                  </Title>
                  <Text
                    style={{
                      color: "black",
                      fontWeight: "900",
                      fontSize: "3.5rem",
                    }}
                  >
                    {Safety_Equipments_Detectiondata.count}
                    {/* {getCountByType("Safety_Equipments_Detection")} */}
                    {/* {allData.Safety_Equipments_Detection} */}
                  </Text>
                </Col>
                <Col>
                  <Image
                    style={{ width: "4rem", objectFit: "contain" }}
                    src={ppe}
                    preview={false}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={70} lg={6}>
            <Card
              style={{ height: "11rem" }}
              onClick={() => {
                if (trespassingData.count > 0) {
                  Trespassing(trespassingData.id);
                } else {
                  notification.error({
                    message: "No Data",
                    description: "There is no data available for Trespassing.",
                    duration: 2,
                  });
                }
              }}
            >
              <Row align="middle" justify="space-between">
                <Col>
                  <Title level={5} type="secondary">
                    Trespassing
                  </Title>
                  <Text
                    style={{
                      color: "black",
                      fontWeight: "900",
                      fontSize: "3.5rem",
                    }}
                  >
                    {trespassingData.count}
                  </Text>
                </Col>
                <Col>
                  <Image
                    style={{ width: "4rem", objectFit: "contain" }}
                    src={tressp}
                    preview={false}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={70} lg={6}>
            <Card
              style={{ height: "11rem" }}
              //  onClick={handleClick3}
              // onClick={() => handleClick3(Human_Zone_Crossingdata.id)}
              onClick={() => {
                if (Human_Zone_Crossingdata.count > 0) {
                  HumnZone(Human_Zone_Crossingdata.id);
                } else {
                  notification.error({
                    message: "No Data",
                    description:
                      "There is no data available for Human Zone Crossing.",
                    duration: 2,
                  });
                }
              }}
            >
              <Row align="middle" justify="space-between">
                <Col xs={{ span: 12, offset: 0 }} sm={{ span: 15, offset: 0 }}>
                  <Title level={5} type="secondary">
                    Human-Zone Crossing
                  </Title>
                  <Text
                    style={{
                      color: "black",
                      fontWeight: "900",
                      fontSize: "3.5rem",
                    }}
                  >
                    {Human_Zone_Crossingdata.count}
                    {/* {getCountByType("Human_Zone_Crossing")} */}
                  </Text>
                </Col>
                <Col>
                  <Image
                    style={{ width: "4rem", objectFit: "contain" }}
                    src={humanVehicle}
                    preview={false}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={70} lg={6}>
            <Card
              style={{ height: "11rem" }}
              // onClick={handleClick4}
              onClick={() => {
                if (Vehicle_Zone_CrossingData.count > 0) {
                  Vehiclezone(Vehicle_Zone_CrossingData.id);
                } else {
                  notification.error({
                    message: "No Data",
                    description:
                      "There is no data available for vehicle Zone Crossing.",
                    duration: 2,
                  });
                }
              }}
              // onClick={() => handleClick4(Vehicle_Zone_CrossingData.id)}
            >
              <Row align="middle" justify="space-between">
                <Col xs={{ span: 12, offset: 0 }} sm={{ span: 15, offset: 0 }}>
                  <Title level={5} type="secondary">
                    Vehicle-Zone Crossing
                  </Title>
                  <Text
                    style={{
                      color: "black",
                      fontWeight: "900",
                      fontSize: "3.5rem",
                    }}
                  >
                    {Vehicle_Zone_CrossingData.count}
                    {/* {getCountByType("Vehicle_Zone_Crossing")} */}
                  </Text>
                </Col>
                <Col>
                  <Image
                    style={{ width: "4rem", objectFit: "contain" }}
                    src={vehicle}
                    preview={false}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
      {/* <hr style={{ color: "#f2f2f2" }} /> */}
      <Divider />
      <div style={{ padding: "2rem 1rem", boxSizing: "border-box" }}>
        <Row gutter={[16, 16]} justify="center">
          <Col xs={24} sm={24} md={24} lg={16}>
            <Card
              style={{ width: "xs: 100%, md: 80%, lg: 100%" }}
              title={
                <Row
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Title level={4} type="secondary">
                    Camera-Wise Report({filterCam})
                  </Title>
                  <Text>Total detection:{chartState.Total}</Text>
                  <Row style={{ display: "flex", gap: "1rem" }}>
                    <Select
                      placeholder="camera"
                      value={cam}
                      style={{ width: 120 }}
                      onChange={cameraChange}
                    >
                      <Option value={0}>OverAll</Option>
                      {camera.map((item) => {
                        return (
                          <Option value={item.cameraId}>
                            {item.cameraName}
                          </Option>
                        );
                      })}
                    </Select>
                    {filterCam == "Select-Date" ? (
                      <RangePicker
                        onChange={(dates) => {
                          if (dates) {
                            setSelectedDate(dates);
                          } else {
                            setSelectedDate("");
                          }
                        }}
                        disabledDate={disabledDate}
                      />
                    ) : (
                      ""
                    )}
                    <Select
                      value={filterCam}
                      style={{ width: 120 }}
                      onChange={(value) => {
                        setFilterCam(value);
                      }}
                    >
                      <Option value="OverAll">OverAll</Option>
                      <Option value="Weekly-wise">Weekly Data</Option>
                      <Option value="15Days">15 Days Data</Option>
                      <Option value="Monthly-wise">Monthly Data</Option>
                      <Option value="Select-Date">Select Date</Option>
                    </Select>
                  </Row>
                </Row>
              }
            >
              <div>
                <Chart
                  options={chartState.options}
                  series={chartState.series}
                  type="bar"
                  width="100%"
                  height="300"
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default DashboardMain;
