// import React, { useState, useRef, useEffect } from "react";
// import { FaRegDotCircle, FaBars } from "react-icons/fa";
// import { Drawer, Button } from "antd";
// import Live from "./Live";
// import Weekly from "./Weekly";
// import FloorMap from "./FloorMap";

// const FootfallMall = () => {
//   const [activeKey, setActiveKey] = useState("1");
//   const [isDrawerOpen, setIsDrawerOpen] = useState(false);
//   const [isDesktop, setIsDesktop] = useState(window.innerWidth > 850);
//   const contentRef = useRef(null);

//   const handleTabChange = (key) => {
//     setActiveKey(key);
//     setIsDrawerOpen(false); // Close drawer on tab change
//   };

//   const handleKeyPress = (event) => {
//     if (contentRef.current) {
//       if (event.key === "PageDown") {
//         contentRef.current.scrollBy({ top: window.innerHeight, behavior: "smooth" });
//       }
//       if (event.key === "PageUp") {
//         contentRef.current.scrollBy({ top: -window.innerHeight, behavior: "smooth" });
//       }
//       if (event.key === "ArrowDown") {
//         contentRef.current.scrollBy({ top: 100, behavior: "smooth" });
//       }
//       if (event.key === "ArrowUp") {
//         contentRef.current.scrollBy({ top: -100, behavior: "smooth" });
//       }
//     }
//   };

//   const updateScreenSize = () => {
//     setIsDesktop(window.innerWidth > 850);
//   };

//   useEffect(() => {
//     window.addEventListener("resize", updateScreenSize);
//     window.addEventListener("keydown", handleKeyPress);
//     return () => {
//       window.removeEventListener("resize", updateScreenSize);
//       window.removeEventListener("keydown", handleKeyPress);
//     };
//   }, []);

//   return (
//     <div style={{ display: "flex", height: "100vh", width: "100vw" }}>
//       {isDesktop ? (
//         <div
//           style={{
//             width: "12%",
//             backgroundColor: "#ffffff",
//             borderRight: "1px solid rgba(0,0,0,0.25)",
//             position: "sticky",
//             height: "100vh",
//           }}
//         >
//           <h1 style={{ width: "100%", textAlign: "center" }}>Dashboard</h1>
//           <ul
//             style={{
//               listStyle: "none",
//               padding: 0,
//               width: "100%",
//               display: "flex",
//               alignItems: "center",
//               flexDirection: "column",
//             }}
//           >
//             <li onClick={() => handleTabChange("1")} style={{ cursor: "pointer", padding: "1rem", backgroundColor: activeKey === "1" ? "#f0f0f0" : "#ffffff", width: "100%", textAlign: "start", fontWeight: "bold", color: activeKey === "1" ? "#1677ff" : "black", fontSize: "18px", height: "3.6rem" }}>
//               <p style={{ display: "flex", alignItems: "center", gap: "7px" }}>
//                 Live <FaRegDotCircle color="green" />
//               </p>
//             </li>
//             <li onClick={() => handleTabChange("2")} style={{ cursor: "pointer", padding: "1rem", backgroundColor: activeKey === "2" ? "#f0f0f0" : "#ffffff", width: "100%", textAlign: "start", fontWeight: "bold", color: activeKey === "2" ? "#1677ff" : "black", fontSize: "18px" }}>
//               Analytics
//             </li>
//             <li onClick={() => handleTabChange("3")} style={{ cursor: "pointer", padding: "1rem", backgroundColor: activeKey === "3" ? "#f0f0f0" : "#ffffff", width: "100%", textAlign: "start", fontWeight: "bold", color: activeKey === "3" ? "#1677ff" : "black", fontSize: "18px" }}>
//               Floor Map
//             </li>
//           </ul>
//         </div>
//       ) : (
//         <>
//           <Button
//             icon={<FaBars />}
//             type="text"
//             onClick={() => setIsDrawerOpen(true)}
//             style={{ fontSize: "24px", padding: "1rem" }}
//           />
//           <Drawer
//             title="Dashboard"
//             placement="left"
//             onClose={() => setIsDrawerOpen(false)}
//             visible={isDrawerOpen}
//             width="75%"
//           >
//             <ul style={{ listStyle: "none", padding: 0 }}>
//               <li onClick={() => handleTabChange("1")} style={{ cursor: "pointer", padding: "1rem", backgroundColor: activeKey === "1" ? "#f0f0f0" : "#ffffff", fontWeight: "bold", color: activeKey === "1" ? "#1677ff" : "black", fontSize: "18px" }}>
//                 <p style={{ display: "flex", alignItems: "center", gap: "7px" }}>
//                   Live <FaRegDotCircle color="green" />
//                 </p>
//               </li>
//               <li onClick={() => handleTabChange("2")} style={{ cursor: "pointer", padding: "1rem", backgroundColor: activeKey === "2" ? "#f0f0f0" : "#ffffff", fontWeight: "bold", color: activeKey === "2" ? "#1677ff" : "black", fontSize: "18px" }}>
//                 Analytics
//               </li>
//               <li onClick={() => handleTabChange("3")} style={{ cursor: "pointer", padding: "1rem", backgroundColor: activeKey === "3" ? "#f0f0f0" : "#ffffff", fontWeight: "bold", color: activeKey === "3" ? "#1677ff" : "black", fontSize: "18px" }}>
//                 Floor Map
//               </li>
//             </ul>
//           </Drawer>
//         </>
//       )}

//       <div ref={contentRef} style={{ width: isDesktop ? "88%" : "100%", height: "100vh", overflowY: "scroll" }}>
//         {activeKey === "1" && <Live />}
//         {activeKey === "2" && <Weekly />}
//         {activeKey === "3" && <FloorMap />}
//       </div>
//     </div>
//   );
// };

// export default FootfallMall;



import React, { useState, useRef, useEffect } from "react";
import { FaRegDotCircle, FaBars } from "react-icons/fa";
import { Drawer, Button } from "antd";
import Live from "./Live";
import Weekly from "./Weekly";
import FloorMap from "./FloorMap";

const FootfallMall = () => {
  const [activeKey, setActiveKey] = useState("1");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 850);
  const contentRef = useRef(null);

  const handleTabChange = (key) => {
    setActiveKey(key);
    setIsDrawerOpen(false); // Close drawer on tab change
  };

  const handleKeyPress = (event) => {
    if (contentRef.current) {
      if (event.key === "PageDown") {
        contentRef.current.scrollBy({ top: window.innerHeight, behavior: "smooth" });
      }
      if (event.key === "PageUp") {
        contentRef.current.scrollBy({ top: -window.innerHeight, behavior: "smooth" });
      }
      if (event.key === "ArrowDown") {
        contentRef.current.scrollBy({ top: 100, behavior: "smooth" });
      }
      if (event.key === "ArrowUp") {
        contentRef.current.scrollBy({ top: -100, behavior: "smooth" });
      }
    }
  };

  const updateScreenSize = () => {
    setIsDesktop(window.innerWidth > 850);
  };

  useEffect(() => {
    window.addEventListener("resize", updateScreenSize);
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("resize", updateScreenSize);
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  return (
    <div style={{ display: "flex", height: "100vh", width: "100vw" }}>
      {/* Mobile Drawer */}
      {!isDesktop && (
        <>
          <Button
            icon={<FaBars />}
            type="text"
            onClick={() => setIsDrawerOpen(true)}
            style={{
              fontSize: "24px",
              position: "absolute",
              top: "1rem",
              left: "1rem",
              zIndex: 1000,
              background: "transparent",
              border: "none",
            }}
          />
          <Drawer
            title="Dashboard"
            placement="left"
            onClose={() => setIsDrawerOpen(false)}
            visible={isDrawerOpen}
            width="75%"
          >
            <ul style={{ listStyle: "none", padding: 0 }}>
              <li onClick={() => handleTabChange("1")} style={{ cursor: "pointer", padding: "1rem", backgroundColor: activeKey === "1" ? "#f0f0f0" : "#ffffff", fontWeight: "bold", color: activeKey === "1" ? "#1677ff" : "black", fontSize: "18px" }}>
                <p style={{ display: "flex", alignItems: "center", gap: "7px" }}>
                  Live <FaRegDotCircle color="green" />
                </p>
              </li>
              <li onClick={() => handleTabChange("2")} style={{ cursor: "pointer", padding: "1rem", backgroundColor: activeKey === "2" ? "#f0f0f0" : "#ffffff", fontWeight: "bold", color: activeKey === "2" ? "#1677ff" : "black", fontSize: "18px" }}>
                Analytics
              </li>
              <li onClick={() => handleTabChange("3")} style={{ cursor: "pointer", padding: "1rem", backgroundColor: activeKey === "3" ? "#f0f0f0" : "#ffffff", fontWeight: "bold", color: activeKey === "3" ? "#1677ff" : "black", fontSize: "18px" }}>
                Floor Map
              </li>
            </ul>
          </Drawer>
        </>
      )}

      {/* Desktop Sidebar */}
      {isDesktop && (
        <div
          style={{
            width: "12%",
            backgroundColor: "#ffffff",
            borderRight: "1px solid rgba(0,0,0,0.25)",
            position: "sticky",
            height: "100vh",
          }}
        >
          <h1 style={{ width: "100%", textAlign: "center" }}>Dashboard</h1>
          <ul
            style={{
              listStyle: "none",
              padding: 0,
              width: "100%",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <li
              onClick={() => handleTabChange("1")}
              style={{
                cursor: "pointer",
                padding: "1rem",
                backgroundColor: activeKey === "1" ? "#f0f0f0" : "#ffffff",
                width: "100%",
                textAlign: "start",
                fontWeight: "400",
                color: activeKey === "1" ? "#1677ff" : "black",
                fontSize: "18px",
                fontWeight: "bold",
                height: "3.6rem",
              }}
            >
              <p style={{ display: "flex", alignItems: "center", gap: "7px" }}>
                Live <FaRegDotCircle color="green" />
              </p>
            </li>
            <li
              onClick={() => handleTabChange("2")}
              style={{
                cursor: "pointer",
                padding: "1rem",
                backgroundColor: activeKey === "2" ? "#f0f0f0" : "#ffffff",
                width: "100%",
                textAlign: "start",
                fontWeight: "400",
                color: activeKey === "2" ? "#1677ff" : "black",
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              Analytics
            </li>
            <li
              onClick={() => handleTabChange("3")}
              style={{
                cursor: "pointer",
                padding: "1rem",
                backgroundColor: activeKey === "3" ? "#f0f0f0" : "#ffffff",
                width: "100%",
                textAlign: "start",
                fontWeight: "400",
                color: activeKey === "3" ? "#1677ff" : "black",
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              Floor Map
            </li>
          </ul>
        </div>
      )}

      {/* Content Container */}
      <div ref={contentRef} style={{ width: isDesktop ? "88%" : "100%", height: "100vh", overflowY: "scroll" }}>
        {activeKey === "1" && <Live />}
        {activeKey === "2" && <Weekly />}
        {activeKey === "3" && <FloorMap />}
      </div>
    </div>
  );
};

export default FootfallMall;
