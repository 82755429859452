import React, { useState, useEffect } from "react";
import { Component } from "react";
import {
  UserOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from "@ant-design/icons";
import { PlusOutlined } from "@ant-design/icons";
import { MinusOutlined } from "@ant-design/icons";
import {
  Label,
  Input,
  Select,
  Space,
  Cascader,
  Form,
  Checkbox,
  message,
  Typography,
  Alert,
  Skeleton,
} from "antd";
import { SettingOutlined } from "@ant-design/icons";
import { InputNumber } from "antd";
import { Button, Radio } from "antd";
import { Row, Col, Card, Switch, DatePicker } from "antd";
import { createFromIconfontCN } from "@ant-design/icons";
import { Layout, Menu, Breadcrumb } from "antd";
import { PageHeader } from "antd";
import { Image } from "antd";
import { BrowserRouter as Router, Route, Link, Await } from "react-router-dom";
import axiosClient from "../../Authentication/ApiCall";
import authService from "../../Authentication/authService";
import moment from "moment/moment";
// import CameraAccessList from "./CameraAccessList";
// import EventSetupList from "./EventSetupList";
import ReactLasso from "react-lasso-select";
import { Footer } from "antd/lib/layout/layout";
import axios from "axios";

const { Option } = Select;
const { Title, Text } = Typography;




// unused files
function HVcrossing() {
  document.title = "KEN - Event Setup";
  const [form] = Form.useForm();
  const [AddRefresh, setAddRefresh] = useState(1);
  const [UserList, setUserList] = useState([]);
  const [events, setevents] = useState([]);
  const [displaymarker, setdisplaymarker] = useState(true);
  const [src, setsrc] = useState("./pic1.jpg");

  const [src1] = useState("./index.jpeg");
  const [img, setImg] = useState({ width: 100, height: 100 });
  const [width, setWidth] = useState(400);
  const [points, setpoints] = useState([]);
  const [disablefield, setdisablefield] = useState(true);
  const [image, setimage] = useState(false);
  const [loadingImage, setloadingImage] = useState(true);
  const [cameraId, setcameraId] = useState();
  const [baseImage, setbaseImage] = useState(null);
  const [showThreshold, setshowThreshold] = useState(true);
  const [cameralist, setcameralist] = useState([]);
  const [RTSPtoserevr, setRTSPtoserevr] = useState();
  const [Threshold, setThreshold] = useState([
    0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1,
  ]);
  useEffect(() => {
    axiosClient
      .get("/camera/get_cameraList")
      .then((response) => {
        if (response.status === 200) {
          setcameralist(response.data.data);
        } else {
          console.log("something went wrong");
        }
      })
      .catch((err) => {
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });

    
  }, []);

  const onFinish = (values) => {
    axiosClient
      .post("/cameraEvents/addCameraEvents", {
        cameraId: values.cameralist,
        eventId: values.events,
        coordinates: points,
        cam_images: baseImage,
        threshold: values.threshold,
      })
      .then((response) => {
        if (response.data.issuccess === true) {
          message.success(response.data.msg);
          setAddRefresh(AddRefresh + 1);
        }
      })
      .catch((err) => {
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
    form.resetFields();
    setshowThreshold(true);
    setdisplaymarker(true);
  };

  async function properties(e) {
    if (e === 1 || e === 5) {
      // form.setFieldsValue({ marker: [] });
      setshowThreshold(false);
      setdisplaymarker(false);
      setbaseImage("");
    } else {
      setshowThreshold(true);
      form.setFieldsValue({ threshold: 0 });
    }

    if (e == 5) {
      let CamRTSP = null;

      cameralist.map((item) => {
        if (item.id == cameraId) {
          CamRTSP = item.RTSP;
        }
      });

      if (CamRTSP != null && CamRTSP != "" && CamRTSP != undefined) {
        setRTSPtoserevr(CamRTSP.trim());
        await axios
          .post("http://192.168.79.178:2010/vf_frame", { cam: CamRTSP.trim() })
          .then((response) => {
            if (response.status == 200) {
              function update(response) {
                setbaseImage(response);

                setimage("data:image/png;base64," + response.replace());

                // setdisplaymarker(true);
                setloadingImage(false);
              }
              update(response.data);

              // image.replace(/\"/g, "");
            } else {
              console.log("err");
            }
          })
          .catch((rr) => {
            console.log("rrr", rr);
          });
        setdisplaymarker(false);
      } else {
        setdisplaymarker(true);
      }
    } else {
      setdisplaymarker(true);
    }
  }

  return (
    <Row className="top-space8 " gutter={[15, 10]}>
      <Col
        xs={{ span: 24, offset: 0 }}
        lg={{ span: 2, offset: 0 }}
        xl={{ span: 10, offset: 0 }}
      >
        <Card
          hoverable
          bordered={true}
          title="Details for Human-Vehicle Crossing"
        >
          <Form
            form={form}
            name="basic"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 22,
            }}
            initialValues={
              {
                // remember: true,
              }
            }
            onFinish={onFinish}
            // onFinishFailed={(e) => {
            //   message.error("failed");
            // }}
            autoComplete="off"
          >
            <Form.Item
              className="top-space"
              label="Camera List"
              name="cameralist"
              rules={[
                {
                  type: "number",
                  required: true,
                  message: "Please select!",
                },
              ]}
            >
              <Select
                // mode="multiple"
                onChange={(e) => {
                 
                  setcameraId(e);
                  setdisablefield(false);
                  setpoints([]);
                  setdisplaymarker(true);
                }}
                allowClear
                style={{
                  width: "100%",
                }}
                placeholder="Please select Camera's to give access"
              >
                {cameralist.map((option) => (
                  <Option key={option.id} value={option.id}>
                    {option.cameraName}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              // className="top-space"
              label="Camera Events"
              name="events"
              rules={[
                {
                  required: true,
                  message: "Please Select Camera Events!",
                },
              ]}
            >
              <Select
                disabled={disablefield}
                // mode="multiple"
                onChange={properties}
                // mode="multiple"
                allowClear
                style={{
                  width: "100%",
                }}
                placeholder="Please select Events for the camera"
                // defaultValue={["a10", "c12"]}
                // onChange={handleChange}
              >
                {events.map((option) => (
                  <Option key={option.id} value={option.id}>
                    {option.event_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              hidden={showThreshold}
              className="top-space"
              label="Threshold"
              name="threshold"
              rules={[
                {
                  type: "number",
                  required: true,
                  message: "Please select!",
                },
              ]}
            >
              <Select
               

                allowClear
                style={{
                  width: "100%",
                }}
                placeholder="Please select Threshold for Anomaly"
              >
                {/* {cameralist.map((option) => (
                  <Option key={option.id} value={option.id}>
                    {option.cameraName}
                  </Option>
                ))} */}
                {/* <Option key={0} value={0}>
                  0
                </Option> */}
                {Threshold.map((value, index) => {
                  return (
                    <Option key={index} value={value}>
                      {value}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item
              hidden={displaymarker}
              label="Mark the Area"
              name="marker"
              tooltip="Mark the area by clicking Mouse, Complete the event with connecting to the Initial Point"
              rules={[
                {
                  type: "array",
                  required: false,
                  message: "Please select!",
                },
              ]}

              // children={{(e)=>{
              //   return(<Button
              //     onClick={(e) => {
              //       setpoints([]);
              //     }}
              //     style={{ width: "70%" }}
              //     type="danger"
              //     danger
              //   >
              //     Reset
              //   </Button>)
              // }}}
            >
              <Skeleton loading={loadingImage} active round>
                <ReactLasso
                  // src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
                  // src="../index1.jpeg"
                  // src={"data:image/png;base64," + image.replace(/\"/g, "")}
                  // src={image.replace(/\"/g, "")}
                  // src={image ? image.replace(/\"/g, "") : "../index1.jpeg"}
                  // src={image.replace(/\"/g, "")}
                  src={image}
                  imageAlt="image"
                  value={points}
                  // disabled
                  // viewBox={{ width: 20, height: 20 }}
                  // src={src1}
                  onChange={(path) => {
                    setpoints(path);
                  }}
                  imageStyle={{ width: "100%" }}
                  onImageLoad={(e) => {
                    const img = e.target;
                    setImg({
                      width: img.naturalWidth,
                      height: img.naturalHeight,
                    });
                  }}
                />
            
                <Col
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    margin: "5px",
                  }}
                >
                  <Button
                    onClick={(e) => {
                      setpoints([]);
                    }}
                    type="danger"
                    danger
                  >
                    Reset
                  </Button>
                </Col>

                <Alert
                  message="Please Refer the Below Image"
                  type="info"
                  showIcon
                  style={{
                    width: "100%",
                    height: "30px",
                    marginBottom: "0.5em",
                  }}
                />
                <Image src="../sample.png" />

                {/* </Image> */}
              </Skeleton>
            </Form.Item>
            <Form.Item
              wrapperCol={{
                xs: { span: 24, offset: 0 },
                sm: { span: 12, offset: 8 },
                md: { span: 12, offset: 8 },
                lg: { span: 12, offset: 8 },
                xl: { span: 8, offset: 8 },
              }}
            >
              <Button
                style={{ backgroundColor: "#3e3a75", border: "none" }}
                type="primary"
                block
                htmlType="submit"
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>

      <Col
        xs={{ span: 24, offset: 0 }}
        lg={{ span: 2, offset: 0 }}
        xl={{ span: 14, offset: 0 }}
      >
        <Card hoverable bordered={true} title="Existing List">
          {/* <EventSetupList props={AddRefresh} /> */}
        </Card>
      </Col>
    </Row>
  );
}

export default HVcrossing;
