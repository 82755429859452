import {
  Row,
  Space,
  Table,
  Tag,
  Col,
  Card,
  Image,
  Modal,
  Skeleton,
  DatePicker,
  Button,
  Alert,
  Typography,
  message,
  Form,
  Select,
  Collapse,
  Dropdown,
  Radio,
  Tooltip,
  Timeline,
  List,
  Empty,
  Spin,
  Progress,
  Result,
  Avatar,
  notification,
  Flex,
  Tabs,
} from "antd";
import ANPRreport from "./ANPRreport";
import FootfallReport from "./FootfallReport";
import ReactPlayer from "react-player";
import axios from "axios";
import { TbReportAnalytics, TbAlertTriangle } from "react-icons/tb";
import { GoVerified } from "react-icons/go";

// import
// import authService from "../Authentication/authService";
import TabPane from "antd/lib/tabs/TabPane";
import {
  PlayCircleOutlined,
  UserOutlined,
  DownOutlined,
  DownloadOutlined,
  HistoryOutlined,
  LoadingOutlined,
  EyeOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { MultiSelect } from "react-multi-select-component";
// import { Player } from "video-react";
import React, { useRef } from "react";
import { ImLocation2 } from "react-icons/im";
import { IoCameraReverse } from "react-icons/io5";
import { MdAddLocationAlt } from "react-icons/md";
import axiosClient from "../../Authentication/ApiCall";
import { useEffect, useState } from "react";
import moment from "moment/moment";
import { toBeVisible } from "@testing-library/jest-dom/dist/matchers";
import { CSVLink } from "react-csv";
import ReactToPrint from "react-to-print";
import dayjs from "dayjs";
import { Player } from "video-react";
import { saveAs } from "file-saver";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import TextArea from "antd/lib/input/TextArea";
import authService from "../../Authentication/authService";
import { Loading } from "react-fullscreen-loading";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import { constrainPoint } from "@fullcalendar/react";
import { MdVideoChat } from "react-icons/md";

//   import ImageInfo from "./imageInfo";
import { FaFileDownload } from "react-icons/fa";
import { FaDownload } from "react-icons/fa6";
import { getInitialReportAPI } from "./Controller/ReportPageApicall";

import { RiUserLocationLine } from "react-icons/ri";
import { FaCameraRetro } from "react-icons/fa";
import { io } from "socket.io-client";
import { IoIosCamera } from "react-icons/io";
import OtherReports from "./OtherReports";
import { LuFileVideo2 } from "react-icons/lu";

const { RangePicker } = DatePicker;

const { Link, Title, Text } = Typography;
const { Option } = Select;
const Reports = () => {
  document.title = "KEN - REPORT";
  const [activeKey, setActiveKey] = useState("1");

  // Effect for when the active tab changes
  useEffect(() => {
    console.log(`Active tab changed to: ${activeKey}`);
  }, [activeKey]);

  const ReportItems = [
    {
      key: "1",
      label: "Footfall",
      children: <FootfallReport />,
    },
    // {
    //   key: "2",
    //   label: "ANPR",
    //   children: <ANPRreport />,
    // },
    // {
    //   key: "3",
    //   label: "Others",
    //   children: <OtherReports />,
    // },
  ];

  return (
    <div
      style={{
        backgroundColor: "#f7f7f7",
        height: "100vh",
        // overflowY: "hidden",
      }}
    >
      {/* Your content here */}

      <Row gutter={[10, 10]} justify="center" style={{ rowGap: "0" }}>
        <Col xs={24}>
          <Row gutter={[10, 10]}>
            <Col
              xs={24}
              style={{
                backgroundColor: "#000716",
                width: "100%",
                overflowX: "hidden",
              }}
            >
              <Row align="middle" justify="center" gutter={[20, 20]}>
                <Col xs={23}>
                  <Row align="middle" justify="space-between">
                    {/* <Col>
                      <Title style={{ color: "white" }} level={4}>
                        Reports
                      </Title>
                    </Col> */}
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>

        <Col xs={24} style={{ width: "100%", padding: 0 }}>
          <Tabs activeKey={activeKey} onChange={setActiveKey} tabPosition="top">
            {ReportItems.map((item) => (
              <TabPane tab={item.label} key={item.key}>
                {item.children}
              </TabPane>
            ))}
          </Tabs>
        </Col>
      </Row>
    </div>
  );
};

export default Reports;
