import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  Col,
  Row,
  Select,
  Typography,
  Tabs,
  Modal,
  message,
  Table,
  Button,
  Spin,
  Alert,
  Result,
  Form,
  Radio,
  Space,
  Tag,
  Divider,
  notification,
  Progress,
  Avatar,
  List,
  Menu,
  Dropdown,
  Tooltip,
  Image,
  Skeleton,
  Empty,
  Flex,
  TimePicker,
  Drawer,
} from "antd";
import dayjs from "dayjs";
import { DatePicker } from "antd";
import moment from "moment/moment";
import Chart from "react-apexcharts";
import axiosClient from "../../../Authentication/ApiCall";
import randomColor from "randomcolor";
import { MdOutlineDirections, MdOutlinePeopleAlt } from "react-icons/md";
import { RxLapTimer } from "react-icons/rx";
import {
  AiOutlineHistory,
  AiOutlineUserSwitch,
  AiTwotoneEdit,
} from "react-icons/ai";
import _, { filter, find, flatMap, map, orderBy, values } from "lodash";
import { IoIosSearch, IoMdCloseCircle } from "react-icons/io";
import { LuFilter } from "react-icons/lu";
import ReactApexChart from "react-apexcharts";
import { Carousel } from "react-responsive-carousel";
import { GrDirections } from "react-icons/gr";
import "../../../../font/Helvetica-Bold.ttf";
import "../../../../font/Helvetica.ttf";
import { PDFExport } from "@progress/kendo-react-pdf";
import heat from "./image/image_223.jpg"

const { RangePicker } = DatePicker;
const { Option } = Select;

const { Title, Link, Text } = Typography;

let RenderComp = {
  IngressEgress: false,
  DwellFootfall: false,
  EngagedBounced: false,
  AgeGender: false,
  PeakHours: false,
};
let EngaedAndBouncedDetails = {
  CameraData: null,
  ZoneData: null,
};
let UniqueHourlyDetails = {
  camlist: [],
  selectedcam: "",
  selectedDate: dayjs().format("YYYY-MM-DD"),
  DataOfDate: null,
  selectedcameraname: null,
};
let DwellHourlyDetails = {
  camlist: [],
  selectedcam: "",
  selectedDate: dayjs().format("YYYY-MM-DD"),
  DataOfDate: null,
  selectedcameraname: null,
};
let weeklybouncedDetails = {
  SelectedOption: "Weekly",
  StartDate: dayjs().startOf("month").format("YYYY-MM-DD"),
  EndDate: dayjs().endOf("month").format("YYYY-MM-DD"),
  search: false,
};
let PeakHoursDetails = {
  SelectedOption: "Weekly",
  StartDate: dayjs().startOf("month").format("YYYY-MM-DD"),
  EndDate: dayjs().endOf("month"),
  search: false,
};
let FootfallDirectionHourlyDetails = {
  camlist: [],
  selectedcam: "",
  selectedcamName: "",
  selectedDate: dayjs().subtract(9,"day").format("YYYY-MM-DD"),
  DataOfDate: "",
  DirectionImage: null,
  DirectionData: [],
  loading: false,
  Hour: "04", // moment().hours(),
};

let IngressEgressDetails = {
  SelectedDate: dayjs().subtract(1, "day").format("YYYY-MM-DD"),
  ContinueFetch: true,
};

const Weekly = () => {
  const [RenderCompController, setRenderCompController] = useState(RenderComp);
  const targetRef = useRef();
  const containerRef = useRef(null);
  const [DownloadDashboard, setDownloadDashboard] = useState(false);
  const [selectdwelltimedate, setselectdwelltimedate] = useState();
  const [dwelltimedata, setdwelltimedata] = useState("");
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [EngaedandBouncedController, setEngaedandBouncedController] = useState(
    []
  );
  const [UniqueHourlyChart, setUniqueHourlyChart] = useState({
    options: {
      chart: {
        id: "basic-bar",
        type: "bar",
        stacked: true,
        toolbar: { show: false },
        contextmenu: { enabled: false },
      },
      plotOptions: {
        bar: { horizontal: false },
      },
      xaxis: {
        categories: [],
        title: { text: "Hour ", offsetY: -10 },
      },
      yaxis: { title: { text: "count" } },
      legend: {
        position: "top",
        horizontalAlign: "center",
        offsetX: 0,
      },
      colors: ["#FF5733", "#C70039", "#900C3F", "#FFC300", "#DAF7A6"],
    },
    series: [],
  });

  const [EngagedandBouncedData, setEngagedandBouncedData] = useState(
    EngaedAndBouncedDetails
  );
  // const [chartFootfallAnalysis, setChartFootfallAnalysis] = useState({
  //   series: [],
  //   options: {
  //     chart: {
  //       type: "polarArea",
  //       animations: {
  //         enabled: false,
  //       },
  //     },
  //     labels: [],
  //     fill: {
  //       opacity: 0.8,
  //     },
  //     stroke: {
  //       width: 1,
  //     },
  //     colors: [
  //       "#00e396",
  //       "#feb019",
  //       "#ff4560",
  //       "#775dd0",
  //       "#008ffb",
  //       "#00d9e9",
  //       "#ff9f00",
  //     ],
  //   },
  // });
  const [chartFootfallAnalysis, setChartFootfallAnalysis] = useState({
    series: [],
    options: {
      chart: {
        height: 400,
        type: "radar",
        dropShadow: {
          enabled: true,
          blur: 1,
          left: 1,
          top: 1,
        },
      },
      stroke: {
        width: 2,
      },
      fill: {
        opacity: 0.2,
      },
      markers: {
        size: 4,
      },
      yaxis: {
        stepSize: 200,
      },
      xaxis: {
        categories: [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
          "Sunday",
        ],
      },
      tooltip: {
        y: {
          formatter: (val) => val,
        },
      },
    },
  });

  const [UniqueHourlyController, setUniqueHourlyController] =
    useState(UniqueHourlyDetails);
  const [DwellHourlyController, setDwellHourlyController] = useState({
    camlist: [],
    selectedcam: null,
    selectedDate: dayjs().subtract(1, "day").format("YYYY-MM-DD"),
    DataOfDate: null,
    selectedcameraname: null,
  });
  const [DwellHourly, setDwellHourly] = useState({
    options: {
      chart: {
        id: "basic-bar",
        type: "bar",
        stacked: true,
        toolbar: { show: false },
        contextmenu: { enabled: false },
        animations: {
          enabled: false,
        },
      },
      plotOptions: {
        bar: { horizontal: false },
      },
      xaxis: {
        categories: [],
        title: { text: "Hour ", offsetY: -10 },
      },
      yaxis: { title: { text: "Seconds" } },
      legend: {
        position: "top",
        horizontalAlign: "center",
        offsetX: 0,
      },
      colors: [
        "#00e396",
        "#feb019",
        "#ff4560",
        "#775dd0",
        "#008ffb",
        "#00d9e9",
        "#ff9f00",
      ],
      tooltip: {
        enabled: true,
        y: {
          formatter: function (val) {
            return val + " sec";
          },
        },
      },
    },
    series: [],
  });

  const [WeeklyBouncedController, setWeeklyBouncedController] =
    useState(weeklybouncedDetails);

  const [selectedbounceMenu, setSelectedbounceMenu] = useState("Weekly");
  const [showRangePickerforBounce, setShowRangePickerforBounce] =
    useState(false);
  const [Engagersweeklychart, setEngagersweeklychart] = useState({
    options: {
      series: [
        {
          name: "Engagers",
          data: [],
          // data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
        },
      ],
      chart: {
        height: 350,
        type: "bar",
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },

      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: [],
        title: { text: "Date ", offsetY: -10 },
      },
      yaxis: { title: { text: "Count" } },
    },
  });
  const [Bouncedweeklychart, setBouncedweeklychart] = useState({
    options: {
      series: [
        {
          name: "Bounced",
          data: [],
          // data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
        },
      ],

      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      xaxis: {
        categories: [],
        title: { text: "Date ", offsetY: -10 },
      },
      yaxis: { title: { text: "Count" } },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      colors: ["#00e396"],
    },
  });
  const [selecteddate, setselecteddate] = useState("");
  // const [chartDatapieGender, setChartDatapieGender] = useState({
  //   series: [],

  //   options: {
  //     plotOptions: {
  //       pie: {
  //         donut: {
  //           labels: {
  //             show: true,
  //           },
  //         },
  //       },
  //     },
  //     chart: {
  //       width: 380,
  //       type: "pie",
  //     },
  //     labels: ["Male", "Female"],
  //     responsive: [
  //       {
  //         breakpoint: 480,
  //         options: {
  //           chart: {
  //             width: 200,
  //           },
  //           legend: {
  //             position: "bottom",
  //           },
  //         },
  //       },
  //     ],
  //   },
  // });
  // const [chartDatapieAge, setChartDatapieAge] = useState({
  //   series: [],
  //   options: {
  //     plotOptions: {
  //       pie: {
  //         donut: {
  //           labels: {
  //             show: true,
  //           },
  //         },
  //       },
  //     },
  //     chart: {
  //       width: 380,
  //       type: "pie",
  //     },
  //     colors: [
  // "#F44336",
  // "#E91E63",
  // "#9C27B0",
  // "#255aee",
  // "#4eed9e",
  // "#078f4b",
  // "#6088d6",
  // "#FFC107",
  //     ],
  //     labels: [
  //       "0-10",
  //       "11-20",
  //       "21-30",
  //       "31-40",
  //       "41-50",
  //       "51-60",
  //       "61-70",
  //       "71-100",
  //     ],
  //     responsive: [
  //       {
  //         breakpoint: 480,
  //         options: {
  //           chart: {
  //             width: 200,
  //           },
  //           legend: {
  //             position: "bottom",
  //           },
  //         },
  //       },
  //     ],
  //   },
  // });
  const [chartDatapieGender, setChartDatapieGender] = useState({
    options: {
      labels: ["Male", "Female"],
      tooltip: {
        enabled: false,
        formatter: function(val) {
          return `percentage ${val}%`;
        },
      },
      dataLabels: {
        enabled: false // Disable showing values on hover
      }
     
    },
      
    series: [],
    
  });
  const [chartDatapieAge, setChartDatapieAge] = useState({
    options: {
      labels: [],
      colors: [
        "#F44336",
        "#E91E63",
        "#9C27B0",
        "#255aee",
        "#4eed9e",
        "#078f4b",
        "#6088d6",
        "#FFC107",
      ],
    },
    series: [],
  });
  const [datedata, setdatedata] = useState("");
  const [loading, setloading] = useState(true);
  const [selectedpickhourMenu, setSelectedpickhourMenu] = useState("Weekly");
  const [showRangePickerforpeakHour, setshowRangePickerforpeakHourr] =
    useState(false);
  const [pickhoursweeklyData, setpickhoursweeklydata] = useState({
    options: {
      chart: {
        height: 350,
        type: "area",
        color: "#64e38a",
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        // curve: "straight",
        width: 1,
      },
      pattern: {
        strokeWidth: 0.1,
      },
      // title: {
      //   text: "Weekly Data for Bounced",
      //   align: "left",
      // },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },

      xaxis: {
        title: "Date",
      },

      yaxis: {
        min: 0,
        max: 24,
        tickAmount: 12,
        title: { text: "Hour" },
        labels: {
          formatter: function (value) {
            return Math.round(value); // Convert the y-axis values to decimal format
          },
        },
      },

      series: [
        {
          name: "Peak Hour",
          data: [],
          // data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
        },
      ],
    },
    series: [],
  });
  const [PeakHoursController, setPeakHoursController] =
    useState(PeakHoursDetails);
  const [
    FootfallDirectionHourlyController,
    setFootfallDirectionHourlyController,
  ] = useState(FootfallDirectionHourlyDetails);

  const [ingressloading, setingressloading] = useState(true);
  const [chartData, setChartData] = useState({
    options: {
      chart: {
        id: "basic-bar",
        animations: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
        contextmenu: {
          enabled: false,
        },
        zoom: {
          enabled: false, // Ensure zoom is disabled
        },
      },
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      xaxis: {
        type: "category", // Change type to category for categorical data
        categories: [], // Initialize categories as empty array
        title: {
          text: "Hour",
          offsetY: -10,
        },
      },
      yaxis: {
        type: "count",
        title: {
          text: "count",
          offsetY: -10,
        },
        labels: {
          formatter: function (value) {
            return parseInt(value); // Convert the y-axis values to decimal format
          },
        },
      },
      colors: ["#00e396", "#008ffb"],
      dataLabels: {
        enabled: false,
        offsetY: -15,
        style: {
          colors: ["#615494", "#f27777"],
        },
      },
      stroke: {
        width: 1,
      },
      pattern: {
        strokeWidth: 0.1,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          dataLabels: {
            position: "top",
            offsetY: -20,
            width: 1,
            style: {
              fontSize: "12px",
              colors: [" #000"],
              offsetY: -20,
            },
          },
        },
        dataLabels: {
          enabled: true,
          hideOverflowingLabels: true,
          formatter: function () {
            return 25;
          },
          offsetY: -20,
        },
      },
    },
    series: [
      {
        name: "Ingress",
        data: [],
      },
      {
        name: "Egress",
        data: [],
      },
    ],
  });
  const [HandleDateForIngressEgress, setHandleDateForIngressEgress] =
    useState(IngressEgressDetails);

  //main filters
  const [mainFilter, setMainFilter] = useState({
    selectFilter: 2,
    startDate: dayjs().subtract(17, "day").format("YYYY-MM-DD"),
    endDate: dayjs().subtract(9, "day").format("YYYY-MM-DD"),
  });

  const onMainFilterChange = (val) => {
    if (val == 1) {
      setMainFilter((prevState) => ({
        ...prevState,
        selectFilter: val,
        startDate: dayjs().subtract(9, "day").format("YYYY-MM-DD"),
        endDate: dayjs().subtract(9, "day").format("YYYY-MM-DD"),
      }));
    } else if (val == 2) {
      setMainFilter((prevState) => ({
        ...prevState,
        selectFilter: val,
        startDate: dayjs().subtract(17, "day").format("YYYY-MM-DD"),
        endDate: dayjs().subtract(9, "day").format("YYYY-MM-DD"),
      }));
    } else if (val == 3) {
      setMainFilter((prevState) => ({
        ...prevState,
        selectFilter: val,
        startDate: dayjs().subtract(25, "day").format("YYYY-MM-DD"),
        endDate: dayjs().subtract(9, "day").format("YYYY-MM-DD"),
      }));
    } else if (val == 4) {
      setMainFilter((prevState) => ({
        ...prevState,
        selectFilter: val,
        startDate: dayjs().startOf("month").format("YYYY-MM-DD"),
        endDate: dayjs().subtract(1, "day").format("YYYY-MM-DD"),
      }));
    } else if (val == 5) {
      setMainFilter((prevState) => ({
        ...prevState,
        selectFilter: val,
      }));
    }
  };
  const onMainFilterDateChange = (date) => {
    if (date == null || date == "") {
      setMainFilter((prevState) => ({
        ...prevState,
      }));
    } else {
      setMainFilter((prevState) => ({
        ...prevState,

        startDate: dayjs(date).format("YYYY-MM-DD"),
        endDate: dayjs(date).format("YYYY-MM-DD"),
      }));
    }
  };
  const onMainRangeChange = (date) => {
    if (date == null || date == "") {
      setMainFilter((prevState) => ({
        ...prevState,
      }));
    } else {
      setMainFilter((prevState) => ({
        ...prevState,

        startDate: dayjs(date[0]).format("YYYY-MM-DD"),
        endDate: dayjs(date[1]).format("YYYY-MM-DD"),
      }));
    }
  };

  //menu
  const menuforBounced = [
    {
      key: "Weekly",
      label: <Text>Weekly Data</Text>,
    },
    {
      key: "15Days",
      label: <Text>15 Days Data</Text>,
    },
    {
      key: "Monthly",
      label: <Text>Monthly Data</Text>,
    },
    {
      key: "search",
      label: (
        <Text>
          <Flex align="center" justify="space-evenly">
            search <IoIosSearch />
          </Flex>
        </Text>
      ),
    },
  ];
  const menuforpeakvalue = [
    {
      key: "Weekly",
      label: <Text>Weekly Data</Text>,
    },
    {
      key: "15Days",
      label: <Text>15 Days Data</Text>,
    },
    {
      key: "Monthly",
      label: <Text>Monthly Data</Text>,
    },
    {
      key: "search",
      label: (
        <Text>
          <Flex align="center" justify="space-evenly">
            search <IoIosSearch />
          </Flex>
        </Text>
      ),
    },
  ];

  //-----------------------
  const handleDateSelect = (date) => {
    if (date === null) {
      console.log("Date cleared!");
    } else {
      setselectdwelltimedate(date);

      const formattedDate = date.format("YYYY-MM-DD");

      axiosClient
        .get(`/dwelltime/getaveragebasedondate?date=${formattedDate}`)
        .then((response) => {
          if (response.data.issuccess === true) {
            setdwelltimedata(response.data.data.dwellTimeAvg);
          }
        })
        .catch((error) => {});

      setShowDatePicker(false);
    }
  };
  //-------------------------

  useEffect(() => {
    EngagedAndBounced();
    handleDateRangeChangeforPeakHours()
    PeakDay();
    AgeBasedMaleFemaleCount()
    genderGraph()
  }, [mainFilter.selectFilter, mainFilter.startDate, mainFilter.endDate]);

  //Get Dwell Time  data
  useEffect(() => {
    axiosClient
      .get("/dwelltime/getaveragebasedondate?date=2024-06-12")
      .then((response) => {
        if (response) {
          setdwelltimedata(response.data.data.dwellTimeAvg);
        }
      })
      .catch((err) => {
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  }, []);
  //-------------------------------------
  useEffect(() => {
    setTimeout(() => {
      setRenderCompController((prev) => ({ ...prev, IngressEgress: true }));
    }, 1000);

    setTimeout(() => {
      setRenderCompController((prev) => ({ ...prev, DwellFootfall: true }));
    }, 1000);
    setTimeout(() => {
      setRenderCompController((prev) => ({ ...prev, EngagedBounced: true }));
    }, 1000);

    setTimeout(() => {
      setRenderCompController((prev) => ({ ...prev, AgeGender: true }));
    }, 1000);
    setTimeout(() => {
      setRenderCompController((prev) => ({ ...prev, PeakHours: true }));
    }, 1000);
  }, []);

  //-----------------------------------------

  // initial fetch of engagae &bounce
  useEffect(() => {
    axiosClient
      .get("/dwelltime/getdwelltimebasedoncameraid?cameraId=")

      .then((response) => {
        if (response.data.issuccess === true) {
          if (response.data.data.length > 0) {
            const EBdataDefault = response.data.defaultData;
            const newMenuList = response.data.data.map((item, index) => {
              return {
                key: item.cameraId.toString(),
                label: item.cameraName,
                children:
                  item.zoneData.length > 0
                    ? item.zoneData.map((zone) => ({
                        key: `${item.cameraId}-${zone.id}`,
                        label: (
                          <Text
                            onClick={() => handleEngagedAndBounced(zone, item)}
                          >
                            {zone.Area}
                          </Text>
                        ),
                        item: { zone },
                      }))
                    : [],
              };
            });
            setEngaedandBouncedController(newMenuList);
            setEngagedandBouncedData((prev) => ({
              ...prev,
              CameraData: EBdataDefault,
              ZoneData:
                EBdataDefault.zoneData && EBdataDefault.zoneData.length > 0
                  ? EBdataDefault.zoneData[0]
                  : null,
            }));
          }
        } else {
          console.log("error engage data api call successfully");
        }
      })
      .catch((err) => {
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  }, []);

  //-----------------------------------------
  const DwellHourComp = () => {
    return (
      <Card
        title={
          <>
            <Row justify={"space-between"} align={"middle"}>
              <Col>
                <Title level={5} type="secondary" style={{fontFamily:"Helvetica"}}>Dwell time per hour</Title>
                {/* <Tag>
                  {DwellHourlyController.DataOfDate &&
                    DwellHourlyController.DataOfDate}
                  {DwellHourlyController.selectedcameraname &&
                    " - " + DwellHourlyController.selectedcameraname}
                </Tag> */}
              </Col>
              <Col>
                <Row align="center" gutter={[10, 0]}>
                  <Col>
                    <DatePicker
                      onChange={onChangeDateDwellHourly}
                      format={"DD-MM-YYYY"}
                      disabledDate={(current) =>
                        current >= dayjs().subtract(1, "day").endOf("day")
                      }
                      value={dayjs(DwellHourlyController.selectedDate)}
                    />
                  </Col>
                  <Col>
                    <Select
                      onChange={OnChangeCameraSelect}
                      placeholder={<Text type="secondary">Camera</Text>}
                      //value={DwellHourlyController.selectedcam}
                      //value={"vinoth"}
                      style={{ width: 120 }}
                    >
                      {DwellHourlyController.camlist.map((item) => (
                        <Option key={item.value} value={item.value}>
                          {item.label}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        }
      >
        <Chart
          options={DwellHourly.options}
          series={DwellHourly.series}
          type="bar"
          height={"200%"}
        />
      </Card>
    );
  };
  //----------------------------------------------

  const UniqueHourComp = () => {
    return (
      <Card
        styles={{
          body: { maxHeight: "100%" },
        }}
        title={
          <>
            <Row justify={"space-between"} align={"middle"}>
              <Col>
                <Title level={5}>Unique count per hour</Title>
                <Tag>
                  {UniqueHourlyController.DataOfDate &&
                    UniqueHourlyController.DataOfDate}
                  {UniqueHourlyController.selectedcameraname &&
                    " - " + UniqueHourlyController.selectedcameraname}
                </Tag>
              </Col>
              <Col>
                <Row align="center" gutter={[10, 0]}>
                  <Col>
                    <DatePicker
                      onChange={UniqueHourlyHandleDate}
                      format={"YYYY-MM-DD"}
                      disabledDate={(current) => {
                        return current && current > moment().endOf("day");
                      }}
                      defaultValue={dayjs()}
                    />
                  </Col>
                  <Col>
                    <Select
                      onChange={UniqueHourlyHandleSelectCamera}
                      defaultValue={UniqueHourlyController.selectedcam}
                      style={{
                        width: 120,
                      }}
                      options={UniqueHourlyController.camlist}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        }
      >
        <Chart
          options={UniqueHourlyChart.options}
          series={UniqueHourlyChart.series}
          type="bar"
          height={"190%"}
        />
      </Card>
    );
  };

  //Month picker for spider graph
  // const onChange = (date, dateString) => {
  //   if (date === null) {
  //     axiosClient.get(`/livereport/spidergraphdata`).then((response) => {
  //       if (response.data.issuccess === true) {
  //         const responseData = response.data.data;

  //         const uniqueWeekwise = [
  //           ...new Set(
  //             responseData.spiderIngress[0].map((item) => item.weekwise)
  //           ),
  //         ];

  //         // Extracting data for the radar chart
  //         const seriesData = uniqueWeekwise.map((weekwise) => {
  //           return {
  //             name: weekwise,
  //             data: responseData.spiderIngress[0]
  //               .filter((item) => item.weekwise === weekwise)
  //               .map((item) => item.totalingress),
  //           };
  //         });

  //         // Setting up chart options
  //         const options = {
  //           chart: {
  //             height: 400,
  //             type: "radar",
  //           },
  //           xaxis: {
  //             categories: responseData.spiderIngress[0].map(
  //               (item) => item.week
  //             ),
  //             title: {
  //               text: "Week",
  //               offsetY: -10,
  //               labels: {
  //                 show: true,
  //                 style: {
  //                   colors: ["#a8a8a8"],
  //                 },
  //               },
  //             },
  //           },
  //           colors: [
  //             "#008FFB",
  //             "#00E396",
  //             "#FEB019",
  //             "#FF4560",
  //             "#775DD0",
  //             "#FF9F00",
  //           ],
  //         };

  //         setChartFootfallanalysis({
  //           series: seriesData,
  //           options: options,
  //         });
  //       }
  //     });
  //   } else {
  //     const formattedmonth = date.format("YYYY-MM");

  //     axiosClient
  //       .get(`/livereport/spidergraphdata?month=` + formattedmonth)
  //       .then((response) => {
  //         if (response.data.issuccess === true) {
  //           const responseData = response.data.data;

  //           const uniqueWeekwise = [
  //             ...new Set(responseData.newspider.map((item) => item.weekwise)),
  //           ];

  //           // Extracting data for the radar chart
  //           const seriesData = uniqueWeekwise.map((weekwise) => {
  //             return {
  //               name: weekwise,
  //               data: responseData.newspider
  //                 .filter((item) => item.weekwise === weekwise)
  //                 .map((item) => item.totalingress),
  //             };
  //           });

  //           // Setting up chart options
  //           const options = {
  //             chart: {
  //               height: 400,
  //               type: "radar",
  //             },
  //             xaxis: {
  //               categories: responseData.newspider.map((item) => item.week),
  //               title: {
  //                 text: "Week",
  //                 offsetY: -10,
  //                 labels: {
  //                   show: true,
  //                   style: {
  //                     colors: ["#a8a8a8"],
  //                   },
  //                 },
  //               },
  //             },
  //             colors: [
  //               "#008FFB",
  //               "#00E396",
  //               "#FEB019",
  //               "#FF4560",
  //               "#775DD0",
  //               "#FF9F00",
  //             ],
  //           };

  //           setChartFootfallanalysis({
  //             series: seriesData,
  //             options: options,
  //           });
  //         }
  //       })
  //       .catch((err) => {
  //         console.log("errr", err);
  //         if (err.status === 0) {
  //           message.error("Server error");
  //         } else {
  //           message.error(err.msg);
  //         }
  //       });
  //   }
  // };
  //--------------------------------------------------
  const disabledFutureMonths = (current) => {
    return current && current > moment().endOf("month");
  };
  //------------------------------------------------------
  // useEffect(() => {
  //   axiosClient
  //     .get(`/livereport/spidergraphdata`)
  //     .then((response) => {
  //       if (response.data.issuccess === true) {
  //         // Spiderchart
  //         const responseData =
  //           response.data.data.spiderIngress.length > 0
  //             ? response.data.data.spiderIngress[0]
  //             : [];

  //         const uniqueWeekwise = [
  //           ...new Set(responseData.map((item) => item.weekwise)),
  //         ];

  //         // Extracting data for the radar chart
  //         const seriesData = uniqueWeekwise.map((weekwise) => {
  //           return {
  //             name: weekwise,
  //             data: responseData
  //               .filter((item) => item.weekwise === weekwise)
  //               .map((item) => item.totalingress),
  //           };
  //         });

  //         // Setting up chart options
  //         const options = {
  //           chart: {
  //             height: 400,
  //             type: "radar",
  //           },
  //           xaxis: {
  //             categories: responseData.map((item) => item.week),
  //             title: {
  //               text: "Week",
  //               offsetY: -10,
  //               labels: {
  //                 show: true,
  //                 style: {
  //                   colors: ["#a8a8a8"],
  //                 },
  //               },
  //             },
  //           },
  //           colors: [
  //             "#008FFB",
  //             "#00E396",
  //             "#FEB019",
  //             "#FF4560",
  //             "#775DD0",
  //             "#FF9F00",
  //           ],
  //         };

  //         setChartFootfallanalysis({
  //           series: seriesData,
  //           options: options,
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("errr", err);
  //       if (err.status === 0) {
  //         message.error("Network error");
  //       } else {
  //         message.error(err.message);
  //       }
  //     });
  // }, []);
  // useEffect(() => {
  //   axiosClient
  //     .get(`/livereport/spidergraphdata`)
  //     .then((response) => {
  //       if (response.data.issuccess === true) {
  //         const responseData = response.data.data.newspider;
  //         console.log("NEW GRAPH------------", responseData);

  //         // Aggregate the data by weekwise
  //         const weekData = responseData.reduce((acc, item) => {
  //           const week = item.weekwise;
  //           if (!acc[week]) {
  //             acc[week] = 0;
  //           }
  //           acc[week] += item.totalingress;
  //           return acc;
  //         }, {});

  //         // Prepare series data and labels
  //         const categories = Object.keys(weekData);
  //         const data = Object.values(weekData);

  //         // Set the state with the aggregated data
  //         setChartFootfallAnalysis({
  //           series: data,
  //           options: {
  //             ...chartFootfallAnalysis.options,
  //             labels: categories,
  //           },
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("Error", err);
  //       if (err.status === 0) {
  //         message.error("Network error");
  //       } else {
  //         message.error(err.message);
  //       }
  //     });
  // }, []);

  useEffect(() => {
    axiosClient
      .get(`/livereport/spidergraphdata`)
      .then((response) => {
        if (response.data.issuccess === true) {
          const responseData = response.data.data.newspider;

          const weeks = {};
          responseData.forEach((item) => {
            if (!weeks[item.weekwise]) {
              weeks[item.weekwise] = {
                Monday: 0,
                Tuesday: 0,
                Wednesday: 0,
                Thursday: 0,
                Friday: 0,
                Saturday: 0,
                Sunday: 0,
              };
            }
            weeks[item.weekwise][item.week] = item.totalingress;
          });

          const series = Object.keys(weeks).map((week, index) => ({
            name: `Week ${index + 1}`,
            data: [
              weeks[week].Monday,
              weeks[week].Tuesday,
              weeks[week].Wednesday,
              weeks[week].Thursday,
              weeks[week].Friday,
              weeks[week].Saturday,
              weeks[week].Sunday,
            ],
          }));

          setChartFootfallAnalysis((prevState) => ({
            ...prevState,
            series: series,
          }));
        }
      })
      .catch((err) => {
        console.log("Error", err);
        if (err.status === 0) {
          message.error("Network error");
        } else {
          message.error(err.message);
        }
      });
  }, []);

  //---------------------------------------------------
  const UniqueHourlyHandleSelectCamera = (selectedcam) => {
    if (selectedcam !== null && selectedcam !== undefined) {
      setUniqueHourlyController((prev) => ({ ...prev, selectedcam }));
    }
  };
  //---------------------------------------------------

  useEffect(() => {
    axiosClient
      .get(
        `/livereport/getuniquecount?date=${UniqueHourlyController.selectedDate}&camId=${UniqueHourlyController.selectedcam}`
      )
      .then((response) => {
        if (response.data.issuccess === true) {
          const data = response.data.data.data ? response.data.data.data : [];

          const selectedcamDetails = response.data.data.selectedCameraData;
          const CameraList = response.data.data.cameraData;
          if (selectedcamDetails) {
            setUniqueHourlyController((prev) => ({
              ...prev,
              selectedcam: selectedcamDetails.cameraId,
              selectedcameraname: selectedcamDetails.cameraName,
            }));
          }
          if (CameraList !== null && CameraList !== undefined) {
            const newCamList = CameraList.map((item) => ({
              value: item.cameraid,
              label: item.cameraname,
            }));

            setUniqueHourlyController((prev) => ({
              ...prev,
              camlist: [...newCamList],
            }));
          }

          if (data.length > 0 || data !== null || data !== undefined) {
            const xaxis = data.map((item) => item.hour);
            const series = data.map((item) => item.totalCount);

            setUniqueHourlyChart((prevState) => ({
              ...prevState,
              options: {
                ...prevState.options,
                xaxis: { ...prevState.options.xaxis, categories: xaxis },
              },
              series: [{ name: "Unique counts", data: series }],
            }));

            setUniqueHourlyController((prev) => ({
              ...prev,
              DataOfDate: UniqueHourlyController.selectedDate,
            }));
          }
        } else {
          console.log("error engage data api call successfully");
        }
      })
      .catch((err) => {
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  }, [UniqueHourlyController.selectedcam, UniqueHourlyController.selectedDate]);

  //----------------------------------------------
  const UniqueHourlyHandleDate = (date, dateString) => {
    if (dateString !== "") {
      setUniqueHourlyController((prev) => ({
        ...prev,
        selectedDate: dateString,
        selectedcam: "",
      }));
    } else {
      setUniqueHourlyController((prev) => ({
        ...prev,
        selectedDate: "",
        selectedcam: "",
      }));
    }
  };
  //-----------------------------------------
  useEffect(() => {
    axiosClient
      .get(
        `/dwelltime/getDwellTimeHourData?date=${DwellHourlyController.selectedDate}&camId=${DwellHourlyController.selectedcam}`
      )

      .then((response) => {
        if (response.data.issuccess === true) {
          const data = response.data.data.dwellData
            ? response.data.data.dwellData
            : [];

          const selectedcamDetails = response.data.data.selectedCameraDetails;
          const CameraList = response.data.data.cameraData;
          // if (selectedcamDetails) {
          //   setDwellHourlyController((prev) => ({
          //     ...prev,
          //     selectedcam: selectedcamDetails.cameraId,
          //     selectedcameraname: selectedcamDetails.cameraName,
          //   }));
          // }
          if (CameraList !== null && CameraList !== undefined) {
            const newCamList = CameraList.map((item) => ({
              value: item.cameraId,
              label: item.cameraName,
            }));
            //console.log("vinoth-------CAM", newCamList);

            setDwellHourlyController((prev) => ({
              ...prev,
              camlist: [...newCamList],
            }));
            // CameraList.map((item) => {
            //   let cam = {
            //     value: item.cameraId,
            //     label: item.cameraName,
            //   };

            //   setDwellHourlyController((prev) => ({
            //     ...prev,
            //     camlist: [...prev.camlist, cam],
            //   }));
            // });
          }

          if (data.length > 0 || data !== null || data !== undefined) {
            const zoneNames = extractZoneNames(data).sort();
            if (zoneNames.length > 0) {
              const { hours, series, seriesEngage, seriesBounce } =
                transformData(data, zoneNames);
              const colors = generateColorPalette(
                zoneNames ? zoneNames.length : 0
              );
              setDwellHourly((prevState) => ({
                ...prevState,
                options: {
                  ...prevState.options,
                  xaxis: {
                    ...prevState.options.xaxis,
                    categories: hours,
                  },
                },
                series: series,
              }));

              setDwellHourlyController((prev) => ({
                ...prev,
                DataOfDate: DwellHourlyController.selectedDate,
              }));
            }
          }
        } else {
          console.log("error engage data api call successfully");
        }
      })
      .catch((err) => {
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  }, [DwellHourlyController.selectedcam, DwellHourlyController.selectedDate]);
  //--------------------------------------
  const generateColorPalette = (numberOfColors) => {
    const palette = [];
    if (
      numberOfColors > 0 &&
      numberOfColors !== null &&
      numberOfColors !== undefined
    ) {
      for (let j = 0; j < numberOfColors; j++) {
        palette.push(
          randomColor({
            luminosity: "dark",
            // hue: "blue",
          })
        );
      }
    }

    return palette;
  };
  //------------------------------------------------
  const transformData = (data, zoneNames) => {
    const hours = _.map(data, "hour");
    const series = _.map(zoneNames, (zone) => {
      const DwellTime_avg = _.map(data, zone + ".average_dwell_time");
      const Final_Dwell = DwellTime_avg.map((item) => Math.round(item));
      return {
        name: zone,
        data: Final_Dwell,
      };
    });

    return { hours, series };
  };
  //---------------------------

  const extractZoneNames = (data) => {
    return _(data)
      .flatMap(_.keys)
      .filter((key) => key.startsWith("Area"))
      .uniq()
      .value();
  };
  //---------------------------------
  const OnChangeCameraSelect = (cam) => {
    //console.log('Selected camera:', cam);

    const selectedCameraName =
      DwellHourlyController.camlist.find((item) => item.value === cam)?.label ||
      null;

    setDwellHourlyController((prev) => ({
      ...prev,
      selectedcam: cam,
      selectedcameraname: selectedCameraName,
    }));
  };

  // Onchange date function for Dwell hourly
  const onChangeDateDwellHourly = (date) => {
    if (date !== "") {
      setDwellHourlyController((prev) => ({
        ...prev,
        selectedDate: dayjs(date).format("YYYY-MM-DD"),
        selectedcam: null,
      }));
    } else {
      setDwellHourlyController((prev) => ({
        ...prev,
        selectedDate: dayjs().subtract(1, "day").format("YYYY-MM-DD"),
        selectedcam: null,
      }));
    }
  };
  const handleEngagedAndBounced = (zone, item) => {
    setEngagedandBouncedData({ CameraData: item, ZoneData: zone });
  };

  //weekly
  const EngageAndBounce = () => {
    return (
      <Col
        xs={{ span: 24 / 1, offset: 0 }}
        sm={{ span: 24 / 1, offset: 0 }}
        md={{ span: 24 / 1, offset: 0 }}
        lg={{ span: 24 / 1, offset: 0 }}
        xl={{ span: 24 / 1, offset: 0 }}
      >
        <Card
          style={{
            userSelect: "none",
            boxShadow: "none",
          }}
          title={
            <>
              <Row justify={"space-between"} align={"middle"}>
                <Col>
                  <Title
                    level={5}
                    type="secondary"
                    style={{ fontFamily: "Helvetica" }}
                  >
                    {mainFilter.selectFilter === 1
                      ? "Engaged and Bounced"
                      : mainFilter.selectFilter === 2
                      ? "Weekly Engaged and Bounced"
                      : mainFilter.selectFilter === 3
                      ? "Last 15 Days Engaged and Bounced"
                      : mainFilter.selectFilter === 4
                      ? "Monthly Engaged and Bounced"
                      : "Specific Date range Engaged and Bounced"}
                  </Title>
                </Col>

                <Col>
                  <Flex gap={10} align="center">
                    {/* {WeeklyBouncedController.search && (
                      <RangePicker
                        onChange={handleDateRangeChangeforBounce}
                        disabledDate={(current) => {
                          return current && current > moment().endOf("day");
                        }}
                        allowClear
                      />
                    )}
                    <Dropdown
                      menu={{
                        items: menuforBounced,
                        onClick: handleMenuClickWeeklyBounced,
                      }}
                      placement="bottomRight"
                    >
                      <Button>
                        <Flex gap={10} align="center">
                          Filters
                          <LuFilter />
                        </Flex>
                      </Button>
                    </Dropdown> */}
                    <Text type="secondary">Selected Date: {mainFilter.selectFilter==1?dayjs(mainFilter.startDate).format("DD-MM-YYYY"):dayjs(mainFilter.startDate).format("DD-MM-YYYY")+" - "+dayjs(mainFilter.endDate).format("DD-MM-YYYY")}</Text>
                  </Flex>
                </Col>
              </Row>
            </>
          }
        >
          <Row gutter={[1, 10]} justify={"space-between"} align={"middle"}>
            <Col
              xs={{ span: 23 / 1, offset: 0 }}
              sm={{ span: 24 / 1, offset: 0 }}
              md={{ span: 11 / 1, offset: 0 }}
              lg={{ span: 11 / 1, offset: 0 }}
              xl={{ span: 11, offset: 0 }}
            >
              <div style={{ height: "250px" }}>
                <Chart
                  type="bar"
                  options={Engagersweeklychart.options}
                  series={Engagersweeklychart.options.series}
                  height="100%"
                />
              </div>
            </Col>
            <Divider type="vertical" style={{ height: "250px" }} />
            <Col
              xs={{ span: 24 / 1, offset: 0 }}
              sm={{ span: 24 / 1, offset: 0 }}
              md={{ span: 11 / 1, offset: 0 }}
              lg={{ span: 11 / 1, offset: 0 }}
              xl={{ span: 11, offset: 0 }}
            >
              <div style={{ height: "250px" }}>
                <Chart
                  type="bar"
                  options={Bouncedweeklychart.options}
                  series={Bouncedweeklychart.options.series}
                  height="100%"
                />
              </div>
            </Col>
          </Row>
        </Card>
      </Col>
    );
  };

  //---------------------------
  const handleMenuClickWeeklyBounced = (e) => {
    const key = e.key;
    setSelectedbounceMenu(key);
    setWeeklyBouncedController((Prev) => ({ ...Prev, SelectedOption: key }));
    switch (key) {
      case "Weekly":
        handleweeklyDataBounced();
        break;
      case "15Days":
        handleFetch15DaysDataBounced();
        break;
      case "Monthly":
        handleBouncedMonthClick();
        break;
      case "search":
        setWeeklyBouncedController((prev) => ({ ...prev, search: true }));
        break;
      default:
        break;
    }
  };

  //-------------------------------
  const handleDateRangeChangeforBounce = (dates, dateStrings) => {
    if (dates == null) {
      setWeeklyBouncedController((prev) => ({
        ...prev,
        search: false,
        SelectedOption: "Weekly",
      }));
      handleweeklyDataBounced();
    } else {
      const startDate = dates[0].format("YYYY-MM-DD");
      const endDate = dates[1].format("YYYY-MM-DD");
    }
  };

  //----------------------------------
  const handleweeklyDataBounced = () => {
    setShowRangePickerforBounce(false);
    axiosClient
      .get("dwelltime/getbounceandengagedperweek")
      .then((response) => {
        if (response.data.issuccess === true) {
          const apiData = response.data.data;
          const categories = apiData.map((item) => item.createdDate);
          const seriesData = apiData.map((item) => item.totalbounce);
          const BouncedData = apiData.map((item) => item.totalengaged);

          setEngagersweeklychart((prevState) => ({
            ...prevState,
            options: {
              ...prevState.options,
              series: [{ name: "Engagers", data: seriesData }],
              xaxis: { ...prevState.options.xaxis, categories: categories },
            },
          }));
          setBouncedweeklychart((prevState) => ({
            ...prevState,
            options: {
              ...prevState.options,
              series: [
                {
                  ...prevState.options.series,
                  name: "Bounced",
                  data: BouncedData,
                },
              ],
              xaxis: { ...prevState.options.xaxis, categories: categories },
            },
          }));
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  //Get bounced 15days data
  const handleFetch15DaysDataBounced = () => {
    setShowRangePickerforBounce(false);
    axiosClient
      .get("dwelltime/getbounceandengagedperfifteendays")
      .then((response) => {
        const apiData = response.data.data;
        const categories = apiData.map((item) => item.createdDate);
        const seriesData = apiData.map((item) => item.totalbounce);
        const BouncedData = apiData.map((item) => item.totalengaged);

        setEngagersweeklychart((prevState) => ({
          ...prevState,
          options: {
            ...prevState.options,
            series: [{ name: "Engagers", data: seriesData }],
            xaxis: { ...prevState.options.xaxis, categories: categories },
          },
        }));

        setBouncedweeklychart((prevState) => ({
          ...prevState,
          options: {
            ...prevState.options,
            series: [
              {
                ...prevState.options.series,
                name: "Bounced",
                data: BouncedData,
              },
            ],
            xaxis: { ...prevState.options.xaxis, categories: categories },
          },
        }));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  //------------------
  const handleBouncedMonthClick = () => {
    axiosClient
      .get(
        `dwelltime/getbounceandengagedforspecifieddates?startDate=${WeeklyBouncedController.StartDate}&endDate=${WeeklyBouncedController.EndDate}`
      )
      .then((response) => {
        setShowRangePickerforBounce(false);
        const apiData = response.data.data;
        const categories = apiData.map((item) => item.createdDate);
        const seriesData = apiData.map((item) => item.totalengaged);
        const BouncedData = apiData.map((item) => item.totalbounce);

        setEngagersweeklychart((prevState) => ({
          ...prevState,
          options: {
            ...prevState.options,
            series: [{ name: "Engagers", data: seriesData }],
            xaxis: { ...prevState.options.xaxis, categories: categories },
          },
        }));

        setBouncedweeklychart((prevState) => ({
          ...prevState,
          options: {
            ...prevState.options,
            series: [
              {
                ...prevState.options.series,
                name: "Bounced",
                data: BouncedData,
              },
            ],
            xaxis: { ...prevState.options.xaxis, categories: categories },
          },
        }));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  //--------------------------------
  const onChangeDate = (date) => {
    //console.log("vinoth--------------", dateString);
    if (date === null || date === undefined || date === "") {
      setselecteddate("");
    } else {
      setselecteddate(dayjs(date).format("YYYY-MM-DD"));
      // genderGraph(dayjs(date).format("YYYY-MM-DD"));
    }
  };

  //-------------------------
  
  const EngagedAndBounced = () => {
    axiosClient
      .get(
        `dwelltime/getbounceandengagedforspecifieddates?startDate=${mainFilter.startDate}&endDate=${mainFilter.endDate}`
      )
      .then((response) => {
        setShowRangePickerforBounce(false);
        const apiData = response.data.data;
        const categories = apiData.map((item) => item.createdDate);
        const seriesData = apiData.map((item) => item.totalbounce);

        const BouncedData = apiData.map((item) => item.totalengaged);
        setEngagersweeklychart((prevState) => ({
          ...prevState,
          options: {
            ...prevState.options,
            series: [{ name: "Engagers", data: seriesData }],
            xaxis: { ...prevState.options.xaxis, categories: categories },
          },
        }));
        setBouncedweeklychart((prevState) => ({
          ...prevState,
          options: {
            ...prevState.options,
            series: [
              {
                ...prevState.options.series,
                name: "Bounced",
                data: BouncedData,
              },
            ],
            xaxis: { ...prevState.options.xaxis, categories: categories },
          },
        }));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  //-----------------------------------
  //Get age & gender
  useEffect(() => {
    genderGraph();
  }, [selecteddate]); // Ensure selecteddate is the only dependency

  //---------------------------------
  // function genderGraph(selecteddate){
  //   axiosClient
  //     .get(`/livereport/getageandgender?date=` + selecteddate + `&camid=`)
  //     .then((response) => {
  //       setloading(false);
  //       if (response.data.issuccess === true) {
  //         setdatedata(response.data.data.date);
  //         const ageDistributionData = response.data.data.age_distribution;
  //         const seriesData = ageDistributionData.map((item) =>
  //           parseInt(item.count)
  //         );
  //         const labelsData = ageDistributionData.map((item) => item.age);

  //         setChartDatapieAge((prev) => ({
  //           ...prev,
  //           series: seriesData,
  //           options: {
  //             ...prev.options,
  //             labels: labelsData,
  //           },
  //         }));

  //         if (
  //           response.data.data.male_percentage !== null &&
  //           response.data.data.male_percentage !== undefined &&
  //           response.data.data.female_percentage !== null &&
  //           response.data.data.female_percentage !== undefined
  //         ) {
  //           setChartDatapieGender((prev) => ({
  //             ...prev,
  //             series: [
  //               response.data.data.male_percentage,
  //               response.data.data.female_percentage,
  //             ],
  //           }));
  //         } else {
  //           setChartDatapieGender((prev) => ({
  //             ...prev,
  //             series: [],
  //           }));
  //         }
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("errr", err);
  //       if (err.status === 0) {
  //         message.error("Server error");
  //       } else {
  //         message.error(err.msg);
  //       }
  //     });
  // }

  //--------

  const genderGraph = () => {
    axiosClient
      .get(`/livereport/getageandgender?date=${mainFilter.startDate}&endDate=${mainFilter.endDate}&camid=`)
      .then((response) => {
        console.log("response", response.data.data);
        //setLoading(false);
        if (response.data.issuccess === true) {
          const responseData = response.data.data;

             // Handle null or zero values for gender percentages
          const malePercentage = responseData.male_percentage || 0;
          const femalePercentage = responseData.female_percentage || 0;
          const maleCount = responseData.male_count || 0;
          const femaleCount = responseData.female_count || 0;


          setChartDatapieGender((prevState) => ({
            ...prevState,
            series: [malePercentage, femalePercentage],
            maleCount:maleCount,
            femaleCount:femaleCount,
            options:{
              ...prevState,
              labels:[`Male: ${maleCount}`,`Female: ${femaleCount}`]
            }
            
          }));

          // Handle zero values for age distribution
          const ageLabels = responseData.age_distribution.map(
            (group) => group.age
          );
          const ageCounts = responseData.age_distribution.map(
            (group) => group.count
          );
          setChartDatapieAge({
            ...chartDatapieAge,
            options: { ...chartDatapieAge.options, labels: ageLabels },
            series: ageCounts,
          });
        }
      })
      .catch((err) => {
        console.log("Error:", err);
        //setLoading(false);
        if (err.response && err.response.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.message);
        }
      });
  };
  const hasNonZeroValues =
    chartDatapieGender.series.reduce((acc, curr) => acc + curr, 0) > 0;
  const hasNonZeroValues2 =
    chartDatapieAge.series.reduce((acc, curr) => acc + curr, 0) > 0;

  //------------weekly data
  const handleMenuClick2 = (e) => {
    const key = e.key;
    setSelectedpickhourMenu(key);

    switch (key) {
      case "Weekly":
        handleweeklyDataPeakHour();
        break;
      case "15Days":
        handleFetch15DaysDatapeakhour();
        break;
      case "Monthly":
        handleFetchMonthlyDataPeakHour();
        break;
      case "search":
        setPeakHoursController((prev) => ({ ...prev, search: true }));
        break;
      default:
        break;
    }
  };

  //-------------------------------
  const handleDateRangeChangeforPeakHours = () => {
    axiosClient
    .get(
      `livereport/getpeakhourforspecifieddates?startDate=${mainFilter.startDate}&endDate=${mainFilter.endDate}`
    )
    .then((response) => {
      setshowRangePickerforpeakHourr(false);
      const apiData = response.data.data;

      const categories = apiData.map((item) => item.date);

      const seriesData = apiData.map((item) =>
        item.peakHour == null || item.peakHour == undefined
          ? 0
          : item.peakHour
      );

      const yAxisLabels = apiData.map((item) => {
        if (typeof item.peakHour === "string") {
          return item.peakHour;
        } else {
          return "";
        }
      });

      setpickhoursweeklydata((prevState) => ({
        ...prevState,
        options: {
          ...prevState.options,
          series: [
            {
              name: "Peak hour",
              data: seriesData,
            },
          ],
          xaxis: { categories: categories, title: { text: "Date" } },
          // yaxis: { categories: yAxisLabels },
        },
      }));
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
  };
  //--------------------------------------------
  const handleweeklyDataPeakHour = () => {
    setshowRangePickerforpeakHourr(false);
    axiosClient
      .get("livereport/getpeakhourperweek")
      .then((response) => {
        const apiData = response.data.data;

        const categories = apiData.map((item) => item.date);

        const seriesData = apiData.map((item) =>
          item.peakHour == null || item.peakHour == undefined
            ? 0
            : item.peakHour
        );

        const yAxisLabels = apiData.map((item) => {
          if (typeof item.peakHour === "string") {
            return item.peakHour;
          } else {
            return "";
          }
        });

        setpickhoursweeklydata((prevState) => ({
          ...prevState,
          options: {
            ...prevState.options,
            series: [
              {
                name: "Peak hour",
                data: seriesData,
              },
            ],
            xaxis: { categories: categories, title: { text: "Date" } },
            // yaxis: { categories: yAxisLabels },
          },
        }));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  //----------------------------------
  const handleFetch15DaysDatapeakhour = () => {
    setshowRangePickerforpeakHourr(false);
    axiosClient
      .get("livereport/getpeakhourperfifteendays")
      .then((response) => {
        const apiData = response.data.data;

        const categories = apiData.map((item) => item.date);

        const seriesData = apiData.map((item) =>
          item.peakHour == null || item.peakHour == undefined
            ? 0
            : item.peakHour
        );

        const yAxisLabels = apiData.map((item) => {
          if (typeof item.peakHour === "string") {
            return item.peakHour;
          } else {
            return "";
          }
        });

        setpickhoursweeklydata((prevState) => ({
          ...prevState,
          options: {
            ...prevState.options,
            series: [
              {
                name: "Peak hour",
                data: seriesData,
              },
            ],
            xaxis: { categories: categories, title: { text: "Date" } },
            // yaxis: { categories: yAxisLabels },
          },
        }));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  //---------------------------------------
  const handleFetchMonthlyDataPeakHour = () => {
    setshowRangePickerforpeakHourr(true);
    const startDate = moment().startOf("month").format("YYYY-MM-DD");
    const endDate = moment().endOf("month").format("YYYY-MM-DD");
    axiosClient
      .get(
        `livereport/getpeakhourforspecifieddates?startDate=${startDate}&endDate=${endDate}`
      )
      .then((response) => {
        setshowRangePickerforpeakHourr(false);
        const apiData = response.data.data;

        const categories = apiData.map((item) => item.date);

        const seriesData = apiData.map((item) =>
          item.peakHour == null || item.peakHour == undefined
            ? 0
            : item.peakHour
        );

        const yAxisLabels = apiData.map((item) => {
          if (typeof item.peakHour === "string") {
            return item.peakHour;
          } else {
            return "";
          }
        });

        setpickhoursweeklydata((prevState) => ({
          ...prevState,
          options: {
            ...prevState.options,
            series: [
              {
                name: "Peak hour",
                data: seriesData,
              },
            ],
            xaxis: { categories: categories, title: { text: "Date" } },
            // yaxis: { categories: yAxisLabels },
          },
        }));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  //-------------------------

  //Pickhours weekly data
  // useEffect(() => {
  //   axiosClient
  //     .get("livereport/getpeakhourperweek")
  //     .then((response) => {
  //       if (response.data.issuccess === true) {
  //         const apiData = response.data.data;

  //         const categories = apiData.map((item) => item.date);

  //         const seriesData = apiData.map((item) =>
  //           item.peakHour == null || item.peakHour == undefined
  //             ? 0
  //             : item.peakHour
  //         );

  //         setpickhoursweeklydata((prevState) => ({
  //           ...prevState,
  //           options: {
  //             ...prevState.options,
  //             series: [{ name: "Peak hour", data: seriesData }],
  //             xaxis: { categories: categories, title: { text: "Date" } },
  //             // yaxis: { categories: yAxisLabels },
  //           },
  //         }));
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching data:", error);
  //     });
  // }, []);

  //-----------heatmap
  const [HeatmapController, setHeatmapController] = useState({
    loading: false,
    cameralist: [],
  });
  //footfall analysis
  useEffect(() => {
    setFootfallDirectionHourlyController((prev) => ({
      ...prev,
      loading: true,
    }));
    axiosClient
      .get(
        `/livereport/getfootfalldirectionimage?date=${dayjs(
          FootfallDirectionHourlyController.selectedDate
        ).format("YYYY-MM-DD")}&camId=${
          FootfallDirectionHourlyController.selectedcam
        }&hour=${FootfallDirectionHourlyController.Hour}`
      )

      .then((response) => {
        if (response.data.issuccess === true) {
          const data = response.data.data.dwellData
            ? response.data.data.dwellData
            : [];

          const selectedcamDetails = response.data.data;
          const CameraList = response.data.data.cameraData;
          if (
            selectedcamDetails.selectedCameraData !== null &&
            selectedcamDetails.selectedCameraData !== undefined &&
            selectedcamDetails.selectedCameraData !== ""
          ) {
            setFootfallDirectionHourlyController((prev) => ({
              ...prev,
              selectedcam: selectedcamDetails.selectedCameraData.cameraId,

              selectedcamName: selectedcamDetails.selectedCameraData.cameraName,
            }));
          }

          if (
            selectedcamDetails.directionData !== null &&
            selectedcamDetails.directionData !== undefined &&
            selectedcamDetails.directionData !== ""
          ) {
            setFootfallDirectionHourlyController((prev) => ({
              ...prev,
              DirectionData: selectedcamDetails.directionData,
            }));
          }
          if (
            selectedcamDetails.directionImage !== null &&
            selectedcamDetails.directionImage !== undefined
          ) {
            setFootfallDirectionHourlyController((prev) => ({
              ...prev,
              DirectionImage: selectedcamDetails.directionImage,
            }));
          }

          if (
            selectedcamDetails.hour !== null &&
            selectedcamDetails.hour !== undefined &&
            selectedcamDetails.hour !== ""
          ) {
            setFootfallDirectionHourlyController((prev) => ({
              ...prev,
              Hour: selectedcamDetails.hour,
            }));
          }
          if (CameraList !== null && CameraList !== undefined) {
            const newCamList = CameraList.map((item) => ({
              value: item.cameraid,
              label: item.cameraname,
            }));

            setFootfallDirectionHourlyController((prev) => ({
              ...prev,
              camlist: [...newCamList],
            }));
          }
        } else {
          console.log("error engage data api call successfully");
        }
        setFootfallDirectionHourlyController((prev) => ({
          ...prev,
          loading: false,
        }));
      })
      .catch((err) => {
        setFootfallDirectionHourlyController((prev) => ({
          ...prev,
          loading: false,
        }));
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  }, [
    FootfallDirectionHourlyController.selectedcam,
    FootfallDirectionHourlyController.selectedDate,
    FootfallDirectionHourlyController.Hour,
  ]);
  const HandleSelectCameraDirection = (id) => {
    setFootfallDirectionHourlyController((prev) => ({
      ...prev,
      selectedcam: id,
    }));
  };
  const onChangeTimeDirection = (date) => {
    if (date) {
      setFootfallDirectionHourlyController((prev) => ({
        ...prev,
        selectedDate: dayjs(date).format("YYYY-MM-DD"),
      }));
    } else {
      setFootfallDirectionHourlyController((prev) => ({
        ...prev,
        selectedDate: "",
      }));
    }
  };

  const handleHourChangeFootfalldirection = (time, hour) => {
    setFootfallDirectionHourlyController((prev) => ({
      ...prev,
      Hour: hour,
    }));
  };
  const disabledTime = () => {
    return {
      disabledHours: () => {
        const currentHour =
          moment().format("YYYY-MM-DD") ===
          FootfallDirectionHourlyController.selectedDate
            ? moment().hours()
            : 24;
        return [...Array(24).keys()].slice(currentHour);
      },
    };
  };

  //Dwell time data
  useEffect(() => {
    setHeatmapController((prev) => ({ ...prev, loading: true }));

    axiosClient
      .get("/dwelltime/getDwellTimeDetails?id=")
      .then((response) => {
        if (response.data.issuccess === true) {
          setHeatmapController((prev) => ({
            ...prev,
            cameralist: response.data.data,
          }));
          setHeatmapController((prev) => ({ ...prev, loading: false }));
        }
      })
      .catch((err) => {
        setHeatmapController((prev) => ({ ...prev, loading: false }));
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  }, []);

  //http://pi/livereport/getprocesseduniquecount?date=2024-06-05&camId=1
  const [PasserTableValue, setPasserTableValue] = useState({
    table: [],
    selecteddate: dayjs().subtract(1, "day"),
  });
  const [uniqueCount, setUniqueCount] = useState({
    cameraList: [],
    TotalCount: 0,
    selectedCamId: "",
    selectedDate: "2024-06-12",
  });
  const [PeakDayCount, setPeakDayCount] = useState({
    cameraList: [],
    TotalCount: 0,
    selectedCamId: 0,
    selectedDate: "",
    startDate: dayjs().subtract(8, "day").format("YYYY-MM-DD"),
    endDate: dayjs().subtract(1, "day").format("YYYY-MM-DD"),
  });

  useEffect(() => {
    UniqueCount();
    getPasserByCount();
    PeakDay();
  }, [
    uniqueCount.selectedCamId,
    uniqueCount.selectedDate,
    PasserTableValue.selecteddate,
  ]);
  function UniqueCount() {
    axiosClient
      .get(
        `/livereport/getprocesseduniquecount?date=${uniqueCount.selectedDate}&camId=${uniqueCount.selectedCamId}`
      )
      .then((response) => {
        if (response.data.issuccess === true) {
          console.log("Unique count change------------------", response.data);
          setUniqueCount((prevState) => ({
            ...prevState,
            cameraList:
              response.data.data.cameraData.length > 0
                ? response.data.data.cameraData
                : [],
            TotalCount:
              response.data.data.data.length > 0
                ? response.data.data.data[0].totalCount
                : 0,
          }));
        }
      })
      .catch((err) => {
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  }
  const onUniqueCamChange = (id) => {
    if (id) {
      setUniqueCount((prevState) => ({
        ...prevState,
        selectedCamId: id,
      }));
      //UniqueCount("",id);
    } else {
      setUniqueCount((prevState) => ({
        ...prevState,
        selectedCamId: "",
      }));
    }
  };
  const onUniqueDateChange = (date) => {
    if (date) {
      setUniqueCount((prevState) => ({
        ...prevState,
        selectedDate: dayjs(date).format("YYYY-MM-DD"),
      }));
      //UniqueCount("",id);
      console.log(dayjs(date).format("YYYY-MM-DD"));
    } else {
      setUniqueCount((prevState) => ({
        ...prevState,
        selectedDate: "",
      }));
    }
  };


  function PeakDay() {
    axiosClient
      .get(
        `/livereport/getpeakday?startDate=${mainFilter.startDate}&endDate=${mainFilter.endDate}&cameraId=${PeakDayCount.selectedCamId}`
      )
      .then((response) => {
        if (response.data.issuccess === true) {
          //console.log("Peak Day count change------------------", response.data.data);
          setPeakDayCount((prevState) => ({
            ...prevState,
            TotalCount: dayjs(response.data.data).format("DD-MM-YYYY"),
          }));
        }
      })
      .catch((err) => {
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          console.log("Error---------------", err.msg);
          message.error(err);
        }
      });
  }
  const onPeakDayCamChange = (id) => {
    if (id) {
      setPeakDayCount((prevState) => ({
        ...prevState,
        selectedCamId: id,
      }));
      //UniqueCount("",id);
    } else {
      setPeakDayCount((prevState) => ({
        ...prevState,
        selectedCamId: "",
      }));
    }
  };
  const onPeakDayChange = (val) => {
    if (val == 1) {
      setPeakDayCount((prevState) => ({
        ...prevState,
        startDate: dayjs().subtract(8, "day").format("YYYY-MM-DD"),
        endDate: dayjs().subtract(1, "day").format("YYYY-MM-DD"),
      }));
    } else if (val == 2) {
      setPeakDayCount((prevState) => ({
        ...prevState,
        startDate: dayjs().subtract(16, "day").format("YYYY-MM-DD"),
        endDate: dayjs().subtract(1, "day").format("YYYY-MM-DD"),
      }));
    } else if (val == 3) {
      setPeakDayCount((prevState) => ({
        ...prevState,
        startDate: dayjs().startOf("month").format("YYYY-MM-DD"),
        endDate: dayjs().subtract(1, "day").format("YYYY-MM-DD"),
      }));
    }
  };


  function getPasserByCount() {
    axiosClient
      .get(
        `/livereport/getpasserbycount?camId=&date=${PasserTableValue.selecteddate}`
      )
      .then((response) => {
        if (response.data.issuccess === true) {
          // console.log(
          //   "getpasserbycount count------------------",
          //   response.data.data
          // );
          const updatedData = response.data.data.data
            .filter((item) => item.hour >= 7)
            .sort((a, b) => b.hour - a.hour) // Sort by hour in descending order
            .map((item) => ({
              hour: moment(item.hour, "HH").format("h A"),
              passerby: item.passerby || 0, // Set passerby to 0 if null, undefined, or empty
              ingress: item.ingress || 0, // Set ingress to 0 if null, undefined, or empty
            }));

          //console.log("TABLE--------------", updatedData);
          setPasserTableValue((prev) => ({
            ...prev,
            table: updatedData,
          }));
        }
      })
      .catch((err) => {
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  }

  const onPasserDateChange = (date) => {
    if (date) {
      setPasserTableValue((prev) => ({
        ...prev,
        selecteddate: dayjs(date).format("YYYY-MM-DD"),
      }));
    } else {
      setPasserTableValue((prev) => ({
        ...prev,
        selecteddate: "",
      }));
    }
  };
  //passer table column
  const PasserColumn = [
    {
      title: "Hour",
      dataIndex: "hour",
      key: "hour",
      render: (text) => <span style={{ color: "black" }}>{text}</span>,
      align: "center",
    },
    {
      title: "Passerby",
      dataIndex: "passerby",
      key: "passerby",
      render: (text) => <span style={{ color: "black" }}>{text}</span>,
      align: "center",
    },
    {
      title: "Ingress",
      dataIndex: "ingress",
      key: "ingress",
      render: (text) => <span style={{ color: "black" }}>{text}</span>,
      align: "center",
    },
  ];
  const staticData = [
    { key: '1', hour: '07:00', passerby: 5, ingress: 3 },
    { key: '2', hour: '08:00', passerby: 10, ingress: 6 },
    { key: '3', hour: '09:00', passerby: 15, ingress: 9 },
    { key: '4', hour: '10:00', passerby: 20, ingress: 12 },
    { key: '5', hour: '11:00', passerby: 25, ingress: 15 },
    { key: '6', hour: '12:00', passerby: 30, ingress: 18 },
    { key: '7', hour: '13:00', passerby: 35, ingress: 21 },
    { key: '8', hour: '14:00', passerby: 40, ingress: 24 },
    { key: '9', hour: '15:00', passerby: 45, ingress: 27 },
    { key: '10', hour: '16:00', passerby: 50, ingress: 30 },
    { key: '11', hour: '17:00', passerby: 55, ingress: 33 },
    { key: '12', hour: '18:00', passerby: 60, ingress: 36 },
    { key: '13', hour: '19:00', passerby: 65, ingress: 39 },
    { key: '14', hour: '20:00', passerby: 70, ingress: 42 },
    { key: '15', hour: '21:00', passerby: 75, ingress: 45 },
    { key: '16', hour: '22:00', passerby: 80, ingress: 48 },
    { key: '17', hour: '23:00', passerby: 85, ingress: 51 },
  ];
  const paginationConfig = {
    pageSize: 3,
  };
  //gender age graph male female count based on age
  const [AgeMaleFemaleCount, setAgeMaleFemaleCount] = useState({
    graph: {
      series: [
        {
          name: "Male",
          data: [],
        },
        {
          name: "Female",
          data: [],
        },
      ],
      options: {
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          title: {
            text: "Count",
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: (val) => `${val} Count`,
          },
        },
      },
    },
    selecteddate: "",
  });

  useEffect(() => {
    AgeBasedMaleFemaleCount(AgeMaleFemaleCount);
  }, [AgeMaleFemaleCount.selecteddate]);

  //  // Handle null or zero values for gender percentages
  //  const malePercentage =
  //  responseData.male_percentage !== null
  //    ? parseInt(responseData.male_percentage)
  //    : 0;
  // const femalePercentage =
  //  responseData.female_percentage !== null
  //    ? parseInt(responseData.female_percentage)
  //    : 0;

  // // Update Gender Distribution Chart Data
  // setChartDatapieGender({
  //  ...chartDatapieGender,
  //  series: [malePercentage, femalePercentage],
  // });

  // // Handle zero values for age distribution
  // const ageLabels = responseData.age_distribution.map(
  //  (group) => group.age
  // );
  // const ageCounts = responseData.age_distribution.map(
  //  (group) => group.count
  // );
  // setChartDatapieAge({
  //  ...chartDatapieAge,
  //  options: { ...chartDatapieAge.options, labels: ageLabels },
  //  series: ageCounts,
  // });

  const AgeBasedMaleFemaleCount = () => {
    axiosClient
      .get(
        `/livereport/getageandgender?date=${mainFilter.startDate}&endDate=${mainFilter.endDate}&camid=`
      )
      .then((response) => {
        if (response.data.issuccess === true) {
          const responseData = response.data.data.gender_based_age_distribution;
          console.log("Age based gender -----------", responseData);
          const maleData = responseData.map((item) => item.maleCount);
          const femaleData = responseData.map((item) => item.femaleCount);
          const categories = responseData.map((item) => item.ageRange);

          setAgeMaleFemaleCount((prevState) => ({
            ...prevState,
            graph: {
              ...prevState.graph,
              series: [
                {
                  name: "Male",
                  data: maleData,
                },
                {
                  name: "Female",
                  data: femaleData,
                },
              ],
              options: {
                ...prevState.graph.options,
                xaxis: {
                  categories: categories,
                },
              },
            },
          }));
        }
      })
      .catch((err) => {
        console.log("Error:", err);
        //setLoading(false);
        if (err.response && err.response.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.message);
        }
      });
  };

  const onDateChangeAgeBasedMaleFemaleCount = (date) => {
    if (date) {
      setAgeMaleFemaleCount((prevState) => ({
        ...prevState,
        selecteddate: dayjs(date).format("YYYY-MM-DD"),
      }));
    } else {
      setAgeMaleFemaleCount((prevState) => ({
        ...prevState,
        selecteddate: "",
      }));
    }
  };

  //PDF
  const generatePDF = async () => {
    if (containerRef.current && targetRef.current) {
      setDownloadDashboard(true);
      console.log("container adding pdf");

      try {
        const pdfExportComponent = containerRef.current;
        await pdfExportComponent.save();
        setDownloadDashboard(false);
      } catch (error) {
        console.log("unable to save", error);
      }
      console.log("container save the pdf");
    }
  };

  //Ingress and engress graph
  useEffect(() => {
    axiosClient
      .get(
        `/livereport/getingressegress?date=` +
          HandleDateForIngressEgress.SelectedDate +
          `&camid=` +
          ""
      )
      .then((response) => {
        if (response.data.issuccess === true) {
          setingressloading(false);

          const list_hour = map(response.data.data.total_records_hour, "hour");

          const list_ingress = map(
            response.data.data.total_records_hour,
            "totalingress"
          );
          const list_egress = map(
            response.data.data.total_records_hour,
            "totalegress"
          );
          setChartData((prev) => ({
            ...prev,
            options: {
              ...prev.options,
              xaxis: {
                // ...prev.options.xaxis,
                categories: list_hour,
              },
            },
            series: [
              {
                name: "Ingress",
                data: list_ingress,
              },
              {
                name: "Egress",
                data: list_egress,
              },
            ],
          }));
        }
      })
      .catch((err) => {
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Network error");
        } else {
          message.error(err.message);
        }
      });
  }, [HandleDateForIngressEgress.SelectedDate]);

  const HandleDateselectIngressEgress = (date) => {
    if (date === null || date === undefined) {
      setHandleDateForIngressEgress((prev) => ({
        ContinueFetch: true,
        SelectedDate: "",
      }));
    } else {
      setHandleDateForIngressEgress((prev) => ({
        ContinueFetch: false,
        SelectedDate: dayjs(date).format("YYYY-MM-DD"),
      }));
    }
  };

  return (
    <div style={{ margin: "1rem" }}>
      <Row
        justify={"end"}
        gutter={[30, 30]}
        style={{ margin: "15px", width: "100%" }}
      >
        <Flex style={{ height: "100%", width: "100%" }} align="center" gap={50}>
          <Flex
            align="center"
            gap={10}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Title level={5} style={{ fontFamily: "Helvetica" }}>
              Filter:
            </Title>

            <Select
              style={{ width: "10rem" }}
              defaultValue={2}
              onChange={onMainFilterChange}
            >
             
              <Option value={2}>Weekly</Option>
              <Option value={3}>Last 15 days</Option>
              <Option value={4}>Monthly</Option>
              <Option value={1}>Single Day</Option>
              <Option value={5}>Select date</Option>
            </Select>
          </Flex>

          {mainFilter.selectFilter === 5 ? (
            <RangePicker
              onChange={onMainRangeChange}
              disabledDate={(current) => {
                // Disable today and future dates
                const isFutureOrToday =
                  current >= dayjs().subtract(1, "day").endOf("day");
                // Disable dates older than 45 days from yesterday
                const isBefore45DaysAgo =
                  current < dayjs().subtract(45, "day").startOf("day");
                return isFutureOrToday || isBefore45DaysAgo;
              }}
            />
          ) : (
            ""
          )}
          {mainFilter.selectFilter === 1 ? (
            <DatePicker
              //style={{ width: "6rem" }}
              format={"DD-MM-YYYY"}
              className="new_card_date"
              onChange={onMainFilterDateChange}
              disabledDate={(current) =>
                current >= dayjs().subtract(1, "day").endOf("day")
              }
              value={dayjs(mainFilter.startDate)}
            />
          ) : (
            ""
          )}

          {/* <Button
              title="Download"
              type="default"
              htmlType="submit"
              loading={DownloadDashboard}
              onClick={generatePDF}
            >
              Download
            </Button> */}
        </Flex>
      </Row>

      <PDFExport
        margin={20}
        ref={containerRef}
        paperSize="auto"
        fileName={`footfall-report-${moment().format(
          "YYYY-MM-DD-HH-mm-ss"
        )}.pdf`}
        title="Footfall"
      >
        <div ref={targetRef}>
          {RenderCompController.DwellFootfall && (
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 24, offset: 0 }}
              xl={{ span: 24, offset: 0 }}
            >
              <Row gutter={[20, 30]}>
                <Col
                  xs={{ span: 24, offset: 0 }}
                  sm={{ span: 22, offset: 0 }}
                  md={{ span: 22, offset: 0 }}
                  lg={{ span: 24, offset: 0 }}
                  xl={{ span: 24, offset: 0 }}
                  //style={{backgroundColor:"red"}}
                >
                  <Row
                    align="stretch"
                    justify="space-between"
                    gutter={[20, 30]}
                  >
                    <Col
                      xs={{ span: 24, offset: 0 }}
                      sm={{ span: 24, offset: 0 }}
                      md={{ span: 24, offset: 0 }}
                      lg={{ span: 24, offset: 0 }}
                      xl={{ span: 24, offset: 0 }}
                    >
                      <Row gutter={[20, 30]}>
                        <Col
                          xs={{ span: 24, offset: 0 }}
                          sm={{ span: 12, offset: 0 }}
                          md={{ span: 12, offset: 0 }}
                          lg={{ span: 16, offset: 0 }}
                          xl={{ span: 16, offset: 0 }}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "1rem",
                          }}
                        >
                          <Row gutter={[20, 30]}>
                            <Col
                              xs={{ span: 24, offset: 0 }}
                              sm={{ span: 12, offset: 0 }}
                              md={{ span: 12, offset: 0 }}
                              lg={{ span: 12, offset: 0 }}
                              xl={{ span: 12, offset: 0 }}
                            >
                              <Card
                                className="ingress-hover"
                                style={{
                                  cursor: "pointer",
                                  userSelect: "none",
                                  boxShadow: "none",
                                  minHeight: "172px",
                                }}
                                title={
                                  <>
                                    <Row justify="space-between" align="middle">
                                      <Col>
                                        <Title
                                          level={5}
                                          type="secondary"
                                          style={{ fontFamily: "Helvetica" }}
                                        >
                                          Unique FootFall
                                        </Title>
                                      </Col>
                                      <Col
                                        style={{ display: "flex", gap: "5px" }}
                                      >
                                        <Col>
                                          <Select
                                            placeholder={
                                              <Text type="secondary">
                                                camera
                                              </Text>
                                            }
                                            onChange={onUniqueCamChange}
                                            style={{
                                              width: 120,
                                            }}
                                          >
                                            {uniqueCount.cameraList.map(
                                              (item) => {
                                                return (
                                                  <Option value={item.cameraid}>
                                                    {item.cameraName}
                                                  </Option>
                                                );
                                              }
                                            )}
                                          </Select>
                                        </Col>
                                        <Col>
                                          <DatePicker
                                            //style={{ width: "6rem" }}
                                            format={"DD-MM-YYYY"}
                                            className="new_card_date"
                                            onChange={onUniqueDateChange}
                                            disabledDate={(current) =>
                                              current >=
                                              dayjs()
                                                .subtract(1, "day")
                                                .endOf("day")
                                            }
                                            defaultValue={dayjs().subtract(
                                              9,
                                              "day"
                                            )}
                                          />
                                        </Col>
                                      </Col>
                                    </Row>
                                  </>
                                }
                                styles={{
                                  body: { paddingTop: 0, paddingBottom: 0 },
                                }}
                              >
                                <div>
                                  <Title
                                    level={1}
                                    style={{
                                      fontWeight: "normal",
                                      fontFamily: "Helvetica",
                                    }}
                                  >
                                    {uniqueCount.TotalCount}
                                  </Title>
                                </div>
                              </Card>
                            </Col>
                            <Col
                              xs={{ span: 24, offset: 0 }}
                              sm={{ span: 12, offset: 0 }}
                              md={{ span: 12, offset: 0 }}
                              lg={{ span: 12, offset: 0 }}
                              xl={{ span: 12, offset: 0 }}
                            >
                              <Card
                                className="ingress-hover"
                                style={{
                                  cursor: "pointer",
                                  userSelect: "none",
                                  boxShadow: "none",
                                  minHeight: "172px",
                                }}
                                title={
                                  <>
                                    <Row justify="space-between" align="middle">
                                      <Col>
                                     <Title type="secondary" style={{fontFamily:"Helvetica"}} level={5}> {mainFilter.selectFilter === 1
                      ? "Peak Day"
                      : mainFilter.selectFilter === 2
                      ? "Weekly Peak Day"
                      : mainFilter.selectFilter === 3
                      ? "Last 15 Days Peak Day"
                      : mainFilter.selectFilter === 4
                      ? "Monthly Peak Day"
                      : "Date range Peak Day"}</Title>
                                      </Col>
                                      <Col>
                                      <Text type="secondary">Selected Date: {mainFilter.selectFilter==1?dayjs(mainFilter.startDate).format("DD-MM-YYYY"):dayjs(mainFilter.startDate).format("DD-MM-YYYY")+" - "+dayjs(mainFilter.endDate).format("DD-MM-YYYY")}</Text>
                                      </Col>
                                    </Row>
                                  </>
                                }
                                styles={{
                                  body: { paddingTop: 0, paddingBottom: 0 },
                                }}
                              >
                                <div>
                                  <Title
                                    level={1}
                                    style={{
                                      fontWeight: "normal",
                                      fontFamily: "Helvetica",
                                    }}
                                  >
                                    {PeakDayCount.TotalCount}
                                  </Title>
                                </div>
                              </Card>
                            </Col>
                          </Row>

                          <Row gutter={[20, 30]}>
                            <Col
                              xs={{ span: 24, offset: 0 }}
                              sm={{ span: 12, offset: 0 }}
                              md={{ span: 8, offset: 0 }}
                              lg={{ span: 12, offset: 0 }}
                              xl={{ span: 12, offset: 0 }}
                            >
                              <Card
                                style={{
                                  cursor: "pointer",
                                  userSelect: "none",
                                  boxShadow: "none",
                                  minHeight: "172px",
                                }}
                                title={
                                  <>
                                    <Row
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <Col>
                                        <Title
                                          level={5}
                                          type="secondary"
                                          style={{ fontFamily: "Helvetica" }}
                                        >
                                          Engaged & Bounced
                                        </Title>
                                      </Col>
                                      <Col>
                                        <Dropdown
                                          menu={{
                                            items: EngaedandBouncedController,
                                          }}
                                        >
                                          <Button>
                                            <Space>
                                              <Text>Select camera</Text>
                                              <AiTwotoneEdit />
                                            </Space>
                                          </Button>
                                        </Dropdown>
                                      </Col>
                                    </Row>
                                  </>
                                }
                                styles={{
                                  body: { paddingTop: 0, paddingBottom: 0 },
                                }}
                              >
                                <Row>
                                  <Col>
                                    {EngagedandBouncedData.CameraData && (
                                      <Tag>
                                        {
                                          EngagedandBouncedData.CameraData
                                            .cameraName
                                        }
                                      </Tag>
                                    )}
                                  </Col>
                                  <Col>
                                    {EngagedandBouncedData.ZoneData && (
                                      <Tag>
                                        {EngagedandBouncedData.ZoneData.Area}
                                      </Tag>
                                    )}
                                  </Col>
                                </Row>
                                <div style={{ margin: 0, padding: 0 }}>
                                  <>
                                    <Row justify="space-between">
                                      <Col>
                                        <Title
                                          level={2}
                                          style={{
                                            fontWeight: "normal",
                                            fontFamily: "Helvetica",
                                          }}
                                        >
                                          Engaged :{" "}
                                          {EngagedandBouncedData.ZoneData
                                            ? EngagedandBouncedData.ZoneData
                                                .engaged
                                            : 0}
                                        </Title>
                                      </Col>
                                      <Col>
                                        <Title
                                          level={2}
                                          style={{
                                            fontWeight: "normal",
                                            fontFamily: "Helvetica",
                                          }}
                                        >
                                          Bounced :{" "}
                                          {EngagedandBouncedData.ZoneData
                                            ? EngagedandBouncedData.ZoneData
                                                .bounced
                                            : 0}
                                        </Title>
                                      </Col>
                                    </Row>
                                  </>
                                </div>
                              </Card>
                            </Col>
                            <Col
                              xs={{ span: 24, offset: 0 }}
                              sm={{ span: 12, offset: 0 }}
                              md={{ span: 8, offset: 0 }}
                              lg={{ span: 12, offset: 0 }}
                              xl={{ span: 12, offset: 0 }}
                            >
                              <Card
                                className="ingress-hover"
                                style={{
                                  cursor: "pointer",
                                  userSelect: "none",
                                  boxShadow: "none",
                                  minHeight: "172px",
                                }}
                                title={
                                  <>
                                    <Row justify="space-between" align="middle">
                                      <Col>
                                        <Title
                                          level={5}
                                          type="secondary"
                                          style={{ fontFamily: "Helvetica" }}
                                        >
                                          DwellTime
                                        </Title>
                                      </Col>
                                      <Col>
                                        <DatePicker
                                          className="dwell_date"
                                          format={"DD-MM-YYYY"}
                                          onChange={handleDateSelect}
                                          disabledDate={(current) =>
                                            current >=
                                            dayjs()
                                              .subtract(1, "day")
                                              .endOf("day")
                                          }
                                          defaultValue={dayjs().subtract(
                                            9,
                                            "day"
                                          )}
                                        />
                                      </Col>
                                    </Row>
                                  </>
                                }
                                styles={{
                                  body: { paddingTop: 0, paddingBottom: 0 },
                                }}
                              >
                                <div>
                                  <Title
                                    level={1}
                                    style={{
                                      fontWeight: "normal",
                                      fontFamily: "Helvetica",
                                    }}
                                  >
                                    {dwelltimedata !== null &&
                                    dwelltimedata !== undefined
                                      ? dwelltimedata
                                      : 0}
                                  </Title>
                                </div>
                              </Card>
                            </Col>
                          </Row>
                        </Col>

                        <Col
                          xs={{ span: 24, offset: 0 }}
                          sm={{ span: 12, offset: 0 }}
                          md={{ span: 12, offset: 0 }}
                          lg={{ span: 8, offset: 0 }}
                          xl={{ span: 8, offset: 0 }}
                        >
                          <Card
                            style={{ height: "22.5rem" }}
                            //styles={{ body: { minHeight: "100%" } }}
                            title={
                              <>
                                <Flex
                                  gap={5}
                                  align="center"
                                  justify="space-between"
                                >
                                  <Title
                                    level={5}
                                    type="secondary"
                                    style={{ fontFamily: "Helvetica" }}
                                  >
                                    Passer Count table
                                  </Title>
                                  <Col>
                                    <DatePicker
                                      //  style={{ width: "7rem", display:"flex",alignItems:"center"}}
                                      format={"DD-MM-YYYY"}
                                      className="new_card_date"
                                      onChange={onPasserDateChange}
                                      disabledDate={(current) =>
                                        current >=
                                        dayjs().subtract(1, "day").endOf("day")
                                      }
                                      defaultValue={dayjs().subtract(1, "day")}
                                    />
                                  </Col>
                                </Flex>
                              </>
                            }
                          >
                            <Table
                              dataSource={PasserTableValue.table}
                              columns={PasserColumn}
                              rowKey="hour"
                              pagination={paginationConfig}
                            />
                          </Card>
                        </Col>
                      </Row>
                    </Col>
                    <Col
                      xs={{ span: 24, offset: 0 }}
                      sm={{ span: 24, offset: 0 }}
                      md={{ span: 24, offset: 0 }}
                      lg={{ span: 24, offset: 0 }}
                      xl={{ span: 24, offset: 0 }}
                    >
                      <Row gutter={[20, 30]}>
                        <Col
                          xs={{ span: 24, offset: 0 }}
                          sm={{ span: 24, offset: 0 }}
                          md={{ span: 12, offset: 0 }}
                          lg={{ span: 12, offset: 0 }}
                          xl={{ span: 12, offset: 0 }}
                        >
                          <Card
                            loading={ingressloading}
                            // hoverable

                            style={{
                              cursor: "pointer",
                              userSelect: "none",
                              boxShadow: "none",
                            }}
                            title={
                              <Row justify={"space-between"} align={"middle"}>
                                <Col>
                                  <Row gutter={5} align={"middle"}>
                                    <Col>
                                      <Title
                                        level={5}
                                        type="secondary"
                                        style={{ fontFamily: "Helvetica" }}
                                      >
                                        Ingress and Egress Counts per Hour
                                      </Title>
                                    </Col>
                                    {/* <Col>
                                        {HandleDateForIngressEgress.SelectedDate ? (
                                          <>
                                            <Tag color="green">
                                              {
                                                HandleDateForIngressEgress.SelectedDate
                                              }
                                            </Tag>
                                          </>
                                        ) : (
                                          <LiveTag />
                                        )}
                                      </Col> */}
                                  </Row>
                                </Col>
                                <Col>
                                  <DatePicker
                                    className="dwell_date"
                                    format={"DD-MM-YYYY"}
                                    onChange={HandleDateselectIngressEgress}
                                    disabledDate={(current) =>
                                      current >=
                                      dayjs().subtract(1, "day").endOf("day")
                                    }
                                    defaultValue={dayjs().subtract(1, "day")}
                                  />
                                </Col>
                              </Row>
                            }
                          >
                            {chartData.series && chartData.series.length > 0 ? (
                              <div className="app">
                                <div className="row">
                                  <div className="mixed-chart">
                                    <Chart
                                      options={chartData.options}
                                      series={chartData.series}
                                      type="area"
                                      height={"200%"}
                                    />
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div
                                style={{
                                  textAlign: "center",
                                  padding: "20px",
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: "16px",
                                    color: "gray",
                                  }}
                                >
                                  No Data Available
                                </Text>
                              </div>
                            )}
                          </Card>
                        </Col>
                        <Col
                          xs={{ span: 24, offset: 0 }}
                          sm={{ span: 24, offset: 0 }}
                          md={{ span: 12, offset: 0 }}
                          lg={{ span: 12, offset: 0 }}
                          xl={{ span: 12, offset: 0 }}
                        >
                          <DwellHourComp />
                        </Col>
                      </Row>

                      {/* //DwellHourComp */}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          )}
          {RenderCompController.EngagedBounced && (
            <Col
              xs={{ span: 24 / 1, offset: 0 }}
              sm={{ span: 24 / 1, offset: 0 }}
              md={{ span: 24 / 1, offset: 0 }}
              lg={{ span: 24 / 1, offset: 0 }}
              xl={{ span: 24 / 1, offset: 0 }}
            >
              <Row style={{ width: "100%", marginTop: "1rem" }}>
                <EngageAndBounce />
              </Row>
            </Col>
          )}

          {/* ---------- */}

          {/* {RenderCompController.AgeGender && ( */}
          <Col
            xs={{ span: 24 / 1, offset: 0 }}
            sm={{ span: 24 / 1, offset: 0 }}
            md={{ span: 24 / 1, offset: 0 }}
            lg={{ span: 24 / 1, offset: 0 }}
            xl={{ span: 24 / 1, offset: 0 }}
          >
            <Card
              style={{
                // borderRadius: "15px",
                // height: "360px",
                userSelect: "none",
                boxShadow: "none",
                marginTop: "1rem",
              }}
              title={
                <>
                  <Row justify={"space-between"} align={"middle"}>
                    <Col>
                      <Title
                        level={5}
                        type="secondary"
                        style={{ fontFamily: "Helvetica" }}
                      >
                        Total detected Gender Distribution in %
                      </Title>
                    </Col>
                    <Col>
                    <Text type="secondary">Selected Date: {mainFilter.selectFilter==1?dayjs(mainFilter.startDate).format("DD-MM-YYYY"):dayjs(mainFilter.startDate).format("DD-MM-YYYY")+" - "+dayjs(mainFilter.endDate).format("DD-MM-YYYY")}</Text>
                    </Col>
                    <Col>
                      <Title
                        level={5}
                        type="secondary"
                        style={{ fontFamily: "Helvetica" }}
                      >
                        Total detected Age Distribution in %
                      </Title>
                    </Col>
                  </Row>
                </>
              }
            >
              <Row
                style={{ height: "100%" }}
                justify={"space-between"}
                align={"middle"}
              >
                <Col
                  xs={{ span: 23 / 1, offset: 0 }}
                  sm={{ span: 23 / 1, offset: 0 }}
                  md={{ span: 11 / 1, offset: 0 }}
                  lg={{ span: 11 / 1, offset: 0 }}
                  xl={{ span: 11, offset: 0 }}
                >
                  <div style={{ height: "250px" }}>
                    {hasNonZeroValues ? (
                      <Chart
                        options={chartDatapieGender.options}
                        series={chartDatapieGender.series}
                        type="donut"
                        height="100%"
                      />
                    ) : (
                      <Col
                        xs={{ span: 24, offset: 0 }}
                        sm={{ span: 24, offset: 0 }}
                        md={{ span: 24, offset: 0 }}
                        lg={{ span: 24, offset: 0 }}
                        xl={{ span: 24, offset: 0 }}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          minHeight: "100%",
                          padding: "30px",
                        }}
                      >
                        <Empty />
                      </Col>
                    )}
                  </div>
                </Col>
                <Divider type="vertical" style={{ height: "250px" }} />
                <Col
                  xs={{ span: 23 / 1, offset: 0 }}
                  sm={{ span: 23 / 1, offset: 0 }}
                  md={{ span: 11 / 1, offset: 0 }}
                  lg={{ span: 11 / 1, offset: 0 }}
                  xl={{ span: 11, offset: 0 }}
                  style={{ height: "100%" }}
                >
                  <div style={{ height: "250px" }}>
                    {hasNonZeroValues2 ? (
                      <Chart
                        options={chartDatapieAge.options}
                        series={chartDatapieAge.series}
                        type="donut"
                        height="100%"
                      />
                    ) : (
                      <Col
                        xs={{ span: 24, offset: 0 }}
                        sm={{ span: 24, offset: 0 }}
                        md={{ span: 24, offset: 0 }}
                        lg={{ span: 24, offset: 0 }}
                        xl={{ span: 24, offset: 0 }}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          minHeight: "100%",
                          padding: "30px",
                        }}
                      >
                        <Empty />
                      </Col>
                    )}
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
          {/* -------------------------------------------- */}

          {RenderCompController.PeakHours && (
            <div>
              <Col
                xs={{ span: 24 / 1, offset: 0 }}
                sm={{ span: 24 / 1, offset: 0 }}
                md={{ span: 24 / 1, offset: 0 }}
                lg={{ span: 24 / 1, offset: 0 }}
                xl={{ span: 24 / 1, offset: 0 }}
              >
                <Row gutter={[20, 30]}>
                  <Col
                    xs={{ span: 23 / 1, offset: 0 }}
                    sm={{ span: 20 / 1, offset: 0 }}
                    md={{ span: 18 / 1, offset: 0 }}
                    lg={{ span: 12 / 1, offset: 0 }}
                    xl={{ span: 12, offset: 0 }}
                  >
                    <Card
                      style={{
                        // borderRadius: "15px",
                        // height: "360px",
                        userSelect: "none",
                        boxShadow: "none",
                        marginTop: "1rem",
                      }}
                      title={
                        <>
                          <Row justify={"space-between"} align={"middle"}>
                            <Col>
                              <Title
                                level={5}
                                type="secondary"
                                style={{ fontFamily: "Helvetica" }}
                              >
                                Total detected gender age distribution in count
                              </Title>
                            </Col>
                            <Col>
                            <Text type="secondary">Selected Date: {mainFilter.selectFilter==1?dayjs(mainFilter.startDate).format("DD-MM-YYYY"):dayjs(mainFilter.startDate).format("DD-MM-YYYY")+" - "+dayjs(mainFilter.endDate).format("DD-MM-YYYY")}</Text>
                            </Col>
                          </Row>
                        </>
                      }
                    >
                      <div style={{ height: "250px" }}>
                        <ReactApexChart
                          options={AgeMaleFemaleCount.graph.options}
                          series={AgeMaleFemaleCount.graph.series}
                          type="bar"
                          height={250}
                        />
                      </div>
                    </Card>
                  </Col>
                  <Col
                    xs={{ span: 23 / 1, offset: 0 }}
                    sm={{ span: 20 / 1, offset: 0 }}
                    md={{ span: 18 / 1, offset: 0 }}
                    lg={{ span: 12 / 1, offset: 0 }}
                    xl={{ span: 12, offset: 0 }}
                  >
                    <Card
                      style={{
                        // borderRadius: "15px",
                        // height: "360px",
                        userSelect: "none",
                        boxShadow: "none",
                        marginTop: "1rem",
                      }}
                      title={
                        <>
                          <Row justify={"space-between"} align={"middle"}>
                            <Col>
                            <Title
                    level={5}
                    type="secondary"
                    style={{ fontFamily: "Helvetica" }}
                  >
                    {mainFilter.selectFilter === 1
                      ? "Peak Hour"
                      : mainFilter.selectFilter === 2
                      ? "Weekly Peak Hour"
                      : mainFilter.selectFilter === 3
                      ? "Last 15 Days Peak Hour"
                      : mainFilter.selectFilter === 4
                      ? "Monthly Peak Hour"
                      : "Specific Date range Peak Hour"}
                  </Title>
                            </Col>
                            <Col>
                              <Flex gap={10}>
                                {/* {PeakHoursController.search && (
                                  <RangePicker
                                    onChange={handleDateRangeChangeforPeakHours}
                                    style={{ marginBottom: "10px" }}
                                    disabledDate={(current) =>
                                      current && current > moment().endOf("day")
                                    }
                                    allowClear
                                  />
                                )}

                                <Dropdown
                                  // overlay={menuforpeakvalue}
                                  menu={{
                                    items: menuforpeakvalue,
                                    onClick: handleMenuClick2,
                                  }}
                                  placement="bottomRight"
                                >
                                  <Button>
                                    <Flex gap={10} align="center">
                                      Filters
                                      <LuFilter />
                                    </Flex>
                                  </Button> 
                                </Dropdown>*/}
                                 <Text type="secondary">Selected Date: {mainFilter.selectFilter==1?dayjs(mainFilter.startDate).format("DD-MM-YYYY"):dayjs(mainFilter.startDate).format("DD-MM-YYYY")+" - "+dayjs(mainFilter.endDate).format("DD-MM-YYYY")}</Text>
                              </Flex>
                            </Col>
                          </Row>
                        </>
                      }
                    >
                      <div style={{ height: "250px" }}>
                        <Chart
                          options={pickhoursweeklyData.options}
                          series={pickhoursweeklyData.options.series}
                          type="area"
                          height="100%"
                        />
                      </div>
                    </Card>
                  </Col>
                </Row>
              </Col>
              <Divider style={{ margin: "1rem 0rem" }}></Divider>
              <Col
                xs={{ span: 24 / 1, offset: 0 }}
                sm={{ span: 24 / 1, offset: 0 }}
                md={{ span: 24 / 1, offset: 0 }}
                lg={{ span: 24 / 1, offset: 0 }}
                xl={{ span: 24 / 1, offset: 0 }}
                style={{ marginTop: "1rem" }}
              >
                <Row gutter={[20, 30]}>
                  <Col
                    xs={{ span: 24, offset: 0 }}
                    sm={{ span: 24, offset: 0 }}
                    md={{ span: 12, offset: 0 }}
                    lg={{ span: 8, offset: 0 }}
                    xl={{ span: 8, offset: 0 }}
                  >
                    <Card
                      style={{ height: "33rem" }}
                      //bodyStyle={{ minHeight: "33rem",maxHeight:"33rem" }}
                      title={
                        <Title
                          level={5}
                          type="secondary"
                          style={{ fontFamily: "Helvetica" }}
                        >
                          Dwell Time Heatmap
                        </Title>
                      }
                    >
                      <Row>
                        <Col span={24}>
                          <div>
                          <Image
                                      src={heat}
                                      style={{
                                        height: "26rem",
                                        objectFit: "cover",
                                      }}
                                    />

                            {/* {HeatmapController.cameralist &&
                            HeatmapController.cameralist.length > 0 ? (
                              <Carousel showThumbs={false}>
                                {HeatmapController.cameralist.map((item) => (
                                  <Skeleton
                                    loading={HeatmapController.loading}
                                    active
                                    round
                                    key={item.cameraName}
                                  >
                                    <Image
                                      src={`data:image/png;base64,${item.heatMapBase64}`}
                                      style={{
                                        height: "26rem",
                                        objectFit: "cover",
                                      }}
                                    />
                                     
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Text className="label-heatmap">
                                        Avg: {item.avg} sec
                                        <br />
                                        Camera: {item.cameraName}
                                      </Text>
                                    </div>
                                  </Skeleton>
                                ))}
                              </Carousel>
                            ) : (
                              <Col
                                xs={{ span: 24, offset: 0 }}
                                sm={{ span: 24, offset: 0 }}
                                md={{ span: 24, offset: 0 }}
                                lg={{ span: 24, offset: 0 }}
                                xl={{ span: 24, offset: 0 }}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  minHeight: "100%",
                                  padding: "30px",
                                }}
                              >
                                <Empty />
                              </Col>
                            )} */}
                          </div>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                  <Col
                    xs={{ span: 24, offset: 0 }}
                    sm={{ span: 24, offset: 0 }}
                    md={{ span: 12, offset: 0 }}
                    lg={{ span: 8, offset: 0 }}
                    xl={{ span: 8, offset: 0 }}
                  >
                    <Card
                      style={{ height: "33rem" }}
                      //styles={{ body: { minHeight: "100%" } }}
                      title={
                        <>
                          <Row
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              padding: "1rem 0rem",
                            }}
                          >
                            <Col span={6}>
                              <Flex gap={5}>
                                <Title
                                  level={5}
                                  type="secondary"
                                  style={{ fontFamily: "Helvetica" }}
                                >
                                  Footfall Direction <GrDirections />
                                </Title>
                              </Flex>

                              <Tag>
                                {FootfallDirectionHourlyController.selectedcamName ? (
                                  FootfallDirectionHourlyController.selectedcamName +
                                  " - "
                                ) : (
                                  <></>
                                )}
                                {FootfallDirectionHourlyController.selectedDate ? (
                                  FootfallDirectionHourlyController.selectedDate +
                                  " - "
                                ) : (
                                  <></>
                                )}
                                {FootfallDirectionHourlyController.Hour ? (
                                  FootfallDirectionHourlyController.Hour + " hr"
                                ) : (
                                  <></>
                                )}
                              </Tag>
                            </Col>
                            <Col>
                              <Flex gap={5}>
                                <Select
                                  placeholder={
                                    <Text type="secondary">camera</Text>
                                  }
                                  onChange={HandleSelectCameraDirection}
                                  style={{
                                    width: 120,
                                  }}
                                  options={
                                    FootfallDirectionHourlyController.camlist
                                  }
                                />

                                <Col
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "5px",
                                  }}
                                >
                                  <Col>
                                    <DatePicker
                                      style={{ width: "120px" }}
                                      format={"DD-MM-YYYY"}
                                      placeholder="Date"
                                      onChange={onChangeTimeDirection}
                                      defaultValue={dayjs().subtract(9,"day")}
                                      disabledDate={(current) =>
                                        current >=
                                        dayjs().subtract(1, "day").endOf("day")
                                      }
                                    />
                                  </Col>
                                  <Col>
                                    <TimePicker
                                      style={{ width: "120px" }}
                                      placeholder="Hour"
                                      disabledTime={disabledTime}
                                      format={"HH"}
                                      onChange={
                                        handleHourChangeFootfalldirection
                                      }
                                    
                                      
                                    />
                                  </Col>
                                </Col>
                              </Flex>
                            </Col>
                          </Row>
                        </>
                      }
                    >
                      <div style={{ maxHeight: "250px" }}>
                        {FootfallDirectionHourlyController.DirectionImage &&
                        FootfallDirectionHourlyController.DirectionImage ? (
                          <Skeleton
                            style={{
                              maxHeight: "250px",
                              minHeight: "250px",
                            }}
                            loading={FootfallDirectionHourlyController.loading}
                            active
                            round
                          >
                            <Image
                              src={
                                "data:image/png;base64," +
                                FootfallDirectionHourlyController.DirectionImage
                              }
                              style={{
                                maxHeight: "370px",
                                minHeight: "370px",
                              }}
                            ></Image>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              {FootfallDirectionHourlyController.DirectionData !==
                              "No data" ? (
                                // FootfallDirectionHourlyController.DirectionData.map(
                                //   (item) => (
                                //     <Text className="label-Direction">
                                //       {item.directionname} :{" "}
                                //       {item.count}
                                //     </Text>
                                //   )
                                // )
                                <></>
                              ) : (
                                <></>
                              )}
                            </div>
                          </Skeleton>
                        ) : (
                          <Col
                            xs={{ span: 24, offset: 0 }}
                            sm={{ span: 24, offset: 0 }}
                            md={{ span: 24, offset: 0 }}
                            lg={{ span: 24, offset: 0 }}
                            xl={{ span: 23, offset: 0 }}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              // marginTop: "60px",
                            }}
                          >
                            <Empty />
                          </Col>
                        )}
                      </div>
                    </Card>
                  </Col>
                  <Col
                    xs={{ span: 24, offset: 0 }}
                    sm={{ span: 24, offset: 0 }}
                    md={{ span: 12, offset: 0 }}
                    lg={{ span: 8, offset: 0 }}
                    xl={{ span: 8, offset: 0 }}
                  >
                    <Card
                      style={{ height: "33rem" }}
                      title={
                        <Title
                          level={5}
                          type="secondary"
                          style={{ fontFamily: "Helvetica" }}
                        >
                          Footfall Analysis
                        </Title>
                      }
                    >
                      {chartFootfallAnalysis.series.length > 0 ? (
                        <ReactApexChart
                          options={chartFootfallAnalysis.options}
                          series={chartFootfallAnalysis.series}
                          type="radar"
                          height={420}
                        />
                      ) : (
                        <Col
                          xs={{ span: 24, offset: 0 }}
                          sm={{ span: 24, offset: 0 }}
                          md={{ span: 24, offset: 0 }}
                          lg={{ span: 24, offset: 0 }}
                          xl={{ span: 24, offset: 0 }}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            minHeight: "100%",
                            padding: "30px",
                          }}
                        >
                          <Empty />
                        </Col>
                      )}
                    </Card>
                  </Col>
                </Row>
              </Col>
            </div>
          )}
        </div>
      </PDFExport>
    </div>
  );
};

export default Weekly;
