import React from "react";
// import "antd/dist/antd.css";
import { useNavigate } from "react-router-dom";
import {
  Layout,
  Menu,
  Alert,
  Row,
  Col,
  Table,
  Image,
  Card,
  Upload,
  message,
  Space,
  Input,
  Button,
  Form,
  Typography,
  Checkbox,
  Select,
  DatePicker,
  Empty,
  Skeleton,
  Pagination,
  Tooltip,
} from "antd";
import { useState } from "react";
import {
  UserOutlined,
  DeleteOutlined,
  PlusOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
// import axiosClient from "../Authentication/ApiCall";
import { useEffect } from "react";
import authService from "../../Authentication/authService";
import axiosClient from "../../Authentication/ApiCall";
import moment from "moment/moment";
import { identity } from "@fullcalendar/react";
// import Title from "antd/lib/skeleton/Title";
// import authService from "../Authentication/authService";

const { Text, Link, Title } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;

export default function Anomaly() {
  document.title = "KEN - Add user images";

  const [formAnomaly] = Form.useForm();
  const [formTrained] = Form.useForm();

  const [TableData, setTableData] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [ImageName, setImageName] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [UserList, setUserList] = useState([]);
  const [ellipsisWrap, setellipsisWrap] = useState();
  const [selectedImages, setselectedImages] = useState([]);
  const [AnomalyImages, setAnomalyImages] = useState([]);
  const [cameralist, setcameralist] = useState([]);
  const [startdate, setstartdate] = useState("");
  const [enddate, setenddate] = useState("");
  const [cameraid, setcameraid] = useState();
  const [TrainedImages, setTrainedImages] = useState([]);
  const [anomalyloading, setanomalyloading] = useState();
  const [Trainedimageloading, setTrainedimageloading] = useState();
  const [CameraidTrained, setCameraidTrained] = useState();
  const [trainedTotalCount, settrainedTotalCount] = useState();
  const [trainedcurrentpage, settrainedcurrentpage] = useState();
  const [AnomalyTotalCount, setAnomalyTotalCount] = useState();
  const [AnomalycurrentPage, setAnomalycurrentPage] = useState();
  // const [isPreviewVisible, setPreviewVisible] = useState(false);

  useEffect(() => {
    axiosClient
      .get("anomaly/getAnomlayAssignedCameras")
      .then((response) => {
        if (response.data.issuccess === true) {
          // setcameralist(response.data.data);
          setcameralist(response.data.data);
        } else {
          message.error(response.data.data.msg);
        }
      })
      .catch((err) => {
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  }, []);

  const HandleAnomalyPagination = ({ id, startdate, enddate, page, size }) => {
    setanomalyloading(true);

    axiosClient
      .get(
        "anomaly/getAnomalyImagesforTrain?cameraid=" +
          id +
          "&&startdate=" +
          startdate +
          "&&enddate=" +
          enddate +
          "&&page=" +
          page +
          "&&size=" +
          size
      )
      .then((response) => {
        if (response.data.issuccess === true) {
          // setcameralist(response.data.data);
          setAnomalyImages(response.data.data);
          setanomalyloading(false);
          setAnomalyTotalCount(response.data.totaldatacount);
          setAnomalycurrentPage(response.data.page);
        } else {
          message.error(response.data.data);
        }
      })
      .catch((err) => {
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  };

  const HandleTrainedPagination = ({ id, startdate, enddate, page, size }) => {
    setTrainedimageloading(true);

    axiosClient
      .get(
        "anomaly/getTrainedAnomalyImages?cameraid=" +
          id +
          "&&startdate=" +
          startdate +
          "&&enddate=" +
          enddate +
          "&&page=" +
          page +
          "&&size=" +
          size
      )
      .then((response) => {
        if (response.data.issuccess === true) {
          // setcameralist(response.data.data);
          setTrainedImages(response.data.data);
          setTrainedimageloading(false);
          settrainedTotalCount(response.data.totaldatacount);
          settrainedcurrentpage(response.data.page);
        } else {
          message.error(response.data.data);
        }
      })
      .catch((err) => {
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  };

  const onFinishtrained = (values) => {
    setCameraidTrained(values.trainedcamera);
    setTrainedimageloading(true);

    axiosClient
      .get(
        "anomaly/getTrainedAnomalyImages?cameraid=" +
          values.trainedcamera +
          "&&startdate=" +
          startdate +
          "&&enddate=" +
          enddate +
          "&&page=" +
          1 +
          "&&size=" +
          12
      )
      .then((response) => {
        if (response.data.issuccess === true) {
        
          setTrainedImages(response.data.data);
       
          setTrainedimageloading(false);
          settrainedTotalCount(response.data.totaldatacount);
          settrainedcurrentpage(response.data.page);
        } else {
          message.error(response.data.data);
        }
      })
      .catch((err) => {
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  };

  const onFinish = (values) => {
    setanomalyloading(true);
    setcameraid(values.camera);

    axiosClient
      .get(
        "anomaly/getAnomalyImagesforTrain?cameraid=" +
          values.camera +
          "&&startdate=" +
          startdate +
          "&&enddate=" +
          enddate +
          "&&page=" +
          1 +
          "&&size=" +
          12
      )
      .then((response) => {
        if (response.data.issuccess === true) {
          // setcameralist(response.data.data);
          setAnomalyImages(response.data.data);
          setanomalyloading(false);
          setAnomalyTotalCount(response.data.totaldatacount);
          setAnomalycurrentPage(response.data.page);
        } else {
          message.error(response.data.data);
        }
      })
      .catch((err) => {
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });

    setCameraidTrained(values.camera);
    setTrainedimageloading(true);

    axiosClient
      .get(
        "anomaly/getTrainedAnomalyImages?cameraid=" +
          values.camera +
          "&&startdate=" +
          // startdate +
          "&&enddate=" +
          // enddate +
          "&&page=" +
          1 +
          "&&size=" +
          12
      )
      .then((response) => {
        if (response.data.issuccess === true) {
          // setcameralist(response.data.data);
          setTrainedImages(response.data.data);
         
          setTrainedimageloading(false);
          settrainedTotalCount(response.data.totaldatacount);
          settrainedcurrentpage(response.data.page);
        } else {
          message.error(response.data.data);
        }
      })
      .catch((err) => {
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  };

 
  const OnSubmit = () => {
    axiosClient
      .put("anomaly/sendRequestToAnomalyImagesTrain", {
        ids: selectedImages,
        cameraid: cameraid,
      })
      .then((response) => {
        if (response.data.issuccess === true) {
          message.success(response.data.msg);
          setselectedImages([]);
          setAnomalyImages([]);
          formAnomaly.resetFields();

          // setcameralist(response.data.data);
          // setAnomalyImages(response.data.data);
        } else {
          message.error(response.data.msg);
        }
      })
      .catch((err) => {
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  };

  const OnSelect = (val) => {
    // val.preventDefault();
    if (!selectedImages.includes(val)) {
      return setselectedImages((selectedImages) => [...selectedImages, val]);
    } else if (selectedImages.includes(val)) {
      // let remaining = selectedImages.slice(val);
      // setselectedImages(remaining);
      return setselectedImages((selectedImages) =>
        selectedImages.filter((image) => image !== val)
      );
    }
  };

  return (
    <>
      {/* <Row gutter={[30, 5]}> */}

      <Row
        gutter={[30, 5]}
        style={{
          display: "flex",
          justifyContent: "space-evenly",
        }}
      >
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 20, offset: 0 }}
          md={{ span: 18, offset: 0 }}
          lg={{ span: 16, offset: 0 }}
          xl={{ span: 12, offset: 0 }}
        >
          <Card hoverable bordered={true} title="Set Event For Camera">
            <Row>
              <Col
                xxl={{ span: 24, offset: 0 }}
                xs={{ span: 18, offset: 0 }}
                sm={{ span: 18, offset: 0 }}
                lg={{ span: 18, offset: 0 }}
                xl={{ span: 18, offset: 0 }}
                style={{ marginBottom: "1em" }}
              >
                <Form
                  form={formAnomaly}
                  name="addimage"
                  layout="inline"
                  onFinish={onFinish}
                >
                  <Form.Item
                    // wrapperCol={{ offset: 0, span: 8 }}
                    className="top-space"
                    // label="Select Camera"
                    name="camera"
                    rules={[
                      {
                        type: "integer",
                        required: true,
                        message: "Please select!",
                      },
                    ]}
                  >
                    <Select
                      // open={showOption}
                      allowClear
                      placeholder="Select camera"
                      onChange={() => {
                        setselectedImages([]);
                      }}
                      style={{ width: "100%" }}
                    >
                      {cameralist.map((option) => {
                        return (
                          <Option key={option.cameraid} value={option.cameraid}>
                            {option.cameraname}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    tooltip="Not a Mandatory field, if not selected, it will display last 10 Anomaly Images"
                    className="top-space"
                    label=" "
                    name="date"
                  >
                    <RangePicker
                      placeholder={["Select start Date ", "End Date"]}
                      ranges={{
                        Today: [moment(), moment()],
                        Yesterday: [
                          moment().subtract(1, "days"),
                          moment().subtract(1, "days"),
                        ],

                        "This week": [moment().startOf("week"), moment()],
                        "This Month": [moment().startOf("month"), moment()],
                      }}
                      format={"YYYY-MM-DD ddd"}
                      disabledDate={(current) => {
                        let customDate = moment().format("YYYY-MM-DD");
                        // return (
                        //   current && current > moment(customDate, "YYYY-MM-DD")
                        // );
                        return current && current > moment().endOf("day");
                      }}
                      // onChange={HandleDatePicker}
                      onChange={(values) => {
                        setstartdate(moment(values[0]).format("YYYY-MM-DD"));
                        setenddate(moment(values[1]).format("YYYY-MM-DD"));
                       
                      }}
                    />
                  </Form.Item>
                  <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
                    <Button
                      style={{ backgroundColor: "#3e3a75", border: "none" }}
                      type="primary"
                      // block
                      htmlType="submit"
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
                {/* <Space> */}

                {/* <Alert showIcon message="Get Report by Date"></Alert> */}
                {/* </Space> */}
              </Col>
            </Row>
            <Row className="top-space" gutter={[20, 20]}>
              <Skeleton loading={anomalyloading} avatar active>
                {AnomalyImages && AnomalyImages.length >= 1 ? (
                  AnomalyImages.map((item, index) => {
                    return selectedImages.includes(item.id) ? (
                      <Col
                        key={item.id}
                        // className={selectedImages.includes("jj") ? "focused-colum" : ""}
                        xxl={{ span: 6, offset: 0 }}
                        xs={{ span: 6, offset: 0 }}
                        sm={{ span: 6, offset: 0 }}
                        lg={{ span: 6, offset: 0 }}
                        xl={{ span: 6, offset: 0 }}
                        // style={{ borderWidth: "5px", paddingTop: "5px" }}
                        style={{
                          // width:"80",
                          maxWidth: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Card
                         
                          hoverable
                          bordered
                          bodyStyle={{ padding: "0px" }}
                          // style={{width:"100%"}}
                        >
                          <div>
                            <label className="option_item ">
                              <input
                                type="checkbox"
                                className="checkbox"
                                // value="hhh"
                                // style={{ paddingBottom: "25px" }}
                                defaultChecked
                              />
                              <div
                                className="option_inner facebook"
                                style={{ padding: "10px" }}
                              >
                                <div
                                  className="tickmark"
                                  // style={{ padding: "0px" }}
                                ></div>
                                <Image
                                  // style={{ height: "100px" }}
                                  // preview={{
                                  //   visible: isPreviewVisible,
                                  //   onVisibleChange: (visible, prevVisible) =>
                                  //     setPreviewVisible(visible),
                                  // }}
                                  alt="User Image"
                                  // src="/face.jpeg"
                                  src={
                                    "data:image/png;base64," +
                                    item.anomaly_image.replace(/\"/g, "")
                                  }
                                  style={{
                                    marginBottom: "10px",
                                    height: "100%",
                                  }}
                                />
                                <Link
                                  style={{ padding: "25px" }}
                                  onClick={(value) => {
                                    value.preventDefault();

                                    OnSelect(item.id);
                                    // setselectedImages((selectedImages) => [...selectedImages, val]);
                                  }}
                                  // onClick={() => setPreviewVisible(!isPreviewVisible)}
                                >
                                  Selected
                                </Link>
                              </div>
                            </label>
                          </div>
                        </Card>
                      </Col>
                    ) : (
                      <Col
                        // className={selectedImages.includes("jj") ? "focused-colum" : ""}
                        xxl={{ span: 6, offset: 0 }}
                        xs={{ span: 6, offset: 0 }}
                        sm={{ span: 6, offset: 0 }}
                        lg={{ span: 6, offset: 0 }}
                        xl={{ span: 6, offset: 0 }}
                        // style={{ borderWidth: "5px", paddingTop: "5px" }}
                        style={{
                          maxWidth: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                       
                      >
                        <Card
                         
                          hoverable
                          bordered
                          bodyStyle={{ padding: "0px" }}
                        >
                          <div>
                            <label className="option_item ">
                              
                              <div className="option_inner facebook">
                                <div className="tickmark"></div>
                                <Image
                                  // style={{ height: "100px" }}
                                  alt="User Image"
                                  // src="/face.jpeg"
                                  src={
                                    "data:image/png;base64," +
                                    item.anomaly_image
                                  }
                                  style={{
                                    marginBottom: "10px",
                                    minHeight: "100%",
                                  }}
                                />

                                <Link
                                  onClick={(value) => {
                                    value.preventDefault();

                                    OnSelect(item.id);
                                    // setselectedImages((selectedImages) => [...selectedImages, val]);
                                  }}
                                  // onClick={() => setPreviewVisible(!isPreviewVisible)}
                                >
                                  select
                                </Link>
                              </div>
                            </label>
                          </div>
                        </Card>
                      </Col>
                    );
                  })
                ) : (
                  <Row justify="center" style={{ minWidth: "100%" }}>
                    <Col>
                      <Empty
                        description={
                          <>
                            <Space align="center" direction="vertical">
                              <Text>No Images</Text>
                              <Text>Kindly select a cemera</Text>
                            </Space>
                          </>
                        }
                      />
                    </Col>
                  </Row>
                )}
              </Skeleton>
            </Row>

            <Row justify="end" className="top-space">
              <Col>
                <Pagination
                  total={AnomalyTotalCount}
                  pageSize={12}
                  current={AnomalycurrentPage}
                  showSizeChanger={false}
                  onChange={(page) => {
                    HandleAnomalyPagination({
                      id: cameraid,
                      startdate: "",
                      enddate: "",
                      page: page,
                      size: 12,
                    });
                  }}
                />
              </Col>
            </Row>

            {/* pagination={{
                // pageSize: 10,
                current: PageNo,
                total: PageTotal,
                showSizeChanger: false,
                onChange: (page) => {
                  HandleDatePicker("", "", page, 10);
                },

                // total: 10,
              }} */}

            {selectedImages && selectedImages.length >= 30 ? (
              <Row justify="center" className="top-space">
                <Col>
                  {/* <Space direction="horizontal"> */}

                  <Button type="primary" onClick={OnSubmit}>
                    Add
                  </Button>
                  {/* </Space> */}
                </Col>
              </Row>
            ) : (
              <>
                <Row justify="center" className="top-space">
                  <Col>
                    {/* <Space direction="horizontal"> */}
                    <Tooltip title="select atleast 30 images">
                      <Button
                        tooltip="select atleast 30 images"
                        type="primary"
                        disabled
                      >
                        Add
                      </Button>
                    </Tooltip>
                    {/* </Space> */}
                  </Col>
                </Row>
                <Alert
                  className="top-space"
                  message="Note :"
                  description="Minimum 30 Images should be selected to Train Anomaly"
                  type="info"
                  showIcon
                  style={{ width: "70%", marginTop: "1em" }}
                />
              </>
            )}
          </Card>
        </Col>

        <Col
          xs={{ span: 24, offset: 0 }}
          lg={{ span: 20, offset: 0 }}
          xl={{ span: 12, offset: 0 }}
        >
          <Card
            title="Existing Images"
            hoverable
            headStyle={{ backgroundColor: "#001529", color: "white" }}
          >
            <Row>
              <Col
                xxl={{ span: 24, offset: 0 }}
                xs={{ span: 18, offset: 0 }}
                sm={{ span: 18, offset: 0 }}
                lg={{ span: 18, offset: 0 }}
                xl={{ span: 18, offset: 0 }}
                style={{ marginBottom: "1em" }}
              >
               
              </Col>
            </Row>
            <Row className="top-space" gutter={[20, 20]}>
              <Skeleton loading={Trainedimageloading} avatar active>
                {TrainedImages && TrainedImages.length >= 1 ? (
                  TrainedImages.map((item, index) => {
                    return (
                      <Col
                        xxl={{ span: 6, offset: 0 }}
                        xs={{ span: 6, offset: 0 }}
                        sm={{ span: 6, offset: 0 }}
                        lg={{ span: 6, offset: 0 }}
                        xl={{ span: 6, offset: 0 }}
                        // style={{ maxWidth: "100%" }}
                      >
                        <Card
                          bordered
                          hoverable
                          cover={
                            <div>
                              <Image
                                style={{ height: "100px" }}
                                alt="User Image"
                                
                                src={
                                  "data:image/png;base64," + item.anomaly_image
                                }
                              />
                            </div>
                          }
                        >
                          <div>
                            <Title style={{ marginBottom: "0px" }} level={5}>
                              Trainedby
                            </Title>
                            <Text
                              style={ellipsisWrap ? { width: 100 } : undefined}
                              // ellipsis={ellipsisWrap ? { tooltip: item.username } : false}
                            >
                              {/* {item.username} */}
                              {item.trainedbyName}
                            </Text>
                          </div>

                          <Text
                            type="secondary"
                            style={ellipsisWrap ? { width: 100 } : undefined}
                          >
                            {moment(item.traineddate).format(
                              "YYYY-MM-DD HH:mm"
                            )}
                          </Text>
                        </Card>
                      </Col>
                    );
                  })
                ) : (
                  <Row justify="center" style={{ minWidth: "100%" }}>
                    <Col>
                      <Empty
                        description={
                          <>
                            <Space align="center" direction="vertical">
                              <Text>No Images</Text>
                              <Text>Kindly select a cemera</Text>
                            </Space>
                          </>
                        }
                      />
                    </Col>
                  </Row>
                )}
              </Skeleton>
            </Row>

            <Row justify="end" className="top-space">
              <Col>
                <Pagination
                  total={trainedTotalCount}
                  pageSize={12}
                  current={trainedcurrentpage}
                  showSizeChanger={false}
                  onChange={(page) => {
                    HandleTrainedPagination({
                      id: CameraidTrained,
                      startdate: "",
                      enddate: "",
                      page: page,
                      size: 12,
                    });
                  }}
                />
              </Col>
            </Row>

            {TrainedImages && TrainedImages.length >= 1 ? (
              <Row justify="center" className="top-space">
                <Col>
                  {/* <Space direction="horizontal"> */}

                  <Button danger onClick={OnSubmit}>
                    Reset
                  </Button>
                  {/* </Space> */}
                </Col>
              </Row>
            ) : (
              <></>
            )}
          </Card>
        </Col>
      </Row>
      {/* </Row> */}
    </>
  );
}
