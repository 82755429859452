import React from "react";

import {
  Card,
  Col,
  Row,
  Select,
  Typography,
  Tabs,
  Modal,
  message,
  Table,
  Button,
  Spin,
  Alert,
  Result,
  Form,
  Radio,
  Space,
  Tag,
  Divider,
  notification,
  Progress,
  Avatar,
  Image,
  Tooltip,
  Menu,
  Empty,
  Dropdown,
  Skeleton,
  Badge,
  Flex,
  Input,
  Pagination,
} from "antd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import authService from "../../Authentication/authService";
import socket from "../../../Socket";
import ReactApexChart from "react-apexcharts";

import moment from "moment/moment";

import axiosClient from "../../Authentication/ApiCall";

import { DatePicker } from "antd";
import { BiMinus } from "react-icons/bi";
import { FaCaretDown } from "react-icons/fa";
import { useEffect, useState, useRef, createRef } from "react";
import { useLocation } from "react-router-dom";

import { useScreenshot } from "use-react-screenshot";

import ImageMapper from "react-img-mapper";
import { GiCctvCamera } from "react-icons/gi";
import { AiFillAlert } from "react-icons/ai";

const { Title, Link, Text } = Typography;
const { Meta } = Card;
const { RangePicker } = DatePicker;

const Trespassing = ({ item }) => {
  const { Option } = Select;

  const PAGE_SIZE = 4;

  const canvasRef = useRef(null);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [imageList, setImageList] = useState([]);
  console.log("imageList", imageList);
  const [imageUrl, setImageUrl] = useState("");
  console.log("imageUrl blacklist people", imageUrl);

  const [Trespassingcamera, setTrespassingcamera] = useState("");
  const [cameraoption, setcameraoption] = useState([]);
  const [totalcamera, settotalcamera] = useState("");

  const [previewImage, setPreviewImage] = useState(null);

  const [hoveredImage, setHoveredImage] = useState(null);
  const [id, setid] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [responseData, setresponseData] = useState("");
  const [currentPage2, setCurrentPage2] = useState(1);
  const [imageModelopen, setImageModelOpen] = useState(false);
  const uploadRef = useRef(null);
  const [AddRefresh, setAddRefresh] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [socketconnected, setsocketconnected] = useState(false);
  const [coordinates, setCoordinates] = useState([]);
  const [img, setImg] = useState({ width: 50, height: 100 });
  const [areas, setAreas] = useState([]);
  console.log("Formatted Areas444444:", areas);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [totalincidents, settotalincidents] = useState(null);
  const [restdata, setrestdata] = useState("");
  const [restdata1, setrestdata1] = useState("");
  const [notificationcount, setnotificationcount] = useState([]);

  const [socketEmitted, setSocketEmitted] = useState(false);

  const [trespassingtabledata, settrespassingtabledata] = useState([]);

  const [cameraOptions, setCameraOptions] = useState([]);
  const [locationOptions, setLocationOptions] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);

  const pageSize = 6;
  const [currentalertPage, setCurrentalertPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  const [pageSizealert, setPageSizealert] = useState(5); // default page size

  const imgRef = useRef(null);
  const imageDimensions = {
    width: 1920, // Original image width
    height: 1080, // Original image height
  };
  const IMGPAGE_SIZE = 4;
  const EVENT_ID = 5;
  const [page, setPage] = useState(1);
  const [imageData, setImageData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [markedRegionData, setMarkedRegionData] = useState([]);
  const [isModalVisible2, setIsModalVisible2] = useState(false);
  const [selectedImageDetails, setSelectedImageDetails] = useState(null);

  // Handler for page change
  const formatDate = (time) => {
    let momentDate = "";
    let momentTime = moment(time).format("HH:mm:ss");

    if (moment(time).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")) {
      momentDate = "Today";
    } else if (
      moment(time).format("YYYY-MM-DD") ===
      moment().subtract(1, "days").format("YYYY-MM-DD")
    ) {
      momentDate = "Yesterday";
    }

    return momentDate !== ""
      ? momentDate + " " + momentTime
      : moment(time).format("ddd DD MMM YYYY     HH:mm:ss");
  };

  const pageSize2 = 7;

  useEffect(() => {
    console.log("Areas state updated:", areas);
  }, [areas]);

  //fetching notification alert data through socket
  useEffect(() => {
    console.log("Inside Trespassing");
    socket.on("connect", (data) => {
      console.log("socket.connected");
    });

    socket.on("Notification", (data) => {
      console.log("Notification received:", data);
    });

    socket.on("Trespassing", (data) => {
      console.log("Trespassing notification received", data);
      console.log("Notification Clicked!");
      fetchNotifications(currentalertPage, pageSizealert);
    });
    return () => {
      socket.disconnect();
    };
  }, [socketEmitted]);

  //Total camera count for facebased trespassing
  useEffect(() => {
    const ActiveCamera = () => {
      axiosClient
        .get("/notification/getEventBasedActiveCamera?eventid=" + 5)
        .then((response) => {
          if (response.data.issuccess === true) {
            setTrespassingcamera(response.data.eventBasedCameraDetails.count);
            settotalcamera(response.data.onboardCameraDetails.count);

            // Collect camera IDs and options in arrays
            const cameraIds = [];
            const cameraOptions = [];

            response.data.eventBasedCameraDetails.rows.forEach((camera) => {
              cameraIds.push(camera.id);
              cameraOptions.push({ id: camera.id, name: camera.cameraName });
            });

            setid(cameraIds);
            setcameraoption(cameraOptions);

            console.log(cameraIds);
            console.log(cameraOptions);
          } else {
            console.log("error");
          }
        })
        .catch((err) => {
          console.log("errr", err);
          if (err.status === 0) {
            message.error("Server error");
          } else {
            message.error(err.msg);
          }
        });
    };

    // Initial fetch
    ActiveCamera();

    // Set up interval to fetch data every 30 seconds
    const intervalId = setInterval(ActiveCamera, 30000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  //total incident data
  useEffect(() => {
    axiosClient
      .get("/trespassing/gettotalincident")

      .then((response) => {
        if (response.data.issuccess === true) {
          settotalincidents(response.data.data.totalincident);
          console.log("settotalincidents", response.data.data.totalincident);
        } else {
          console.log("error");
        }
      })
      .catch((err) => {
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  }, []);

  const handleDateSelect = (date) => {
    if (date === null) {
      console.log("Date cleared!");
      setSelectedDate(null);
    } else {
      setSelectedDate(date);
      const formattedDate = date.format("YYYY-MM-DD");
      console.log("date for total incidents", formattedDate);

      axiosClient
        .get(`/trespassing/gettotalincident?date=${formattedDate}`)
        .then((response) => {
          if (response.data.issuccess === true) {
            console.log("response", response);
          }
        })
        .catch((error) => {
          console.error("Error fetching total incidents", error);
          message.error("An error occurred while fetching total incidents");
        });
    }
  };

  //initial alert received data

  useEffect(() => {
    fetchNotifications(currentalertPage, pageSizealert);
  }, [currentalertPage, pageSizealert]);

  // get detection notification
  const fetchNotifications = (page, size) => {
    axiosClient
      .get(`/notification/getTresspassingAlert?page=${page}`)
      .then((response) => {
        if (response.data.issuccess === true) {
          console.log(
            "Initial API call:",
            response.data.data.notificationDetails
          );
          setTotalCount(response.data.data.count);
          setnotificationcount(response.data.data.notificationDetails);
          setPageSizealert(response.data.data.pageSize);
        } else {
          console.log("Error while fetching trespassing alert");
        }
      })
      .catch((err) => {
        console.log("Error:", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  };
  const handlePageChange = (page) => {
    setCurrentalertPage(page);
  };
  //Api for trespassing table data
  useEffect(() => {
    axiosClient
      .get("/trespassing/dashboarddetailstrespassing")
      .then((response) => {
        if (response.data.issuccess === true) {
          console.log("trespassing dashboard table data ", response.data);
          settrespassingtabledata(response.data.data);
          const data = response.data.data;
          const cameras = Array.from(
            new Set(data.map((item) => item.cameraName))
          );
          const locations = Array.from(
            new Set(data.map((item) => item.location))
          );
          setCameraOptions(
            cameras.map((camera) => ({ text: camera, value: camera }))
          );
          setLocationOptions(
            locations.map((location) => ({ text: location, value: location }))
          );
        } else {
          console.log("Error while fetching trespassing alert");
        }
      })
      .catch((err) => {
        console.log("Error:", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  }, []);

  //Charts for displaying trespassing Detection
  const [barChartOptions, setBarChartOptions] = useState({
    chart: {
      type: "bar",
    },
    xaxis: {
      categories: [],
      title: {
        text: "Day",
      },
    },
    yaxis: {
      title: {
        text: "Count",
      },
    },
  });

  const [barChartSeries, setBarChartSeries] = useState([
    {
      name: "Data",
      data: [],
    },
  ]);

  const [pieChartOptions, setPieChartOptions] = useState({
    labels: [],
    xaxis: { categories: [] },
  });
  const [pieChartSeries, setPieChartSeries] = useState([]);
  const [filter, setFilter] = useState("Today");
  const [title, setTitle] = useState("Today's Detection Data");

  const [dateRange, setDateRange] = useState([null, null]);

  //getting detection data in response for
  const fetchData = async (startDate = "", endDate = "") => {
    try {
      const response = await axiosClient.get(
        `trespassing/gettrespassingdetailsforbarchart?startDate=${startDate}&endDate=${endDate}`
      );
      const responseData = response.data;

      if (responseData.issuccess) {
        const dates = responseData.data.map((item) => item.date);
        const counts = responseData.data.map((item) => item.count);

        setBarChartOptions((prevOptions) => ({
          ...prevOptions,
          xaxis: {
            ...prevOptions.xaxis,
            categories: dates,
          },
        }));

        setBarChartSeries([
          {
            name: "Count",
            data: counts,
          },
        ]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchPieChartData = async (startDate = "", endDate = "") => {
    try {
      const response = await axiosClient.get(
        `trespassing/gettrespassingdetailsforpiechart?startDate=${startDate}&endDate=${endDate}`
      );
      const responseData = response.data;

      if (responseData.issuccess) {
        const labels = responseData.data.map(
          (item) => item.cameraName || item.cameraId
        );
        const counts = responseData.data.map((item) =>
          parseInt(item.count, 10)
        );

        setPieChartOptions((prevOptions) => ({
          ...prevOptions,
          labels: labels,
          xaxis: {
            ...prevOptions.xaxis,
            categories: labels,
          },
        }));

        setPieChartSeries(counts);
      }
    } catch (error) {
      console.error("Error fetching pie chart data:", error);
    }
  };
  const areAllCountsZeroOrNull = (counts) => {
    return counts.every((count) => count === 0 || count === null);
  };
  //Filter changing for Charts
  const handleFilterChange = (value) => {
    setFilter(value);
    let startDate, endDate;
    let newTitle;

    if (value === "Last7days") {
      endDate = moment().format("YYYY-MM-DD");
      startDate = moment().subtract(6, "days").format("YYYY-MM-DD");
      newTitle = "Last 7 Days' Detection Data";
    } else if (value === "Monthly") {
      endDate = moment().format("YYYY-MM-DD");
      startDate = moment().subtract(30, "days").format("YYYY-MM-DD");
      newTitle = "Last Month Detection Data";
    } else if (value === "Today") {
      startDate = "";
      endDate = "";
      newTitle = "Today's Detection Data";
    } else if (value === "SelectDateWise") {
      newTitle = "Date Wise Detection Data";
    }

    setTitle(newTitle);
    fetchData(startDate, endDate);
    fetchPieChartData(startDate, endDate);
    // fetchPieChartData(startDate, endDate);
  };
  //Date picker for selecting dates In Barchart
  const handlebarDateRangeChange = (dates) => {
    setDateRange(dates);
    if (dates && dates.length === 2) {
      const [startDate, endDate] = dates;
      fetchData(startDate.format("YYYY-MM-DD"), endDate.format("YYYY-MM-DD"));
      fetchPieChartData(
        startDate.format("YYYY-MM-DD"),
        endDate.format("YYYY-MM-DD")
      );
    }
  };

  useEffect(() => {
    handleFilterChange(filter);
  }, []);

  //getting trespassing amrked area images through api
  const fetchImageData = (page, pageSize) => {
    axiosClient

      .get(
        `notification/getMarkedRegionForTrespassing?eventId=${EVENT_ID}&page=${page}`
      )
      .then((response) => {
        if (response.data.issuccess) {
          const { markedRegionDetails, totalPages } = response.data.data;
          setMarkedRegionData(markedRegionDetails);
          console.log("markedRegionDetails", markedRegionDetails);
          setTotalPages(totalPages);
        } else {
          message.error("Failed to fetch data");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        message.error("Failed to fetch data");
      });
  };

  useEffect(() => {
    fetchImageData(page, pageSize);

    const intervalId = setInterval(() => {
      fetchImageData(page, pageSize);
    }, 30000); // 30000 milliseconds = 30 seconds

    return () => clearInterval(intervalId);
  }, [page, pageSize]);

  const IMghandlePageChange = (page) => {
    setPage(page);
  };

  const filledData = [...markedRegionData];
  while (filledData.length < IMGPAGE_SIZE) {
    filledData.push({});
  }

  const newImgWidth2 = 470;
  const newImgHeight2 = 300;
  const newImgWidth = 420;
  const newImgHeight = 260;
  const widthScale = (newImgWidth / imageDimensions.width) * 3.9;
  const heightScale = (newImgHeight / imageDimensions.height) * 3.5;

  const scaleCoordinates = (coords, widthScale, heightScale) => {
    return coords.map((coord, index) =>
      index % 2 === 0 ? coord * widthScale : coord * heightScale
    );
  };
  const showModal4 = (item) => {
    console.log("Selected item:", item);
    setSelectedImageDetails(item);
    setIsModalVisible2(true);
  };

  const handleCancel4 = () => {
    setIsModalVisible2(false);
    setSelectedImageDetails(null);
  };
  // const emitCameraData = () => {
  //   const cameraData = { cameraid: 2, camera_status: "active" };

  //   socket.emit("RTSP", cameraData);
  //   console.log("Emitted Camera Data:", cameraData);
  // };

  // useEffect(() => {
  //   emitCameraData();
  // }, []);
  return (
    <
    >
      <Row gutter={[20, 20]} >
        <Col
          xs={{ span: 14 / 1, offset: 0 }}
          sm={{ span: 14 / 1, offset: 0 }}
          md={{ span: 14 / 1, offset: 0 }}
          lg={{ span: 18 / 1, offset: 0 }}
          xl={{ span: 18 / 1, offset: 0 }}
          xxl={{ span: 18 / 1, offset: 0 }}
          style={{ marginTop: "20px" }}
        >
          <Row gutter={[20, 20]}>
            <Col
              xs={{ span: 7 / 1, offset: 0 }}
              sm={{ span: 7 / 1, offset: 0 }}
              md={{ span: 7 / 1, offset: 0 }}
              lg={{ span: 7 / 1, offset: 0 }}
              xl={{ span: 7 / 1, offset: 0 }}
              xxl={{ span: 7 / 1, offset: 0 }}
            >
              <Card
                className="ingress-hover"
                style={{
                  cursor: "pointer",
                  userSelect: "none",
                  boxShadow: "none",
                  // height: "150px",

                  height: "200px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Row
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Col>
                    <div
                      style={{
                        fontSize: "18px",
                        color: "#585c59",
                      }}
                    >
                      Active Camera
                    </div>
                    <span style={{ fontSize: "50px" }}>
                      {Trespassingcamera !== null &&
                      Trespassingcamera !== undefined
                        ? Trespassingcamera
                        : 0}
                    </span>
                    <div>
                      {" "}
                      Total Camera :{" "}
                      {totalcamera !== null && totalcamera !== undefined
                        ? totalcamera
                        : 0}
                    </div>
                  </Col>
                  <Col>
                    <GiCctvCamera size={70} color="#142162" />
                  </Col>
                </Row>
              </Card>
              <Card
                className="ingress-hover"
                style={{
                  cursor: "pointer",
                  userSelect: "none",
                  boxShadow: "none",
                  marginTop: "21px",
                  height: "auto", // Flexible height
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexDirection: window.innerWidth < 768 ? "column" : "row", // Stack on smaller screens
                  padding: "20px", // Padding for better spacing
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: window.innerWidth < 768 ? "column" : "row", // Stack on smaller screens
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%", // Full width
                  }}
                >
                  <span
                    style={{
                      textAlign: window.innerWidth < 768 ? "center" : "left", // Center text on smaller screens
                      display: "flex",
                      flexDirection: "column", // Stack text vertically
                      alignItems:
                        window.innerWidth < 768 ? "center" : "flex-start", // Align items based on screen size
                    }}
                  >
                    <span
                      style={{
                        fontSize: "18px",
                        color: "#585c59",
                      }}
                    >
                      <span>
                        {selectedDate ? "Total Alerts" : "Today's Total Alerts"}
                      </span>
                    </span>
                    <span style={{ fontSize: "50px" }}>
                      {totalincidents !== null && totalincidents !== undefined
                        ? totalincidents
                        : 0}
                    </span>
                    <span>
                      <DatePicker
                        onChange={handleDateSelect}
                        allowClear={true}
                        disabledDate={(current) =>
                          current && current > moment().endOf("day")
                        }
                      />
                    </span>
                  </span>
                  <span
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <AiFillAlert size={70} color="#142162" />
                  </span>
                </div>
              </Card>
            </Col>
            <Col
              xs={{ span: 15 }}
              sm={{ span: 15 }}
              md={{ span: 15 }}
              lg={{ span: 15 }}
              xl={{ span: 15 }}
              xxl={{ span: 15 }}
              style={{ marginLeft: "35px" }}
            >
              <div>
                <Row gutter={[20, 20]}>
                  {filledData.map((item, index) => (
                    <Col key={index} span={12}>
                      <Card
                        onClick={() => {
                          if (item.cam_image) {
                            showModal4(item);
                          } else {
                            // Optionally, handle the case where there is no image
                            console.warn(
                              "No image data available for this item."
                            );
                          }
                        }}
                        style={{
                          height: "200px",
                          padding: 0,
                          overflow: "hidden",
                          position: "relative",
                        }}
                        bodyStyle={{ padding: 0 }}
                      >
                        {item.cam_image ? (
                          <>
                            <ImageMapper
                              src={item.cam_image}
                              map={{
                                name: `map-${index}`,
                                areas: [
                                  {
                                    id: `${index}`,
                                    shape: "poly",
                                    coords: scaleCoordinates(
                                      item.coordinates,
                                      widthScale,
                                      heightScale
                                    ),
                                    preFillColor: "rgba(161, 161, 156, 0.7)",
                                    lineWidth: 2,
                                    fillColor: "#eab54d",
                                    strokeColor: "red",
                                  },
                                ],
                              }}
                              width={newImgWidth}
                              imgWidth={imageDimensions.width}
                              height={newImgHeight}
                              // responsive={true}
                            />
                            {item.cameraStatus === "active" ? (
                              <Alert
                                message="All Good"
                                type="success"
                                showIcon
                                style={{
                                  position: "absolute",
                                  top: "10px",
                                  right: "10px",
                                  zIndex: 10,
                                  padding: "5px 10px",
                                  borderRadius: "8px",
                                  backgroundColor: "green",
                                  color: "white",
                                }}
                              />
                            ) : (
                              <div className="alert-overlay">
                                <img
                                  src="/alerttt.png"
                                  alt="Alert"
                                  className="alert-image"
                                />
                                <Alert
                                  message="Not Working"
                                  type="error"
                                  showIcon
                                  style={{
                                    position: "absolute",
                                    top: "10px",
                                    right: "10px",
                                    zIndex: 10,
                                    padding: "5px 10px",
                                    borderRadius: "8px",
                                    backgroundColor: "#fad1ca",
                                    color: "#453b3b",
                                  }}
                                />
                              </div>
                            )}
                          </>
                        ) : (
                          <div
                            style={{
                              width: "100%",
                              height: "200px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              // backgroundColor: "#f0f0f0",
                            }}
                          >
                            <Empty description="No Image" />
                            {/* <Result
                              title={
                                <span
                                  style={{ fontSize: "16px", color: "#888" }}
                                >
                                  No Image Found
                                </span>
                              }
                              style={{
                                textAlign: "center",
                                color: "#f9f9f9",
                                padding: "20px",
                                borderRadius: "5px",
                              }}
                            /> */}
                            {/* <Result title="No Image Found" /> */}
                          </div>
                        )}
                      </Card>
                    </Col>
                  ))}
                </Row>
                <Modal
                  title={`Details for ${
                    selectedImageDetails?.cameraName || ""
                  }`}
                  visible={isModalVisible2}
                  onCancel={handleCancel4}
                  footer={null}
                >
                  {selectedImageDetails ? (
                    <div>
                      <ImageMapper
                        src={selectedImageDetails.cam_image}
                        map={{
                          name: "modal-map",
                          areas: [
                            {
                              id: "modal-area",
                              shape: "poly",
                              coords: scaleCoordinates(
                                selectedImageDetails.coordinates,
                                widthScale,
                                heightScale
                              ),
                              preFillColor: "rgba(161, 161, 156, 0.7)",
                              lineWidth: 2,
                              fillColor: "#eab54d",
                              strokeColor: "red",
                            },
                          ],
                        }}
                        width={newImgWidth2}
                        imgWidth={imageDimensions.width}
                        height={newImgHeight2}
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "15px",
                        }}
                      >
                        {" "}
                        <span
                          style={{
                            fontSize: "17px",
                            // fontWeight: "bold",
                            marginRight: "19px",
                          }}
                        >
                          Status:{" "}
                          <Tag
                            style={{
                              fontSize: "16px",
                              backgroundColor:
                                selectedImageDetails.cameraStatus === "active"
                                  ? "green"
                                  : "red",
                              color: "white",
                            }}
                          >
                            {selectedImageDetails.cameraStatus}
                          </Tag>
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "6px",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "17px",
                            // fontWeight: "bold",
                            marginRight: "19px",
                          }}
                        >
                          Camera Name:{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {selectedImageDetails.cameraName}
                          </span>
                        </span>
                        <span
                          style={{
                            fontSize: "17px",
                            // fontWeight: "bold",
                            marginRight: "19px",
                          }}
                        >
                          Location:{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {selectedImageDetails.location}
                          </span>
                        </span>
                      </div>
                    </div>
                  ) : (
                    <p>No details available</p>
                  )}
                </Modal>

                <Pagination
                  current={page}
                  pageSize={IMGPAGE_SIZE}
                  total={totalPages * IMGPAGE_SIZE}
                  onChange={IMghandlePageChange}
                  style={{
                    marginTop: "14px",
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                />
              </div>
            </Col>
          </Row>
          {/* <Row span={20}><Table/></Row> */}
          <Card
            style={{ marginTop: "2rem", userSelect: "none", boxShadow: "none" }}
          >
            <Row>
              {" "}
              <p
                style={{
                  marginTop: "3px",
                  fontSize: "16px",
                  // fontWeight: "bold",
                }}
              >
                Filter :{" "}
              </p>
              <Select
                defaultValue="Today"
                onChange={handleFilterChange}
                style={{ width: 150, marginLeft: "5px" }}
              >
                <Option value="Today">Today</Option>
                <Option value="Last7days">Last 7 days</Option>
                <Option value="Monthly">Last Month</Option>
                <Option value="SelectDateWise">Select Date Wise</Option>
              </Select>
              {filter === "SelectDateWise" && (
                <RangePicker
                  style={{ marginLeft: 10, height: "33px" }}
                  onChange={handlebarDateRangeChange}
                />
              )}
            </Row>
            {/* <Row>
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: "45%",
                }}
              >
                {title}
              </span>
            </Row> */}
            <Row gutter={[20, 20]}>
              <Col
                xs={{ span: 24 / 1, offset: 0 }}
                sm={{ span: 24 / 1, offset: 0 }}
                md={{ span: 24 / 1, offset: 0 }}
                lg={{ span: 24 / 1, offset: 0 }}
                xl={{ span: 24 / 1, offset: 0 }}
                xxl={{ span: 24 / 1, offset: 0 }}
                // style={{ marginTop: "3rem" }}
              >
                <Row gutter={[20, 20]}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={11}>
                    <Card title={title}>
                      {/* {filter === "SelectDateWise" && (
                      <RangePicker
                        style={{ marginLeft: 10 }}
                        onChange={handlebarDateRangeChange}
                      />
                    )} */}
                      <ReactApexChart
                        options={barChartOptions}
                        series={barChartSeries}
                        type="bar"
                        height={280}
                      />
                    </Card>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12}
                    xl={12}
                    xxl={{ span: 11, offset: 1 }}
                  >
                    <Card title={title} style={{ height: "400px" }}>
                      {areAllCountsZeroOrNull(pieChartSeries) ? (
                        <Empty style={{ marginTop: "39px" }} />
                      ) : (
                        <ReactApexChart
                          options={pieChartOptions}
                          series={pieChartSeries}
                          type="pie"
                          height={294}
                        />
                      )}
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
        {/* //2nd col for alert data */}
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 6 }}
          xl={{ span: 6 }}
          xxl={{ span: 6 }}
        >
          <Card
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    marginBottom: "20px",
                    flexDirection: "row",

                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Title
                    type="secondary"
                    level={5}
                    style={{
                      fontSize: "20px",
                      marginBottom: "10px",
                    }}
                  >
                    Alerts
                  </Title>
                  <img
                    src="/alerttt.png"
                    alt="Image "
                    style={{ width: "30px", height: "30px", marginLeft: "8px" }}
                  />
                </div>
              </>
            }
            style={{ height: "100%" }}
          >
            <div
              style={{
                height: "calc(120vh - 104px)",
                overflowY: "scroll",
                overflowX: "hidden",
                msOverflowStyle: "none",
                scrollbarWidth: "none",
              }}
              className="hide-scrollbar"
            >
              {notificationcount.length > 0 ? (
                notificationcount.map((detail) => (
                  <Card
                    key={detail.id}
                    hoverable
                    style={{
                      width: "100%",
                      marginBottom: "10px",
                      boxShadow: "none",
                      border: "1px solid #cab5b5",
                    }}
                    bodyStyle={{ display: "flex", padding: 0 }}
                  >
                    <Row style={{ width: "100%" }}>
                      <Col span={10}>
                        {detail.imageBase64 ? (
                          <Image
                            alt={detail.eventName}
                            src={`data:image/jpeg;base64,${detail.imageBase64}`}
                            style={{
                              width: "100%",
                              height: "150px",
                              objectFit: "cover",
                            }}
                          />
                        ) : (
                          <div
                            style={{
                              width: "100%",
                              height: "150px",
                              backgroundColor: "#f0f0f0",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            No image
                          </div>
                        )}
                      </Col>
                      <Col
                        span={14}
                        style={{
                          padding: "10px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Meta
                          title={detail.eventName}
                          description={formatDate(detail.createddate)}
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        />
                      </Col>
                    </Row>
                  </Card>
                ))
              ) : (
                <Empty
                  description="No notifications available"
                  style={{ marginTop: "160px" }}
                />
              )}
              <Pagination
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                current={currentalertPage}
                pageSize={pageSizealert}
                total={totalCount}
                onChange={handlePageChange}
                showSizeChanger={false}
              />
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default Trespassing;
