import React, { useState, useEffect } from "react";
import { Component } from "react";
import {
  UserOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from "@ant-design/icons";
import { PlusOutlined, LockOutlined } from "@ant-design/icons";
import { MinusOutlined } from "@ant-design/icons";
import { Label, Input, Select, Space, Cascader, Form, Checkbox } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import { InputNumber } from "antd";
import { Button } from "antd";
import { Row, Col } from "antd";
import { createFromIconfontCN } from "@ant-design/icons";
import { Layout, Menu, Breadcrumb } from "antd";
import { PageHeader } from "antd";
import { Image, message } from "antd";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import authService from "../Authentication/authService";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { atom, useAtom } from "jotai";
import {
  EventListJotai,
  LoadingComp,
  PolicyDetailsJotai,
  Role,
} from "../Menu/Utils/StateExports";

const { Header, Footer, Sider, Content } = Layout;

// Login user
function Login_user() {
  document.title = "KEN - Login";

  // changes the tab title in realtime
  window.addEventListener("blur", () => (document.title = "New Update *"));
  window.addEventListener("focus", () => (document.title = "KEN - Login"));

  const navigate = useNavigate();
  const [Rolea, setRole] = useAtom(Role);
  const [PolicyList, setPolicyList] = useAtom(PolicyDetailsJotai);
  const [PageLoading, setPageLoading] = useAtom(LoadingComp);
  const [Loadinglocal, setLoadinglocal] = useState(false);
  const [DashboardEventsJotai, setDashboardEventsJotai] =
    useAtom(EventListJotai);
  // submit the login details and returns to the page based on roles

  // const onFinish = (event) => {
  //   setLoadinglocal(true);
  //   authService
  //     .login(event.Email, event.password, event.remember)
  //     .then(async (response) => {
  //       if (response.status === 200) {
  //         const decodedToken = jwtDecode(response.data.jwt_token);
  //         setRole(decodedToken.Roles);
  //         setPolicyList(decodedToken.policyDetails || []);

  //         const PolicyData = decodedToken.policyDetails || [];
  //         const RoleFromRes = decodedToken.Roles;

  //         const RouteList = [
  //           { path: "Dashboard", route: "Dashboard" },
  //           { path: "Reports", route: "Reports" },
  //           { path: "Event Addition", route: "EventSetup" },
  //           { path: "Asset Onboarding", route: "Asset_onboarding" },
  //           { path: "Event Calendar", route: "Calendar" },
  //           { path: "Tech Support", route: "TechSupport" },
  //           { path: "OnBoarding Location", route: "StoreOnboarding" },
  //           { path: "Policy Configuration", route: "PolicyConfig" },
  //           { path: "UserMaster", route: "UserMaster" },
  //         ].filter((child) => PolicyData.includes(child.path));

  //         setLoadinglocal(false);
  //         setDashboardEventsJotai(decodedToken.eventId || []);

  //         // Navigate based on Role
  //         if (RoleFromRes === "Admin") {
  //           return navigate("/Admin/Dashboard");
  //         }
  //         if (RoleFromRes === "Security") {
  //           if (RouteList.length > 0) {
  //             return navigate("/security/" + RouteList[0].route);
  //           }
  //         }
  //         if (RoleFromRes === "Supervisor") {
  //           if (RouteList.length > 1) {
  //             return navigate("/supervisor/" + RouteList[1].route);
  //           }
  //         }
  //         if (RoleFromRes === "Management") {
  //           if (RouteList.length > 1) {
  //             return navigate("/management/" + RouteList[1].route);
  //           }
  //         }
  //         if (RoleFromRes === "user") {
  //           if (RouteList.length > 1) {
  //             return navigate("/user/" + RouteList[1].route);
  //           }
  //         }
  //         if (RoleFromRes === "Manager") {
  //           if (RouteList.length > 1) {
  //             return navigate("/manager/" + RouteList[1].route);
  //           }
  //         } else {
  //           console.log("else");
  //         }
  //       } else if (response.status === 400) {
  //         message.error(
  //           response.data?.msg || response.msg || "An error occurred"
  //         );
  //       } else {
  //         setLoadinglocal(false);
  //         handleResponseError(response);

  //         message.error(
  //           response.data?.msg || response.msg || "An error occurred"
  //         );
  //       }
  //     })
  //     .catch((err) => {
  //       setLoadinglocal(false);
  //       handleResponseError(err);

  //       // message.error(
  //       //   err.response?.data?.msg || err.msg || "An error occurred"
  //       // );
  //     });
  // };

  const onFinish = (event) => {
    setLoadinglocal(true);

    authService
      .login(event.Email, event.password, event.remember)
      .then(async (response) => {
        console.log("Response0000:", response);

        if (response.status === 200) {
          const decodedToken = jwtDecode(response.data.jwt_token);
          console.log("Decoded Token:", decodedToken);

          setRole(decodedToken.Roles);
          setPolicyList(decodedToken.policyDetails || []);

          const PolicyData = decodedToken.policyDetails || [];
          const RoleFromRes = decodedToken.Roles;

          const RouteList = [
            { path: "Dashboard", route: "Dashboard" },
            { path: "Reports", route: "Reports" },
            { path: "Event Addition", route: "EventSetup" },
            { path: "Asset Onboarding", route: "Asset_onboarding" },
            { path: "Event Calendar", route: "Calendar" },
            { path: "Tech Support", route: "TechSupport" },
            { path: "OnBoarding Location", route: "StoreOnboarding" },
            { path: "Policy Configuration", route: "PolicyConfig" },
            { path: "UserMaster", route: "UserMaster" },
            { path: "ServerOnboarding", route: "ServerOnboarding" },
          ].filter((child) => PolicyData.includes(child.path));

          console.log("RoleFromRes:", RoleFromRes);
          console.log("RouteList:", RouteList);

          setLoadinglocal(false);
          setDashboardEventsJotai(decodedToken.eventId || []);

          // Navigate based on Role
          switch (RoleFromRes) {
            case "Admin":
              console.log("Navigating to Admin Dashboard");
              return navigate("/Admin/Dashboard");
            case "Security":
            case "Supervisor":
            case "user":
            case "Management":
            case "Manager":
              console.log(
                "Navigating to Role-specific Route:",
                RouteList.length > 0 ? RouteList[0].route : "No route available"
              );
              if (RouteList.length > 0) {
                return navigate(
                  `/${RoleFromRes.toLowerCase()}/${RouteList[0].route}`
                );
              } else {
                console.error("RouteList is empty for this role.");
                // Optionally navigate to a default route
                return navigate(`/${RoleFromRes.toLowerCase()}/Dashboard`);
              }
            default:
              console.log("Role not recognized");
          }
        } else if (response.status === 400) {
          console.error(
            "Error:",
            response.data?.msg || response.msg || "An error occurred"
          );
          message.error(
            response.data?.msg || response.msg || "An error occurred"
          );
        } else {
          setLoadinglocal(false);
          handleResponseError(response);
        }
      })
      .catch((err) => {
        setLoadinglocal(false);
        console.error("Catch Error:", err);
        handleResponseError(err);
      });
  };

  const handleResponseError = (error) => {
    console.log("Error:", error);
    const errorMessage =
      typeof error === "object" && error.message
        ? error.message
        : error.data || "Could not connect with server. Try after sometime";

    if (error.status === 0) {
      message.error("Server error");
    } else {
      message.error(errorMessage);
    }
  };

  const onFinishFailed = () => {
    console.log("Login Failed");
  };

  return (
    <Layout
      style={{
        backgroundColor: "white",
        height: "100vh",
        width: "100%",
        overflowX: "hidden",
      }}
    >
      <Content>
        <Row span={24} style={{ height: "100vh" }}>
          <Col xs={0} sm={0} md={0} lg={0} xl={11} style={{ heigh: "100vh" }}>
            <Image
              preview={false}
              style={{ maxHeight: "100vh" }}
              className="image-property"
              src="pic1.jpg"
            ></Image>
          </Col>

          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: "2em",
            }}
            className="gutter-row"
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            lg={{ span: 24, offset: 0 }}
            xl={{ span: 12, offset: 0 }}
            xxl={{ span: 12, offset: 0 }}
          >
            <Form
              // style={{ width: "100%" }}
              name="basic"
              // labelCol={{
              //   span: 8,
              // }}
              labelCol={{
                xs: { span: 24 },
                sm: { span: 7, offset: 0 },
                md: { span: 7, offset: 0 },
                lg: { span: 8, offset: 0 },
                xl: { span: 8, offset: 0 },
              }}
              wrapperCol={{
                xs: { span: 21 },
                sm: { span: 11, offset: 0 },
                md: { span: 11, offset: 0 },
                lg: { span: 8, offset: 0 },
                xl: { span: 8, offset: 0 },
              }}
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              validateTrigger={["onBlur"]}
            >
              <Row
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginRight: "18px",
                }}
              >
                <Col
                  className="gutter-row "
                  xs={{ span: 6, offset: 0 }}
                  sm={4}
                  md={4}
                  lg={4}
                  xl={4}
                  xxl={4}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Image
                    preview={false}
                    className="gutter-row bottom-space"
                    src="ken_black.png"
                    style={{ backgroundColor: "white", marginRight: "10px" }}
                  ></Image>
                </Col>
              </Row>
              <Form.Item
                labelCol={{
                  xs: { span: 24 },
                  sm: { span: 12, offset: 0 },
                  md: { span: 12, offset: 0 },
                  lg: { span: 8, offset: 0 },
                  xl: { span: 8, offset: 0 },
                }}
                wrapperCol={{
                  xs: { span: 21 },
                  sm: { span: 12, offset: 0 },
                  md: { span: 12, offset: 0 },
                  lg: { span: 7, offset: 0 },
                  xl: { span: 7, offset: 0 },
                }}
                className="top-space"
                label="Email"
                name="Email"
                rules={[
                  { required: true, message: "Please Enter your Email!" },
                  { type: "email", warningOnly: true },
                  { type: "string", min: 6 },
                ]}
              >
                <Input
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="Email"
                />
              </Form.Item>

              <Form.Item
                wrapperCol={{
                  xs: { span: 14 },
                  sm: { span: 8 },
                  md: { span: 8 },
                  lg: { span: 7 },
                  xl: { span: 7 },
                }}
                labelCol={{
                  xs: { span: 7 },
                  sm: { span: 8 },
                  md: { span: 8 },
                  lg: { span: 8 },
                  xl: { span: 8 },
                }}
                label="Password"
                name="password"
                tooltip="Account will be locked after 5 failed attempts"
                rules={[
                  {
                    required: true,
                    message: "Please Enter your password!",
                  },
                  { type: "password", warningOnly: true },
                  { type: "string", min: 8 },
                ]}
              >
                <Input.Password
                  type="password"
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  placeholder="Password"
                />
              </Form.Item>

              <Form.Item
                name="remember"
                valuePropName="checked"
                wrapperCol={{
                  xs: { span: 24, offset: 0 },
                  sm: { span: 12, offset: 8 },
                  md: { span: 12, offset: 8 },
                  lg: { span: 7, offset: 8 },
                  xl: { span: 7, offset: 8 },
                }}
              >
                <Checkbox style={{ color: "#3e3a75" }}>Remember me</Checkbox>
              </Form.Item>

              <Form.Item
                wrapperCol={{
                  xs: { span: 18, offset: 2 },
                  sm: { span: 8, offset: 8 },
                  md: { span: 8, offset: 8 },
                  lg: { span: 7, offset: 8 },
                  xl: { span: 7, offset: 8 },
                }}
              >
                <Button
                  style={{ backgroundColor: "#3e3a75", border: "none" }}
                  type="primary"
                  block
                  htmlType="submit"
                  loading={Loadinglocal}
                >
                  Submit
                </Button>
              </Form.Item>

              <Form.Item
                wrapperCol={{
                  xs: { span: 24, offset: 0 },
                  sm: { span: 12, offset: 8 },
                  md: { span: 12, offset: 8 },
                  lg: { span: 6, offset: 8 },
                  xl: { span: 8, offset: 8 },
                }}
              >
                <Link
                  to={"/Forgotpassword"}
                  className="login-form-forgot"
                  href=""
                  target="_blank"
                >
                  Forgot Password?
                </Link>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
}

export default Login_user;
