import React from "react";
// import "./404.scss"

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    console.log("error", error, errorInfo);
  }
  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div id="error-page">
          <div class="content">
            <h2 class="header" data-text="500">
              500
            </h2>
            <h4 data-text="Opps! Page not found">Opps! Session Timeout</h4>
            <p>Sorry, Session timeout reached. Refresh the page...</p>
            <div class="btns">
              <a href=".">return home</a>
            </div>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
