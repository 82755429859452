import { Table, message, Modal, Tooltip } from "antd";
import React, { useState, useEffect } from "react";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

import axiosClient from "../../Authentication/ApiCall";
import authService from "../../Authentication/authService";

const { Column } = Table;

function CameraAccessList({ props }) {
  const [data, setdata] = useState([]);
  const [loading, setloading] = useState(true);
  const [DeleteRefresh, setDeleteRefresh] = useState(1);
  const [cameralist, setcameralist] = useState([]);
  const [TableEmail, setTableEmail] = useState([]);
  const [TableUsername, setTableUsername] = useState([]);

  const Dupless = TableEmail.filter(
    (ele, ind) =>
      ind ===
      TableEmail.findIndex(
        (elem) => elem.text === ele.text && elem.value === ele.value
      )
  );

  const Duplessname = TableUsername.filter(
    (ele, ind) =>
      ind ===
      TableUsername.findIndex(
        (elem) => elem.text === ele.text && elem.value === ele.value
      )
  );

  const Duplesscam = cameralist.filter(
    (ele, ind) =>
      ind ===
      cameralist.findIndex(
        (elem) => elem.text === ele.text && elem.value === ele.value
      )
  );
  const OnDelete = (record) => {
    Modal.confirm({
      title: "Confirm Delete?",

      okText: "Confirm",
      okType: "danger",
      onOk: () => {
        axiosClient
          .put("/cameraaccess/deleteUsercameraAccess", {
            id: record.sno,
            // delete_reason:record
          })
          .then((response) => {
            if (response.data.issuccess === true) {
              message.success(response.data.msg);
              setDeleteRefresh(DeleteRefresh + 1);
            } else {
              console.log("error");
            }
          })
          .catch((err) => {
            console.log("errr", err);
            if (err.status === 0) {
              message.error("Server error");
            } else {
              message.error(err.msg);
            }
          });
      },
    });
  };

  useEffect(() => {
    axiosClient
      .get("/cameraaccess/getUserCameraAccessList")
      .then((response) => {
        if (response.status === 200) {
          setdata(response.data.data);
          setloading(false);

          response.data.data.map((item, index) => {
            let data = item.username;
            let name = {
              text: data,
              value: data,
            };
            let locdata = item.email;
            let mailid = {
              text: locdata,
              value: locdata,
            };

            let camname = item.cameraName;
            let camdata = {
              text: camname,
              value: camname,
            };
            setTableEmail((emailprev) => [...emailprev, mailid]);
            // removeDuplicates(TableEmail);

            setTableUsername((prevUsername) => [...prevUsername, name]);
            setcameralist((cam) => [...cam, camdata]);
          });
        } else {
          console.log("error");
        }
      })
      .catch((err) => {
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  }, [props, DeleteRefresh]);

  return (
    <Table
      loading={loading}
      rowKey="sno"
      dataSource={data}
      pagination={{ showSizeChanger: "true" }}
      scroll={{ x: "max-content" }}
    >
      <Column
        title="S.no"
        key="id"

        render={(item, record, index) => {
          ++index;
          return index;
        }}
      />

      <Column
        title="Username"
        dataIndex="username"
        key="username"
        filterse
        filterSearch={true}
        filters={Duplessname.map((item) => item)}
        onFilter={(value, record) => record.username.indexOf(value) === 0}
      />
      <Column
        title="Email"
        dataIndex="email"
        key="email"
        filterSearch={true}
        filters={Dupless.map((item) => item)}
        onFilter={(value, record) => record.email.indexOf(value) === 0}
      />

      <Column
        title="Camera Name"
        dataIndex="cameraName"
        key="CameraName"
        filterSearch={true}
        filters={Duplesscam.map((item) => item)}
        onFilter={(value, record) => record.cameraName.indexOf(value) === 0}
      />
      
      {authService.getCurrentUserRole() === "Admin" ||
      authService.getCurrentUserRole() === "Management" ? (
        <Column
          align="center"
          title="Delete"
          key="action"
          render={(record) => {
            return (
              <>
                {authService.getCurrentUserReadOnly() ? (
                  <Tooltip title="you need access to delete">
                    <DeleteOutlined disabled />
                  </Tooltip>
                ) : (
                  <DeleteOutlined
                    style={{ color: "red" }}
                    onClick={(e) => {
                
                      OnDelete(record);
                      
                    }}
                  />
                )}
              </>
            );
          }}
        />
      ) : (
        ""
      )}
    </Table>
  );
}

export default CameraAccessList;
