import {
  Divider,
  Table,
  message,
  Tag,
  Typography,
  Form,
  Row,
  Col,
  Select,
  DatePicker,
  Button,
  Tooltip,
} from "antd";
import React, { useState, useEffect } from "react";
import axiosClient from "../../Authentication/ApiCall";
import { CalendarOutlined } from "@ant-design/icons";
import moment from "moment";
import { HiDocumentDownload } from "react-icons/hi";
import * as FileSaver from "file-saver";
import authService from "../../Authentication/authService";

const { RangePicker } = DatePicker;
const { Column } = Table;

const { Option } = Select;

const { Title, Text } = Typography;
function TechSupportList({ props }) {
  const [TableData, setTableData] = useState([]);
  const [CurrentPage, setCurrentPage] = useState();
  const [TotalPages, setTotalPages] = useState();
  const [StartDate, setStartDate] = useState();
  const [EndDate, setEndDate] = useState();

  useEffect(() => {
    axiosClient
      .get("/techSupprot/getTicketsList?start_date=&&end_date=&&page=&&size=10")
      .then((response) => {
        if (response.data.issuccess === true) {
          // setRolesList(response.data.data);
          setCurrentPage(response.data.page);
          setTotalPages(response.data.totaldatacount);
          setTableData(response.data.data);
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((err) => {
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  }, [props]);

  const HandlePagination = (startdate, enddate, page, size) => {
    axiosClient
      .get(
        "/techSupprot/getTicketsList?start_date=" +
          startdate +
          "&&end_date=" +
          enddate +
          "&&page=" +
          page +
          "&&size=" +
          size
      )
      .then((response) => {
        if (response.data.issuccess === true) {
          // setRolesList(response.data.data);
          setCurrentPage(response.data.page);
          setTotalPages(response.data.totaldatacount);
          setTableData(response.data.data);
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((err) => {
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  };

  const OnChangeDate = (Date) => {
    setStartDate();
    setEndDate();
    setStartDate(Date[0]._d);
    setEndDate(Date[1]._d);
  };

  const HandleRangPicker = () => {
    if (StartDate && EndDate) {
      axiosClient
        .get(
          "/techSupprot/getTicketsList?start_date=" +
            StartDate +
            "&&end_date=" +
            EndDate +
            "&&page=&&size="
        )
        .then((response) => {
          if (response.data.issuccess === true) {
            // setRolesList(response.data.data);
            setCurrentPage(response.data.page);
            setTotalPages(response.data.totaldatacount);
            setTableData(response.data.data);
          } else {
            console.log("Something went wrong");
          }
        })
        .catch((err) => {
          console.log("errr", err);
          if (err.status === 0) {
            message.error("Server error");
          } else {
            message.error(err.msg);
          }
        });
    }
  };

  const Getfile = (id) => {
    axiosClient
      .get("/techSupprot/downlodaTicketFile?id=" + id, {
        responseType: "blob",
      })
      .then((response) => {
        if (response.status === 200) {
          FileSaver.saveAs(response.data, "Document");
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((err) => {
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  };
  return (
    <>
      <Row>
        <Col
          xxl={{ span: 12, offset: 0 }}
          xs={{ span: 12, offset: 0 }}
          sm={{ span: 12, offset: 0 }}
          lg={{ span: 12, offset: 0 }}
          xl={{ span: 12, offset: 0 }}
        >
          <Text strong>History</Text>
        </Col>
        <Col
          xxl={{ span: 12, offset: 0 }}
          xs={{ span: 12, offset: 0 }}
          sm={{ span: 12, offset: 0 }}
          lg={{ span: 12, offset: 0 }}
          xl={{ span: 12, offset: 0 }}
          style={{
            marginBottom: "1em",
            width: "100%",
            display: "flex",
            justifyContent: "end",
          }}
        >
          <RangePicker
            // allowClear
            defaultValue={[moment().subtract(5, "d"), moment()]}
            placeholder={["Select start Date ", "End Date"]}
            ranges={{
              Today: [moment(), moment()],
              Yesterday: [
                moment().subtract(1, "days"),
                moment().subtract(1, "days"),
              ],

              "This week": [moment().startOf("week"), moment()],
              "This Month": [moment().startOf("month"), moment()],
            }}
            format={"YYYY-MM-DD ddd"}
            disabledDate={(current) => {
              // let customDate = moment().format("YYYY-MM-DD");
              // return (
              //   current && current > moment(customDate, "YYYY-MM-DD")
              // );
              return current && current > moment().endOf("day");
            }}
            // onChange={HandleDatePicker}
            onChange={OnChangeDate}
            onOpenChange={HandleRangPicker}
          />

          {/* <Space> */}

          {/* <Alert showIcon message="Get Report by Date"></Alert> */}
          {/* </Space> */}
        </Col>
      </Row>
      <Table
        // title={() => "History"}
        // loading={loading}
        rowKey="id"
        dataSource={TableData}
        // pagination={{ showSizeChanger: "true" }}
        scroll={{ x: "max-content" }}
        bordered
        expandable={{
          expandedRowRender: (record) => {
            return (
              <p style={{ margin: 0 }}>
                {/* <Text>Message : </Text> */}
                <div style={{ textAlign: "center" }}>
                  <Text strong level={5}>
                    Message
                  </Text>
                </div>

                {/* {record.body} */}
                <div dangerouslySetInnerHTML={{ __html: record.body }} />
              </p>
            );
          },
          // rowExpandable: (record) => record.name !== 'Not Expandable',
        }}
        pagination={{
          // pageSize: 10,
          current: CurrentPage,
          total: TotalPages,
          showSizeChanger: false,
          onChange: (page) => {
            HandlePagination(
              StartDate ? StartDate : "",
              EndDate ? EndDate : "",
              page,
              10
            );
          },
        }}
      >
        <Column
          title="ID"
          dataIndex="id"
          key="id"
          //   filterSearch={true}
          //   filters={Duplesscam.map((item) => item)}
          //   onFilter={(value, record) => record.cameraName.indexOf(value) === 0}
        />
        <Column
          title="Priority"
          dataIndex="priority"
          key="prority"
          align="center"
          width={"10%"}
          //   filterSearch={true}
          //   filters={Duplesscam.map((item) => item)}
          //   onFilter={(value, record) => record.cameraName.indexOf(value) === 0}
          render={(record) => {
            let color = "cyan";
            if (record === "High") {
              color = "magenta";
            }
            if (record === "Medium") {
              color = "volcano";
            }

            return <Tag color={color}>{record}</Tag>;
          }}
        />
        <Column
          title="Subject"
          dataIndex="subject"
          key="subject"
          //   filterSearch={true}
          //   filters={Duplesscam.map((item) => item)}
          //   onFilter={(value, record) => record.cameraName.indexOf(value) === 0}
        />
        <Column
          title={<Text style={{ textAlign: "center" }}>Phone number</Text>}
          dataIndex="mobilenumber"
          key="phone_number"
        />
        <Column
          title={<Text style={{ textAlign: "center" }}>Date</Text>}
          align="center"
          width={"10%"}
          dataIndex="ticketRaised_dt"
          key="date"
          //   filterSearch={true}
          //   filters={Duplesscam.map((item) => item)}
          //   onFilter={(value, record) => record.cameraName.indexOf(value) === 0}
          render={(record) => {
            let momentDate = "";
            let momentTime = moment(record).format("HH:mm A");
            if (
              moment(record).format("YYYY-MM-DD") ===
              moment().format("YYYY-MM-DD")
            ) {
              momentDate = "Today";
            }
            if (
              moment(record).format("YYYY-MM-DD") ===
              moment().subtract(1, "days").format("YYYY-MM-DD")
            ) {
              momentDate = "Yesterday";
            }

            // return (
            //   <Tag icon={<CalendarOutlined />} color="purple">
            //     {moment(record).format("YYYY-MM-DD HH:mm")}
            //   </Tag>
            // );

            return momentDate === "Today" ? (
              <Tag icon={<CalendarOutlined />} color="purple">
                {momentDate + " " + momentTime}
              </Tag>
            ) : momentDate === "Yesterday" ? (
              <Tag icon={<CalendarOutlined />} color="geekblue">
                {momentDate + " " + momentTime}
              </Tag>
            ) : (
              <Tag icon={<CalendarOutlined />} color="#595959">
                {moment(record).format("ddd DD-MM-YYYY     HH:mm A ")}
              </Tag>
            );
          }}
        />

        <Column
          title={<Text style={{ textAlign: "center" }}>User</Text>}
          align="center"
          width={"10%"}
          key="User"
          render={(_, record) => {
            // isfile_exist
            return record.ticketRaiesByMail;
          }}
        />

        <Column
          title={<Text style={{ textAlign: "center" }}>File</Text>}
          align="center"
          width={"10%"}
          key="Document"
          render={(_, record) => {
            // isfile_exist
            return record.isfile_exist ? (
              <>
                {!authService.getCurrentUserReadOnly() ? (
                  <HiDocumentDownload
                    className="file-download"
                    size={"1.6em"}
                    color="gray"
                    // onMouseEnter={{ color: "blue" }}
                    onMouseOver={({ target }) => (target.style.color = "blue")}
                    onMouseOut={({ target }) => (target.style.color = "gray")}
                    onClick={() => Getfile(record.id)}
                  />
                ) : (
                  <Tooltip title="You need access to download file">
                    <HiDocumentDownload
                      className="file-download"
                      size={"1.6em"}
                    />
                  </Tooltip>
                )}
              </>
            ) : (
              ""
            );
          }}
        />
      </Table>
    </>
  );
}

export default TechSupportList;
