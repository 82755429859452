import React, { useEffect } from "react";

import { Row } from "antd";

import Iframe from "react-iframe";

// import Typography from "antd/lib/typography/Typography";

const IP = process.env.REACT_APP_HTTP;
const PORT = process.env.REACT_APP_HTTP_PORT;
const HTMLip = process.env.REACT_APP_VIDEO_SOCKET_KEY;

export default function Home() {
  document.title = "KEN - Home";

  window.addEventListener(
    "blur",
    () => (document.title = "Check for new updates*")
  );
  window.addEventListener("focus", () => (document.title = "KEN - Home"));

  // useEffect(() => {
  //   setInterval(() => {
  //     window.location.reload(true);
  //   }, 5 * 60 * 1000);
  // }, []);

  return (
    <>
      <Row key="row" justify="space-around " style={{ marginBottom: "2em" }}>
        <Iframe
          url={
            "http://" +
            IP +
            ":" +
            PORT +
            "/index_copy.html?user=" +
            JSON.parse(localStorage.getItem("user")) +
            "&&ip=" +
            HTMLip
          }
          frameBorder="0"
          seamless
          width="100%"
          height="1000"
          id=""
          className=""
          allowFullScreen
        />
      </Row>
    </>
  );
}
