import {
  Row,
  Space,
  Table,
  Tag,
  Col,
  Card,
  Image,
  Modal,
  Skeleton,
  DatePicker,
  Button,
  Alert,
  Typography,
  message,
  Form,
  Select,
  Collapse,
  Dropdown,
  Radio,
  Tooltip,
  Timeline,
  List,
  Empty,
  Spin,
  Progress,
  Result,
  Input,
  Menu,
  Switch,
  Flex,
} from "antd";
import ReactPlayer from "react-player";

import { TbReportAnalytics, TbAlertTriangle } from "react-icons/tb";
import { GoVerified } from "react-icons/go";
import { FiLink } from "react-icons/fi";

import {
  PlayCircleOutlined,
  UserOutlined,
  DownOutlined,
  DownloadOutlined,
  HistoryOutlined,
  LoadingOutlined,
  EyeOutlined,
  SearchOutlined,
  CameraOutlined,
  PushpinOutlined,
  LinkOutlined,
  LockOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { MultiSelect } from "react-multi-select-component";
// import { Player } from "video-react";
import React, { useRef } from "react";
import axiosClient from "../../Authentication/ApiCall";
import { useEffect, useState } from "react";
import moment from "moment/moment";

import { CSVLink } from "react-csv";
import ReactToPrint from "react-to-print";
import dayjs from "dayjs";
import { Player } from "video-react";
import { saveAs } from "file-saver";
import { TbCameraSearch } from "react-icons/tb";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import TextArea from "antd/lib/input/TextArea";
import authService from "../../Authentication/authService";
import { Loading } from "react-fullscreen-loading";
import { useDispatch, useSelector } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import { useNavigate } from "react-router-dom";
import { constrainPoint } from "@fullcalendar/react";
//   import ImageInfo from "./imageInfo";
import { FaFileDownload } from "react-icons/fa";
import { FaDownload } from "react-icons/fa6";
import { Tabs } from "antd/lib";
import TabPane from "antd/es/tabs/TabPane";
import { CiImageOn } from "react-icons/ci";
import { TbCameraPlus } from "react-icons/tb";
import { PulseLoader } from "react-spinners";
const { RangePicker } = DatePicker;
const { Column } = Table;
const { Link, Title, Text } = Typography;
const { Option } = Select;

let InitalPreview = {
  rtsp: null,
  image: null,
  openmodal: false,
  loading: false,
};
const Asset_onboarding = () => {
  const [form] = Form.useForm();
  const [FormWithoutRTSP] = Form.useForm();
  const [listdata, setlistdata] = useState([]);
  const [timeid, settimeid] = useState("");
  const [submitformwithrtsp] = Form.useForm();
  const [isCameraWorking, setIsCameraWorking] = useState(true);
  const [cameralist, setcameralist] = useState([]);
  const [events, setevents] = useState([]);
  const [selectedcameralist, setselectedcameralist] = useState([]);
  const [IpadressList, setIpadressList] = useState([]);
  const [refresh, setrefresh] = useState(false);
  const [data, setdata] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [TableData, setTableData] = useState([]);
  const [camname, setcamname] = useState([]);
  const [location, setlocation] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalWithoutRTSP, setmodalWithoutRTSP] = useState(false);
  const [loading, setloading] = useState(true);
  const [DefaultStatus, setDefaultStatus] = useState(false);
  const [visible, setvisible] = useState(false);
  const [camid, setcamid] = useState();
  const [viewmodal, setviewmodal] = useState(false);
  const [locations, setLocations] = useState([]);
  const [AddRefresh, setAddRefresh] = useState(1);
  const [Pageload, setPageload] = useState(false);
  const count = useSelector((state) => state.count.value);
  const pageSize = 10;
  const [isPreviewClicked, setIsPreviewClicked] = useState(false);
  const [DisableIPSelect, setDisableIPSelect] = useState(true);

  const [PreviewDetails, setPreviewDetails] = useState({
    rtsp: null,
    image: null,
    openmodal: false,
    loading: false,
    LoadRTSP: null,
  });
  //test
  const [PreviewWithoutRTSP, setPreviewWithoutRTSP] = useState({
    rtsp: null,
    image: null,
    openmodal: false,
    loading: false,
    valid: false,
  });
  // const currentPage = 1;

  // remove duplicates of cam name
  const Duplesscam = camname.filter(
    (ele, ind) =>
      ind ===
      camname.findIndex(
        (elem) => elem.text === ele.text && elem.value === ele.value
      )
  );
  // remove duplicates location
  const Duplesslocation = location.filter(
    (ele, ind) =>
      ind ===
      location.findIndex(
        (elem) => elem.text === ele.text && elem.value === ele.value
      )
  );
  const paginationConfig = {
    current: currentPage,
    pageSize: pageSize,
    total: data.length,
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
    onChange: (page) => setCurrentPage(page),
  };

  // api to get Ipaddress list
  useEffect(() => {
    axiosClient
      .get("/camera/getRTSPusingnmap")
      .then((response) => {
        if (response.status === 200) {
          const ipOptions = response.data.msg.map((ip) => ({
            label: ip,
            value: ip,
          }));

          setIpadressList(ipOptions);
          console.log("ipadress", ipOptions);
        }
      })
      .catch((err) => {
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  }, []);

  //Table data
  useEffect(() => {
    axiosClient
      .get("/camera/get_cameraListForEdit")
      .then((response) => {
        if (response.data.issuccess === true) {
          const flattenedData = response.data.data.flat();
          setTableData(flattenedData);
          // setloading(false);

          flattenedData.map((item, index) => {
            let data = item.cameraName;
            let tosave = {
              text: data,
              value: data,
            };

            let loc = item.location;
            let locdata = {
              text: loc,
              value: loc,
            };

            setcamname((cam) => [...cam, tosave]);
            setlocation((loc) => [...loc, locdata]);
          });
        } else {
          console.log("error");
        }
      })
      .catch((err) => {
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  }, [AddRefresh]);

  // on finish edit
  const OnFinishEdit = (values) => {
    console.log("onedit of aseetonboarding7777777777", values);
    console.log("onedit of aseetonboarding99999999", camid);
    setvisible(false);

    setPageload(true);
    axiosClient
      .put("/camera/EditCameraDetail", {
        cameraName: values.name,
        RTSP: values.rtsp.trim(),
        floor: values.floor,
        id: camid,
        isdele: values.status,
        location: values.location,
        onBoardLocation: values.onboardLocation,
      })
      .then((response) => {
        if (response) {
          setPageload(false);
          setAddRefresh(AddRefresh + 1);
          message.success(response.data.msg);
        } else {
          message.error(response.data.msg);
        }
      })
      .catch((err) => {
        setPageload(false);
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });

    form.resetFields();
  };

  // on click edit button, fill the values in text box
  const onEditRecord = (record) => {
    setcamid(record.id);

    setDefaultStatus(record.isdele);
    form.setFieldsValue({
      name: record.cameraName,
      rtsp: record.RTSP,
      floor: record.floor,
      location: record.location,
      status: record.isdele,
      onboardLocation: record.onBoardLocation,
    });

    setvisible(!visible);
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };
  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };

  //adding new Camera
  // const onFinish = (values) => {
  //   setPageload(true);
  //   if (isCameraWorking === true) {
  //     axiosClient
  //       .post(
  //         "/camera/AddCameras",
  //         {
  //           location: values.location,
  //           cameraName: values.cam_name,
  //           RTSP: values.rtsp.trim(),
  //           userName: null,
  //           password: null,
  //           ipaddress: null,
  //           floor: values.floor,
  //           onBoardLocation: values.onboardLocation,
  //         }
  //         // apiData
  //       )
  //       .then((response) => {
  //         if (response.data.issuccess === true) {
  //           setAddRefresh(AddRefresh + 1);
  //           setPageload(false);
  //           message.success(response.data.msg);
  //           submitformwithrtsp.resetFields();
  //           setModal(false);
  //         } else {
  //           message.error(response.data.msg);
  //           submitformwithrtsp.resetFields();
  //           setPageload(false);
  //           setModal(false);
  //         }
  //       })
  //       .catch((err) => {
  //         setPageload(false);
  //         form.resetFields();
  //         setModal(false);
  //         console.log("errr", err);
  //         if (err.status === 0) {
  //           message.error("Server error");
  //         } else {
  //           message.error(err.msg);
  //         }
  //       });
  //   } else {
  //     message.error(
  //       "Camera is not working. Please Check the RTSP Before Submitting."
  //     );
  //     setPageload(false);
  //   }
  // };
  const onFinish = (values) => {
    if (isPreviewClicked == true) {
      setPageload(true);
      if (isCameraWorking == true) {
        axiosClient
          .post("/camera/AddCameras", {
            location: values.location,
            cameraName: values.cam_name,
            RTSP: values.rtsp.trim(),
            userName: null,
            password: null,
            ipaddress: null,
            floor: values.floor,
            onBoardLocation: values.onboardLocation,
          })
          .then((response) => {
            if (response.data.issuccess === true) {
              setAddRefresh(AddRefresh + 1);
              setPageload(false);
              message.success(response.data.msg);
              submitformwithrtsp.resetFields();
              setModal(false);
            } else {
              message.error(response.data.msg);
              submitformwithrtsp.resetFields();
              setPageload(false);
              setModal(false);
            }
          })
          .catch((err) => {
            setPageload(false);
            submitformwithrtsp.resetFields();
            setModal(false);
            console.log("errr", err);
            message.error(err.status === 0 ? "Server error" : err.msg);
          });
      } else {
        message.error("Please check the RTSP  before submitting.");
        setPageload(false);
      }
    } else {
      message.error(
        "Please click the Preview button and ensure the camera is working before submitting."
      );
      setPageload(false);
      // submitformwithrtsp.resetFields();
      // submitformwithrtsp.setFieldsValue({
      //   rtsp: "",
      // });
      submitformwithrtsp.resetFields(["rtsp"]);
    }
  };

  //adding Camera Without RTSP
  const onFinishwithoutRTSP = (values) => {
    setPageload(true);
    // // Check if any field is empty
    // console.log("withoutrtspvalues", values);
    // const isEmptyField = Object.values(values).some((value) => !value);

    // if (isEmptyField) {
    //   Modal.error({
    //     title: "Error",
    //     content: "Please fill in all fields before submitting.",
    //   });
    //   return;
    // }

    axiosClient
      .post(
        "/camera/AddCameras",
        {
          location: values.location,
          cameraName: values.cam_name,
          RTSP: null,
          userName: values.userName,
          password: values.password,
          ipaddress: values.ipaddress,

          events_list: values.events,
          onBoardLocation: values.onboardLocation,
        }
        // apiData
      )
      .then((response) => {
        if (response.data.issuccess === true) {
          setPageload(false);
          message.success(response.data.msg);
          setAddRefresh(AddRefresh + 1);
          form.resetFields();
          setmodalWithoutRTSP(false);
        } else {
          setPageload(false);
          message.error(response.data.msg);
          form.resetFields();
          setmodalWithoutRTSP(false);
        }
      })
      .catch((err) => {
        setPageload(false);
        form.resetFields();
        setmodalWithoutRTSP(false);
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
    // Modal.success({
    //   title: 'Form Submitted',
    //   content: 'Your form has been submitted successfully!',
    // });
  };

  const addCamera = () => {
    setModal(true);
  };
  const handlecreateeventclose = () => {
    setModal(false);
    setmodalWithoutRTSP(false);
    setvisible(false);
  };

  const handleclose = () => {
    setvisible(false);
  };
  const handleMenuClick = (value) => {
    console.log(`Clicked on ${value}`);
  };

  const handlemenu = () => {
    setModal(true);
    submitformwithrtsp.resetFields();
    setIsPreviewClicked(false);
  };

  const handleRTSPmenu = () => {
    setmodalWithoutRTSP(true);
  };
  const oncanceledit = () => {
    setvisible(false);
  };

  const menu = (
    <Menu onClick={({ key }) => handleMenuClick(key)}>
      <Menu.Item key="value1" onClick={handlemenu}>
        <Flex
          title="Add with RTSP"
          gap={10}
          align="center"
          style={{ textAlign: "center" }}
        >
          <FiLink />
          <Text>add</Text>
          {/* <TbCameraPlus /> */}
        </Flex>
      </Menu.Item>
      <Menu.Item key="value2" onClick={handleRTSPmenu}>
        <Flex align="center" title="Find the cameras" gap={10}>
          <TbCameraSearch />
          scan
        </Flex>
      </Menu.Item>
    </Menu>
  );

  //Delete Camera List
  const onDeleteRecord = (record) => {
    Modal.confirm({
      title: "Confirm Delete?",

      okText: "Confirm",
      okType: "danger",
      onOk: () => {
        setPageload(true);
        axiosClient
          .put("/camera/DeleteCamera", {
            id: record.id,
            // delete_reason:record
          })
          .then((response) => {
            if (response.data.issuccess === true) {
              message.success(response.data.msg);
              setAddRefresh(AddRefresh + 1);
              setPageload(false);
            } else {
              console.log("error");
              setPageload(false);
              setAddRefresh(AddRefresh + 1);
            }
          })
          .catch((err) => {
            console.log("errr", err);
            setAddRefresh(AddRefresh + 1);
            setPageload(false);
            if (err.status === 0) {
              message.error("Server error");
            } else {
              message.error(err.msg);
            }
          });
      },
    });
    form.resetFields();
  };

  // Preview modal open
  const OpenPreviewModal = () => {
    setPreviewDetails((prev) => ({ ...prev, openmodal: true }));
  };

  // Preview modal close
  const ClosePreviewModal = () => {
    setPreviewDetails(InitalPreview);
  };

  // onchange of Without RTSP form
  const OnchageFormWithoutRTSP = () => {
    if (
      FormWithoutRTSP.getFieldsValue().userName &&
      FormWithoutRTSP.getFieldsValue().password
    ) {
      setDisableIPSelect(false);
    } else {
      setDisableIPSelect(true);
    }
  };
  const OnchangeIpSelect = async (ip) => {
    setPreviewDetails((prev) => ({ ...prev, loading: true }));
    if (
      FormWithoutRTSP.getFieldsValue().userName &&
      FormWithoutRTSP.getFieldsValue().password &&
      ip
    ) {
      let rtsp = `rtsp://${FormWithoutRTSP.getFieldsValue().userName}:${
        FormWithoutRTSP.getFieldsValue().password
      }@${ip}:554/Streaming/channels/101`;
      await axiosClient
        .get("/cameraEvents/getCameraFramefromAI?RTSP=" + rtsp + "&cameraid=0")
        .then((response) => {
          if (response.data.issuccess === true) {
            setPreviewDetails((prev) => ({
              ...prev,
              image: response.data.msg.frame,
              loading: false,
              rtsp: rtsp,
            }));
          } else {
            message.error("image unavailable");
            FormWithoutRTSP.resetFields();
          }
        })
        .catch((rr) => {
          console.log("rrr", rr);
          message.error("image unavailable");
          setPreviewDetails((prev) => ({ ...prev, loading: false }));
          FormWithoutRTSP.resetFields();
        });
    }
  };

  const OnCancelWithoutRtsp = () => {
    setmodalWithoutRTSP(false);
    setPreviewDetails(InitalPreview);
    FormWithoutRTSP.resetFields();
  };

  // onclick for preview with RTSP
  // const getImagewithRTSP = async () => {
  //   console.log("rtsp check88888888", submitformwithrtsp.getFieldsValue().rtsp);
  //   if (submitformwithrtsp.getFieldValue("rtsp")) {
  //     submitformwithrtsp
  //       .validateFields(["rtsp"])
  //       .then(async (res) => {
  //         setPreviewDetails((prev) => ({ ...prev, loading: true }));
  //         let newurl = res.rtsp;
  //         await axiosClient
  //           .get(
  //             "/cameraEvents/getCameraFramefromAI?RTSP=" +
  //               encodeURIComponent(newurl) +
  //               "&cameraid=0"
  //           )
  //           .then((response) => {
  //             if (response.data.issuccess === true) {
  //               setIsCameraWorking(true);
  //               setPreviewDetails((prev) => ({
  //                 ...prev,
  //                 image: response.data.frame,
  //                 loading: false,
  //                 rtsp: res.rtsp,
  //                 openmodal: true,
  //               }));
  //             } else if (response.data.issuccess === false) {
  //               message.error("Camera is not Working");
  //               setPreviewDetails((prev) => ({ ...prev, loading: false }));
  //               setIsCameraWorking(false);
  //               submitformwithrtsp.resetFields();
  //             } else {
  //               message.error("Camera is not Working");
  //               setIsCameraWorking(false);
  //               setPreviewDetails((prev) => ({ ...prev, loading: false }));

  //               submitformwithrtsp.resetFields();
  //             }
  //           })
  //           .catch((rr) => {
  //             console.log("rrr", rr);
  //             setIsCameraWorking(false);
  //             message.error("Camera is not Working");
  //             setPreviewDetails((prev) => ({ ...prev, loading: false }));
  //             // form.resetFields();
  //           });
  //       })
  //       .catch((errr) => {
  //         setPreviewDetails((prev) => ({ ...prev, loading: false }));
  //         console.log("error from validatefields", errr);
  //         message.error("Invalid RTSP");
  //       });
  //   } else {
  //     message.error("Please enter RTSP");
  //   }
  // };
  const getImagewithRTSP = async () => {
    console.log("rtsp check88888888", submitformwithrtsp.getFieldsValue().rtsp);

    const rtspValue = submitformwithrtsp.getFieldValue("rtsp");

    if (rtspValue) {
      submitformwithrtsp
        .validateFields(["rtsp"])
        .then(async (res) => {
          setPreviewDetails((prev) => ({ ...prev, loading: true }));
          let newurl = res.rtsp;

          try {
            const response = await axiosClient.get(
              `/cameraEvents/getCameraFramefromAI?RTSP=${encodeURIComponent(
                newurl
              )}&cameraid=0`
            );

            if (response.data.issuccess === true) {
              setPreviewDetails((prev) => ({
                ...prev,
                image: response.data.msg.frame,
                loading: false,
                rtsp: res.rtsp,
                openmodal: true,
              }));
              setIsCameraWorking(true); // Camera is working
            } else {
              handleCameraError();
            }
          } catch (error) {
            handleCameraError();
          }
        })
        .catch((errr) => {
          setPreviewDetails((prev) => ({ ...prev, loading: false }));
          console.log("error from validatefields", errr);
          message.error("Invalid RTSP");
        });
    } else {
      message.error("Please enter RTSP");
    }
  };

  const handleCameraError = () => {
    message.error("Camera is not Working");
    setIsCameraWorking(false);
    // setIsPreviewClicked(false);
    setPreviewDetails((prev) => ({ ...prev, loading: false }));
  };

  const getImagewithRTSPTable = async (record) => {
    console.log("rtsp check", record.RTSP);
    setPreviewDetails((prev) => ({
      ...prev,
      loading: true,
      LoadRTSP: record.RTSP,
    }));
    await axiosClient
      .get(
        "/cameraEvents/getCameraFramefromAI?RTSP=" +
          encodeURIComponent(record.RTSP) +
          "&cameraid=0"
      )
      .then((response) => {
        if (response.data.issuccess === true) {
          setPreviewDetails((prev) => ({
            ...prev,
            image: response.data.msg.frame,
            loading: false,
            rtsp: record.RTSP,
            openmodal: true,
            LoadRTSP: null,
          }));
        } else {
          message.error("image unavailable");
          setPreviewDetails((prev) => ({ ...prev, loading: false }));

          //FormWithoutRTSP.resetFields();
        }
      })
      .catch((rr) => {
        console.log("rrr", rr);
        //message.error("image unavailable");
        setPreviewDetails((prev) => ({ ...prev, loading: false }));
        // form.resetFields();
      });
  };

  const oncancelview = () => {
    setviewmodal(false);
  };
  const oncancelwithoutRTSP = () => {
    form.resetFields();
    setPreviewDetails((prev) => ({
      ...prev,
      openmodal: false,
      rtsp: null,
      loading: false,
      image: null,
    }));
    setModal(false);
  };

  const handleViewClick = async (record) => {
    setviewmodal(true);
    const id = record.id;
    settimeid(id);
    try {
      const response = await axiosClient.get(
        "camera/getinactivelog?id=" + id + "&startDate=" + " "
      );
      setlistdata(response.data.data);
      // Handle the response as needed
      console.log("API response:", response.data);
    } catch (error) {
      console.error("Error while calling the API:", error);
    }
  };
  const formatTime = (dateTime) => moment(dateTime).format("HH:mm:ss");
  const handleDateChange = async (date) => {
    if (date) {
      const selectedDate = date.format("YYYY-MM-DD");
      try {
        // setLoading(true);
        const response = await axiosClient.get(
          "camera/getinactivelog?id=" + timeid + "&startDate=" + selectedDate
        );
        if (response.data.issuccess) {
          setlistdata(response.data.data);
        } else {
          message.error("Failed to fetch data");
        }
      } catch (error) {
        message.error("Error fetching data");
      } finally {
        // setLoading(false);
      }
    }
  };
  // useEffect(() => {
  //   axiosClient
  //     .get("/storeonboard/getstoreonboarddetails")
  //     .then((response) => {
  //       if (response && response.data && response.data.data) {
  //         const locationValues = response.data.data.map(
  //           (store) => store.onBoardLocation
  //         );
  //         setLocations(locationValues);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("errr", err);
  //       if (err.status === 0) {
  //         message.error("Server error");
  //       } else {
  //         message.error(err.msg);
  //       }
  //     });
  // }, []);

  useEffect(() => {
    axiosClient
      .get("/storeonboard/getstoreonboarddetails")
      .then((response) => {
        if (response && response.data && response.data.data) {
          setLocations(response.data.data);
        }
      })
      .catch((err) => {
        console.log("err", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.message);
        }
      });
  }, []);

  const columns = [
    {
      title: (
        <span style={{ color: "#000816", fontSize: "1rem" }}>From Time</span>
      ),
      dataIndex: "fromTime",
      key: "fromTime",
      align: "center",
      render: (text) => moment(text).format("HH:mm:ss"),
    },
    {
      title: (
        <span style={{ color: "#000816", fontSize: "1rem" }}> To Time</span>
      ),
      dataIndex: "toTime",
      key: "toTime",
      align: "center",
      render: (text) => moment(text).format("HH:mm:ss"),
    },
  ];
  return (
    <>
      <div
        className="report-content"
        style={{
          // height: "120px",
          backgroundColor: "#000816",
          width: "100%",
          // marginLeft: "9px",
          margin: 0,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        {/* First Row */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginLeft: "3%",
            padding: "10px",
          }}
        >
          <p
            style={{
              color: "white",
              fontSize: "21px",
              fontFamily: "revert-layer",
              marginTop: "10px",
            }}
          >
            Asset Onboarding
          </p>
          <div style={{ display: "flex", marginRight: "3%" }}>
            <Dropdown overlay={menu} placement="bottomLeft" trigger={["hover"]}>
              <Button className="adduser">
                + New <DownOutlined />
              </Button>
            </Dropdown>
          </div>
        </div>
      </div>
      {/* //Asset-onboarding Table Data */}
      <Row gutter={[4, 4]} style={{ maxHeight: "90%" }}>
        <Col
          xs={{ span: 24, offset: 0 }}
          lg={{ span: 24, offset: 0 }}
          xl={{ span: 24, offset: 0 }}
          style={{ marginLeft: "1%" }}
        >
          <Table
            dataSource={TableData}
            pagination={paginationConfig}
            scroll={{ x: true }}
          >
            <Column
              width={"16%"}
              title="Name"
              dataIndex="cameraName"
              key="CameraName"
              filterSearch={true}
              filters={Duplesscam.map((item) => item)}
              render={(text) => <span style={{ color: "black" }}>{text}</span>}
              onFilter={(value, record) =>
                record.cameraName.indexOf(value) === 0
              }
            />
            <Column
              width={"22%"}
              title="RTSP"
              render={(text) => <span style={{ color: "black" }}>{text}</span>}
              dataIndex="RTSP"
              key="rtsp"
            />
            <Column
              width={"4%"}
              title="View"
              render={(text, record) => {
                return (
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={() => getImagewithRTSPTable(record)}
                  >
                    {PreviewDetails.loading &&
                    PreviewDetails.LoadRTSP == text ? (
                      <>
                        <Button type="primary">
                          <PulseLoader size={2} color="white" />
                        </Button>
                      </>
                    ) : (
                      <Button type="primary">
                        <EyeOutlined
                          style={{ fontSize: "18px", color: "#fffff" }}
                        />
                      </Button>
                    )}{" "}
                  </span>
                );
              }}
              dataIndex="RTSP"
              key="rtsp"
              align="center"
            />
            <Column
              // width={"34%"}
              title="Floor"
              render={(text) => <span style={{ color: "black" }}>{text}</span>}
              dataIndex="floor"
              key="floor"
            />
            <Column
              title="cameraStatus"
              render={(text) => (
                <Tag color={text === "active" ? "green" : "red"}>{text}</Tag>
              )}
              dataIndex="cameraStatus"
              key="cameraStatus"
            />
            <Column
              width={"10%"}
              title="Location"
              dataIndex="location"
              key="location"
              filterSearch={true}
              render={(text) => <span style={{ color: "black" }}>{text}</span>}
              filters={Duplesslocation.map((item) => item)}
              onFilter={(value, record) => record.location.indexOf(value) === 0}
            />
            <Column
              title="Inactive Log"
              key="view"
              align="center"
              render={(text, record) => (
                <Button type="primary" onClick={() => handleViewClick(record)}>
                  View
                </Button>
              )}
            />
            <Column
              width={"10%"}
              title="OnBoard Location"
              dataIndex="onBoardLocation"
              key="onBoardLocation"
              filterSearch={true}
              render={(text) => <span style={{ color: "black" }}>{text}</span>}
              // filters={Duplesslocation.map((item) => item)}
              onFilter={(value, record) =>
                record.onBoardLocation.indexOf(value) === 0
              }
            />
            {/* {authService.getCurrentUserRole() === "Admin" ||
            authService.getCurrentUserRole() === "Management" ? ( */}
            {/* <Column
              // width={"4%"}
              title="Edit"
              key="edit"
              align="center"
              render={(record) => {
                return (
                  <Row
                    gutter={[0, 0]}
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                    }}
                  >
                    <Col>
                      {authService.getCurrentUserReadOnly() ? (
                        <Tooltip title="you need access to edit" disabled>
                          <EditOutlined disabled />
                        </Tooltip>
                      ) : (
                        <EditOutlined
                          onClick={(e) => {
                            onEditRecord(record);
                          }}
                        />
                      )}
                    </Col>
                  </Row>
                );
              }}
            /> */}
            {/* ) : (
              ""
            )} */}

            <Column
              // width={"2%"}
              title="Delete"
              key="delete"
              align="center"
              render={(_, record) => {
                return (
                  <Row
                    gutter={[0, 0]}
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <Col>
                      {authService.getCurrentUserReadOnly() ? (
                        <Tooltip title="you need access to Delete" disabled>
                          <DeleteOutlined disabled />
                        </Tooltip>
                      ) : (
                        <DeleteOutlined
                          style={{ color: "red" }}
                          onClick={(e) => {
                            onDeleteRecord(record);
                          }}
                        />
                      )}
                    </Col>
                  </Row>
                );
              }}
            />
          </Table>
        </Col>
      </Row>
      <Modal
        destroyOnClose
        centered
        open={viewmodal}
        onCancel={oncancelview}
        footer={null}
      >
        <div
          style={{
            marginBottom: "16px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span style={{ marginRight: "13px", fontWeight: "bold" }}>
            Select Date :
          </span>
          <DatePicker
            onChange={handleDateChange}
            format="YYYY-MM-DD"
            style={{ width: "30%" }}
            placeholder="Select a date"
            defaultValue={dayjs()}
            // defaultValue={moment()}
          />
        </div>
        <Table columns={columns} dataSource={listdata} />

        {/* {listdata.length === 0 ? (
          <Empty />
        ) : (
          <>
            <Row
              gutter={16}
              align={"middle"}
              justify={"center"}
              style={{ marginBottom: "16px" }}
            >
              <Col span={12}>
                <p>From Time :</p>
              </Col>
              <Col span={12}>
                <p>To Time :</p>
              </Col>
            </Row>
            {listdata.map((item, index) => (
              <Row key={index} gutter={16} style={{ marginBottom: "16px" }}>
                <Col span={12}>
                  <Input defaultValue={formatTime(item.fromTime)} />
                </Col>
                <Col span={12}>
                  <Input defaultValue={formatTime(item.toTime)} />
                </Col>
              </Row>
            ))}
          </>
        )} */}
      </Modal>
      {/* //Modal for Adding Camera  With RTSP  */}
      <Modal
        className="onsubmitrtspmodal"
        destroyOnClose
        centered
        open={modal}
        onCancel={oncancelwithoutRTSP}
        title={
          <>
            <Row align={"middle"} justify={"center"}>
              <Col>
                <Text className="Modal-Header">Asset Onboarding</Text>
              </Col>
            </Row>
          </>
        }
        styles={{ body: { paddingTop: "10px" } }}
        footer={
          <>
            <Row justify={"space-around"}>
              <Col>
                {authService.getCurrentUserReadOnly() ? (
                  <Tooltip title="you need permission to submit">
                    <Button type="primary" block disabled>
                      Submit
                    </Button>
                  </Tooltip>
                ) : (
                  <Button
                    className="primary-button"
                    form="submitbasicformwithrtsp"
                    type="primary"
                    block
                    htmlType="submit"
                  >
                    Submit
                  </Button>
                )}
              </Col>
              <Col>
                <Button onClick={() => handlecreateeventclose()}>Cancel</Button>
              </Col>
            </Row>
          </>
        }
      >
        <Form
          form={submitformwithrtsp}
          id="submitbasicformwithrtsp"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 22,
          }}
          onFinish={onFinish}
          {...formItemLayout}
          // layout="inline"
          style={{ marginLeft: "10px", padding: "25px" }}
        >
          <Form.Item
            name="cam_name"
            label="CameraName"
            rules={[
              {
                type: "string",
                required: true,
                message: "Please Enter the CameraName",
              },
            ]}
          >
            <Input
              className="ant-input-custom-input"
              placeholder="CameraName"
              prefix={<CameraOutlined className="site-form-item-icon" />}
            />
          </Form.Item>
          <Form.Item
            name="location"
            label="Location"
            rules={[
              {
                type: "string",
                required: true,
                message: "Please Enter the Location",
              },
            ]}
          >
            <Input
              prefix={<PushpinOutlined className="site-form-item-icon" />}
              placeholder="Location"
            />
          </Form.Item>

          <Form.Item
            name="rtsp"
            label="RTSP"
            rules={[
              {
                type: "url",
                required: true,
                message: "Please Enter the RTSP link!",
              },
            ]}
          >
            <Flex gap={10}>
              <Input
                prefix={<LinkOutlined className="site-form-item-icon" />}
                placeholder="RTSP Link"
              />
              <Button
                title="Preview"
                onClick={() => {
                  getImagewithRTSP();
                  setIsPreviewClicked(true);
                }}
              >
                {PreviewDetails.loading ? (
                  <PulseLoader color="gray" size={2} />
                ) : (
                  <EyeOutlined />
                )}
              </Button>
            </Flex>
          </Form.Item>

          <Form.Item
            label="Onboard Location"
            name="onboardLocation"
            rules={[
              {
                type: "string",
                required: true,
                message: "Please select any location!",
              },
            ]}
          >
            <Select placeholder="Select a location">
              {locations.map((location, index) => (
                <Option key={index} value={location.onBoardLocation}>
                  {location.onBoardLocation}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="floor"
            label="Floor"
            rules={[
              {
                required: true,
                message: "Please Enter Floor!",
              },
            ]}
          >
            <Input placeholder="Select a Floor" />
          </Form.Item>
        </Form>
      </Modal>

      {/* //Modal for Adding Camera  Without RTSP  */}
      <Modal
        className="onsubmitrtspmodal"
        destroyOnClose
        centered
        open={modalWithoutRTSP}
        onCancel={OnCancelWithoutRtsp}
        title={
          <>
            <Row align={"middle"} justify={"center"}>
              <Col>
                <Text className="Modal-Header">Asset Onboarding</Text>
              </Col>
            </Row>
          </>
        }
        styles={{ body: { paddingTop: "10px" } }}
        footer={
          <>
            <Row justify={"space-around"}>
              <Col>
                {authService.getCurrentUserReadOnly() ? (
                  <Tooltip title="you need permission to submit">
                    <Button type="primary" block disabled>
                      Submit
                    </Button>
                  </Tooltip>
                ) : (
                  <Button
                    className="primary-button"
                    form="submitbasicform"
                    type="primary"
                    block
                    htmlType="submit"
                  >
                    Submit
                  </Button>
                )}
              </Col>
              <Col>
                <Button onClick={OnCancelWithoutRtsp}>Cancel</Button>
              </Col>
            </Row>
          </>
        }
      >
        <Form
          form={FormWithoutRTSP}
          id="submitbasicform"
          onFinish={onFinishwithoutRTSP}
          {...formItemLayout}
          style={{ marginLeft: "10px", padding: "25px" }}
          onChange={OnchageFormWithoutRTSP}
        >
          <Form.Item label="User Name" name="userName">
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="UserName"
            />
          </Form.Item>

          <Form.Item label="Password" name="password">
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder="Password"
            />
          </Form.Item>

          <Form.Item
            // wrapperCol={{ span: 12 }}
            label="IP Address"
            name="ipaddress"
          >
            <Flex gap={10}>
              <Select
                loading={PreviewDetails.loading}
                disabled={DisableIPSelect}
                onSelect={(e) => OnchangeIpSelect(e)}
                placeholder="select IP Address"
                options={IpadressList}
              ></Select>
              <Button
                style={{
                  display: PreviewDetails.image ? "inline-block" : "none",
                }}
                title="preview"
                onClick={OpenPreviewModal}
              >
                <EyeOutlined></EyeOutlined>
              </Button>
            </Flex>
          </Form.Item>

          <Form.Item name="cam_name" label="Camera Name">
            <Input
              className="ant-input-custom-input"
              placeholder="CameraName"
              prefix={<CameraOutlined className="site-form-item-icon" />}
            />
          </Form.Item>
          <Form.Item label="Location" name="location">
            <Input
              prefix={<PushpinOutlined className="site-form-item-icon" />}
              placeholder="Location"
            />
          </Form.Item>
          <Form.Item name="floor" label="Floor">
            <Input />
          </Form.Item>
          <Form.Item
            label="Onboard Location"
            name="onboardLocation"
            rules={[
              {
                type: "string",
                required: true,
                message: "Please select any location!",
              },
            ]}
          >
            <Select placeholder="Select a location">
              {locations.map((location, index) => (
                <Option key={index} value={location.onBoardLocation}>
                  {location.onBoardLocation}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>

      {/* edit modal */}
      <Modal
        title="Edit Record"
        centered
        onCancel={oncanceledit}
        open={visible}
        footer={
          <>
            <Row justify={"space-around"}>
              <Col>
                {authService.getCurrentUserReadOnly() ? (
                  <Tooltip title="you need permission to submit">
                    <Button type="primary" block disabled>
                      Submit
                    </Button>
                  </Tooltip>
                ) : (
                  <Button
                    className="primary-button"
                    form="submiteditform"
                    type="primary"
                    block
                    htmlType="submit"
                  >
                    Submit
                  </Button>
                )}
              </Col>
              <Col>
                <Button onClick={() => handleclose()}>Cancel</Button>
              </Col>
            </Row>
          </>
        }
        // onCancel={handleCancel}
      >
        <Form
          id="submiteditform"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 22,
          }}
          form={form}
          onFinish={OnFinishEdit}
        >
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                type: "string",
                required: true,
                message: "Please Enter the name!",
              },
            ]}
          >
            <Input placeholder="Name..." />
          </Form.Item>

          <Form.Item
            name="location"
            label="Location"
            rules={[
              {
                type: "string",
                required: true,
                message: "Please Enter the location!",
              },
            ]}
          >
            <Input placeholder="Location..." />
          </Form.Item>

          <Form.Item
            name="rtsp"
            label="RTSP Link"
            rules={[
              {
                type: "url",
                required: true,
                message: "Please Enter the RTSP link!",
              },
            ]}
          >
            <Input disabled placeholder="RTSP LInk..." />
          </Form.Item>

          <Form.Item
            label="Onboard Location"
            name="onboardLocation"
            rules={[
              {
                type: "string",
                required: true,
                message: "Please select any location!",
              },
            ]}
          >
            <Select placeholder="Select a location">
              {locations.map((location, index) => (
                <Option key={index} value={location.onBoardLocation}>
                  {location.onBoardLocation}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="floor"
            label="Floor"
            rules={[
              {
                required: true,
                message: "Please Enter the name Floor!",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>

      {/* 
          Loading modal */}

      <Modal
        className="LoadinModal"
        open={Pageload}
        style={{ background: "none", textAlign: "center" }}
        footer={false}
        closable={false}
        centered
      >
        <ClipLoader
          color="#23234d"
          loading={Pageload}
          cssOverride={{
            display: "block",
            margin: "0 auto",

            borderWidth: "5px",
            marginBottom: "10px",
          }}
          size={100}
          aria-label="Loading Spinner"
          data-testid="loader"
          title="Loading..."
        />
        <Text style={{ color: "white", fontSize: "1.2em" }}>Loading ...</Text>
      </Modal>

      {/* Modal to preview image */}
      <Modal
        title={
          <>
            <Row align={"middle"} justify={"center"}>
              <Col>
                <Text className="Modal-Header">Preview</Text>
              </Col>
            </Row>
          </>
        }
        centered
        destroyOnClose
        open={PreviewDetails.openmodal}
        onCancel={ClosePreviewModal}
        onOk={ClosePreviewModal}
        cancelButtonProps={{ style: { display: "none" } }}
        style={{
          minWidth: "40%",
        }}
      >
        {PreviewDetails.image && PreviewDetails.image ? (
          <>
            <Row justify={"center"} gutter={[20, 20]}>
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 24, offset: 0 }}
                lg={{ span: 24, offset: 0 }}
                xl={{ span: 24, offset: 0 }}
              >
                {PreviewDetails.image && (
                  <Image
                    alt={"Preview image="}
                    src={"data:image/png;base64," + PreviewDetails.image}
                  />
                )}
              </Col>
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 24, offset: 0 }}
                lg={{ span: 24, offset: 0 }}
                xl={{ span: 24, offset: 0 }}
              >
                <Row justify={"center"}>
                  <Col>
                    <Text strong>
                      RTSP :{" "}
                      <Text title="RTSP" type="secondary">
                        {PreviewDetails.rtsp && PreviewDetails.rtsp}
                      </Text>
                    </Text>
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        ) : (
          <Space
            direction="vertical"
            style={{ width: "100%" }}
            styles={{
              item: {
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              },
            }}
          >
            <CiImageOn title="Image unavailable" size={150} />
            <Text strong>Image unavailable</Text>
          </Space>
        )}
      </Modal>
    </>
  );
};
export default Asset_onboarding;
