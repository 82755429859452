import React from "react";
import {
  faCar,
  faMapMarkerAlt,
  faClock,
  faCamera,
} from "@fortawesome/free-solid-svg-icons";
import locale from "antd/es/date-picker/locale/en_GB";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Card,
  Col,
  Row,
  Select,
  Typography,
  Tabs,
  Modal,
  message,
  Table,
  Button,
  Spin,
  Alert,
  Result,
  Form,
  Radio,
  Space,
  Tag,
  Divider,
  notification,
  Progress,
  Image,
  Input,
  Empty,
  Tooltip,
} from "antd";
import {
  SearchOutlined,
  CloseOutlined,
  EyeOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import { DatePicker } from "antd";
import moment from "moment/moment";
import { CarOutlined } from "@ant-design/icons";

import { useEffect, useState, useRef } from "react";

import ReactApexChart from "react-apexcharts";
import axiosClient from "../../Authentication/ApiCall";
const { Title, Link, Text } = Typography;
const { RangePicker } = DatePicker;
const ANPR = () => {
  const { Option } = Select;
  const [entryValues, setEntryValues] = useState([]);
  const [exitValues, setExitValues] = useState([]);
  const [ActiveCamera, setactivecamera] = useState("");
  const [totalCamera, settotalcamera] = useState("");
  const [dwelltime, setdwelltime] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [filter, setFilter] = useState("Today");
  const [Areafilter, setAreaFilter] = useState("Last7days");
  const [title, setTitle] = useState("Today's Detection Data");
  const [areatittle, setareatittle] = useState("Last 7day's Dwell Time Data");
  const [dateRange, setDateRange] = useState([null, null]);
  const [ANPRdata, setANPRdata] = useState("");
  const [imageurl, setimageurl] = useState("");

  const [barChartOptions, setBarChartOptions] = useState({
    chart: {
      type: "bar",
      toolbar: {
        show: true,
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: [],
      title: {
        text: "Hour",
      },
    },
    yaxis: {
      title: {
        text: "Count",
      },
    },
  });
  const [barChartSeries, setBarChartSeries] = useState([
    {
      name: "Data",
      data: [],
    },
  ]);
  const [DwellchartOptions, setDwellchartOptions] = useState({
    chart: {
      type: "area",
      toolbar: {
        show: false,
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 0.4,
    },
    xaxis: {
      categories: [],
      title: {
        text: "Date",
      },
    },
    yaxis: {
      title: {
        text: "dwellTime (minute)",
      },
    },
  });
  const [DwellchartSeries, setDwellchartSeries] = useState([
    {
      name: "Data",
      data: [],
    },
  ]);
  const [series, setSeries] = useState([]);

  const handleFilterChange = (value) => {
    console.log("Filter changed to:", value);
    setFilter(value);
    let startDate, endDate;
    let newTitle;
    let xAxisTitle = "Hour";

    if (value === "Last7days") {
      endDate = moment().format("YYYY-MM-DD");
      startDate = moment().subtract(6, "days").format("YYYY-MM-DD");
      newTitle = "Last 7 Days' Detection Data";
      xAxisTitle = "Date";
    } else if (value === "Monthly") {
      endDate = moment().format("YYYY-MM-DD");
      startDate = moment().subtract(30, "days").format("YYYY-MM-DD");
      newTitle = "Last Month Detection Data";
      xAxisTitle = "Date";
    } else if (value === "Today") {
      startDate = "";
      endDate = "";
      newTitle = "Today's Detection Data";
      xAxisTitle = "hour";
    } else if (value === "SelectDateWise") {
      newTitle = "Date Wise Detection Data";
      xAxisTitle = "Date";
    }

    console.log("New Title:", newTitle); // Log the new title
    console.log("xAxis Title:", xAxisTitle); // Log the xAxis title

    // Update the title and x-axis options
    setBarChartOptions((prevOptions) => ({
      ...prevOptions,
      xaxis: {
        ...prevOptions.xaxis,
        title: {
          text: xAxisTitle,
        },
      },
    }));

    setTitle(newTitle);
    fetchData(startDate, endDate);
  };

  const [searchText, setSearchText] = useState("");

  const handleareaFilterChange = (value) => {
    setAreaFilter(value);
    let startDate, endDate;
    let newTitle;
    let xAxisTitle = "Hour";
    if (value === "Last7days") {
      endDate = moment().format("YYYY-MM-DD");
      startDate = moment().subtract(6, "days").format("YYYY-MM-DD");
      newTitle = "Last 7 Day's DwellTime Data";
      xAxisTitle = "Date";
    } else if (value === "Monthly") {
      endDate = moment().format("YYYY-MM-DD");
      startDate = moment().subtract(30, "days").format("YYYY-MM-DD");
      newTitle = "Last Month DwellTime Data";
      xAxisTitle = "Date";
    } else if (value === "SelectDateWise") {
      newTitle = "Date Wise DwellTime Data";
      xAxisTitle = "Date";
    } else if (value === "Today") {
      endDate = "";
      startDate = "";
      newTitle = "Today's DwellTime Data";
      xAxisTitle = "Hour";
    }
    setDwellchartOptions((prevOptions) => ({
      ...prevOptions,
      xaxis: {
        ...prevOptions.xaxis,
        title: {
          text: xAxisTitle,
        },
      },
    }));

    setareatittle(newTitle);
    areachartdata(startDate, endDate);
  };
  const [noData, setNoData] = useState(false);

  // const fetchData = async (startDate = "", endDate = "") => {
  //   try {
  //     const response = await axiosClient.get(
  //       `numberplate/getnumberplatedetailsforbarchart?startDate=${startDate}&endDate=${endDate}`
  //     );
  //     const responseData = response.data;

  //     if (responseData.issuccess) {
  //       const dates = responseData.data.map((item) => item.date);
  //       const counts = responseData.data.map((item) => item.count);

  //       setBarChartOptions((prevOptions) => ({
  //         ...prevOptions,
  //         xaxis: {
  //           ...prevOptions.xaxis,
  //           categories: dates,
  //         },
  //       }));

  //       setBarChartSeries([
  //         {
  //           name: "Count",
  //           data: counts,
  //         },
  //       ]);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };
  const fetchData = async (startDate = "", endDate = "") => {
    try {
      const response = await axiosClient.get(
        `numberplate/getnumberplatedetailsforbarchart?startDate=${startDate}&endDate=${endDate}`
      );
      const responseData = response.data;

      if (responseData.issuccess) {
        const data = responseData.data;
        const dates = data.map((item) => item.date);
        const counts = data.map((item) => item.count);

        // Check if all counts are zero
        const hasData = counts.some((count) => count > 0);

        if (hasData) {
          setBarChartOptions((prevOptions) => ({
            ...prevOptions,
            xaxis: {
              ...prevOptions.xaxis,
              categories: dates,
            },
          }));

          setBarChartSeries([
            {
              name: "Count",
              data: counts,
            },
          ]);

          setNoData(false); // Flag to indicate there is data
        } else {
          setNoData(true); // Flag to indicate no data
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setNoData(true); // Flag to indicate no data in case of error
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  const [AddRefresh, setAddRefresh] = useState(1);
  const [location, setlocation] = useState(null);
  const [store, setstore] = useState(null);
  useEffect(() => {
    console.log("Fetching data after AddRefresh:", AddRefresh);

    const fetchData1 = () => {
      axiosClient
        .get("/storeonboard/getstoreonboarddetails")
        .then((response) => {
          if (response && response.data && response.data.data.length > 0) {
            // Flatten the nested arrays
            // const flattenedData = response.data.data[0].map(
            //   (item) => item.onBoardLocation
            // );
            const flattenedData = response.data.data[0].onBoardLocation;
            const storedata = response.data.data[0].storeName;

            // const storedata = response.data.data.map((item) => item.storeName);
            setlocation(flattenedData);
            setstore(storedata);
            console.log("flattenedData.onBoardLocation", flattenedData);
          }
        })
        .catch((err) => {
          console.log("err", err);
          if (err.status === 0) {
            message.error("Server error");
          } else {
            message.error(err.msg);
          }
        });
    };

    fetchData1();
  }, [AddRefresh]);
  const column = [
    {
      title: "Vehicle Number",
      dataIndex: "number",
      key: "number",
    },
    {
      title: "In Time",
      dataIndex: "inTime",
      key: "inTime",
      render: (text) => {
        const date = moment(text);
        return date.isValid() ? date.format("YYYY-MM-DD HH:mm:ss") : "--";
      },
    },
    {
      title: "Out Time",
      dataIndex: "outTime",
      key: "outTime",
      render: (text) => {
        const date = moment(text);
        return date.isValid() ? date.format("YYYY-MM-DD HH:mm:ss") : "--";
      },
    },
  ];

  //getting Active camera & active event
  useEffect(() => {
    axiosClient
      // .get("/notification/getEventBasedActiveCamera?eventid=" + 3)
      .get("/notification/getEventBasedActiveCamera?eventid=" + 3)
      .then((response) => {
        if (response.data.issuccess === true) {
          setactivecamera(response.data.eventBasedCameraDetails.count);
          settotalcamera(response.data.onboardCameraDetails.count);
        } else {
          console.log("something went wrong");
        }
      })
      .catch((err) => {
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  }, []);
  const fetchNumberPlateDetails = async (number) => {
    try {
      const response = await axiosClient.get(
        `/numberplate/getnumberplatedetails?number=${number}`
      );
      if (response.data.issuccess === true) {
        setANPRdata(response.data.data.totalnumberplate);
        console.log("Successfully fetched data");
      } else {
        message.error(response.data.msg);
        console.log("Something went wrong");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      if (error.response && error.response.status === 0) {
        message.error("Server error");
      } else {
        // message.error(error.message);
      }
    }
  };

  // get number plate table details
  useEffect(() => {
    fetchNumberPlateDetails("");
  }, []);

  // on search of vehicle number
  const handleSearch = (e) => {
    console.log("Search triggered");
    const value = e.target.value.toUpperCase();
    setSearchText(value);

    if (value) {
      fetchNumberPlateDetails(value);
    }
  };
  // // on clear  of search vehicle number
  const handleClear = () => {
    setSearchText("");
    fetchNumberPlateDetails("");
  };
  const [dwelltimeData, setdwelltimeNoData] = useState(false);
  ///getting dwell time data in chart

  const areachartdata = (startDate, endDate) => {
    axiosClient
      .get(
        `/numberplate/getdwelltimeforbarchart?startDate=${startDate}&endDate=${endDate}`
      )
      .then((response) => {
        if (response.data.issuccess === true) {
          const data = response.data.data;
          const dates = data.map((item) => item.date);
          const values = data.map((item) => parseFloat(item.dwellTime) || 0);

          // Check if all values are zero or if no data is available
          const hasData = values.some((value) => value > 0);

          setdwelltimeNoData(!hasData);

          if (hasData) {
            setDwellchartOptions({
              chart: {
                type: "area",
              },
              toolbar: {
                show: false,
                tools: {
                  download: false,
                  selection: false,
                  zoom: false,
                  zoomin: false,
                  zoomout: false,
                  pan: false,
                  reset: false,
                },
              },

              xaxis: {
                categories: dates,
              },
              stroke: {
                width: 0.4,
              },
              dataLabels: {
                enabled: false,
              },
            });

            setDwellchartSeries([
              {
                name: "Dwell Time",
                data: values,
              },
            ]);
          } else {
            // Clear the chart series if no valid data
            setDwellchartSeries([]);
          }

          console.log("response", response);
        } else {
          console.log("something went wrong");
        }
      })
      .catch((err) => {
        console.log("errr", err);
        if (err.response && err.response.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.message || "Unknown error");
        }
      });
  };
  // get dwell time
  useEffect(() => {
    axiosClient
      .get("/numberplate/gettotaldwell")

      .then((response) => {
        if (response.data.issuccess === true) {
          setdwelltime(response.data.data.totaldwell);
          console.log("successfully");
        } else {
          message.error(response.data.msg);
        }
      })
      .catch((err) => {
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  }, []);
  //get Entry & exit count
  useEffect(() => {
    const fetchEntryExitValues = async () => {
      try {
        const response = await axiosClient.get(
          "/numberplate/gettotalentryandexit"
        );

        if (response.data.issuccess == true) {
          console.log("entries444445555", response.data.data.totalentry);
          setEntryValues(response.data.data.totalentry);
          setExitValues(response.data.data.totalexit);
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchEntryExitValues();
    const intervalId = setInterval(fetchEntryExitValues, 30000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  //Date picker for selecting dates In Numberplate Detection
  const handlebarDateRangeChange = (dates) => {
    setDateRange(dates);
    if (dates && dates.length === 2) {
      const [startDate, endDate] = dates;
      fetchData(startDate.format("YYYY-MM-DD"), endDate.format("YYYY-MM-DD"));
    }
  };
  const [selectedDates, setSelectedDates] = useState([]);

  //Date picker for selecting dates In DwellTime
  const handleareaDateRangeChange = (dates) => {
    setSelectedDates(dates);
    if (dates && dates.length === 2) {
      const [startDate, endDate] = dates;

      setDateRange(dates);
      areachartdata(
        startDate.format("YYYY-MM-DD"),
        endDate.format("YYYY-MM-DD")
      );
    }
  };
  const isSameStartEndDate =
    selectedDates &&
    selectedDates.length === 2 &&
    selectedDates[0].isSame(selectedDates[1], "day");

  useEffect(() => {
    handleareaFilterChange("Today");
  }, []);
  //get last numberplate Detection image
  useEffect(() => {
    // Define the function that fetches the data
    const fetchData = async () => {
      try {
        const response = await axiosClient.get(
          "/numberplate/getlastdetectednumberplate"
        );
        if (response.data.issuccess === true) {
          const image = response.data.data;
          setimageurl(image);
          console.log("Image URL received:", image);
        } else {
          console.log("Something went wrong:", response.data);
          message.error(response.data.msg);
        }
      } catch (err) {
        console.log("Error:", err);
        if (err.response && err.response.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.message || "Unknown error");
        }
      }
    };

    fetchData();

    const intervalId = setInterval(fetchData, 10000);

    // Clear the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);
  useEffect(() => {
    console.log("Updated image URL:", imageurl);
  }, [imageurl]);

  const [currentTime, setCurrentTime] = useState(dayjs().format("HH:mm"));

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(dayjs().format("HH:mm"));
    }, 60000); // Update every minute

    return () => clearInterval(intervalId);
  }, []);
  return (
    <>
      <header
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <span
          style={{
            marginLeft: "47px",
            marginTop: "28px",
            fontSize: "22px",
            fontWeight: "bold",
          }}
        >
          ANPR Dashboard
        </span>
        <span
          style={{
            display: "flex",
            flexDirection: "column", // Default to column
            alignItems: "flex-end",
            marginRight: "87px",
            fontSize: "20px",
            fontWeight: "bold",
          }}
        >
          <span
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "5px",
            }}
          >
            <Title type="secondary" level={5} style={{ fontSize: "19px" }}>
              Location:
            </Title>
            <span
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                marginLeft: "8px",
              }}
            >
              {location ? location : "No Location added"}
            </span>
          </span>
          <span
            style={{
              display: "flex",
              flexDirection: "row", // Default to row
              alignItems: "center",
              flexWrap: "wrap", // Allow wrapping
            }}
          >
            <span
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: "8px",
              }}
            >
              <Title type="secondary" level={5} style={{ fontSize: "19px" }}>
                Store:
              </Title>
              <span
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  marginLeft: "8px",
                }}
              >
                {store ? store : "No Store added"}
              </span>
            </span>
            <span
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Title type="secondary" level={5} style={{ fontSize: "19px" }}>
                Time:
              </Title>
              <span
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  marginLeft: "8px",
                }}
              >
                {currentTime}
              </span>
            </span>
          </span>
        </span>
      </header>
      {/* <hr
        style={{
          border: "none",
          borderTop: "1px solid #ddd",
          // margin: "0 45px",
          width: "100%",
        }}
      /> */}

      <div>
        <Row
          gutter={[16, 16]}
          md={{ span: 24, offset: 0 }}
          lg={{ span: 24, offset: 0 }}
        >
          <Col xs={21} sm={24} md={18} lg={14} xl={14} xxl={14}>
            <Row gutter={[16, 16]}>
              <Col
                xs={20}
                s={20}
                sm={11}
                md={11}
                lg={11}
                xl={11}
                xxl={11}
                offset={1}
                style={{ padding: "10px" }}
              >
                <Card
                  style={{
                    background:
                      "linear-gradient(to right, #4b81a6,#226a9c,#6ba6cf)",
                    color: "#333",
                    height: "170px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    position: "relative",
                    // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    borderRadius: "30px",
                  }}
                >
                  <div
                    style={{
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginBottom: "10px",
                      color: "#e1e8ed",
                    }}
                  >
                    <p style={{ margin: 0 }}>Entry :</p>
                    <span style={{ fontSize: "35px" }}>
                      {entryValues != null ? entryValues : 0}
                    </span>
                  </div>

                  <div
                    style={{
                      position: "absolute",
                      top: "40px",
                      right: "50px",
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faCar}
                      style={{
                        fontSize: "57px",
                        color: "rgba(51, 51, 51, 0.5)",
                      }}
                    />
                  </div>
                  {/* <div>
                <DatePicker
                  onChange={handleDateSelect}
                  allowClear={true}
                  disabledDate={(current) =>
                    current && current > moment().endOf("day")
                  }
                />
              </div> */}
                </Card>
              </Col>
              <Col
                xs={{ span: 20 }}
                s={{ span: 20 }}
                sm={11}
                md={11}
                lg={11}
                xl={11}
                xxl={11}
                style={{ padding: "10px" }}
              >
                <Card
                  style={{
                    background: "linear-gradient(to right,#a35a69, #c78593)",
                    color: "#333",
                    height: "170px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    position: "relative",
                    // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    borderRadius: "30px",
                  }}
                >
                  {" "}
                  <div
                    style={{
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginBottom: "10px",
                      color: "#e1e8ed",
                    }}
                  >
                    <p style={{ margin: 0 }}>Active Camera :</p>
                    <span style={{ fontSize: "35px" }}>{ActiveCamera}</span>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      top: "40px",
                      right: "50px",
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faCamera}
                      style={{
                        fontSize: "57px",
                        color: "rgba(51, 51, 51, 0.5)",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      fontSize: "15px",
                      fontWeight: "bold",
                      marginBottom: "10px",
                      color: "#e1e8ed",
                    }}
                  >
                    Total Camera : {totalCamera != null ? totalCamera : 0}
                  </div>
                </Card>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col
                xs={20}
                s={20}
                sm={11}
                md={11}
                lg={11}
                xl={11}
                xxl={11}
                offset={1}
                style={{ padding: "10px" }}
              >
                <Card
                  style={{
                    background: "linear-gradient(to right, #56b373, #449c60)",
                    color: "#333",
                    height: "170px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    position: "relative",
                    // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    borderRadius: "30px",
                  }}
                >
                  <div
                    style={{
                      fontSize: "22px",
                      fontWeight: "bold",
                      marginBottom: "10px",
                      color: "#e1e8ed",
                    }}
                  >
                    <p style={{ margin: 0 }}>Exit :</p>
                    <span style={{ fontSize: "35px" }}>
                      {exitValues != null ? exitValues : 0}
                    </span>
                  </div>

                  <div
                    style={{
                      position: "absolute",
                      top: "40px",
                      right: "50px",
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faCar}
                      style={{
                        fontSize: "57px",
                        color: "rgba(51, 51, 51, 0.5)",
                      }}
                    />
                  </div>
                </Card>
              </Col>
              <Col
                xs={{ span: 20 }}
                s={{ span: 20 }}
                sm={11}
                md={11}
                lg={11}
                xl={11}
                xxl={11}
                style={{ padding: "10px" }}
              >
                <Card
                  style={{
                    background: "linear-gradient(to right,#e6b743,#ebcb63)",
                    color: "#333",
                    height: "170px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    position: "relative",
                    // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    borderRadius: "30px",
                  }}
                >
                  {" "}
                  <div
                    style={{
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginBottom: "10px",
                      color: "#e1e8ed",
                    }}
                  >
                    <p style={{ margin: 0 }}>Dwell Time :</p>
                    <span style={{ fontSize: "35px" }}>
                      {dwelltime != null ? dwelltime : 0}
                    </span>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      top: "40px",
                      right: "50px",
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faClock}
                      style={{
                        fontSize: "57px",
                        color: "rgba(51, 51, 51, 0.5)",
                      }}
                    />
                  </div>
                </Card>
              </Col>
            </Row>
            <Row style={{ marginLeft: "41px", marginTop: "4px" }}>
              <Col
                span={23}
                // style={{ padding: "10px",  }}
              >
                <Card style={{ width: "auto" }}>
                  {" "}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "10px",
                      flexWrap: "wrap",
                    }}
                  >
                    <Tag
                      style={{ margin: 0, fontSize: "17px", padding: "5px" }}
                    >
                      {title}
                    </Tag>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <p style={{ margin: "3px 5px 0 0", fontSize: "16px" }}>
                        Filter :
                      </p>
                      <Select
                        defaultValue="Today"
                        onChange={handleFilterChange}
                        style={{ width: 150 }}
                      >
                        <Option value="Today">Today</Option>
                        <Option value="Last7days">Last 7 days</Option>
                        <Option value="Monthly">Last Month</Option>
                        <Option value="SelectDateWise">Select Date Wise</Option>
                      </Select>
                      {filter === "SelectDateWise" && (
                        <RangePicker
                          style={{ marginLeft: 10, height: "33px" }}
                          onChange={handlebarDateRangeChange}
                          disabledDate={(current) => {
                            return current && current > moment().endOf("day");
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <ReactApexChart
                    options={barChartOptions}
                    series={barChartSeries}
                    type="bar"
                    height={240}
                  />
                  {/* {noData ? (
                    <div
                      style={{
                        textAlign: "center",
                        padding: "20px",
                        height: "260px",
                      }}
                    >
                      <Empty />
                    </div>
                  ) : (
                    <ReactApexChart
                      options={barChartOptions}
                      series={barChartSeries}
                      type="bar"
                      height={240}
                    />
                  )} */}
                </Card>
              </Col>
            </Row>
            <Row
              style={{
                marginLeft: "41px",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              <Col
                span={23}
                // style={{ padding: "10px",  }}
              >
                <Card style={{ width: "auto" }}>
                  {" "}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "10px",
                      flexWrap: "wrap",
                    }}
                  >
                    <div>
                      {" "}
                      <Tag
                        style={{ margin: 0, fontSize: "17px", padding: "5px" }}
                      >
                        {areatittle}
                      </Tag>
                      {(Areafilter === "Today" || isSameStartEndDate) && (
                        <Tooltip title="Average Dwell Time Data">
                          <QuestionCircleOutlined
                            style={{
                              marginLeft: "10px",
                              fontSize: "15px",
                              cursor: "pointer",
                            }}
                          />
                        </Tooltip>
                      )}
                    </div>

                    <div style={{ display: "flex", alignItems: "center" }}>
                      <p style={{ margin: "3px 5px 0 0", fontSize: "16px" }}>
                        Filter :
                      </p>
                      <Select
                        defaultValue="Today"
                        onChange={handleareaFilterChange}
                        style={{ width: 150 }}
                      >
                        <Option value="Today">Today</Option>
                        <Option value="Last7days">Last 7 days</Option>
                        <Option value="Monthly">Last Month</Option>
                        <Option value="SelectDateWise">Select Date Wise</Option>
                      </Select>

                      {Areafilter === "SelectDateWise" && (
                        <RangePicker
                          style={{ marginLeft: 10, height: "33px" }}
                          onChange={handleareaDateRangeChange}
                          disabledDate={(current) => {
                            return current && current > moment().endOf("day");
                          }}
                        />
                      )}
                    </div>
                  </div>
                  {/* {dwelltimeData ? (
                    <div
                      style={{
                        textAlign: "center",
                        padding: "20px",
                        height: "260px",
                      }}
                    >
                      <Empty />
                    </div>
                  ) : (
                    <ReactApexChart
                      options={DwellchartOptions}
                      series={DwellchartSeries}
                      type="area"
                      height={240}
                    />
                  )} */}
                  <ReactApexChart
                    options={DwellchartOptions}
                    series={DwellchartSeries}
                    type="area"
                    height={240}
                  />
                </Card>
              </Col>
            </Row>
          </Col>

          <Col
            xs={20}
            sm={24}
            md={15}
            lg={9}
            xl={9}
            xxl={9}
            style={{ marginTop: "10px" }}
          >
            <Row>
              <Col span={24}>
                {/* <Card>
                <Title
                  type="secondary"
                  level={5}
                  style={{
                    fontSize: "20px",
                    marginBottom: "10px",
                  }}
                >
                  Last Detected Number Plate
                </Title>
                <Image
                  // src="/numberplate.jpg"
                  src={imageurl}
                  style={{
                    width: "100%",
                    height: "350px",
                    // objectFit: "cover",
                  }}
                  alt="Last Detected Number Plate"
                />
              </Card> */}
                <Card>
                  <Title
                    type="secondary"
                    level={5}
                    style={{
                      fontSize: "20px",
                      marginBottom: "10px",
                    }}
                  >
                    Last Detected Number Plate
                  </Title>

                  {imageurl ? (
                    <Image
                      src={"data:image/png;base64," + imageurl}
                      style={{
                        width: "100%",
                        height: "350px",
                        objectFit: "cover",
                      }}
                      alt="Last Detected Number Plate"
                      onError={() => {
                        message.error("Failed to load image.");
                      }}
                    />
                  ) : (
                    <div
                      style={{
                        width: "100%",
                        height: "350px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#f0f0f0",
                      }}
                    >
                      <p>No Image</p>
                    </div>
                  )}
                </Card>
              </Col>
            </Row>
            <Row style={{ marginTop: "1rem" }}>
              <Col span={24}>
                <Card style={{ marginBottom: "30px" }}>
                  <Row>
                    <Col span={23}>
                      {" "}
                      {/* <Input
                        placeholder="Search Number Plates..."
                        value={searchText}
                        style={{
                          width: "100%",
                          backgroundColor: "#f0f2f5",
                          padding: "5px",
                          marginRight: "5px",
                        }}
                        onChange={handleSearch}
                        onFocus={() => console.log("Input focused")}
                        suffix={
                          searchText && ( // Conditionally render the suffix icon
                            <CloseOutlined
                              onClick={handleClear}
                              style={{
                                cursor: "pointer",
                                color: "rgba(0, 0, 0, 0.45)",
                                fontSize: "16px",
                              }}
                            />
                          )
                        }
                      /> */}
                      <div>
                        <input
                          placeholder="Search Number Plates..."
                          value={searchText}
                          style={{
                            width: "93%",
                            height: "34px",
                            backgroundColor: "#f0f2f5",
                            padding: "5px",
                            marginRight: "5px",
                            border: "none",
                            outline: "none",
                            boxShadow: "none",
                          }}
                          onChange={handleSearch}
                          onFocus={() => console.log("Input focused")}
                        />
                        {searchText && (
                          <CloseOutlined
                            onClick={handleClear}
                            style={{
                              cursor: "pointer",
                              // color: "rgba(0, 0, 0, 0.45)",
                              fontSize: "16px",
                            }}
                          />
                        )}
                      </div>
                    </Col>
                    {/* <Col>
                    <Button
                      type="primary"
                      icon={<SearchOutlined />}
                      // onClick={handleSearch}
                    >
                      Search
                    </Button>
                  </Col> */}
                  </Row>

                  <div
                    style={{
                      height: "525px",
                      // overflowY: "auto"
                    }}
                  >
                    <Table
                      dataSource={ANPRdata}
                      columns={column}
                      pagination={false}
                      scroll={{ y: 471 }}
                    />
                  </div>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default ANPR;
