import { setRef } from "@fullcalendar/react";
import { Col, Row, Card, Switch, Alert, Tooltip } from "antd";
import {
  Label,
  Input,
  Select,
  Space,
  Cascader,
  Form,
  Checkbox,
  InputNumber,
  Button,
  Radio,
  message,
  Typography,
} from "antd";
import { useForm } from "antd/es/form/Form";

import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { LabelList } from "recharts";
import axiosClient from "../../Authentication/ApiCall";
import authService from "../../Authentication/authService";
import ReportUserList from "./ReportUserList";
const { Option } = Select;
const { Title, Text } = Typography;

export default function ReportControl() {
  document.title = "KEN - Reports control";

  const [Notificationform] = Form.useForm();
  const [ReportType, setReportType] = useState({});
  const [UserList, setUserList] = useState([]);
  const [cameralist, setcameralist] = useState([]);
  const [events, setevents] = useState([]);
  const [CamUserList, setCamUserList] = useState([]);

  const [seelectedcamid, setseelectedcamid] = useState();
  const [selectedeventid, setselectedeventid] = useState();
  const [existingLevel2, setexistingLevel2] = useState([]);
  // var selected = [];
  const [selected, setselected] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [AddRefresh, setAddRefresh] = useState(false);

  // initial fetch for camera list and user list
  useEffect(() => {
    axiosClient
      .get("/camera/get_cameraList")
      .then((response) => {
        if (response.data.issuccess === true) {
          setcameralist(response.data.data);
        } else {
        }
      })
      .catch((err) => {
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });

    axiosClient
      .get("/cameraaccess/getUSerList")
      .then((response) => {
        if (response.data.issuccess === true) {
          setUserList(response.data.data);
        } else {
        }
      })
      .catch((err) => {
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  }, []);

  // Notification details submit
  const onFinish = (values) => {
    axiosClient
      .post("/mailreport/addReportNotificationSendto", {
        status: values.ReportType,
        cameraid: values.cameralist,
        eventid: values.events,
        userlist: values.User,
      })
      .then((response) => {
        if (response.data.issuccess === true) {
          message.success(response.data.msg);
          Notificationform.resetFields();
          setAddRefresh(true);
        }
      })
      .catch((err) => {
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
    Notificationform.resetFields();
  };

  const getEventForCam = (selectedId) => {
    axiosClient
      .get("/notification/getEventsForCamera?cameraid=" + selectedId)
      .then((response) => {
        if (response.data.issuccess === true) {
          setevents(response.data.data);
        } else {
          console.log("error");
        }
      })
      .catch((err) => {
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });

    Notificationform.resetFields([
      "events",
      "level1",
      "level2",
      "level3",
      // "level4",
    ]);
    setselected([]);
    // setexistingLevel1([]);
    // setexistingLevel3([]);
  };

  const GetUserList = (id) => {
    axiosClient
      .get("/mailreport/getUserListBasedOnCameraAccess?id=" + id)
      .then((response) => {
        if (response.data.issuccess === true) {
          setUserList(response.data.data);
        } else {
          console.log("error");
        }
      })
      .catch((err) => {
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  };

  return (
    <Row className="top-space8 " gutter={[35, 20]}>
      <Col
        xs={{ span: 22, offset: 2 }}
        // md={{ span: 16, offset: 2 }}

        lg={{ span: 11, offset: 1 }}
        xl={{ span: 8, offset: 0 }}
      >
        <Card hoverable bordered={true} title="Report Control">
          <Form
            form={Notificationform}
            name="basic"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 22,
            }}
            onFinish={onFinish}
            // onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              className="top-space"
              label="Report Type"
              name="ReportType"
              rules={[
                {
                  type: "number",
                  required: true,
                  message: "Please select!",
                },
              ]}
            >
              <Select
                onChange={(e) => {
                  if (e !== undefined) {
                    setReportType(e);
                  }
                }}
                style={{
                  width: "100%",
                }}
                placeholder="Please select report type"
              >
                <Option key="1" value={1}>
                  Daily Report
                </Option>
                <Option key="2" value={2}>
                  Weekly Report
                </Option>
              </Select>
            </Form.Item>
            <Form.Item
              className="top-space"
              label="Camera List"
              name="cameralist"
              rules={[
                {
                  type: "number",
                  required: true,
                  message: "Please select!",
                },
              ]}
            >
              <Select
                onChange={(e) => {
                  if (e !== undefined) {
                    console.log(e);
                    getEventForCam(e);
                    GetUserList(e);
                    setseelectedcamid(e);
                  } else {
                    setUserList([]);
                  }
                }}
                onClear={(e) => setseelectedcamid()}
                allowClear
                style={{
                  width: "100%",
                }}
                placeholder="Please select Camera's to give access"
              >
                {cameralist.map((option) => (
                  <Option key={option.id} value={option.id}>
                    {option.cameraName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Camera Events"
              name="events"
              rules={[
                {
                  required: true,
                  message: "Please Select Camera Events!",
                },
              ]}
            >
              <Select
                allowClear
                style={{
                  width: "100%",
                }}
                placeholder="Please select Events for the camera"
                // defaultValue={["a10", "c12"]}
                onChange={(e) => {
                  setselectedeventid(e);
                }}
              >
                {events.map((option) => (
                  <Option key={option.eventid} value={option.eventid}>
                    {option.eventname}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              className="top-space"
              label="Users"
              name="User"
              rules={[
                {
                  type: "array",
                  required: selectedUser.length >= 1 ? false : true,
                  message: "Please select!",
                },
              ]}
            >
              <Select
                key={selectedUser}
                mode="multiple"
                allowClear
                style={{
                  width: "100%",
                }}
                placeholder="Please select a user"
                defaultValue={selectedUser}
              >
                {UserList.map((option) => {
                  return (
                    <Option key={option.id} value={option.id}>
                      <Text>{option.username}</Text>&nbsp;-&nbsp;
                      <Text type="secondary">{option.email}</Text>
                    </Option>
                  );
                })}
                {/* {level1[0] === undefined ? (
                  <></>
                ) : level1.length > 1 ? (
                  level1.map((option) => {
                    return (
                      <Option key={option.id} value={option.id}>
                        <Title style={{ color: "#23234D" }} level={5}>
                          {option.username}
                        </Title>
                        <Text type="secondary">{option.email}</Text>
                      </Option>
                    );
                  })
                ) : (
                  <Option key={level1[0].id} value={level1[0].id}>
                    <Text> {level1[0].username}</Text>
                    <Text type="secondary">(&nbsp;{level1[0].email})</Text>)
                  </Option>
                )} */}
              </Select>
            </Form.Item>
            <Form.Item
              wrapperCol={{
                xs: { span: 24, offset: 0 },
                sm: { span: 12, offset: 8 },
                md: { span: 12, offset: 8 },
                lg: { span: 12, offset: 8 },
                xl: { span: 8, offset: 8 },
              }}
            >
              {authService.getCurrentUserReadOnly() ? (
                <Tooltip title="you need access to submit">
                  <Button type="primary" block disabled>
                    Submit
                  </Button>
                </Tooltip>
              ) : (
                <Button
                  style={{ backgroundColor: "#3e3a75", border: "none" }}
                  type="primary"
                  block
                  htmlType="submit"
                >
                  Submit
                </Button>
              )}
            </Form.Item>
          </Form>
        </Card>
      </Col>

      <Col
        xs={{ span: 22, offset: 2 }}
        lg={{ span: 11, offset: 1 }}
        xl={{ span: 16, offset: 0 }}
      >
        <Card hoverable bordered={true} title="Report List">
          <ReportUserList refresh={AddRefresh} />
        </Card>
      </Col>
    </Row>
  );
}
