import React from "react";

import { Typography } from "antd";

import { DatePicker } from "antd";

const { Title, Link, Text } = Typography;
const { RangePicker } = DatePicker;

function OverallDashboard() {
  return (
    <>
      <div>
        Dashboard
        {/* <Row gutter={16}>
          {/* 1st Column */}
        {/* <Col span={8}>
            <Card style={{ marginBottom: 16 }}>Content for Card 4</Card>
          </Col> */}
        {/* 2nd Column */}
        {/* <Col span={6}>
          <Card style={{ marginBottom: 16, backgroundColor: "#2436bd" }}>
              <Row>
                <p
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontSize: "18px",
                  }}
                >
                Time At Work 
                </p>
              </Row>
             
              <Row>
                {" "}
               <p  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontSize: "20px",
                  }}>
                    2 h 43 min
                  </p>
            </Row>
                <Row>
                <Col
                  xs={{ span: 22, offset: 0 }}
                  sm={{ span: 22, offset: 0 }}
                  md={{ span:22, offset: 0 }}
                  lg={{ span: 22, offset: 0 }}
                  xl={{ span: 22, offset: 0 }}
                  // style={{ alignItems: "end" }}
                >
                  <Sparklines
                    data={Records[0].values}
                    width={190}
                    margin={0.2}
                    height={70}
                    svgWidth={100}
                    svgHeight={50}
                    style={{ fill: "#e4e5f0" }}
                  >
                    <SparklinesBars style={{ fill: "#e4e5f0" }} />
                  </Sparklines>
                </Col>
              </Row>
            </Card>
          </Col> */}
        {/* 3rd Column */}
        {/* <Col span={5}>
            <Card style={{ marginBottom: 16, backgroundColor: "#a1ced6" }}>
              <Row>
                <p
                  style={{
                    color: "#128da3",
                    fontWeight: "bold",
                    fontSize: "18px",
                  }}
                >
                  Communication
                </p>
              </Row>
              <Row>
                <Col>
                  <p style={{ color: "#128da3", fontWeight: "bold" }}>+5%</p>
                </Col>
              </Row>
              <Row>
                {" "}
                <Col span={14}>
                  <p
                    style={{
                      color: "#128da3",
                      fontWeight: "bold",
                      fontSize: "20px",
                    }}
                  >
                    1 h 21 min
                  </p>
                </Col>
                <Col
                  xs={{ span: 6, offset: 0 }}
                  sm={{ span: 6, offset: 0 }}
                  md={{ span: 6, offset: 0 }}
                  lg={{ span: 6, offset: 0 }}
                  xl={{ span: 8, offset: 0 }}
                  // style={{ alignItems: "end" }}
                >
                  <Sparklines
                    data={Records[0].values}
                    width={60}
                    margin={0.2}
                    height={70}
                    svgWidth={100}
                    svgHeight={50}
                    style={{ fill: "#128da3" }}
                  >
                    <SparklinesBars style={{ fill: "#128da3" }} />
                  </Sparklines>
                </Col>
              </Row>
            </Card>
            <Card style={{ marginBottom: 16 }}>Content for Card 2</Card>
            <Card style={{ marginBottom: 16 }}>Content for Card 3</Card>
          </Col>
          <Col span={5}><Card><p>Notification</p></Card></Col>
        </Row>  */}
      </div>
    </>
  );
}

export default OverallDashboard;
