import {
  Row,
  Space,
  Table,
  Tag,
  Col,
  Card,
  Image,
  Modal,
  Skeleton,
  DatePicker,
  Button,
  Alert,
  Typography,
  message,
  Form,
  Select,
  Collapse,
  Dropdown,
  Radio,
  Tooltip,
  Timeline,
  List,
  Empty,
  Spin,
  Progress,
  Result,
} from "antd";
import ReactPlayer from "react-player";
import axios from "axios";
import { TbReportAnalytics, TbAlertTriangle } from "react-icons/tb";
import { GoVerified } from "react-icons/go";

import {
  PlayCircleOutlined,
  UserOutlined,
  DownOutlined,
  DownloadOutlined,
  HistoryOutlined,
  LoadingOutlined,
  EyeOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { MultiSelect } from "react-multi-select-component";
// import { Player } from "video-react";
import React, { useRef } from "react";
import axiosClient from "../../Authentication/ApiCall";
import { useEffect, useState } from "react";
import moment from "moment/moment";
import { toBeVisible } from "@testing-library/jest-dom/dist/matchers";
import { CSVLink } from "react-csv";
import ReactToPrint from "react-to-print";
import dayjs from "dayjs";
import { Player } from "video-react";
import { saveAs } from "file-saver";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import TextArea from "antd/lib/input/TextArea";
import authService from "../../Authentication/authService";
import { Loading } from "react-fullscreen-loading";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import { constrainPoint } from "@fullcalendar/react";
//   import ImageInfo from "./imageInfo";
import { FaFileDownload } from "react-icons/fa";
import { FaDownload } from "react-icons/fa6";

const { RangePicker } = DatePicker;

const { Link, Title, Text } = Typography;
const { Option } = Select;
const NotificationControl = () => {
  const [cameralist, setcameralist] = useState([]);
  const [events, setevents] = useState([]);
  const [selectedcameralist, setselectedcameralist] = useState([]);
  const [cameralistmultiselect, setcameralistmultiselect] = useState([]);
  const [refresh, setrefresh] = useState(false);
  const [data, setdata] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const count = useSelector((state) => state.count.value);
  const pageSize = 10;
  // const currentPage = 1;

  const paginationConfig = {
    current: currentPage,
    pageSize: pageSize,
    total: data.length,
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
    onChange: (page) => setCurrentPage(page),
  };

  const columns = [
    // {
    //   title: "Serial No",
    //   dataIndex: "serialNo",
    //   align: "center",
    //   render: (text, record, index) => (currentPage - 1) * pageSize + index + 1,
    // },
    {
      title: "Sl No",
      dataIndex: "serialNo",
      align: "center",
      render: (text, record, index) => (currentPage - 1) * pageSize + index + 1,
    },
    {
      title: "User Name",
      dataIndex: "cameraName",
      key: Math.random(),
      align: "center",
      render: (text) => <a>{text}</a>,
      filters: cameralist.map((item) => item),
      filterSearch: true,
      onFilter: (value, record) => record.cameraName.indexOf(value) === 0,
    },
    {
      title: "Event Name",
      dataIndex: "eventName",
      key: Math.random(),
      align: "center",
    },
    {
      title: "Location",
      dataIndex: "location",
      key: Math.random(),
      align: "center",
      // filters: location.map((item) => item),
      filterSearch: true,
      onFilter: (value, record) => record.location.indexOf(value) === 0,
    },

    {
      title: "Created Date",
      dataIndex: "createddate",
      align: "center",
      key: "createddate",
    },
  ];

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };
  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };

  return (
    <>
      <div
        className="report-content"
        style={{
          height: "120px",
          backgroundColor: "#000716",
          width: "100%",
          // marginLeft: "9px",
          margin: 0,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        {/* First Row */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginLeft: "3%",
          }}
        >
          <p
            style={{
              color: "white",
              fontSize: "21px",
              fontFamily: "revert-layer",
            }}
          >
          NOTIFICATION CONTROL
          </p>
          <div style={{ display: "flex", marginRight: "3%"  }}>
            <button className="adduser">
              + New
            </button>
            {/* <select className="logs" style={{ textAlign: "center" }}>
              <option value="Logs">Logs</option>
              <option value="massiveDelete">Mass Delete</option>
              <option value="massiveDelete">Mass Update</option>
              <option value="massiveDelete">Print View</option>
            </select> */}
            {/* <button className="logs">Logs</button> */}
          </div>
        </div>

        <div
          style={{ display: "flex", alignItems: "center", marginLeft: "3%" }}
        >
          <p style={{ color: "#5e5a5a" }}>Sort by:</p>
          <Form
            {...formItemLayout}
            layout="inline"
            style={{ marginLeft: "10px" }}
          >
            <Form.Item>
              <Select
                style={{ width: 120 }}
                placeholder="Asset Id"
                //   options={cameralistmultiselect}
                //   value={selectedcameralist}
              ></Select>
            </Form.Item>
            <Form.Item>
              <Select
                style={{ width: 120 }}
                placeholder="Location"
                //   options={cameralistmultiselect}
                //   value={selectedcameralist}
              ></Select>
            </Form.Item>
            <Form.Item>
              <Select
                style={{ width: 120 }}
                placeholder="Status"
                //   options={cameralistmultiselect}
                //   value={selectedcameralist}
              ></Select>
            </Form.Item>
            <Form.Item>
              <Select
                style={{ width: 120 }}
                placeholder="Tag"
                options={events}
              ></Select>
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
              <Tooltip title="search">
                <Button
                  style={{ color: "black", backgroundColor: "white" }}
                  icon={<SearchOutlined />}
                  shape="circle"
                  size="small"
                  type="primary"
                  htmlType=" submit"
                />
              </Tooltip>
            </Form.Item>
          </Form>
        </div>
      </div>

      <Row gutter={[4, 4]} style={{ maxHeight: "90%" }}>
        <Col
          xs={{ span: 24, offset: 0 }}
          lg={{ span: 24, offset: 0 }}
          xl={{ span: 24, offset: 0 }}
          style={{ marginLeft: "1%" }}
        >
          <Table
            rowSelection={{
              ...rowSelection,
            }}
            columns={columns}
            //   dataSource={data}
            pagination={paginationConfig}
            // pagination={{
            //     pageSize,
            //     showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            //   }}
          />
        </Col>
      </Row>
    </>
  );
};
export default NotificationControl;
