import axios from "axios";
import decode from "jwt-decode";
import axiosClient from "./ApiCall";
import moment, { utc } from "moment";
import authService from "../Authentication/authService";
import { Navigate } from "react-router-dom";
import { message } from "antd";

const API_URL = process.env.REACT_APP_API_KEY;

// Authservice class has some functions that get jwt token from localstorage and returns the details
class AuthService {
  // login with username and password , rem=true will give additional 2 hrs to jwt token, from=false means the request is from web application if its true then its from mobile application
  login(Email, Password, Remember) {
    return axios
      .post(API_URL + "/userlogin/login", {
        username: Email,
        pass: Password,
        rem: Remember,
        from: false,
      })
      .then((response) => {
        if (response.status === 200) {
          localStorage.setItem("user", JSON.stringify(response.data.jwt_token));
          return response;
        }
      })
      .catch((err) => {
        if (!err.response) {
          return "Network Error";
        } else {
          return err.response;
        }
      });
  }
  // logout clears all the localstorage datas
  logout() {
    console.log("clearing data");
    localStorage.removeItem("user");
    localStorage.removeItem("userRfTkn");
    localStorage.removeItem("LinkToken");
  }

  // returns true if the current user is valid, else returns false
  isAuthenticateduser() {
    try {
      if (
        localStorage.getItem("user") === null ||
        localStorage.getItem("user") === undefined ||
        localStorage.getItem("user") === "undefined"
      ) {
        return false;
      }
      const jwtTkn = localStorage.getItem("user");

      let user = "";
      try {
        user = decode(jwtTkn);
      } catch (error) {
        this.logout();
        console.log("jwt decode issue", error);
      }

      var utcSeconds = user.exp;

      var now = moment().unix();

      if (utcSeconds === null) {
        localStorage.removeItem("user");
        return false;
      }

      if (utcSeconds >= now) {
        return true;
      } else {
        this.logout();
        console.log("Authservice ended || token time out || Invalid Token");
        return false;
        // return true;
      }
    } catch (error) {
      console.log("from Authservice", error);
    }
  }

  //returns username
  getUserName() {
    try {
      const user = decode(localStorage.getItem("user"));
      return user.Username;
    } catch (error) {
      console.log("error in getusername in authservice", error);
      localStorage.clear();
      window.location.href = "/Login";
      message.error("Session timed out");
    }
  }
  getEvent() {
    try {
      const Event = decode(localStorage.getItem("user"));
      console.log("getEvent()", Event);
      return Event.eventId;
    } catch (error) {
      console.log("error in eventid in authservice", error);
      // localStorage.clear();
      // window.location.href = "/Login";
      message.error("Session timed out");
    }
    console.log("getEvent()88888", Event);
  }

  // To get policy details of a user

  getUserPolicyDetails() {
    try {
      const user = decode(localStorage.getItem("user"));
      return user.policyDetails;
    } catch (error) {
      console.log("error in policydetails in authservice", error);
      localStorage.clear();
      window.location.href = "/Login";
      message.error("Session timed out");
    }
  }

  // returns email
  getUserEmail() {
    try {
      const user = decode(localStorage.getItem("user"));
      return user.Email;
    } catch (error) {
      console.log("error in getUserEmail in authservice", error);
      localStorage.clear();
      window.location.href = "/Login";
      message.error("Session timed out");
    }
  }

  // returns user id
  getUserID() {
    // const user = decode(localStorage.getItem("user"));
    // return user.Userid;

    try {
      const user = decode(localStorage.getItem("user"));
      return user.Userid;
    } catch (error) {
      console.log("error in getUserID in authservice", error);
      localStorage.clear();
      window.location.href = "/Login";
    }
  }

  // // returns role
  getCurrentUserRole() {
    try {
      const userdata = decode(localStorage.getItem("user"));
      return userdata.Roles;
    } catch (error) {
      console.log("error in getCurrentUserRole in authservice", error);
      localStorage.clear();
      window.location.href = "/Login";
    }
  }

  // returns true if the user is readonly
  getCurrentUserReadOnly() {
    try {
      const userdata = decode(localStorage.getItem("user"));
      return userdata.Isreadonly;
    } catch (error) {
      console.log("error in getCurrentUserReadOnly in authservice", error);
      localStorage.clear();
      window.location.href = "/Login";
    }
  }

  // returns the current user jwt token
  getCurrentUser() {
    try {
      return JSON.parse(localStorage.getItem("user"));
    } catch (error) {
      console.log("error in getCurrentUser in authservice", error);
      localStorage.clear();
      window.location.href = "/Login";
    }
  }

  // Method to decode the token and get the payload
  getDecodedToken() {
    const user = this.getCurrentUser();
    if (user && user.token) {
      try {
        const base64Url = user.token.split(".")[1];
        const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        const jsonPayload = decodeURIComponent(
          atob(base64)
            .split("")
            .map((c) => {
              return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
        );

        return JSON.parse(jsonPayload);
      } catch (error) {
        console.log("Error decoding token", error);
        localStorage.clear();
        window.location.href = "/Login";
      }
    }
    return null;
  }
  getCreatedDate() {
    try {
      const user = localStorage.getItem("user");
      if (!user) return null;

      const decodedToken = decode(user);
      return decodedToken.createddate || null;
    } catch (error) {
      console.log("Error fetching created date", error);
      return null;
    }
  }

  useCreatedDateInComponent() {
    const createdDate = this.getCreatedDate();
    if (createdDate) {
      const formattedDate = moment(createdDate).format("YYYY-MM-DD");
      console.log("Formatted Created Date:", formattedDate);
    }
  }

  // Method to get onboardLocation from the decoded token
  getOnBoardLocation() {
    const decodedToken = this.getDecodedToken();
    if (decodedToken && decodedToken.onBoardLocation) {
      return decodedToken.onBoardLocation;
    }
    return [];
  }

  // clears localstorage
  removeuser() {
    localStorage.removeItem("user");
    localStorage.clear();
  }
}

export default new AuthService();
