import React from "react";
import axios from "axios";
import authService from "./authService";
import { json, Navigate, Redirect, useHistory } from "react-router-dom";
const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_KEY,
});

// we use axiosclient in every request to reach backend

// intercepting every request to add authorization token
axiosClient.interceptors.request.use(
  (config) => {
    config.headers["authorization"] = authService.getCurrentUser();

    if (authService.isAuthenticateduser()) {
      return config;
    } else {
      console.log("else from apicall");
      return <Navigate to="/Login" replace />;
    }
  },
  (error) => Promise.reject(error)
);


// intercepting every response
axiosClient.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    try {
      if (error.response) {
        // Request made and server responded
        console.log("errt", error);
        // console.log(error.response.data);

        if (error.response.data) {
          return Promise.reject(error.response.data);
        } else {
          return Promise.reject(error.data);
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
        return Promise.reject(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error ff", error);

        return Promise.reject(error);
      }
    } catch (error) {
      console.log("From API call ", error);
    }
  }
);

export default axiosClient;
