import { atom } from "jotai";
import jwtDecode from "jwt-decode";
import authService from "../../Authentication/authService";
import { get } from "lodash";

const Role = atom(
  localStorage.getItem("user") && jwtDecode(localStorage.getItem("user")).Roles
    ? jwtDecode(localStorage.getItem("user")).Roles
    : null
);
const PolicyDetailsJotai = atom(
  localStorage.getItem("user") &&
    jwtDecode(localStorage.getItem("user")).policyDetails
    ? jwtDecode(localStorage.getItem("user")).policyDetails
    : []
);
const EventListJotai = atom(
  localStorage.getItem("user") &&
    jwtDecode(localStorage.getItem("user")).eventId
    ? jwtDecode(localStorage.getItem("user")).eventId
    : []
);

const LoadingComp = atom(false);

const DashboardNavData = atom(null);

export {
  Role,
  PolicyDetailsJotai,
  LoadingComp,
  EventListJotai,
  DashboardNavData,
};
