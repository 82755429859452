import React from "react";
// import "antd/dist/antd.css";
import { useNavigate } from "react-router-dom";
import {
  Layout,
  Menu,
  Alert,
  Row,
  Col,
  Table,
  Image,
  Card,
  Upload,
  message,
  Space,
  Input,
  Button,
  Form,
  Avatar,
  Typography,
  Modal,
  Empty,
  Pagination,
  Tooltip,
} from "antd";
import { useState } from "react";
import { UserOutlined, DeleteOutlined } from "@ant-design/icons";
// import axiosClient from "../Authentication/ApiCall";
import { useEffect } from "react";
import authService from "../../Authentication/authService";
import axiosClient from "../../Authentication/ApiCall";
// import authService from "../Authentication/authService";

import { Buffer } from "buffer";

const { Title, Text, Paragraph } = Typography;
const { Meta } = Card;

export default function Face() {
  // document.title = "KEN - Add user images";

  const [TableData, setTableData] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [ImageName, setImageName] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [UserList, setUserList] = useState([]);
  const [UserImages, setUserImages] = useState([]);
  const [DeleteRefresh, setDeleteRefresh] = useState(1);
  const [AddRefresh, setAddRefresh] = useState(1);
  const [SelectedId, setSelectedId] = useState();
  const [ellipsisWrap, setellipsisWrap] = useState(true);
  const [Loading, setLoading] = useState(true);
  const [TotalCount, setTotalCount] = useState();
  const [CurrentPage, setCurrentPage] = useState();

  const TableColumn = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Extension",
      dataIndex: "Extension",
      key: "Extension",
    },
    {
      title: "Date",
      dataIndex: "Date",
      key: "Date",
    },
    {
      title: "img",
      key: "img",
      dataIndex: "img",
      render: (img) => <Image width={50} src={img} />,
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        return <DeleteOutlined onClick={(e) => DeleteUser(record.name)} />;
      },
    },
  ];

  const DeleteUser = (name) => {
    axiosClient
      .delete("deleteUploadedImages?name=" + name)
      .then((response) => {
        if (response.status === 200) {
          message.success(response.data);
        } else {
          message.warn(response.data);
        }
        setRefresh(!refresh);
      })
      .catch((err) => {
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }

    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }

    return isJpgOrPng && isLt2M;
  };

  const handleChange = (info) => {
    // Get this url from response in real world.
    getBase64(info.file.originFileObj, (url) => {
      setImageUrl(url);
    });
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const HandlePagination = ({ page, size }) => {
    setLoading(true);

    axiosClient
      .get("/face/getUserImages?page=" + page + "&&size=" + size)
      .then((response) => {
        if (response.data.issuccess === true) {
          // setUserList(response.data.data);
          setUserImages(response.data.data);
          setTotalCount(response.data.totaldatacount);
          setCurrentPage(response.data.page);
          setLoading(false);
          
        } else {
          console.log("Failed");
        }
      })
      .catch((err) => {
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (imageUrl === null || imageUrl.trim() === "") {
      message.warn("browse image to upload");
    } else {
      var data = new FormData();
      data.append("user_image", imageUrl);
      data.append("username", ImageName);
      axiosClient
        .post("/face/addUserImage", {
          user_image: imageUrl,
          username: ImageName,
        })
        .then((response) => {
          if (response.data.issuccess === true) {
            setAddRefresh(AddRefresh + 1);

            message.success(response.data.msg);
          }
          if (response.data.issuccess === false) {
            console.log("false");
            message.warn(response.data.msg);
          }
          setImageUrl(null);
          setImageName(null);
          setRefresh(!refresh);
         
        })
        .catch((err) => {
          console.log("errr", err);
          if (err.status === 0) {
            message.error("Server error");
          } else {
            message.error(err.msg);
          }
          setImageUrl(null);
          setImageName(null);
        });
    }
  };

  useEffect(() => {
    setLoading(true);
    axiosClient
      .get("/face/getUserImages?page=1&&size=12")
      .then((response) => {
        if (response.data.issuccess === true) {
          // setUserList(response.data.data);
          setTotalCount(response.data.totaldatacount);
          setCurrentPage(response.data.page);
          setUserImages(response.data.data);
          setLoading(false);
          
        } else {
          console.log("Failed");
        }
      })
      .catch((err) => {
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  }, [DeleteRefresh, AddRefresh]);

  return (
    <Row
      gutter={[30, 20]}
      style={{
        display: "flex",
        justifyContent: "space-evenly",
      }}
    >
      <Col
        xs={{ span: 24, offset: 0 }}
        sm={{ span: 20, offset: 0 }}
        lg={{ span: 15, offset: 0 }}
        xl={{ span: 12, offset: 0 }}
        xxl={{ span: 8, offset: 0 }}
      >
        <Card
          style={{ width: "100%" }}
          title="Add a new user"
          hoverable
          headStyle={{ backgroundColor: "#001529", color: "white" }}
        >
          <Form
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 22,
            }}
            autoComplete="off"
            onSubmitCapture={(e) => handleSubmit(e)}
          >
            <Space
              direction="horizontal"
              size="middle"
              className="form-group"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                beforeUpload={beforeUpload}
                onChange={handleChange}
              >
                {imageUrl ? (
                  <img
                    src={imageUrl}
                    alt="avatar"
                    style={{
                      width: "100%",
                    }}
                  />
                ) : (
                  "Upload"
                )}
              </Upload>
              <Input
                placeholder="Username"
                prefix={<UserOutlined />}
                value={ImageName}
                type="text"
                width="100%"
                required
                onChange={(e) => setImageName(e.target.value)}
              />
            </Space>
            <br />
            <Form.Item
              wrapperCol={{
                xs: { span: 24, offset: 0 },
                sm: { span: 12, offset: 8 },
                md: { span: 12, offset: 8 },
                lg: { span: 12, offset: 8 },
                xl: { span: 8, offset: 8 },
              }}
            >
              <div className="text-center">
                {authService.getCurrentUserReadOnly() ? (
                  <Tooltip title="you need access to submit">
                    <Button type="primary" disabled>
                      Submit
                    </Button>
                  </Tooltip>
                ) : (
                  <Button
                    style={{
                      backgroundColor: "#3e3a75",
                      border: "none",
                      width: "100%",
                    }}
                    type="primary"
                    htmlType="submit"
                  >
                    Submit
                  </Button>
                )}
              </div>
            </Form.Item>
          </Form>
        </Card>

        {/* <div className="mt-05">
          <Alert
            message="Informational Notes"
            description="You can able to upload Images upto 10"
            type="info"
            showIcon
          />
        </div> */}
      </Col>
      <Col
        xs={{ span: 24, offset: 0 }}
        sm={{ span: 20, offset: 0 }}
        lg={{ span: 18, offset: 0 }}
        xl={{ span: 16, offset: 0 }}
        xxl={{ span: 15, offset: 0 }}
        scroll={{ x: "max-content" }}
      >
        <Card
          title="User List"
          hoverable
          headStyle={{ backgroundColor: "#001529", color: "white" }}
          loading={Loading}
          scroll={{ x: "max-content" }}
        >
          <Row gutter={[30, 30]} scroll={{ x: "max-content" }}>
            {UserImages.length >= 1 ? (
              UserImages.map((item) => {
                // const img = new Buffer.from(item.face_image.data).toString(
                //   "base64"
                // );
                // const base64String = btoa(
                //   String.fromCharCode(...new Uint8Array(item.face_image))
                // );

                return (
                  <Col
                    xxl={{ span: 4, offset: 0 }}
                    xs={{ span: 4, offset: 0 }}
                    sm={{ span: 4, offset: 0 }}
                    lg={{ span: 4, offset: 0 }}
                    xl={{ span: 4, offset: 0 }}
                    scroll={{ x: "max-content" }}
                  >
                    {/* <Table columns={TableColumn} dataSource={TableData} /> */}
                    <Card
                      bordered
                      hoverable
                      // bodyStyle={{ display: "flex", justifyContent: "center" }}
                      // style={{ width: 300 }}
                      // style={{ width: "100%", height: "100%" }}
                      cover={
                        <div style={{ height: "100%" }}>
                          <Image
                            style={{ height: "100px" }}
                            alt="User Image"
                            src={
                              "data:image/png;base64," +
                              item.face_image.replace(/\"/g, "")
                            }
                          />
                        </div>
                      }
                      // actions={[
                      //   <SettingOutlined key="setting" />,
                      //   <EditOutlined key="edit" />,
                      //   <EllipsisOutlined key="ellipsis" />,
                      // ]}
                      // actions={[<DeleteOutlined style={{ color: "red" }} />]}
                      // extra={<DeleteOutlined style={{ color: "red" }} />}
                      // actions={[<DeleteOutlined style={{ color: "red" }} />]}
                    >
                      <Space
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                        }}
                      >
                        {/* <Title ellipsis={true} level={5}>
                          {item.username}
                        </Title> */}

                        <Text
                          style={ellipsisWrap ? { width: 70 } : undefined}
                          ellipsis={
                            ellipsisWrap ? { tooltip: item.username } : false
                          }
                        >
                          {item.username}
                        </Text>
                        {/* <Meta
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            wordWrap: "break-word",
                          }}
                          title={item.username}
                          // description={item.username}
                        ></Meta> */}
                        {authService.getCurrentUserReadOnly() ? (
                          <Tooltip title="you need access to delete">
                            <DeleteOutlined style={{ color: "red" }} disabled />
                          </Tooltip>
                        ) : (
                          <DeleteOutlined
                            onClick={() => {
                              // setSelectedId(item.id);
                              // OnDelete();
                              // Meteor.call(OnDelete, item._id)

                              Modal.confirm({
                                title: "Confirm Delete?",

                                okText: "Confirm",
                                okType: "danger",
                                onOk: () => {
                                  axiosClient
                                    .put("/face/deleteUserImage?id=" + item.id)
                                    .then((response) => {
                                      if (response.data.issuccess === true) {
                                        setDeleteRefresh(DeleteRefresh + 1);
                                        message.success(response.data.msg);
                                        
                                      } else {
                                        console.log("Failed");
                                        message.error(response.data.msg);
                                      }
                                    })
                                    .catch((err) => {
                                      console.log("errr", err);
                                      if (err.status === 0) {
                                        message.error("Server error");
                                      } else {
                                        message.error(err.msg);
                                      }
                                    });
                                },
                              });
                            }}
                            style={{ color: "red" }}
                          />
                        )}
                      </Space>

                      {/* <Space direction="horizontal" size={"middle"}>
          <Title  level={5}>long</Title>
          <DeleteOutlined style={{ color: "red" }} />
        </Space> */}
                      {/* <Col
          style={{ display: "flex", justifyContent: "space-evenly" }}
          xxl={{ span: 24, offset: 1 }}
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          lg={{ span: 24, offset: 0 }}
          xl={{ span: 24, offset: 0 }}
        >
          <Title level={5}>
            long userrrrr nammmeeee
            <DeleteOutlined
              style={{ color: "red", marginLeft: "1em" }}
            />
          </Title>
        </Col> */}
                    </Card>
                  </Col>
                );
              })
            ) : (
              <Empty
                style={{ width: "100%", height: "100%" }}
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="Empty"
              />
            )}
          </Row>
          <Row justify="end" className="top-space">
            <Col>
              <Pagination
                total={TotalCount}
                pageSize={12}
                current={CurrentPage}
                showSizeChanger={false}
                onChange={(page) => {
                  HandlePagination({
                    page: page,
                    size: 12,
                  });
                }}
              />
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
}
