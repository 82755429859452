import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Row,
  Typography,
  Select,
  Table,
  Image,
  Col,
  Menu,
  Dropdown,
  Tag,
  Input,
  Space,
  Empty,
} from "antd";
import Highlighter from "react-highlight-words";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { DatePicker, Modal } from "antd";
import moment from "moment";
import styled from "styled-components";
import axiosClient from "../../Authentication/ApiCall";
import * as FileSaver from "file-saver";
import { FaAngleDown, FaCaretDown } from "react-icons/fa";
import {
  UserOutlined,
  LockOutlined,
  DownOutlined,
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
} from "@ant-design/icons";
// import "./ReportStyle.css";

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Text } = Typography;

// cameraId: 1
// ​​
// cameraName: "test1"
// ​​
// createddate: "2024-04-17 09:14:19"
// ​​
// eventId: 5
// ​​
// eventName: "Trespassing"
// ​​
// location: "test1"

// const data = [
//   {
//     key: '1',
//     sno: '1',
//     name: 'John Doe',
//     event: 'Trespassing',
//     location: 'Gate 1',
//     details: 'Detected movement near the gate.',
//     datetime: '2023-05-30 14:00',
//     media: 'https://via.placeholder.com/50',
//     mediaType: 'image',
//     status: 'Pending',
//   },
//   // Add more data objects as needed
// ];

const Container = styled.div`
  .header {
    padding: 1rem 0;
  }

  .filters {
    padding: 0 1rem;
  }

  .table-container {
    padding: 1rem 2rem;
  }
`;

const ANPRreport = () => {
  function disabledDate(current) {
    // Disable dates after today
    return current && current > moment().endOf("day");
  }
  const [data, setData] = useState([]);
  const [EventList, setEventList] = useState([]);
  const [CameraList, setCameraList] = useState([]);
  const [StartDate, setStartDate] = useState("");
  const [EndDate, setEndDate] = useState("");
  const [EventId, setEventId] = useState(null);
  const [cameraId, setCameraId] = useState(null);
  const [status, SetStatus] = useState(null);
  const [eventName, setEventName] = useState("");
  const [flagEvent, setflagEvent] = useState(false);
  const [Model, setModel] = useState(false);
  const [AnprModal, setAnprModal] = useState(false);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [imagedata, setimagedata] = useState("");
  console.log("data6", data);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  console.log("totalCount1111");
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(true);
  //getting  all reports
  const allReports = async (
    startDate,
    endDate,
    eventId,
    cameraId,
    page,
    searchText
  ) => {
    setLoading(true);
    try {
      const response = await axiosClient.get(
        `report1/getreportforanpr?startDate=${startDate}&endDate=${endDate}&cameraId=${cameraId}&page=${page}&pageSize=${pageSize}&number=${encodeURIComponent(
          searchText
        )}`
      );

      if (response.data.issuccess) {
        const responseData = response.data.data
          .sort((a, b) => new Date(b.createddate) - new Date(a.createddate))
          .map((item, index) => ({
            ...item,
            sno: (page - 1) * pageSize + index + 1,
            details: "-",
          }));

        setData(responseData);
        setTotalCount(response.data.count);
      } else {
        console.log("Something went wrong");
      }
    } catch (error) {
      console.error("Error fetching overall events:", error);
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   allReports(startDate, endDate, eventId, cameraId);
  // }, [currentPage]);

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  // const handleSearch = (selectedKeys, confirm, dataIndex) => {
  //   confirm();
  //   setSearchText(selectedKeys[0]);
  //   setSearchedColumn(dataIndex);
  // };
  const handleSearch = async (event) => {
    const value = event.target.value.toUpperCase();
    setSearchText(value);
    // allReports(searchText);
    // await allReports(startDate, endDate, eventId, cameraId, page, value);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters);
              // Add functionality to close dropdown if needed
              // Optionally call confirm to keep the dropdown open or closed
              confirm({ closeDropdown: true });
            }}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  // const [currentPage, setCurrentPage] = useState(1);
  // const pageSize = 10;
  const columns = [
    {
      title: <span style={{ color: "#000816" }}>Sl No</span>,
      key: "sno",
      align: "center",
      width: "10%",
      render: (text, record, index) => (currentPage - 1) * pageSize + index + 1,
    },
    {
      title: <span style={{ color: "#000816" }}>Number</span>,
      dataIndex: "number",
      key: "number",
      align: "center",
      width: "20%",
      // ...getColumnSearchProps("number"),
    },
    {
      title: <span style={{ color: "#000816" }}>In Time</span>,
      dataIndex: "inTime",
      key: "inTime",
      align: "center",
      // render: (text) =>
      // text ? moment(text).format("YYYY-MM-DD HH:mm:ss") : "--",
    },
    {
      title: <span style={{ color: "#000816" }}>Out Time</span>,
      dataIndex: "outTime",
      key: "outTime",
      align: "center",
      // render: (text) =>
      //   text ? moment(text).format("YYYY-MM-DD HH:mm:ss") : "--",
    },

    {
      title: <span style={{ color: "#000816" }}>Dwell Time (min)</span>,
      dataIndex: "dwellTime",
      key: "dwellTime",
      align: "center",
      render: (text) => (text ? text : "--"),
    },

    {
      title: <span style={{ color: "#000816" }}>Image/Video</span>,
      dataIndex: "media",
      key: "media",
      align: "center",
      render: (text, record) => (
        <span>
          <Button
            type="primary"
            onClick={() => {
              OpenModel(record);
            }}
          >
            View
          </Button>
        </span>
      ),
    },
  ];
  //  getColumns(eventName);

  // const columns = [
  //   {
  //     title: <span style={{ color: "#000816" }}>S.No</span>,
  //     dataIndex: "sno",
  //     key: "sno",
  //     align: "center",
  //   },
  //   {
  //     title: <span style={{ color: "#000816" }}>Name</span>,
  //     dataIndex: "cameraName",
  //     key: "name",
  //     align: "center",
  //   },
  //   {
  //     title: <span style={{ color: "#000816" }}>Event</span>,
  //     dataIndex: "eventName",
  //     key: "event",
  //     align: "center",
  //   },
  //   {
  //     title: <span style={{ color: "#000816" }}>Location</span>,
  //     dataIndex: "location",
  //     key: "location",
  //     align: "center",
  //   },
  //   {
  //     title: (
  //       <span style={{ color: "#000816", textAlign: "center" }}>Details</span>
  //     ),
  //     dataIndex: "details",
  //     key: "details",
  //     align: "center",
  //   },
  //   {
  //     title: <span style={{ color: "#000816" }}>Date/Time</span>,
  //     dataIndex: "createddate",
  //     key: "datetime",
  //     align: "center",
  //   },
  //   {
  //     title: <span style={{ color: "#000816" }}>Image/Video</span>,
  //     dataIndex: "media",
  //     key: "media",
  //     align: "center",
  //     render: (text, record) => (
  //       <span>
  //         <Button
  //           type="primary"
  //           onClick={() => {
  //             OpenModel(record);
  //             //imageSetter(console.log("RECORD-----------------",record))
  //           }}
  //         >
  //           click
  //         </Button>
  //       </span>
  //     ),
  //   },
  //   {
  //     title: <span style={{ color: "#000816" }}>Status</span>,
  //     dataIndex: "status",
  //     key: "status",
  //     align: "center",
  //     width: 200,
  //     render: (text, record) => (
  //       <span>
  //         {record.status == true ? "Issue solved" : "Issue Not solved"}
  //       </span>
  //     ),
  //   },
  //   {
  //     title: <span style={{ color: "#000816" }}>Action</span>,
  //     dataIndex: "action",
  //     key: "action",
  //     align: "center",
  //     render: (text, record) => (
  //       <Select
  //         className="custom-select"
  //         style={{ width: 160, color: "grey" }}
  //         placeholder="Action"
  //         dropdownStyle={{ backgroundColor: "#fffff", color: "#fffff" }}
  //         suffixIcon={<FaCaretDown color="white" size={20} />}
  //         value={status == null ? null : null}
  //         onChange={(value) => {
  //           SetStatus(value);
  //           updateAction(record.id, record.eventName, value);
  //         }}
  //       >
  //         <Option value={true}>Issue Solved</Option>;
  //         <Option value={false}>Issue not Solved</Option>;
  //       </Select>
  //     ),
  //   },
  // ];

  const OpenModel = (record) => {
    setAnprModal(true);
    console.log("RECORD-----------------", record.eventId);
    getImageBasednumberplate(record.id, record.eventId);
  };

  const OpenModeltrespassing = (record) => {
    setModel(true);
    console.log("RECORD-----------------", record.eventId);
    getImageBasedId(record.id, record.eventId);
  };
  const imageSetter = (record) => {
    console.log("RECORD-----------------", record);
  };

  const handleOk = () => {
    setModel(false);
    setImage("");
  };

  const handleCancel = () => {
    setModel(false);
    setImage("");
  };

  const onClickDownload = () => {
    axiosClient
      .get(
        "/report1/getreportdetailsbasedoneventid?startDate=" +
          StartDate +
          "&endDate=" +
          EndDate +
          "&eventId=" +
          3 +
          "&cameraId=" +
          cameraId +
          "&download=" +
          true,
        { responseType: "blob" }
      )
      .then((response) => {
        if (response.status === 200) {
          var blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          FileSaver.saveAs(blob, "report.xlsx");
        }
      })
      .catch((err) => {
        console.error("Error fetching overall events:", err);
      });
  };

  //http://localhost:4001/api/report1/getreportdetailsbasedoneventid

  useEffect(() => {
    allReports(StartDate, EndDate, EventId, cameraId, currentPage, searchText);
  }, [StartDate, EndDate, EventId, cameraId, currentPage, searchText]);

  const handleTableChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  useEffect(() => {
    // allReports(StartDate, EndDate, EventId, cameraId);

    getAllEvents();
    getAllCameraList();
    // getImageBasedId();
  }, []);
  const [dateRange, setDateRange] = useState(null);
  const Clear = () => {
    //window.location.reload();
    setDateRange(null);
    getAllEvents();
    getAllCameraList();
    setEventId(null);
    setCameraId(null);
    setSearchText("");
    allReports(StartDate, EndDate, EventId, cameraId, currentPage, searchText);
  };

  function getAllEvents() {
    axiosClient
      .get("/report1/geteventlist")
      .then((response) => {
        if (response.data.issuccess === true) {
          // console.log(
          //   "Overall Events-----------------------------------:",
          //   response.data.data
          // );
          setEventList(response.data.data);
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((err) => {
        console.error("Error fetching overall events:", err);
      });
  }

  function getAllCameraList() {
    axiosClient
      // .get("/report1/getcameralist")
      .get("/report1/getcameralistforreport?eventId=" + 3)
      .then((response) => {
        if (response.data.issuccess === true) {
          console.log(
            "Overall Camera First-----------------------------------:",
            response.data.data
          );
          setCameraList(response.data.data);
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((err) => {
        console.error("Error fetching overall events:", err);
      });
  }
  // http://localhost:4001/api/report1/geteventlistbasedoncameraid?cameraId=1

  function getEventListBasedId(value) {
    axiosClient
      .get("/report1/geteventlistbasedoncameraid?cameraId=" + value)
      .then((response) => {
        if (response.data.issuccess === true) {
          console.log(
            "Overall Based on Camera Event-----------------------------------:",
            response.data.data
          );
          setEventList(response.data.data);
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((err) => {
        console.error("Error fetching overall events:", err);
      });
  }
  // console.log("Event list %55%%%%%%%",EventList)

  // http://localhost:4001/api/report1/getcameralistbasedoneventid?eventId=1
  function getCameraListBasedId(value) {
    axiosClient
      .get("/report1/getcameralistbasedoneventid?eventId=" + value)
      .then((response) => {
        if (response.data.issuccess === true) {
          console.log(
            "Overall Based on Event Camera TEST-----------------------------------:",
            response.data.data
          );
          setCameraList(response.data.data);
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((err) => {
        console.error("Error fetching overall events:", err);
      });
  }

  // http://localhost:4001/api/report1/getEventImageByID?id=1&eventId=5
  const [image, setImage] = useState("");
  console.log("imagw4444444");

  function getImageBasedId(id, eventId) {
    axiosClient
      .get(`/report1/getEventImageByID?id=${id}&eventId=${eventId}`)
      .then((response) => {
        if (response.data.issuccess === true) {
          console.log(
            "IMAGE-----------------------------------:",
            response.data.data
          );
          //setEventList(response.data.data);
          const imageData = response.data.data;
          console.log("IMAGE DATA TO SET:", imageData);
          setImage(imageData);
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((err) => {
        console.error("Error fetching overall events:", err);
      });
  }

  useEffect(() => {
    console.log("Updated image state:", image);
  }, [image]);

  function getImageBasednumberplate(id, eventId) {
    axiosClient
      .get(`/report1/getEventImageByID?id=${id}&eventId=${eventId}`)
      .then((response) => {
        if (response.data.issuccess === true) {
          console.log(
            "IMAGE-----------------------------------:",
            response.data.data
          );
          const imageData = response.data.data;
          console.log("IMAGE DATA TO SET444445555:", imageData.inTimeImage);
          console.log("IMAGE DATA TO outtimeimge:", imageData.outTimeImage);
          setimagedata(imageData);
          // setImage(response.data.data);
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((err) => {
        console.error("Error fetching overall events:", err);
      });
  }

  const onDateChange = (date) => {
    if (date) {
      const [startDate, endDate] = date;
      setStartDate(startDate.format("YYYY-MM-DD"));
      setEndDate(endDate.format("YYYY-MM-DD"));
    } else {
      setStartDate("");
      setEndDate("");
    }
  };

  // http://localhost:4001/api/report1/updateaction?id=2&eventName=Trespassing&status=false
  function updateAction(id, eventName, value) {
    axiosClient
      .put(
        `/report1/updateaction?id=${id}&eventName=${eventName}&status=${value}`
      )
      .then((response) => {
        if (response.data.issuccess === true) {
          // allReports(StartDate, EndDate, EventId, cameraId);
          SetStatus(null);
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((err) => {
        console.error("Error fetching overall events:", err);
      });
  }

  // const uniqueCameras = CameraList.filter(
  //   (item, index, self) =>
  //     index === self.findIndex((t) => t.id === item.id)
  // );
  const onClose = () => {
    setAnprModal(false);
  };
  return (
    <div style={{ marginTop: "15px" }}>
      {/* <Row
          xs={24}
          md={12}
          style={{
            alignItems: "center",
            justifyContent: "space-between",
            padding: "1rem 1rem",
          }}
        >
          <Text
            xs={24}
            md={12}
            style={{ color: "white", fontWeight: "700", fontSize: "2rem" }}
          >
            Report
          </Text>
          <Button
            xs={24}
            md={12}
            style={{ display: "flex", alignItems: "center", gap: "5px" }}
            onClick={onClickDownload}
          >
            Export to xlsx <FaCloudDownloadAlt size={22} />
          </Button>
        </Row> */}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Row
          style={{
            padding: "0rem 1rem",
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            marginLeft: "1%",
            gap: "1rem",
            flexWrap: "wrap",
          }}
        >
          <Col>
            <RangePicker
              //style={{width:260}}
              value={dateRange}
              onChange={(date) => {
                setDateRange(date);
                onDateChange(date);
              }}
              disabledDate={disabledDate}
            />
          </Col>
          <Col>
            <Select
              style={{ color: "grey" }}
              placeholder="Select Camera"
              value={cameraId}
              onChange={(value) => {
                setCameraId(value);
              }}
            >
              {CameraList.map((item) => {
                return <Option value={item.cameraId}>{item.cameraName}</Option>;
              })}
            </Select>
          </Col>
          {/* <Col>
            <Select
              style={{ color: "grey" }}
              placeholder="Select Event"
              value={EventId}
              onChange={(value) => {
                setEventId(value);
              }}
            >
              {EventList.map((item) => {
                return <Option value={item.eventId}>{item.eventName}</Option>;
              })}
            </Select>
          </Col> */}
          <Col>
            {" "}
            <input
              placeholder="Search Number Plates..."
              value={searchText}
              style={{
                // width: "96%",
                height: "34px",
                backgroundColor: "white",
                padding: "5px",
                marginRight: "5px",
                border: "none",
                outline: "none",
                boxShadow: "none",
                borderRadius: "5px",
              }}
              onChange={handleSearch}
              onFocus={() => console.log("Input focused")}
            />
          </Col>
          <Col>
            <Button onClick={Clear}>Clear</Button>
          </Col>
        </Row>
        <Row>
          <Button
            xs={24}
            md={12}
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
              marginRight: "50px",
            }}
            onClick={onClickDownload}
          >
            Export to xlsx <FaCloudDownloadAlt size={22} />
          </Button>
        </Row>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100vw",
          // height: "70vh",
        }}
      >
        <Table
          columns={columns}
          dataSource={data}
          // dataSource={demoData}
          style={{ padding: "1rem 2rem", width: "100%" }}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            total: totalCount,
            onChange: handleTableChange,
          }}
          rowKey="sno"
          scroll={{ x: true }}
        />
      </div>
      <div>
        <Modal
          width={"50vw"}
          title="Basic Modal"
          open={Model}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          {/* <Image
            src={"data:image/png;base64," + image}
            // src={`data:image/jpeg;base64,${image}`}
            alt="Image Not Found"
            preview={false}
            height={500}
          /> */}
          <img
            src={`data:image/png;base64,${image}`}
            alt="Image Not Found"
            style={{ width: "100%", height: "auto" }}
          />
        </Modal>
        <Modal open={AnprModal} onCancel={onClose} footer={null} width={1000}>
          <Row>
            <Col span={11}>
              {" "}
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",

                  marginBottom: "9px",
                  marginLeft: "30px",
                }}
              >
                <Tag
                  style={{
                    padding: "5px",
                    fontSize: "18px",
                    fontWeight: "bold",
                  }}
                >
                  {" "}
                  In Time Image
                </Tag>
              </span>
              {imagedata.inTimeImage ? (
                <Image
                  src={`data:image/jpeg;base64,${imagedata.inTimeImage}`}
                  alt="In Time"
                  style={{ height: "560px" }}
                  className="modal-image"
                />
              ) : (
                <div style={{ textAlign: "center", marginTop: "130px" }}>
                  <Empty />
                </div>
              )}
            </Col>
            <Col span={11} offset={1}>
              {" "}
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",

                  marginBottom: "9px",
                  marginLeft: "40px",
                }}
              >
                <Tag
                  style={{
                    padding: "5px",
                    fontSize: "18px",
                    fontWeight: "bold",
                  }}
                >
                  Out Time Image
                </Tag>
              </span>
              {imagedata.outTimeImage ? (
                <Image
                  src={`data:image/jpeg;base64,${imagedata.outTimeImage}`}
                  alt="Out Time"
                  style={{ height: "560px" }}
                  className="modal-image"
                />
              ) : (
                <div style={{ textAlign: "center", marginTop: "130px" }}>
                  {" "}
                  <Empty />
                </div>
              )}
            </Col>
          </Row>
        </Modal>
      </div>
    </div>
  );
};

const StyledSelect = styled(Select)`
  .ant-select-selection-placeholder {
    color: red; /* Change this to your desired color */
  }
`;

export default ANPRreport;
