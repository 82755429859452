import {
  Card,
  Col,
  Row,
  Select,
  Typography,
  Tabs,
  Modal,
  message,
  Table,
  Button,
  Spin,
  Alert,
  Result,
  Form,
  Radio,
  Space,
  Tag,
  Divider,
  notification,
  Progress,
  Avatar,
  List,
  Menu,
  Dropdown,
  Tooltip,
  Image,
  Skeleton,
  Empty,
  Flex,
  TimePicker,
  Drawer,
} from "antd";

import { DatePicker } from "antd";
import React, { useRef, useState, useEffect } from "react";
import moment from "moment/moment";
import { PDFExport } from "@progress/kendo-react-pdf";
import axiosClient from "../../../Authentication/ApiCall";
import dayjs from "dayjs";
import ReactApexChart from "react-apexcharts";
import { FaCameraRetro, FaRegDotCircle } from "react-icons/fa";
import useInterval from "use-interval";
import Chart from "react-apexcharts";
import "../../../../font/Helvetica.ttf";
import {
  ArrowUpOutlined,
  ArrowDownOutlined,
  CalendarOutlined,
  TeamOutlined,
  CameraOutlined,
  DownOutlined,
  CloseOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";
import { GrDirections } from "react-icons/gr";
import _, { filter, find, flatMap, map, orderBy, values } from "lodash";
import randomColor from "randomcolor";
import { FaChartSimple, FaRegCircleDot } from "react-icons/fa6";


import { GiCctvCamera, GiFootsteps } from "react-icons/gi";
import { ImHourGlass } from "react-icons/im";

const { Title, Text } = Typography;

let RenderComp = {
  IngressEgress: false,
  DwellFootfall: false,
  EngagedBounced: false,
  AgeGender: false,
  PeakHours: false,
};

let IngressEgressDetails = {
  SelectedDate: "",
  ContinueFetch: true,
};
let FootfallDirectionHourlyDetails = {
  camlist: [],
  selectedcam: "",
  selectedcamName: "",
  selectedDate: dayjs().format("YYYY-MM-DD"),
  DataOfDate: "",
  DirectionImage: null,
  DirectionData: [],
  loading: false,
  Hour: dayjs().hour(),
};
let EngageAndBounceHourlyDetails = {
  camlist: [],
  selectedcam: null,
  selectedDate: dayjs().format("YYYY-MM-DD"),
  DataOfDate: null,
};
let UniqueHourlyDetails = {
  camlist: [],
  selectedcam: "",
  selectedDate: dayjs().format("YYYY-MM-DD"),
  DataOfDate: null,
  selectedcameraname: null,
};

const Live = () => {
  const [RenderCompController, setRenderCompController] = useState(RenderComp);
  const [HandleDateForIngressEgress, setHandleDateForIngressEgress] =
    useState(IngressEgressDetails);
  const [totalingress, settotalingress] = useState();
  const [totalingrespercentage, settotalingresspercentage] = useState();
  const [ingressloading, setingressloading] = useState(true);
  const [menuOptions, setMenuOptions] = useState([]);
  const { Option } = Select;
  const [chartData, setChartData] = useState({
    options: {
      chart: {
        id: "basic-bar",
        animations: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
        contextmenu: {
          enabled: false,
        },
        zoom: {
          enabled: false, // Ensure zoom is disabled
        },
      },
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      xaxis: {
        type: "category", // Change type to category for categorical data
        categories: [], // Initialize categories as empty array
        title: {
          text: "Hour",
          offsetY: -10,
        },
      },
      yaxis: {
        type: "Count",
        title: {
          text: "Count",
          offsetY: -10,
        },
        labels: {
          formatter: function (value) {
            return parseInt(value); // Convert the y-axis values to decimal format
          },
        },
      },
      colors: ["#00e396", "#008ffb"],
      dataLabels: {
        enabled: false,
        offsetY: -15,
        style: {
          colors: ["#615494", "#f27777"],
        },
      },
      stroke: {
        width: 1,
      },
      pattern: {
        strokeWidth: 0.1,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          dataLabels: {
            position: "top",
            offsetY: -20,
            width: 1,
            style: {
              fontSize: "12px",
              colors: [" #000"],
              offsetY: -20,
            },
          },
        },
        dataLabels: {
          enabled: true,
          hideOverflowingLabels: true,
          formatter: function () {
            return 25;
          },
          offsetY: -20,
        },
      },
    },
    series: [
      {
        name: "Ingress",
        data: [],
      },
      {
        name: "Egress",
        data: [],
      },
    ],
  });
  const [peakHour, setpeakHour] = useState();
  const [yesterdaypeakHour, setyesterdaypeakHour] = useState();
  const [Totalcamera, setTotalcamera] = useState(0);

  const containerRef = useRef(null);
  const targetRef = useRef();
  const [DownloadDashboard, setDownloadDashboard] = useState(false);
  const [data, setdata] = useState([]);
  const [footfallactivecamera, setfootfallactivecamera] = useState(0);
  const [totalfootfallactivecamera, settotalfootfallactivecamera] = useState(0);
  const [eventbasedactivecamera, seteventbasedactivecamera] = useState(0);
  const [
    FootfallDirectionHourlyController,
    setFootfallDirectionHourlyController,
  ] = useState(FootfallDirectionHourlyDetails);
  const [EngageBounceHourController, setEngageBounceHourController] = useState(
    EngageAndBounceHourlyDetails
  );

  const [EngagedHourlyChart, setEngagedHourlyChart] = useState({
    options: {
      chart: {
        id: "basic-bar",
        type: "bar",
        stacked: true,
        toolbar: { show: false },
        contextmenu: { enabled: false },
      },
      plotOptions: {
        bar: { horizontal: false },
      },
      xaxis: {
        categories: [],
        title: { text: "Hour ", offsetY: -10 },
      },
      yaxis: { title: { text: "Count" } },
      legend: {
        position: "top",
        horizontalAlign: "center",
        offsetX: 0,
      },
      colors: [
        "#00e396",
        "#feb019",
        "#ff4560",
        "#775dd0",
        "#008ffb",
        "#00d9e9",
        "#ff9f00",
      ],
    },
    series: [],
  });
  const [BouncedHourlyChart, setBouncedHourlyChart] = useState({
    options: {
      chart: {
        id: "basic-bar",
        type: "bar",
        stacked: true,
        toolbar: { show: false },
        contextmenu: { enabled: false },
      },
      plotOptions: {
        bar: { horizontal: false },
      },
      xaxis: {
        categories: [],
        title: { text: "Hour ", offsetY: -10 },
      },
      yaxis: { title: { text: "Count" } },
      legend: {
        position: "top",
        horizontalAlign: "center",
        offsetX: 0,
      },
      colors: [
        "#00e396",
        "#feb019",
        "#ff4560",
        "#775dd0",
        "#008ffb",
        "#00d9e9",
        "#ff9f00",
      ],
    },
    series: [],
  });
  //-----------------
  const [UniqueHourlyController, setUniqueHourlyController] =
    useState(UniqueHourlyDetails);
  const [DwellHourlyController, setDwellHourlyController] = useState({
    camlist: [],
    selectedcam: null,
    selectedDate: dayjs().format("YYYY-MM-DD"),
    DataOfDate: null,
    selectedcameraname: null,
  });

  //function
  //----------------------------------
  const LiveTag = () => (
    <Tag color="green">
      <Flex gap={3} align="center">
        <Text
          style={{
            margin: "0px",
            fontSize: "13px",
            fontWeight: "initial",
          }}
        >
          Live
        </Text>
        <FaRegDotCircle />
      </Flex>
    </Tag>
  );

  const generatePDF = async () => {
    if (containerRef.current && targetRef.current) {
      setDownloadDashboard(true);
      console.log("container adding pdf");

      try {
        const pdfExportComponent = containerRef.current;
        await pdfExportComponent.save();
        setDownloadDashboard(false);
      } catch (error) {
        console.log("unable to save", error);
      }
      console.log("container save the pdf");
    }
  };

  //-------------------
  useEffect(() => {
    setTimeout(() => {
      setRenderCompController((prev) => ({ ...prev, IngressEgress: true }));
    }, 10);

    setTimeout(() => {
      setRenderCompController((prev) => ({ ...prev, DwellFootfall: true }));
    }, 10);
    setTimeout(() => {
      setRenderCompController((prev) => ({ ...prev, EngagedBounced: true }));
    }, 10);

    setTimeout(() => {
      setRenderCompController((prev) => ({ ...prev, AgeGender: true }));
    }, 10);
    setTimeout(() => {
      setRenderCompController((prev) => ({ ...prev, PeakHours: true }));
    }, 10);
  }, []);

  useInterval(async () => {
    getPasserByCount();
    getOverallPasserByCount();
    if (HandleDateForIngressEgress.ContinueFetch === true) {
      await axiosClient
        .get(`/livereport/getingressegress?date&camid=`)
        .then((response) => {    
          if (response.data.issuccess === true) {
            setingressloading(false);

            settotalingress(response.data.data.total_ingress_egress);
            settotalingresspercentage(
              response.data.data.percentage_ingress_basedOnTime
            );
            const list_hour = map(
              response.data.data.total_records_hour,
              "hour"
            );

            const list_ingress = map(
              response.data.data.total_records_hour,
              "totalingress"
            );
            const list_egress = map(
              response.data.data.total_records_hour,
              "totalegress"
            );
            setChartData((prev) => ({
              ...prev,
              options: {
                ...prev.options,
                xaxis: {
                  // ...prev.options.xaxis,
                  categories: list_hour,
                },
              },
              series: [
                {
                  name: "Ingress",
                  data: list_ingress,
                },
                {
                  name: "Egress",
                  data: list_egress,
                },
              ],
            }));
          }
        })
        .catch((err) => {
          console.log("errr", err);
          if (err.status === 0) {
            message.error("Network error");
          } else {
            message.error(err.message);
          }
        });
    }
    // try {
    //   const response = await axiosClient
    //     .get("crowdAnalysis/getCrowdAnalysisDetails")
    //     .then((response) => {
    //       if (response.data.issuccess === true) {
    //         setdata(response.data.data);
    //       }
    //     })
    //     .catch((err) => {
    //       console.log("errr", err);
    //       if (err.status === 0) {
    //         message.error("Server error");
    //       } else {
    //         message.error(err.message);
    //       }
    //     });
    // } catch (err) {
    //   console.log("Error from try catch crowd analysis", err);
    // }
  }, 120000);

  useEffect(() => {
    axiosClient
      .get(
        `/livereport/getingressegress?date=` +
          HandleDateForIngressEgress.SelectedDate +
          `&camid=` +
          ""
      )
      .then((response) => {       
        if (response.data.issuccess === true) {
          setingressloading(false);
          settotalingress(response.data.data.total_ingress_egress);
          settotalingresspercentage(
            response.data.data.percentage_ingress_basedOnTime
          );

          setpeakHour(response.data.data.currentPeakHourRange);
          setyesterdaypeakHour(response.data.data.yesterdayPeakHourRange);
          const list_hour = map(response.data.data.total_records_hour, "hour");

          const list_ingress = map(
            response.data.data.total_records_hour,
            "totalingress"
          );
          const list_egress = map(
            response.data.data.total_records_hour,
            "totalegress"
          );
          setChartData((prev) => ({
            ...prev,
            options: {
              ...prev.options,
              xaxis: {
                // ...prev.options.xaxis,
                categories: list_hour,
              },
            },
            series: [
              {
                name: "Ingress",
                data: list_ingress,
              },
              {
                name: "Egress",
                data: list_egress,
              },
            ],
          }));
        }
      })
      .catch((err) => {
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Network error");
        } else {
          message.error(err.message);
        }
      });

    const FetchDataForCrowdAnalysis = async () => {
      try {
        await axiosClient
          .get("crowdAnalysis/getCrowdAnalysisDetails")
          .then((response) => {
            if (response.data.issuccess === true) {
              setdata(response.data.data);
            }
          })
          .catch((err) => {
            console.log("errr", err);
            if (err.status === 0) {
              message.error("Server error");
            } else {
              message.error(err.message);
            }
          });
      } catch (err) {
        console.log("Error from try catch crowd analysis", err);
      }
    };

    try {
      FetchDataForCrowdAnalysis();
    } catch (err) {
      console.log("Error from try catch crowd analysis", err);
    }
  }, [HandleDateForIngressEgress.SelectedDate]);

  useEffect(() => {
    axiosClient
      .get("/notification/getEventBasedActiveCamera?eventid=" + 13)

      .then((response) => {
        if (response.data.issuccess === true) {
          setTotalcamera(response.data.onboardCameraDetails.count);
          setfootfallactivecamera(response.data.eventBasedCameraDetails.count);
        } else {
          console.log("error");
        }
      })
      .catch((err) => {
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  }, []);
  useEffect(() => {
    setFootfallDirectionHourlyController((prev) => ({
      ...prev,
      loading: true,
    }));
    axiosClient
      .get(
        `/livereport/getfootfalldirectionimage?date=${FootfallDirectionHourlyController.selectedDate}&camId=${FootfallDirectionHourlyController.selectedcam}&hour=${FootfallDirectionHourlyController.Hour}`
      )
      //${FootfallDirectionHourlyController.Hour}

      .then((response) => {
        if (response.data.issuccess === true) {
          const data = response.data.data.dwellData
            ? response.data.data.dwellData
            : [];

          const selectedcamDetails = response.data.data;
          const CameraList = response.data.data.cameraData;
          if (
            selectedcamDetails.selectedCameraData !== null &&
            selectedcamDetails.selectedCameraData !== undefined &&
            selectedcamDetails.selectedCameraData !== ""
          ) {
            setFootfallDirectionHourlyController((prev) => ({
              ...prev,
              selectedcam: selectedcamDetails.selectedCameraData.cameraId,

              selectedcamName: selectedcamDetails.selectedCameraData.cameraName,
            }));
          }

          if (
            selectedcamDetails.directionData !== null &&
            selectedcamDetails.directionData !== undefined &&
            selectedcamDetails.directionData !== ""
          ) {
            setFootfallDirectionHourlyController((prev) => ({
              ...prev,
              DirectionData: selectedcamDetails.directionData,
            }));
          }
          if (
            selectedcamDetails.directionImage !== null &&
            selectedcamDetails.directionImage !== undefined
          ) {
            setFootfallDirectionHourlyController((prev) => ({
              ...prev,
              DirectionImage: selectedcamDetails.directionImage,
            }));
          } else {
            setFootfallDirectionHourlyController((prev) => ({
              ...prev,
              DirectionImage: "",
            }));
          }

          if (
            selectedcamDetails.hour !== null &&
            selectedcamDetails.hour !== undefined &&
            selectedcamDetails.hour !== ""
          ) {
            setFootfallDirectionHourlyController((prev) => ({
              ...prev,
              Hour: selectedcamDetails.hour,
            }));
          }
          if (CameraList !== null && CameraList !== undefined) {
            const newCamList = CameraList.map((item) => ({
              value: item.cameraid,
              label: item.cameraname,
            }));

            setFootfallDirectionHourlyController((prev) => ({
              ...prev,
              camlist: [...newCamList],
            }));
          }
        } else {
          console.log("error engage data api call successfully");
        }
        setFootfallDirectionHourlyController((prev) => ({
          ...prev,
          loading: false,
        }));
      })
      .catch((err) => {
        setFootfallDirectionHourlyController((prev) => ({
          ...prev,
          loading: false,
        }));
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  }, [
    FootfallDirectionHourlyController.selectedcam,
    FootfallDirectionHourlyController.selectedDate,
    FootfallDirectionHourlyController.Hour,
  ]);
  const HandleSelectCameraDirection = (id) => {
    setFootfallDirectionHourlyController((prev) => ({
      ...prev,
      selectedcam: id,
    }));
  };
  const onChangeTimeDirection = (date, datestring) => {
    if (datestring) {
      setFootfallDirectionHourlyController((prev) => ({
        ...prev,
        selectedDate: moment(datestring).format("YYYY-MM-DD"),
      }));
    } else {
      setFootfallDirectionHourlyController((prev) => ({
        ...prev,
        selectedDate: "",
      }));
    }
  };

  const handleHourChangeFootfalldirection = (time, hour) => {
    setFootfallDirectionHourlyController((prev) => ({
      ...prev,
      Hour: hour,
    }));
  };

  const HandleDateselectIngressEgress = (date, datestring) => {
    if (date === null || date === undefined) {
      setHandleDateForIngressEgress((prev) => ({
        ContinueFetch: true,
        SelectedDate: "",
      }));
    } else {
      setHandleDateForIngressEgress((prev) => ({
        ContinueFetch: false,
        SelectedDate: datestring,
      }));
    }
  };
  const disabledTime = () => {
    return {
      disabledHours: () => {
        const currentHour =
          moment().format("YYYY-MM-DD") ===
          FootfallDirectionHourlyController.selectedDate
            ? moment().hours()
            : 24;
        return [...Array(24).keys()].slice(currentHour);
      },
    };
  };

  //-------------------

  const EngageAndBounceHourly = () => {
    return (
      <Col
        xs={{ span: 24 / 1, offset: 0 }}
        sm={{ span: 24 / 1, offset: 0 }}
        md={{ span: 24 / 1, offset: 0 }}
        lg={{ span: 24 / 1, offset: 0 }}
        xl={{ span: 24 / 1, offset: 0 }}
      >
        <Card
          style={{
            userSelect: "none",
            boxShadow: "none",
          }}
          title={
            <>
              <Row justify={"space-between"} align={"middle"}>
                <Col>
                  <Flex align="center" gap={10}>
                    <Title
                      level={5}
                      type="secondary"
                      style={{ fontFamily: "Helvetica" }}
                    >
                      Hourly Engaged and Bounced
                    </Title>
                    {/* <Tag>
                      {EngageBounceHourController.DataOfDate &&
                        EngageBounceHourController.DataOfDate}
                    </Tag> */}
                  </Flex>
                </Col>
                <Col>
                  <Row align="center" gutter={[10, 0]}>
                    <Col>
                      {/* <DatePicker
                        onChange={onChangeDateEngageAndBounceHourly}
                        format={"YYYY-MM-DD"}
                        disabledDate={(current) => {
                          return current && current > moment().endOf("day");
                        }}
                        defaultValue={dayjs(
                          EngageBounceHourController.DataOfDate
                            ? EngageBounceHourController.DataOfDate
                            : EngageBounceHourController.selectedDate
                        )}
                      /> */}
                    </Col>
                    <Col>
                      <Select
                        onChange={OnChangeCameraSelectEngageAndBounceHourly}
                        placeholder={<Text type="secondary">Camera</Text>}
                        value={EngageBounceHourController.selectedcam}
                        defaultValue={EngageBounceHourController.selectedcam}
                        style={{
                          width: 120,
                        }}
                        options={EngageBounceHourController.camlist}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>
          }
        >
          <Row gutter={[1, 10]} justify={"space-between"} align={"middle"}>
            <Col
              xs={{ span: 23 / 1, offset: 0 }}
              sm={{ span: 24 / 1, offset: 0 }}
              md={{ span: 11 / 1, offset: 0 }}
              lg={{ span: 11 / 1, offset: 0 }}
              xl={{ span: 11, offset: 0 }}
            >
              <div style={{ height: "250px" }}>
                <ReactApexChart
                  type="bar"
                  options={EngagedHourlyChart.options}
                  series={EngagedHourlyChart.series}
                  height="100%"
                  key={EngagedHourlyChart.series}
                />
              </div>
            </Col>
            <Divider type="vertical" style={{ height: "250px" }} />
            <Col
              xs={{ span: 24 / 1, offset: 0 }}
              sm={{ span: 24 / 1, offset: 0 }}
              md={{ span: 11 / 1, offset: 0 }}
              lg={{ span: 11 / 1, offset: 0 }}
              xl={{ span: 11, offset: 0 }}
            >
              <div style={{ height: "250px" }}>
                <ReactApexChart
                  type="bar"
                  options={BouncedHourlyChart.options}
                  series={BouncedHourlyChart.series}
                  height="100%"
                  key={BouncedHourlyChart.series}
                />
              </div>
            </Col>
          </Row>
        </Card>
      </Col>
    );
  };
  ////--------------------------------
  useEffect(() => {
    axiosClient
      .get(
        `/dwelltime/getDwellTimeHourData?date=${dayjs(
          EngageBounceHourController.selectedDate
        ).format("YYYY-MM-DD")}&camId=${EngageBounceHourController.selectedcam}`
      )

      .then((response) => {
        if (response.data.issuccess === true) {
          const data = response.data.data.dwellData
            ? response.data.data.dwellData
            : [];

          const selectedcamDetails = response.data.data.selectedCameraDetails;
          const CameraList = response.data.data.cameraData;
          if (selectedcamDetails) {
            setEngageBounceHourController((prev) => ({
              ...prev,
              selectedcam: selectedcamDetails.cameraId,
            }));
          }
          if (CameraList !== null && CameraList !== undefined) {
            const newCamList = CameraList.map((item) => ({
              value: item.cameraId,
              label: item.cameraName,
            }));

            setEngageBounceHourController((prev) => ({
              ...prev,
              camlist: [...newCamList],
            }));
          }

          if (data.length > 0 || data !== null || data !== undefined) {
            const zoneNames = extractZoneNames(data).sort();
            if (zoneNames.length > 0) {
              const { hours, seriesEngage, seriesBounce } =
                transformDataEngageAndBounce(data, zoneNames);
              const colors = generateColorPalette(
                zoneNames ? zoneNames.length : 0
              );

              setEngagedHourlyChart((prevState) => ({
                ...prevState,
                options: {
                  ...prevState.options,
                  xaxis: {
                    ...prevState.options.xaxis,
                    categories: hours,
                  },
                },
                series: seriesEngage,
              }));

              setBouncedHourlyChart((prevState) => ({
                ...prevState,
                options: {
                  ...prevState.options,
                  xaxis: {
                    ...prevState.options.xaxis,
                    categories: hours,
                  },
                },
                series: seriesBounce,
              }));

              setEngageBounceHourController((prev) => ({
                ...prev,
                DataOfDate: EngageBounceHourController.selectedDate,
              }));
            }
          }
        } else {
          console.log("error engage data api call successfully");
        }
      })
      .catch((err) => {
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  }, [
    EngageBounceHourController.selectedcam,
    EngageBounceHourController.selectedDate,
  ]);
  //------------------------
  const OnChangeCameraSelectEngageAndBounceHourly = (selectedcam) => {
    if (selectedcam !== null && selectedcam !== undefined) {
      setEngageBounceHourController((prev) => ({ ...prev, selectedcam }));
    }
  };

  // Onchange date function for engaged and bouncee hourly
  const onChangeDateEngageAndBounceHourly = (date, dateString) => {
    const datefrom_picker = moment(dateString, "YYYY-MM-DD");
    if (datefrom_picker._isValid) {
      setEngageBounceHourController((prev) => ({
        ...prev,
        selectedDate: dateString,
        selectedcam: null,
      }));
    }
  };
  //------------------------------------------
  const transformDataEngageAndBounce = (data, zoneNames) => {
    const hours = _.map(data, "hour");

    const seriesEngage = _.map(zoneNames, (zone) => {
      const Engage_avg = _.map(data, zone + ".engaged");
      const Final_Engage = Engage_avg.map((item) => Math.round(item));
      return {
        name: zone,
        data: Final_Engage,
      };
    });

    const seriesBounce = _.map(zoneNames, (zone) => {
      const Bounce_avg = _.map(data, zone + ".bounced");
      const Final_Bounce = Bounce_avg.map((item) => Math.round(item));
      return {
        name: zone,
        data: Final_Bounce,
      };
    });

    return { hours, seriesEngage, seriesBounce };
  };
  // Function to generate a color palette dynamically
  const generateColorPalette = (numberOfColors) => {
    const palette = [];
    if (
      numberOfColors > 0 &&
      numberOfColors !== null &&
      numberOfColors !== undefined
    ) {
      for (let j = 0; j < numberOfColors; j++) {
        palette.push(
          randomColor({
            luminosity: "dark",
            // hue: "blue",
          })
        );
      }
    }

    return palette;
  };

  const extractZoneNames = (data) => {
    return _(data)
      .flatMap(_.keys)
      .filter((key) => key.startsWith("Area"))
      .uniq()
      .value();
  };
  ///////=------------------------------------
  const DwellHourComp = () => {
    return (
      <Card
        // styles={{
        //   body: { maxHeight: "100%" }
        // }}
        style={{ width: "100%" }}
        title={
          <>
            <Row justify={"space-between"} align={"middle"}>
              <Col>
                <Title
                  level={5}
                  type="secondary"
                  style={{ fontFamily: "Helvetica" }}
                >
                  Dwell time per hour
                </Title>
                {/* <Tag>
                  {DwellHourlyController.DataOfDate &&
                    DwellHourlyController.DataOfDate}
                  {DwellHourlyController.selectedcameraname &&
                    " - " + DwellHourlyController.selectedcameraname}
                </Tag> */}
              </Col>
              <Col>
                <Row align="center" gutter={[10, 0]}>
                  <Col>
                    {/* <DatePicker
                      onChange={onChangeDateDwellHourly}
                      format={"YYYY-MM-DD"}
                      disabledDate={(current) => {
                        return current && current > moment().endOf("day");
                      }}
                      defaultValue={dayjs()}
                    /> */}
                  </Col>
                  <Col>
                    <Select
                      onChange={OnChangeCameraSelect}
                      defaultValue={DwellHourlyController.selectedcam}
                      style={{
                        width: 120,
                      }}
                      placeholder={<Text type="secondary">Camera</Text>}
                      options={DwellHourlyController.camlist}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        }
      >
        <ReactApexChart
          options={DwellHourly.options}
          series={DwellHourly.series}
          type="bar"
          height={"100%"}
        />
      </Card>
    );
  };
  const columns = [
    {
      title: <span style={{ color: "#000816" }}>Name</span>,
      dataIndex: "ingressegress",
      key: "ingressegress",
      align: "center",
    },
  ];
  useEffect(() => {
    axiosClient
      .get("/livereport/geteventbasedactivecameraformall")
      .then((response) => {
        if (response.data.issuccess === true) {
          console.log("response", response.data.data.totalCamera);
          console.log(
            "eventsbasedcamera3333333",
            response.data.data[0].eventDetail
          );
          if (response.data.data.length > 0) {
            console.log("eventsbasedcamera", response.data.data[0].eventDetail);
            const eventsbasedcamera = response.data.data[0].eventDetail;
            seteventbasedactivecamera(eventsbasedcamera);

            const totalcount = response.data.data[0].totalCamera;
            settotalfootfallactivecamera(totalcount);
          } else {
            const totalcount = 0;
            settotalfootfallactivecamera(totalcount);
          }
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  }, []);
  //-----------------------------------------
  useEffect(() => {
    axiosClient
      .get(
        `/dwelltime/getDwellTimeHourData?date=${DwellHourlyController.selectedDate}&camId=${DwellHourlyController.selectedcam}`
      )

      .then((response) => {
        if (response.data.issuccess === true) {
          const data = response.data.data.dwellData
            ? response.data.data.dwellData
            : [];

          const selectedcamDetails = response.data.data.selectedCameraDetails;
          const CameraList = response.data.data.cameraData;
          // if (selectedcamDetails) {
          //   setDwellHourlyController((prev) => ({
          //     ...prev,
          //     selectedcam: selectedcamDetails.cameraId,
          //     selectedcameraname: selectedcamDetails.cameraName,
          //   }));
          // }
          if (CameraList !== null && CameraList !== undefined) {
            const newCamList = CameraList.map((item) => ({
              value: item.cameraId,
              label: item.cameraName,
            }));
            //console.log("vinoth-------CAM", newCamList);

            setDwellHourlyController((prev) => ({
              ...prev,
              camlist: [...newCamList],
            }));
            // CameraList.map((item) => {
            //   let cam = {
            //     value: item.cameraId,
            //     label: item.cameraName,
            //   };

            //   setDwellHourlyController((prev) => ({
            //     ...prev,
            //     camlist: [...prev.camlist, cam],
            //   }));
            // });
          }

          if (data.length > 0 || data !== null || data !== undefined) {
            const zoneNames = extractZoneNames(data).sort();
            if (zoneNames.length > 0) {
              const { hours, series, seriesEngage, seriesBounce } =
                transformData(data, zoneNames);
              const colors = generateColorPalette(
                zoneNames ? zoneNames.length : 0
              );
              setDwellHourly((prevState) => ({
                ...prevState,
                options: {
                  ...prevState.options,
                  xaxis: {
                    ...prevState.options.xaxis,
                    categories: hours,
                  },
                },
                series: series,
              }));

              setDwellHourlyController((prev) => ({
                ...prev,
                DataOfDate: DwellHourlyController.selectedDate,
              }));
            }
          }
        } else {
          console.log("error engage data api call successfully");
        }
      })
      .catch((err) => {
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  }, [DwellHourlyController.selectedcam, DwellHourlyController.selectedDate]);

  //--------------------------------
  //---------------------------------
  const OnChangeCameraSelect = (cam) => {
    //console.log('Selected camera:', cam);

    const selectedCameraName =
      DwellHourlyController.camlist.find((item) => item.value === cam)?.label ||
      null;

    setDwellHourlyController((prev) => ({
      ...prev,
      selectedcam: cam,
      selectedcameraname: selectedCameraName,
    }));
  };
  // Onchange date function for Dwell hourly
  const onChangeDateDwellHourly = (date, dateString) => {
    if (dateString !== "") {
      setDwellHourlyController((prev) => ({
        ...prev,
        selectedDate: dateString,
        selectedcam: null,
      }));
    } else {
      setDwellHourlyController((prev) => ({
        ...prev,
        selectedDate: "",
        selectedcam: null,
      }));
    }
  };
  const [DwellHourly, setDwellHourly] = useState({
    options: {
      chart: {
        id: "basic-bar",
        type: "bar",
        stacked: true,
        toolbar: { show: false },
        contextmenu: { enabled: false },
        animations: {
          enabled: false,
        },
      },
      plotOptions: {
        bar: { horizontal: false },
      },
      xaxis: {
        categories: [],
        title: { text: "Hour ", offsetY: -10 },
      },
      yaxis: { title: { text: "Seconds" } },
      legend: {
        position: "top",
        horizontalAlign: "center",
        offsetX: 0,
      },
      colors: [
        "#00e396",
        "#feb019",
        "#ff4560",
        "#775dd0",
        "#008ffb",
        "#00d9e9",
        "#ff9f00",
      ],
      tooltip: {
        enabled: true,
        y: {
          formatter: function (val) {
            return val + " sec";
          },
        },
      },
    },
    series: [],
  });
  //------------------------------------------------
  const transformData = (data, zoneNames) => {
    const hours = _.map(data, "hour");
    const series = _.map(zoneNames, (zone) => {
      const DwellTime_avg = _.map(data, zone + ".average_dwell_time");
      const Final_Dwell = DwellTime_avg.map((item) => Math.round(item));
      return {
        name: zone,
        data: Final_Dwell,
      };
    });

    return { hours, series };
  };

  //ingress and passer by graph
  const [passerby, setPasserby] = useState({
    series: [
      {
        name: "Passer By",
        data: [],
      },
      {
        name: "Ingress",
        data: [],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 150,
        stacked: true,
        stackType: "100%",
        toolbar: {
          show: false, // Disable the toolbar
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: "100%",
        },
      },

      xaxis: {
        categories: ["Passer By and Ingress"],
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        show: false,
      },
      fill: {
        opacity: 1,
        //colors: ['#1E90FF', '#FFD700'] // Define colors for each segment
      },
      legend: {
        show: true,
      },
      title: {
        text: "", // Add the title here
        align: "center", // Align the title to the center
        style: {
          fontSize: "20px",
          fontWeight: "bold",
          color: "#263238",
        },
      },
    },
  });
  //------------------------
  useEffect(() => {
    PasserByNew();
    // getPasserByCount()
    // getOverallPasserByCount()
  }, []);
  
  function getOverallPasserByCount() {
    axiosClient
      .get("/livereport/getoverallpasserbycount")
      .then((response) => {
        if (response.data.issuccess === true) {
          // console.log(
          //   "getoverallpasserbycount count------------------",
          //   response.data.data.data
          // );
          const data = response?.data?.data?.data[0]; // Assuming there's only one object in the array
          const passerbyCount =
            data.passerby == null || data.passerby == undefined
              ? 0
              : parseInt(data.passerby);
          const ingressCount =
            data.ingress == null || data.ingress == undefined
              ? 0
              : parseInt(data.ingress);
          // parseInt(data.passerby);parseInt(data.ingress)

          const updatedSeries = [
            {
              name: "Passer By",
              data: [passerbyCount],
            },
            {
              name: "Ingress",
              data: [ingressCount],
            },
          ];

          const updatedOptions = {
            ...passerby.options,
            title: {
              ...passerby.options.title,
              text: `Total: ${passerbyCount + ingressCount}`,
            },
          };

          setPasserby({ series: updatedSeries, options: updatedOptions });
        }
      })
      .catch((err) => {
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  }

  
  const [PasserTableValue, setPasserTableValue] = useState("");

  //camera list for ingress & egress chart
  useEffect(() => {
    axiosClient
      .get("/livereport/getcameralistforingressegresslive")
      .then((response) => {
        console.log("response", response.data.data);
        setMenuOptions(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching menu options:", error);
      });
  }, []);

  //onclick of menu item call api
  const handleSelectChange = (value) => {
    console.log("handleSelectChange", value);
    const selectedOption = menuOptions.find((option) => option.id === value);
    axiosClient
      .get("/livereport/getingressegress?id=" + selectedOption.id)
      .then((response) => {
        if (response.data.issuccess === true) {
          setingressloading(false);

          const list_hour = map(response.data.data.total_records_hour, "hour");

          const list_ingress = map(
            response.data.data.total_records_hour,
            "totalingress"
          );
          const list_egress = map(
            response.data.data.total_records_hour,
            "totalegress"
          );
          setChartData((prev) => ({
            ...prev,
            options: {
              ...prev.options,
              xaxis: {
                // ...prev.options.xaxis,
                categories: list_hour,
              },
            },
            series: [
              {
                name: "Ingress",
                data: list_ingress,
              },
              {
                name: "Egress",
                data: list_egress,
              },
            ],
          }));
        }
      })
      .catch((err) => {
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  };

  function getPasserByCount() {
    axiosClient
      .get("/livereport/getpasserbycount?camId=&date=")
      .then((response) => {
        if (response.data.issuccess === true) {
          // console.log(
          //   "getpasserbycount count------------------",
          //   response.data.data.data
          // )
          // const updatedData = response.data.data.data
          //   .filter((item) => item.hour >= 7)
          //   .sort((a, b) => b.hour - a.hour) // Sort by hour in descending order
          //   .map((item) => ({
          //     hour: moment(item.hour, "HH").format("h A"),
          //     passerby: item.passerby || 0, // Set passerby to 0 if null, undefined, or empty
          //     ingress: item.ingress || 0, // Set ingress to 0 if null, undefined, or empty
          //   }))
          // console.log("TABLE--------------", updatedData)
          // setPasserTableValue(updatedData)
        }
      })
      .catch((err) => {
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  }
  const [PasserByCount, setPasserByCount] = useState([]);
  const PasserByNew = () => {
    axiosClient
      .get(
        `/livereport/getpasserbydetails?startDate=${dayjs().format(
          "YYYY-MM-DD"
        )}&endDate=${dayjs().format("YYYY-MM-DD")}&cameraId=`
      )
      .then((response) => {
        if (response.data.issuccess === true) {
          console.log(
            "getpasserbycount count------------------",
            response.data.data
          );
          setPasserByCount(response.data.data);
        }
      })
      .catch((err) => {
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  };

  //passer table column  ingressCount
  const PasserColumn = [
    {
      title: "Camera name",
      dataIndex: "cameraName",
      key: "cameraName",
      render: (text) => <span style={{ color: "black" }}>{text}</span>,
      align: "center",
    },
    {
      title: "Store",
      dataIndex: "location",
      key: "location",
      render: (text) => <span style={{ color: "black" }}>{text}</span>,
      align: "center",
    },
    {
      title: "Ingress count",
      dataIndex: "ingressCount",
      key: "ingressCount",
      render: (text) => <span style={{ color: "black" }}>{text}</span>,
      align: "center",
    },
    {
      title: "Passer by count",
      dataIndex: "passerByCount",
      key: "passerByCount",
      render: (text) => <span style={{ color: "black" }}>{text}</span>,
      align: "center",
    },
  ];
  const paginationConfig = {
    pageSize: 4,
    responsive: true,
  };
  const tableContainerStyle = {
    //overflowX: "auto",
   // maxWidth: "100%",
   width:"100%",
    height:window.innerWidth > 850 ?"22.5rem":"auto",
  };

  return (
    <div style={{ backgroundColor: "#fffff", marginBottom: "10rem" }}>
    <Row
      className="Base-row"
      gutter={[30, 20]}
      style={{
        margin: "10px",
        backgroundColor: "white",
        marginBottom: "30px",
      }}
    >
      {RenderCompController.IngressEgress && (
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Row gutter={[20, 20]}>
            <Col xs={24} sm={24} md={24} lg={14} xl={14}>
              <Row gutter={[20, 20]}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Card
                    className="ingress-hover"
                    style={{
                      cursor: "pointer",
                      userSelect: "none",
                      boxShadow: "none",
                      height: "auto",
                    }}
                    title={
                      <Row justify={"space-between"} align={"middle"}>
                        <Col>
                          <Title level={5} type="secondary" style={{ fontFamily: "Helvetica" }}>
                            Footfall
                          </Title>
                        </Col>
                      </Row>
                    }
                  >
                    <Col
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginTop: "2rem",
                        height: "6rem",
                      }}
                    >
                      <Col>
                        <Title
                          level={1}
                          style={{
                            fontWeight: "normal",
                            margin: 0,
                            padding: "5px",
                            fontFamily: "Helvetica",
                          }}
                        >
                          {totalingress !== null && totalingress !== undefined
                            ? totalingress
                            : 0}
                        </Title>
                        <Text style={{ backgroundColor: "#abd1b5" }}>
                          {totalingrespercentage !== null && totalingrespercentage !== undefined
                            ? totalingrespercentage > 100
                              ? "100"
                              : totalingrespercentage
                            : 0}
                          %
                        </Text>
                        <Text> Vs.PreviousDay</Text>
                      </Col>
                      <Col>
                        <GiFootsteps size={70} />
                      </Col>
                    </Col>
                  </Card>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Card
                    className="ingress-hover"
                    style={{
                      cursor: "pointer",
                      userSelect: "none",
                      boxShadow: "none",
                      height: "auto",
                    }}
                    title={
                      <Row justify={"space-between"} align={"middle"}>
                        <Col>
                          <Title level={5} type="secondary" style={{ fontFamily: "Helvetica" }}>
                            Peak Hours
                          </Title>
                        </Col>
                      </Row>
                    }
                  >
                    <Col
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginTop: "2rem",
                        height: window.innerHeight>850?"6rem":"auto",
                      }}
                    >
                      <Col>
                        <Text
                          style={{
                            fontWeight: "bold",
                            margin: 0,
                            padding: "5px",
                            fontFamily: "Helvetica",
                            fontSize: "29px",
                          }}
                        >
                          {peakHour !== null && peakHour !== undefined && peakHour !== "Invalid date - Invalid date"
                            ? peakHour
                            : "- -"}
                        </Text>
                        <Text>
                          Yesterday's Peak Hours{" "}
                          {yesterdaypeakHour !== null && yesterdaypeakHour !== undefined &&
                          yesterdaypeakHour !== "Invalid date - Invalid date"
                            ? yesterdaypeakHour
                            : "- -"}
                        </Text>
                      </Col>
                      <Col>
                        <ImHourGlass size={60} />
                      </Col>
                    </Col>
                  </Card>
                </Col>
              </Row>
              <Row gutter={[20, 20]} style={{ marginTop: "1rem" }}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Card
                    className="ingress-hover"
                    style={{
                      cursor: "pointer",
                      userSelect: "none",
                      boxShadow: "none",
                      height: "auto",
                    }}
                    title={
                      <Row
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Title
                          type="secondary"
                          level={5}
                          style={{ fontFamily: "Helvetica" }}
                        >
                          Total Onboard Camera :
                          <span style={{ marginLeft: "7px" }}>
                            {totalfootfallactivecamera}
                          </span>
                        </Title>
                        <div>
                          <GiCctvCamera size={40} />
                        </div>
                      </Row>
                    }
                  >
                    <Row gutter={[16, 16]}>
                      {eventbasedactivecamera && eventbasedactivecamera.length > 0 &&
                        eventbasedactivecamera.map((item, index) => (
                          <Col key={index} xs={24} sm={12} md={8} style={{height:"7rem"}}>
                            <Card
                              style={{
                                textAlign: "center",
                                borderRadius: "8px",
                                height: "105px",
                              }}
                            >
                              <Title
                                level={5}
                                style={{
                                  fontFamily: "Helvetica",
                                  fontWeight: "bold",
                                  margin: 0,
                                }}
                              >
                                {item.eventName}
                              </Title>
                              <Text
                                type="secondary"
                                style={{
                                  fontSize: "14px",
                                  marginTop: "10px",
                                  display: "block",
                                }}
                              >
                                Active Cameras: {item.cameraCount}
                              </Text>
                            </Card>
                          </Col>
                        ))}
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={24} md={24} lg={10} xl={10}>
              <Row gutter={[20, 20]}>
                <Col xs={24}>
                  <Card
                    style={{
                      cursor: "pointer",
                      userSelect: "none",
                      boxShadow: "none",
                      height: "auto",
                    }}
                    title={
                      <Row justify={"space-between"} align={"middle"}>
                        <Col>
                          <Title level={5} type="secondary" style={{ fontFamily: "Helvetica" }}>
                            Passerby Count
                          </Title>
                        </Col>
                      </Row>
                    }
                  >
                    <div style={tableContainerStyle}>
                      <Table
                      style={{width:"100%"}}
                        dataSource={PasserByCount}
                        columns={PasserColumn}
                        rowKey="cameraId"
                        pagination={paginationConfig}
                       scroll={{ y: "max-content" }}
                      />
                    </div>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={[20, 20]} style={{ marginTop: "1rem" }}>
            <Col xs={24}>
              <Card
                loading={ingressloading}
                style={{
                  cursor: "pointer",
                  userSelect: "none",
                  boxShadow: "none",
                }}
                title={
                  <Row justify={"space-between"} align={"middle"}>
                    <Col>
                      <Title level={5} type="secondary" style={{ fontFamily: "Helvetica",textWrap:"wrap",width:"80%" }}>
                        Ingress and Egress Counts per Hour
                      </Title>
                    </Col>
                    <Col>
                      <Select
                        onChange={handleSelectChange}
                        style={{
                          width: 120,
                        }}
                        placeholder={
                          <Text type="secondary">Camera</Text>
                        }
                      >
                        {menuOptions.map((option) => (
                          <Option key={option.id} value={option.id}>
                            {option.cameraName}
                          </Option>
                        ))}
                      </Select>
                    </Col>
                  </Row>
                }
              >
                {chartData.series && chartData.series.length > 0 ? (
                  <div className="app">
                    <div className="row">
                      <div className="mixed-chart">
                        <ReactApexChart
                          options={chartData.options}
                          series={chartData.series}
                          type="area"
                          height={"200%"}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div style={{ textAlign: "center", padding: "20px" }}>
                    <Text style={{ fontSize: "16px", color: "gray" }}>
                      No Data Available
                    </Text>
                  </div>
                )}
              </Card>
            </Col>
          </Row>
        </Col>
      )}
    </Row>
  </div>
  
  );
};

export default Live;
