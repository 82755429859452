// import logo from './logo.svg';
import "./App.css";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import Login_user from "./Components/Login/Login_user";

import ProtectedRoute from "./Components/Authentication/ProtectedRoute";
import MainDashboard from "./Components/Dashboard/MainDashboard";
import Dashboard from "./Components/Menu/Dashboard/Dashboard";
import Asset_onboarding from "./Components/Menu/Assets/Asset_onboarding";

import Home from "./Components/Menu/Home/Home";
import Reports from "./Components/Menu/Reports/Reports";

import Event_calendar from "./Components/Menu/Calendar/Event_calendar";
import CameraAccess from "./Components/Menu/CameraAccess/CameraAccess";
import ServerOnboarding from "./Components/Menu/ServerOnboarding/ServerOnboarding";
import AccountActivation from "./Components/Account/AccountActivation";
import ForgotPassword from "./Components/Account/ForgotPassword";
import EventSetup from "./Components/Menu/EventsSetup/EventSetup";
import NotificationControl from "./Components/Menu/NotificationControl/NotificationControl";
import authService from "./Components/Authentication/authService";
import ForgotEmail from "./Components/Account/ForgotEmail";
import BasePage from "./Components/Menu/TrainAssets/BasePage";

import CustomerSupport from "./Components/Menu/TechSupport/CustomerSupport";
import ReportControl from "./Components/Menu/EmailReports/ReportControl";
import UserAccess from "./Components/Menu/UserAccess/UserAccess";
import PolicyConfig from "./Components/Menu/PolicyConfig/PolicyConfig";
import AddUser from "./Components/Menu/UserMaster/AddUser";
import StoreOnboarding from "./Components/Menu/StoreOnboarding/StoreOnboarding";
import { useAtom } from "jotai";
import {
  PolicyDetailsJotai,
  Role,
  LoadingComp,
} from "./Components/Menu/Utils/StateExports";
import { FadeLoader } from "react-spinners";
import { Space } from "antd";
import Subscription from "./Components/Subscription/Subscription";
function App() {
  document.title = "KEN";
  window.addEventListener(
    "blur",
    () => (document.title = "Check for new updates*")
  );
  window.addEventListener("focus", () => (document.title = "KEN - AI"));

  if (!authService.isAuthenticateduser()) {
    authService.logout();
  }

  const [Loading, setLoading] = useAtom(LoadingComp);
  const [Rolecheck, setRoletest] = useAtom(Role);

  const [PolicyDetails, setPolicyDetails] = useAtom(PolicyDetailsJotai);
  const RouteList = [
    {
      path: "Streaming",
      route: <Route path="Home" element={<Home />} />,
    },
    {
      path: "Dashboard",
      route: <Route path="Dashboard" element={<Dashboard />} />,
    },
    {
      path: "Reports",
      route: <Route path="Reports" element={<Reports />} />,
    },
    {
      path: "Event Addition",
      route: <Route path="EventSetup" element={<EventSetup />} />,
    },
    {
      path: "Asset Onboarding",
      route: <Route path="Asset_onboarding" element={<Asset_onboarding />} />,
    },
    {
      path: "Event Calendar",
      route: <Route path="Calendar" element={<Event_calendar />} />,
    },
    {
      path: "Tech Support",
      route: <Route path="TechSupport" element={<CustomerSupport />} />,
    },
    {
      path: "OnBoarding Location",
      route: <Route path="StoreOnboarding" element={<StoreOnboarding />} />,
    },
    {
      path: "Policy Configuration",
      route: <Route path="PolicyConfig" element={<PolicyConfig />} />,
    },
    {
      path: "UserMaster",
      route: <Route path="UserMaster" element={<AddUser />} />,
    },
    {
      path: "Subscription",
      route: <Route path="Subscription" element={<Subscription />} />,
    },
    {
      path: "ServerOnboarding",
      route: <Route path="ServerOnboarding" element={<ServerOnboarding />} />,
    },
  ].filter((child) =>
    Rolecheck === "Admin" ? true : PolicyDetails.includes(child.path)
  );

  return (
    <BrowserRouter>
      {Loading ? (
        <Space className="FullpageLoading">
          <FadeLoader loading={Loading} color="#0b36f4" />
        </Space>
      ) : (
        <Routes>
          {/* <Route path={['/',"/login"]} element={<Login_user />} /> */}

          <Route path="/Login" element={<Login_user />} />

          {/* <Route path="PasswordChange:token" element={<ForgotPassword />} />
          <Route path="Forgotpassword" element={<ForgotEmail />} /> */}
          <Route path="Forgotpassword" element={<ForgotEmail />} />

          <Route path="Password">
            {/* <Route path=":token" element={<ForgotPassword />} /> */}
          </Route>
          <Route path="PasswordChange" element={<ForgotPassword />} />

          <Route path="Account">
            <Route path="Activate:token" element={<AccountActivation />} />
            <Route path=":token" element={<AccountActivation />} />

            {/* <Route path="PasswordChange" element={<ForgotPassword />} /> */}
            {/* <Route path={`${}/`}></Route> */}
          </Route>

          <Route
            path="/Admin"
            element={
              // <ProtectedRoute roles={["Admin", "Super Admin"]}>
              <ProtectedRoute roles={["Admin"]}>
                <MainDashboard />
              </ProtectedRoute>
            }
          >
            {RouteList.map((routes) => routes.route)}
          </Route>

          <Route
            path="/security"
            element={
              <ProtectedRoute roles={"Security"}>
                <MainDashboard />
              </ProtectedRoute>
            }
          >
            {RouteList.map((routes) => routes.route)}
          </Route>

          <Route
            path="/manager"
            element={
              <ProtectedRoute roles={"Manager"}>
                <MainDashboard />
              </ProtectedRoute>
            }
          >
            {RouteList.map((routes) => routes.route)}
          </Route>

          <Route
            path="/management"
            element={
              <ProtectedRoute roles={"Management"}>
                <MainDashboard />
              </ProtectedRoute>
            }
          >
            {RouteList.map((routes) => routes.route)}
          </Route>

          <Route
            path="/Supervisor"
            element={
              <ProtectedRoute roles={"Supervisor"}>
                <MainDashboard />
              </ProtectedRoute>
            }
          >
            {RouteList.map((routes) => routes.route)}
          </Route>
          <Route
            path="/user"
            element={
              <ProtectedRoute roles={"user"}>
                <MainDashboard />
              </ProtectedRoute>
            }
          >
            {RouteList.map((routes) => routes.route)}
          </Route>

          <Route path="*" element={<Navigate to="/Login" />} />
        </Routes>
      )}
    </BrowserRouter>
  );
}

export default App;
