import React, { useEffect } from "react";
import { Route, Navigate, useRoutes } from "react-router-dom";
import authService from "../Authentication/authService";
import AuthService from "../Authentication/authService";

// returns to the repective page based on the roles
function ProtectedRoute({ children, roles, ...rest }) {
  if (AuthService.isAuthenticateduser()) {
    const userRoles = AuthService.getCurrentUserRole();
    const authuserr = authService.isAuthenticateduser();

    if (
      (roles != undefined || roles != null || roles != "") &&
      userRoles != null
    ) {
      if (roles.includes(userRoles) && authService.isAuthenticateduser()) {
        return children;
      } else {
        return <Navigate to="/Login" replace />;
      }
    } else {
      return <Navigate to="/Login" replace />;
    }
  } else {
    return <Navigate to="/Login" replace />;
  }
}

export default ProtectedRoute;
