import React, { useState, useEffect } from "react";
import {
  MinusOutlined,
  LockOutlined,
  DownOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import {
  Label,
  Input,
  Select,
  Space,
  Cascader,
  Form,
  Checkbox,
  message,
  Tooltip,
  Typography,
  Modal,
  Table,
  Tag,
  Empty,
  Alert,
} from "antd";

import Marquee from "react-fast-marquee";

import axiosClient from "../../Authentication/ApiCall";
import { SettingOutlined } from "@ant-design/icons";
import { InputNumber } from "antd";
import { Button, Radio } from "antd";
import { Row, Col, Card, Switch, DatePicker } from "antd";
import { createFromIconfontCN } from "@ant-design/icons";
import { Layout, Menu, Breadcrumb } from "antd";
import { PageHeader } from "antd";
import { Image } from "antd";
import ClipLoader from "react-spinners/ClipLoader";
import authService from "../../Authentication/authService";
import _, { values } from "lodash";
const { Link, Title, Text } = Typography;
const { Option } = Select;
const PolicyConfig = () => {
  const [form] = Form.useForm();
  const [editform] = Form.useForm();
  const [RegForm] = Form.useForm();
  const [modal, setModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [notificationEvent, setNotificationEvent] = useState(false);
  const [events, setEvents] = useState([]);
  const [data, setdata] = useState([]);

  const [ReasonModal, setReasonModal] = useState(false);
  const [editModal, seteditModal] = useState(false);
  const [Deleterecord, setDeleterecord] = useState(null);
  const [AddRefresh, setAddRefresh] = useState(1);
  const [editrecord, seteditrecord] = useState(null);
  const [Pageload, setPageload] = useState(false);
  const [streamingModelIds, setStreamingModelIds] = useState("");
  const [assetOnboardingModelIds, setAssetOnboardingModelIds] = useState("");
  const [mapid, setmapid] = useState("");

  const [onboardLocationid, setonboardLocationid] = useState("");
  const [eventadditionid, seteventadditionid] = useState("");
  const [emailReportid, setemailreportid] = useState("");
  const [dashboardid, setdashboardid] = useState("");
  const [notificationid, setnotificationid] = useState("");
  const [telegramid, settelegramid] = useState("");
  const [calenderid, setcalenderid] = useState("");
  console.log("calenderid", calenderid);
  const [techsupportid, settechsupportid] = useState("");
  const [storeonboardingid, setstoreonboardingid] = useState("");
  const [eventReportid, seteventreportid] = useState("");
  const [selectedModels, setSelectedModels] = useState([]);
  const [initialValues, setInitialValues] = useState({});
  const [isSelectDisabled, setIsSelectDisabled] = useState(true);
  const pageSize = 10;

  const handlecreateeventclose = () => {
    setModal(false);
    form.resetFields();
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  const onclickmodal = () => {
    setModal(true);
    form.resetFields();
  };
  const handleclose = () => {
    seteditModal(false);
  };
  const paginationConfig = {
    current: currentPage,
    pageSize: pageSize,
    total: data.length,
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
    onChange: (page) => setCurrentPage(page),
  };

  useEffect(() => {
    console.log("deletedrecord", Deleterecord);
  }, Deleterecord);

  //Get policy config Table data
  useEffect(() => {
    axiosClient
      .get("/policy/getpolicydetails")
      .then((response) => {
        if (response.data.issuccess === true) {
          setdata(response.data.data);
        }
      })
      .catch((err) => {
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  }, [AddRefresh]);

  //Delete Record
  const onDeleteRecord = (items) => {
    console.log("onDeleteRecorditems", items.id);
    // setReasonModal(false);
    Modal.confirm({
      title: (
        <div>
          <h3>Confirm Delete?</h3>
          <h4>
            {" "}
            The policy will be removed from all users who are associated with
            it.
          </h4>
        </div>
      ),

      okText: "Confirm",
      okType: "danger",
      onOk: () => {
        console.log("onok for delete");
        axiosClient
          .delete("/policy/deletepolicydetail?id= " + items.id)
          .then((response) => {
            if (response.data.issuccess === true) {
              message.success(response.data.msg);
              setAddRefresh(AddRefresh + 1);
            } else if (response.data.issuccess === false) {
              console.log("error");
              setAddRefresh(AddRefresh + 1);
            }
          })
          .catch((err) => {
            console.log("errr", err);
            if (err.status === 0) {
              message.error("Server error");
              setAddRefresh(AddRefresh + 1);
            } else {
              message.error(err.msg);
              setAddRefresh(AddRefresh + 1);
            }
          });
      },
    });
    form.resetFields();
  };
  const [formValues, setFormValues] = useState({
    policyName: "",
    eventName: [],
    onBoardLocation: [],
    streaming: false,
    eventSetUp: false,
    dashboard: false,
    assertOnBoard: false,
    report: false,
    telegram: false,
    sendReportToEmail: false,
    eventCalender: false,
    map: false,
    techSupport: false,
  });
  const onEditRecord = (item) => {
    console.log("onEditRecord - item:", item);
    seteditModal(true);

    // Extract event names from the enabled object
    const eventNamesArray = item.enabled.eventName || [];
    console.log("Event Names Array:", eventNamesArray);

    const fieldMap = {
      streaming: streamingModelIds,
      eventSetUp: eventadditionid,
      dashboard: dashboardid,
      assertOnBoard: assetOnboardingModelIds,
      report: eventReportid,
      telegram: telegramid,
      sendReportToEmail: emailReportid,
      eventCalender: calenderid,
      map: mapid,
      notification: notificationid,
      techSupport: techsupportid,
    };

    const newFormValues = {
      policyName: item.policyName,
      eventName: eventNamesArray,
      onBoardLocation: item.onBoardLocation || [],
    };

    Object.keys(fieldMap).forEach((fieldName) => {
      const id = fieldMap[fieldName];
      newFormValues[fieldName] = item.enabled.id.includes(id);
    });

    console.log("New Form Values:", newFormValues);
    setFormValues(newFormValues);
  };

  useEffect(() => {
    console.log("useEffect - editModal:", editModal);
    console.log("useEffect - formValues:", formValues);

    if (editModal) {
      // Update form values when editModal or formValues change
      editform.setFieldsValue(formValues);
      console.log("Form Values Set:", formValues);
    }
  }, [editModal, formValues]);

  // const onEditRecord = (item) => {
  //   console.log("onEditRecorditem77777777", item);
  //   seteditModal(true);

  //   // Extract event names from the enabled object
  //   const eventNamesArray = item.enabled.eventName || [];

  //   const fieldMap = {
  //     streaming: streamingModelIds,
  //     eventSetUp: eventadditionid,
  //     dashboard: dashboardid,
  //     assertOnBoard: assetOnboardingModelIds,
  //     report: eventReportid,
  //     telegram: telegramid,
  //     sendReportToEmail: emailReportid,
  //     eventCalender: calenderid,
  //     map: mapid,
  //     techSupport: techsupportid,
  //   };

  //   const formValues = {
  //     policyName: item.policyName,
  //     eventName: eventNamesArray, // Set the event names array
  //     onBoardLocation: item.onBoardLocation || [], // Ensure it's an array
  //   };

  //   // Set boolean values for fields based on fieldMap
  //   Object.keys(fieldMap).forEach((fieldName) => {
  //     const id = fieldMap[fieldName];
  //     formValues[fieldName] = item.enabled.id.includes(id);
  //   });

  //   console.log("Form Values Before Setting:", formValues);

  //   // Set form values
  //   editform.setFieldsValue(formValues);

  //   // Log form values after setting
  //   setTimeout(() => {
  //     console.log("Form Values After Setting:", editform.getFieldsValue());
  //   }, 0); // Use setTimeout to ensure the form has time to update

  //   // Set initial values
  //   setInitialValues(formValues);
  // };

  // useEffect(() => {
  //   if (editModal) {
  //     // Update form values when editModal or formValues change
  //     editform.setFieldsValue(formValues);
  //   }
  // }, [editModal, formValues]);

  // const onEditRecord = (item) => {
  //   console.log("onEditRecorditem77777777", item);
  //   seteditModal(true);

  //   // Extract event names from the enabled object
  //   const eventNamesArray = item.enabled.eventName || [];

  //   const fieldMap = {
  //     streaming: streamingModelIds,
  //     eventSetUp: eventadditionid,
  //     dashboard: dashboardid,
  //     assertOnBoard: assetOnboardingModelIds,
  //     report: eventReportid,
  //     telegram: telegramid,
  //     sendReportToEmail: emailReportid,
  //     eventCalender: calenderid,
  //     map: mapid,
  //     techSupport: techsupportid,
  //   };

  //   const formValues = {
  //     policyName: item.policyName,
  //     eventName: eventNamesArray, // Set the event names array
  //     onBoardLocation: item.onBoardLocation,
  //   };

  //   Object.keys(fieldMap).forEach((fieldName) => {
  //     const id = fieldMap[fieldName];
  //     formValues[fieldName] = item.enabled.id.includes(id);
  //   });
  //   console.log("Edit Form Instance:", editform);

  //   editform.setFieldsValue(formValues);
  //   console.log("Form Values After Setting:", editform.getFieldsValue());

  //   setInitialValues(formValues);
  // };

  const onFinishedit = (values) => {
    const changedFields = {};
    Object.keys(values).forEach((key) => {
      if (values[key] !== initialValues[key]) {
        changedFields[key] = values[key];
      }
    });

    const fieldMap = {
      streaming: streamingModelIds,
      eventSetUp: eventadditionid,
      dashboard: dashboardid,
      assertOnBoard: assetOnboardingModelIds,
      report: eventReportid,
      telegram: telegramid,
      sendReportToEmail: emailReportid,
      eventCalender: calenderid,
      map: mapid,
      techSupport: techsupportid,
      // onBoardLocation: storeonboardingid,
    };

    const trueFieldIds = [];

    Object.keys(values).forEach((fieldName) => {
      if (fieldName in fieldMap && values[fieldName]) {
        trueFieldIds.push(fieldMap[fieldName]);
      }
    });

    const updatedData = {
      trueFieldIds: trueFieldIds,
    };

    axiosClient
      .put("", {
        updatedData,
        id: editrecord.id,
        policyName: values.policyName,
      })
      .then((response) => {
        console.log("Update successful", response.data);
      })
      .catch((error) => {
        console.error("Error updating record", error);
      });
  };

  useEffect(() => {
    axiosClient
      .get("/policy/getmodeldetails")
      .then((response) => {
        if (response.data.issuccess === true) {
          const { data } = response.data;

          const streamingModelValue = data.find(
            (item) => item.model === "Streaming"
          )?.id;
          console.log("streamingModelValue555555", streamingModelValue);
          setStreamingModelIds(streamingModelValue);

          const assetOnboardingIds = data.find(
            (item) => item.model === "Asset Onboarding"
          )?.id;
          setAssetOnboardingModelIds(assetOnboardingIds);

          const mapid = data.find((item) => item.model === "Map")?.id;
          setmapid(mapid);

          const Eventaddition = data.find(
            (item) => item.model === "Event Addition"
          )?.id;

          seteventadditionid(Eventaddition);

          const Reports = data.find((item) => item.model === "Reports")?.id;
          seteventreportid(Reports);

          const Calender = data.find(
            (item) => item.model === "Event Calendar"
          )?.id;
          setcalenderid(Calender);

          const storeonboardid = data.find(
            (item) => item.model === "OnBoarding Location"
          )?.id;
          setstoreonboardingid(storeonboardid);

          const emailreportid = data.find(
            (item) => item.model === "Send Report To Email"
          )?.id;
          setemailreportid(emailreportid);

          const techsupportid = data.find(
            (item) => item.model === "Tech Support"
          )?.id;
          settechsupportid(techsupportid);

          const telegramid = data.find((item) => item.model === "Telegram")?.id;
          settelegramid(telegramid);

          const dashboardid = data.find(
            (item) => item.model === "Dashboard"
          )?.id;
          setdashboardid(dashboardid);

          const notificationid = data.find(
            (item) => item.model === "Notification"
          )?.id;

          setnotificationid(notificationid);

          const StoreOnBoarding = data.find(
            (item) => item.model === "Store OnBoarding"
          )?.id;
          setonboardLocationid(StoreOnBoarding);

          console.log("streamingIds", streamingModelValue);
          console.log("assetOnboardingIds", assetOnboardingIds);
        } else {
          console.log("Error fetching event list");
        }
      })
      .catch((err) => {
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  }, []);
  // const onFinish = (values) => {
  //   console.log("Form Values:", values);

  //   // Check for duplicates in selectedModels
  //   const uniqueSelectedModels = Array.from(
  //     new Set(selectedModels.map((model) => model.id))
  //   ).map((id) => selectedModels.find((model) => model.id === id));

  //   console.log("Unique Selected Models:", uniqueSelectedModels);

  //   const selectedModelIds = uniqueSelectedModels.map((model) => model.id);
  //   console.log("selectedModelIds", selectedModelIds);

  //   // Check if at least one ID is present
  //   if (selectedModelIds.length === 0) {
  //     message.error("Please select any of the modal.");
  //     return;
  //   }

  //   const eventId =
  //     values.eventId !== undefined && values.eventId !== null
  //       ? values.eventId
  //       : [];
  //   console.log("eventId", eventId);

  //   axiosClient
  //     .post("/policy/addpolicydetails", {
  //       policyName: values.policyName,
  //       id: selectedModelIds, // This should now contain unique IDs
  //       eventId: eventId,
  //       onBoardLocation: values.onboardLocation,
  //     })
  //     .then((response) => {
  //       if (response) {
  //         setAddRefresh(AddRefresh + 1);
  //         form.resetFields();
  //         setModal(false);
  //         message.success(response.data.msg);
  //       } else {
  //         message.error(response.data.msg);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("errr", err);
  //       if (err.status === 0) {
  //         message.error("Server error");
  //       } else {
  //         message.error(err.msg);
  //       }
  //     });
  // };

  const onFinish = (values) => {
    console.log("Form Values:", values);

    // Ensure at least one of 'dashboard' or 'report' is selected
    if (!values.dashboard && !values.report) {
      message.error(
        "Please select at least one of 'Dashboard Event' or 'Report Events'."
      );
      return;
    }

    // Check for duplicates in selectedModels
    const uniqueSelectedModels = Array.from(
      new Set(selectedModels.map((model) => model.id))
    ).map((id) => selectedModels.find((model) => model.id === id));

    console.log("Unique Selected Models:", uniqueSelectedModels);

    const selectedModelIds = uniqueSelectedModels.map((model) => model.id);
    console.log("selectedModelIds", selectedModelIds);

    // Check if at least one ID is present
    // if (selectedModelIds.length === 0) {
    //   message.error("Please select any of the models.");
    //   return;
    // }

    const eventId =
      values.eventId !== undefined && values.eventId !== null
        ? values.eventId
        : [];
    console.log("eventId", eventId);

    axiosClient
      .post("/policy/addpolicydetails", {
        policyName: values.policyName,
        id: selectedModelIds,
        eventId: eventId,
        onBoardLocation: values.onboardLocation,
      })
      .then((response) => {
        if (response.data.issuccess == true) {
          setSelectedModels([]);
          setAddRefresh(AddRefresh + 1);
          form.resetFields();
          setModal(false);
          message.success(response.data.msg);
        } else {
          message.error(response.data.msg);
        }
      })
      .catch((err) => {
        message.error(err.msg);
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          // message.error(err.msg);
        }
      });
  };

  const [switchStates, setSwitchStates] = useState({
    notification: false,
    dashboard: false,
    report: false,
    eventCalender: false,
  });

  const updateSwitchStates = (checked, field) => {
    const newSwitchStates = { ...switchStates, [field]: checked };
    setSwitchStates(newSwitchStates);

    // Enable the Select dropdown if any switch is "Yes", otherwise disable it
    const anySwitchOn = Object.values(newSwitchStates).some(
      (value) => value === true
    );
    setIsSelectDisabled(!anySwitchOn);

    // If the Select dropdown is disabled, remove the value from the form
    if (!anySwitchOn) {
      form.setFieldsValue({ eventId: [] });
    }
  };

  const enableSelectIfNecessary = (checked) => {
    if (checked) {
      setIsSelectDisabled(false);
    } else {
      setIsSelectDisabled(true);
    }
  };
  const handleSwitchChange = (checked, model) => {
    if (checked) {
      setSelectedModels((prevModels) => [...prevModels, model]);
    } else {
      setSelectedModels((prevModels) =>
        prevModels.filter((prevModel) => prevModel.id !== model.id)
      );
    }
  };

  const checkPolicyName = (Text) => {
    console.log("text", Text);
    let list = data && _.map(data, "policyName");
    console.log("list", list);
    let checklist = _.includes(list, Text);
    console.log("dataaa", checklist);
    if (checklist) {
      return Promise.reject("Policy name is already exists");
    } else {
      return Promise.resolve();
    }
  };
  const [onBoardlocationDropdown, setonBoardlocationDropdown] = useState([]);
  useEffect(() => {
    axiosClient
      .get("/storeonboard/getstoreonboarddetails")
      .then((response) => {
        if (response.data.issuccess == true) {
          const data = response.data.data.map((item) => item.onBoardLocation);

          setonBoardlocationDropdown(data);
        } else {
          message.info("No store details available.");
        }
      })
      .catch((err) => {
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  }, []);

  const [isLocationEnabled, setIsLocationEnabled] = useState(false);
  const updateonboardLocation = (checked, { id, model }) => {
    if (!checked) {
      form.setFieldsValue({ location: [] });
      setIsLocationEnabled(false);
      setIsSelectDisabled(true);
      setEvents([]);
      form.setFieldsValue({ eventId: [] });
    } else {
      setIsLocationEnabled(true);
    }
  };

  const handleLocationChange = (selectedLocations) => {
    console.log("Selected locations:", selectedLocations);

    // Reset the eventId field when the onboardLocation changes
    form.setFieldsValue({
      eventId: [],
    });
    setEvents([]);

    axiosClient
      .post("/policy/geteventlistbasedonstore", {
        onboardLocation: selectedLocations,
      })
      .then((response) => {
        if (response.data.issuccess === true) {
          let eventOptions = response.data.data.map((item) => ({
            label: item.eventName,
            value: item.id,
          }));

          setEvents(eventOptions);
          console.log("Fetched events:", events);
        } else {
          message.error(response.data.msg);
        }
      })
      .catch((err) => {
        console.log("Error fetching location details:", err);
        message.error(err.msg);
      });
  };
  const oncancelcard = () => {
    setModal(false);
    form.resetFields();
  };

  return (
    <>
      <div>
        <div
          className="report-content"
          style={{
            backgroundColor: "#000816",
            width: "100%",
            margin: 0,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            overflowX: "hidden",
          }}
        >
          {/* First Row */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginLeft: "3%",
              padding: "10px",
              marginTop: "10px",
            }}
          >
            <p
              style={{
                color: "white",
                fontSize: "21px",
                // fontFamily: "revert-layer",
              }}
            >
              Policy Configuration
            </p>
            <div
              style={{ display: "flex", marginRight: "3%", userSelect: "none" }}
            >
              <Button
                style={{ userSelect: "none" }}
                className="adduser"
                onClick={onclickmodal}
              >
                + New
              </Button>
            </div>
          </div>
        </div>

        <Row
          gutter={[10, 10]}
          align={"middle"}
          justify={"center"}
          style={{ marginTop: "18px" }}
        >
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            lg={{ span: 24, offset: 0 }}
            xl={{ span: 23, offset: 0 }}
            xxl={{ span: 23, offset: 0 }}
          >
            {data.length > 0 ? (
              <Row gutter={[6, 6]}>
                {data.map((items) => (
                  <Col
                    xs={{ span: 14, offset: 1 }}
                    sm={{ span: 14, offset: 1 }}
                    md={{ span: 7, offset: 0 }}
                    lg={{ span: 5, offset: 0 }}
                    xl={{ span: 4, offset: 0 }}
                    xxl={{ span: 4, offset: 0 }}
                  >
                    <Card
                      hoverable
                      title={items.policyName}
                      styles={{
                        title: { textAlign: "center" },
                        body: { padding: 0 },
                        height: "300px",
                      }}
                    >
                      <div
                        className="border-line"
                        style={{ borderBottom: "1px solid #d9d0d0" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "0 16px",
                          }}
                        >
                          <Button
                            type="text"
                            // disabled
                            // icon={<EditOutlined />}
                            icon={<EyeOutlined />}
                            onClick={() => {
                              seteditrecord(items);
                              onEditRecord(items);
                            }}
                          />
                          <Button
                            type="text"
                            icon={<DeleteOutlined style={{ color: "red" }} />}
                            onClick={() => {
                              setDeleterecord(items);
                              onDeleteRecord(items);
                            }}
                          />
                        </div>
                      </div>
                    </Card>
                  </Col>
                ))}
              </Row>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "230px",
                }}
              >
                <Empty />
              </div>
            )}
          </Col>
        </Row>

        {/* //adding policy config field */}
        <Modal
          className="modal-antd"
          destroyOnClose
          centered
          open={modal}
          // style={{height:"800px"}}
          onCancel={() => oncancelcard()}
          title={
            <>
              <Row align={"middle"} justify={"center"}>
                <Col>
                  <Text className="Modal-Header">Policy Configuration</Text>
                </Col>
              </Row>
            </>
          }
          footer={
            <>
              <Row justify={"space-around"}>
                <Col>
                  {authService.getCurrentUserReadOnly() ? (
                    <Tooltip title="you need permission to submit">
                      <Button type="primary" block disabled>
                        Submit
                      </Button>
                    </Tooltip>
                  ) : (
                    <Button
                      className="primary-button"
                      form="submitpolicyForm"
                      type="primary"
                      block
                      htmlType="submit"
                    >
                      Submit
                    </Button>
                  )}
                </Col>
                <Col>
                  <Button onClick={() => handlecreateeventclose()}>
                    Cancel
                  </Button>
                </Col>
              </Row>
            </>
          }
        >
          <div style={{ marginTop: "20px" }}>
            <Form
              form={form}
              id="submitpolicyForm"
              name="basic"
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 22,
              }}
              onFinish={onFinish}
              // onFinishFailed={onFinishFailed}
              autoComplete={"off"}
            >
              <Form.Item
                // className="top-space"
                labelCol={{
                  span: 9,
                }}
                wrapperCol={{
                  span: 12,
                }}
                label="Policy Name"
                name="policyName"
                rules={[
                  {
                    required: true,
                    message: "Please enter your Policy Name!",
                  },
                  {
                    validator: (_, Values) => checkPolicyName(Values),
                  },
                ]}
              >
                <Input
                  placeholder="Policy Name"
                  // onChange={(text) => checkPolicyName(text)}
                />
              </Form.Item>
              <Form.Item
                labelCol={{
                  span: 9,
                }}
                wrapperCol={{
                  span: 12,
                }}
                // className="top-space"
                label="Onboarding Location"
                name="onboardLocation"
                // initialValue={false}
                rules={[
                  {
                    required: true,
                    message: "Please select Onboarding Location!",
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  placeholder="Select OnBoard location"
                  onChange={handleLocationChange}
                >
                  {onBoardlocationDropdown.map((location, index) => (
                    <Option key={index} value={location}>
                      {location}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                labelCol={{
                  span: 9,
                }}
                wrapperCol={{
                  span: 12,
                }}
                label="Events"
                name="eventId"
                initialValue={[]}
                rules={[
                  {
                    required: true,
                    message: "Please select Events!",
                  },
                ]}
              >
                <Select mode="multiple" placeholder="Event" options={events} />
              </Form.Item>

              <Row>
                <Col span={15}>
                  {" "}
                  <Form.Item
                    labelCol={{
                      span: 14,
                    }}
                    wrapperCol={{
                      span: 12,
                    }}
                    label="Dashboard Event"
                    name="dashboard"
                    initialValue={false}
                    rules={[
                      {
                        required: false,
                        message: "Please select!",
                      },
                    ]}
                  >
                    <Switch
                      size="default"
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      onChange={(checked) => {
                        // setNotificationEvent(checked);
                        // enableSelectIfNecessary(checked);
                        updateSwitchStates(checked, "dashboard");
                        handleSwitchChange(checked, {
                          id: dashboardid,
                          model: "Dashboard",
                        });
                      }}
                    ></Switch>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    labelCol={{
                      span: 22,
                    }}
                    wrapperCol={{
                      span: 2,
                    }}
                    // className="top-space"
                    label="Report Events"
                    name="report"
                    initialValue={false}
                    rules={[
                      {
                        required: false,
                        message: "Please select!",
                      },
                    ]}
                  >
                    <Switch
                      size="default"
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      onChange={(checked) => {
                        // setNotificationEvent(checked);
                        // enableSelectIfNecessary(checked);
                        updateSwitchStates(checked, "report");
                        handleSwitchChange(checked, {
                          id: eventReportid,
                          model: "Reports",
                        });
                      }}
                    ></Switch>
                  </Form.Item>
                  {/* <Form.Item
                    labelCol={{
                      span: 22,
                    }}
                    wrapperCol={{
                      span: 2,
                    }}
                    // className="top-space"
                    label="Notification"
                    name="notification"
                    initialValue={false}
                    rules={[
                      {
                        required: false,
                        message: "Please select!",
                      },
                    ]}
                  >
                    <Switch
                      size="default"
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      onChange={(checked) => {
                        // setNotificationEvent(checked);
                        // enableSelectIfNecessary(checked);
                        updateSwitchStates(checked, "notification");
                        handleSwitchChange(checked, {
                          id: notificationid,
                          model: "Notification",
                        });
                      }}
                    />
                  </Form.Item> */}
                </Col>
              </Row>

              <Row>
                <Col span={15}>
                  <Form.Item
                    labelCol={{
                      span: 14,
                    }}
                    wrapperCol={{
                      span: 12,
                    }}
                    // className="top-space"
                    label="Asset Onboarding"
                    name="assertOnBoard"
                    initialValue={false}
                    rules={[
                      {
                        required: false,
                        message: "Please select!",
                      },
                    ]}
                  >
                    <Switch
                      size="default"
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      onChange={(checked) =>
                        handleSwitchChange(checked, {
                          id: assetOnboardingModelIds,
                          model: "Asset Onboarding",
                        })
                      }
                    ></Switch>
                  </Form.Item>
                </Col>
                {/* <Col span={6}>
                  <Form.Item
                    labelCol={{
                      span: 22,
                    }}
                    wrapperCol={{
                      span: 2,
                    }}
                    // className="top-space"
                    label="Calender"
                    name="eventCalender"
                    initialValue={false}
                    rules={[
                      {
                        required: false,
                        message: "Please select!",
                      },
                    ]}
                  >
                    <Switch
                      size="default"
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      onChange={(checked) => {
                        // setNotificationEvent(checked);
                        // enableSelectIfNecessary(checked);
                        updateSwitchStates(checked, "eventCalender");
                        handleSwitchChange(checked, {
                          id: calenderid,
                          model: "Event Calender",
                        });
                      }}
                    ></Switch>
                  </Form.Item>
                </Col> */}
              </Row>

              <Row>
                <Col span={15}>
                  {" "}
                  {/* <Form.Item
                    labelCol={{
                      span: 14,
                    }}
                    wrapperCol={{
                      span: 12,
                    }}
                    // className="top-space"
                    label="Streaming"
                    name="streaming"
                    initialValue={false}
                    rules={[
                      {
                        type: "boolean",
                        required: false,
                        message: "Please select!",
                      },
                    ]}
                  >
                    <Switch
                      size="default"
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      onChange={(checked) =>
                        handleSwitchChange(checked, {
                          id: streamingModelIds,
                          model: "Streaming",
                        })
                      }
                    ></Switch>
                  </Form.Item>{" "} */}
                </Col>

                <Col span={6}>
                  {" "}
                  {/* <Form.Item
                    labelCol={{
                      span: 22,
                    }}
                    wrapperCol={{
                      span: 2,
                    }}
                    // className="top-space"
                    label="Map"
                    name="map"
                    initialValue={false}
                    rules={[
                      {
                        required: false,
                        message: "Please select!",
                      },
                    ]}
                  >
                    <Switch
                      size="default"
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      onChange={(checked) =>
                        handleSwitchChange(checked, {
                          id: mapid,
                          model: "Map",
                        })
                      }
                    ></Switch>
                  </Form.Item> */}
                </Col>
              </Row>

              <Row>
                <Col span={15}>
                  {" "}
                  {/* <Form.Item
                    labelCol={{
                      span: 14,
                    }}
                    wrapperCol={{
                      span: 12,
                    }}
                    // className="top-space"
                    label="Asset Onboarding"
                    name="assertOnBoard"
                    initialValue={false}
                    rules={[
                      {
                        required: false,
                        message: "Please select!",
                      },
                    ]}
                  >
                    <Switch
                      size="default"
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      onChange={(checked) =>
                        handleSwitchChange(checked, {
                          id: assetOnboardingModelIds,
                          model: "Asset Onboarding",
                        })
                      }
                    ></Switch>
                  </Form.Item> */}
                </Col>
                <Col span={6}>
                  {" "}
                  {/* <Form.Item
                    labelCol={{
                      span: 22,
                    }}
                    wrapperCol={{
                      span: 2,
                    }}
                    // className="top-space"
                    label="Event Setup"
                    name="eventSetUp"
                    initialValue={false}
                    rules={[
                      {
                        required: false,
                        message: "Please select!",
                      },
                    ]}
                  >
                    <Switch
                      size="default"
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      onChange={(checked) =>
                        handleSwitchChange(checked, {
                          id: eventadditionid,
                          model: "Event Addition",
                        })
                      }
                    ></Switch>
                  </Form.Item> */}
                </Col>
              </Row>
              <Row>
                <Col span={15}>
                  {/* <Form.Item
                    labelCol={{
                      span: 14,
                    }}
                    wrapperCol={{
                      span: 12,
                    }}
                    // className="top-space"
                    label="Tech Support"
                    name="techSupport"
                    initialValue={false}
                    rules={[
                      {
                        required: false,
                        message: "Please select!",
                      },
                    ]}
                  >
                    <Switch
                      size="default"
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      onChange={(checked) =>
                        handleSwitchChange(checked, {
                          id: techsupportid,
                          model: "Tech Support",
                        })
                      }
                    ></Switch>
                  </Form.Item>{" "} */}
                </Col>
                <Col span={6}>
                  {" "}
                  {/* <Form.Item
                    labelCol={{
                      span: 22,
                    }}
                    wrapperCol={{
                      span: 2,
                    }}
                    // className="top-space"
                    label="Email Reports"
                    name="sendReportToEmail"
                    initialValue={false}
                    rules={[
                      {
                        required: false,
                        message: "Please select!",
                      },
                    ]}
                  >
                    <Switch
                      size="default"
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      onChange={(checked) =>
                        handleSwitchChange(checked, {
                          id: emailReportid,
                          model: "Send Report To Email",
                        })
                      }
                    ></Switch>
                  </Form.Item> */}
                </Col>
              </Row>
              <Row>
                <Col span={15}>
                  {/* <Form.Item
                    labelCol={{
                      span: 14,
                    }}
                    wrapperCol={{
                      span: 12,
                    }}
                    // className="top-space"
                    label="Telegram Notification"
                    name="telegram"
                    initialValue={false}
                    rules={[
                      {
                        required: false,
                        message: "Please select!",
                      },
                    ]}
                  >
                    <Switch
                      size="default"
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      onChange={(checked) =>
                        handleSwitchChange(checked, {
                          id: telegramid,
                          model: "Telegram",
                        })
                      }
                    ></Switch>
                  </Form.Item> */}
                </Col>
                <Col span={6}></Col>
              </Row>
            </Form>
          </div>
        </Modal>
        {/* //displaying modal */}
        <Modal
          centered
          open={editModal}
          footer={
            <>
              <Row justify={"space-around"}>
                {/* <Col>
                  {authService.getCurrentUserReadOnly() ? (
                    <Tooltip title="you need permission to submit">
                      <Button type="primary" block disabled>
                        Submit
                      </Button>
                    </Tooltip>
                  ) : (
                    <Button
                      className="primary-button"
                      form="submiteditform"
                      type="primary"
                      block
                      htmlType="submit"
                    >
                      Submit
                    </Button>
                  )}
                </Col> */}
                <Col>
                  <Button onClick={() => handleclose()}>Cancel</Button>
                </Col>
              </Row>
            </>
          }
          onCancel={() => seteditModal(false)}
        >
          <Form
            disabled
            form={editform}
            id="submiteditform"
            name="basic"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 22,
            }}
            // onFinish={onFinishedit}
            // onFinishFailed={onFinishFailed}
            autoComplete={"off"}
          >
            {" "}
            <Form.Item
              // className="top-space"
              labelCol={{
                span: 9,
              }}
              wrapperCol={{
                span: 12,
              }}
              label="Policy Name"
              name="policyName"
              rules={[
                {
                  required: true,
                  message: "Please enter your Policy Name!",
                },
                {
                  validator: (_, Values) => checkPolicyName(Values),
                },
              ]}
            >
              <Input
                placeholder="Policy Name"
                // onChange={(text) => checkPolicyName(text)}
              />
            </Form.Item>
            <Form.Item
              labelCol={{ span: 9 }}
              wrapperCol={{ span: 12 }}
              label="Onboarding Location"
              name="onBoardLocation"
              rules={[
                {
                  required: true,
                  message: "Please select Onboarding Location!",
                },
              ]}
            >
              <Select
                mode="multiple"
                placeholder="Select OnBoard location"
              ></Select>
            </Form.Item>
            <Form.Item
              labelCol={{
                span: 9,
              }}
              wrapperCol={{
                span: 12,
              }}
              label="Events"
              name="eventName"
              // initialValue={[]}
              rules={[
                {
                  required: true,
                  message: "Please select Events!",
                },
              ]}
            >
              <Select mode="multiple" placeholder="Event" options={events} />
            </Form.Item>
            <Row>
              <Col span={15}>
                {" "}
                <Form.Item
                  labelCol={{
                    span: 14,
                  }}
                  wrapperCol={{
                    span: 12,
                  }}
                  label="Dashboard Event"
                  name="dashboard"
                  initialValue={false}
                  rules={[
                    {
                      required: false,
                      message: "Please select!",
                    },
                  ]}
                >
                  <Switch
                    size="default"
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    onChange={(checked) => {
                      // setNotificationEvent(checked);
                      // enableSelectIfNecessary(checked);
                      updateSwitchStates(checked, "dashboard");
                      handleSwitchChange(checked, {
                        id: dashboardid,
                        model: "Dashboard",
                      });
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  labelCol={{
                    span: 22,
                  }}
                  wrapperCol={{
                    span: 2,
                  }}
                  // className="top-space"
                  label="Report Events"
                  name="report"
                  initialValue={false}
                  rules={[
                    {
                      required: false,
                      message: "Please select!",
                    },
                  ]}
                >
                  <Switch
                    size="default"
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    onChange={(checked) => {
                      // setNotificationEvent(checked);
                      // enableSelectIfNecessary(checked);
                      updateSwitchStates(checked, "report");
                      handleSwitchChange(checked, {
                        id: eventReportid,
                        model: "Reports",
                      });
                    }}
                  ></Switch>
                </Form.Item>

                {/* <Form.Item
                  labelCol={{
                    span: 22,
                  }}
                  wrapperCol={{
                    span: 2,
                  }}
                  // className="top-space"
                  label="Notification"
                  name="notification"
                  initialValue={false}
                  rules={[
                    {
                      required: false,
                      message: "Please select!",
                    },
                  ]}
                >
                  <Switch
                    size="default"
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    onChange={(checked) => {
                      // setNotificationEvent(checked);
                      // enableSelectIfNecessary(checked);
                      updateSwitchStates(checked, "notification");
                      handleSwitchChange(checked, {
                        id: notificationid,
                        model: "Notification",
                      });
                    }}
                  />
                </Form.Item> */}
              </Col>
            </Row>
            <Row>
              <Col span={15}>
                <Form.Item
                  labelCol={{
                    span: 14,
                  }}
                  wrapperCol={{
                    span: 12,
                  }}
                  // className="top-space"
                  label="Asset Onboarding"
                  name="assertOnBoard"
                  initialValue={false}
                  rules={[
                    {
                      required: false,
                      message: "Please select!",
                    },
                  ]}
                >
                  <Switch
                    size="default"
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    onChange={(checked) =>
                      handleSwitchChange(checked, {
                        id: assetOnboardingModelIds,
                        model: "Asset Onboarding",
                      })
                    }
                  ></Switch>
                </Form.Item>
              </Col>
              {/* <Col span={6}>
                <Form.Item
                  labelCol={{
                    span: 22,
                  }}
                  wrapperCol={{
                    span: 2,
                  }}
                  // className="top-space"
                  label="Calender"
                  name="eventCalender"
                  initialValue={false}
                  rules={[
                    {
                      required: false,
                      message: "Please select!",
                    },
                  ]}
                >
                  <Switch
                    size="default"
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    onChange={(checked) => {
                      // setNotificationEvent(checked);
                      // enableSelectIfNecessary(checked);
                      updateSwitchStates(checked, "eventCalender");
                      handleSwitchChange(checked, {
                        id: calenderid,
                        model: "Event Calender",
                      });
                    }}
                  ></Switch>
                </Form.Item>
              </Col> */}
            </Row>
            <Row>
              <Col span={15}>
                {" "}
                {/* <Form.Item
                  labelCol={{
                    span: 14,
                  }}
                  wrapperCol={{
                    span: 12,
                  }}
                  // className="top-space"
                  label="Streaming"
                  name="streaming"
                  disabled
                  initialValue={false}
                  rules={[
                    {
                      type: "boolean",
                      required: false,
                      message: "Please select!",
                    },
                  ]}
                >
                  <Switch
                    size="default"
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    onChange={(checked) =>
                      handleSwitchChange(checked, {
                        id: streamingModelIds,
                        model: "Streaming",
                      })
                    }
                  ></Switch>
                </Form.Item>{" "} */}
              </Col>

              <Col span={6}>
                {" "}
                {/* <Form.Item
                  labelCol={{
                    span: 22,
                  }}
                  wrapperCol={{
                    span: 2,
                  }}
                  // className="top-space"
                  label="Map"
                  name="map"
                  initialValue={false}
                  rules={[
                    {
                      required: false,
                      message: "Please select!",
                    },
                  ]}
                >
                  <Switch
                    size="default"
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    onChange={(checked) =>
                      handleSwitchChange(checked, {
                        id: mapid,
                        model: "Map",
                      })
                    }
                  ></Switch>
                </Form.Item> */}
              </Col>
            </Row>
            <Row>
              <Col span={15}>
                {" "}
                {/* <Form.Item
                  labelCol={{
                    span: 14,
                  }}
                  wrapperCol={{
                    span: 12,
                  }}
                  // className="top-space"
                  label="Asset Onboarding"
                  name="assertOnBoard"
                  initialValue={false}
                  rules={[
                    {
                      required: false,
                      message: "Please select!",
                    },
                  ]}
                >
                  <Switch
                    size="default"
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    onChange={(checked) =>
                      handleSwitchChange(checked, {
                        id: assetOnboardingModelIds,
                        model: "Asset Onboarding",
                      })
                    }
                  ></Switch>
                </Form.Item> */}
              </Col>
              <Col span={6}>
                {" "}
                {/* <Form.Item
                  labelCol={{
                    span: 22,
                  }}
                  wrapperCol={{
                    span: 2,
                  }}
                  // className="top-space"
                  label="Event Setup"
                  name="eventSetUp"
                  initialValue={false}
                  rules={[
                    {
                      required: false,
                      message: "Please select!",
                    },
                  ]}
                >
                  <Switch
                    size="default"
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                  ></Switch>
                </Form.Item> */}
              </Col>
            </Row>
            <Row>
              <Col span={15}>
                {/* <Form.Item
                  labelCol={{
                    span: 14,
                  }}
                  wrapperCol={{
                    span: 12,
                  }}
                  // className="top-space"
                  label="Tech Support"
                  name="techSupport"
                  initialValue={false}
                  rules={[
                    {
                      required: false,
                      message: "Please select!",
                    },
                  ]}
                >
                  <Switch
                    size="default"
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    onChange={(checked) =>
                      handleSwitchChange(checked, {
                        id: techsupportid,
                        model: "Tech Support",
                      })
                    }
                  ></Switch>
                </Form.Item>{" "} */}
              </Col>
              <Col span={6}>
                {" "}
                {/* <Form.Item
                  labelCol={{
                    span: 22,
                  }}
                  wrapperCol={{
                    span: 2,
                  }}
                  // className="top-space"
                  label="Email Reports"
                  name="sendReportToEmail"
                  initialValue={false}
                  rules={[
                    {
                      required: false,
                      message: "Please select!",
                    },
                  ]}
                >
                  <Switch
                    size="default"
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    onChange={(checked) =>
                      handleSwitchChange(checked, {
                        id: emailReportid,
                        model: "Send Report To Email",
                      })
                    }
                  ></Switch>
                </Form.Item> */}
              </Col>
            </Row>
            <Row>
              <Col span={15}>
                {/* <Form.Item
                  labelCol={{
                    span: 14,
                  }}
                  wrapperCol={{
                    span: 12,
                  }}
                  // className="top-space"
                  label="Telegram Notification"
                  name="telegram"
                  initialValue={false}
                  rules={[
                    {
                      required: false,
                      message: "Please select!",
                    },
                  ]}
                >
                  <Switch
                    size="default"
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    onChange={(checked) =>
                      handleSwitchChange(checked, {
                        id: telegramid,
                        model: "Telegram",
                      })
                    }
                  ></Switch>
                </Form.Item> */}
              </Col>
              <Col span={6}></Col>
            </Row>
          </Form>
        </Modal>
        {/* 
     Loading modal */}

        <Modal
          className="LoadinModal"
          open={Pageload}
          style={{ background: "none", textAlign: "center" }}
          footer={false}
          closable={false}
          centered
        >
          <ClipLoader
            color="#23234d"
            loading={Pageload}
            cssOverride={{
              display: "block",
              margin: "0 auto",

              borderWidth: "5px",
              marginBottom: "10px",
            }}
            size={100}
            aria-label="Loading Spinner"
            data-testid="loader"
            title="Loading..."
          />
          <Text style={{ color: "white", fontSize: "1.2em" }}>Loading ...</Text>
        </Modal>
      </div>
    </>
  );
};
export default PolicyConfig;
