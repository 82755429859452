import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  Row,
  Select,
  Typography,
  Tabs,
  Modal,
  message,
  Table,
  Button,
  Spin,
  Alert,
  Result,
  Form,
  Radio,
  Space,
  Tag,
  Divider,
  notification,
  Progress,
  Avatar,
  List,
  Menu,
  Dropdown,
  Tooltip,
  Image,
  Skeleton,
  Empty,
  Flex,
  TimePicker,
  Drawer,
} from "antd";
import dayjs from "dayjs";
import { DatePicker } from "antd";
import moment from "moment/moment";
import Chart from "react-apexcharts";
import axiosClient from "../../../Authentication/ApiCall";
import randomColor from "randomcolor";
import { MdOutlineDirections, MdOutlinePeopleAlt } from "react-icons/md";
import { RxLapTimer } from "react-icons/rx";
import {
  AiOutlineHistory,
  AiOutlineUserSwitch,
  AiTwotoneEdit,
} from "react-icons/ai";
import _, { filter, find, flatMap, map, orderBy, values } from "lodash";
import { IoIosSearch, IoMdCloseCircle } from "react-icons/io";
import { LuFilter } from "react-icons/lu";
import ReactApexChart from "react-apexcharts";

const { RangePicker } = DatePicker;

const { Title, Link, Text } = Typography;

let RenderComp = {
  IngressEgress: false,
  DwellFootfall: false,
  EngagedBounced: false,
  AgeGender: false,
  PeakHours: false,
};
let EngaedAndBouncedDetails = {
  CameraData: null,
  ZoneData: null,
};
let UniqueHourlyDetails = {
  camlist: [],
  selectedcam: "",
  selectedDate: dayjs().format("YYYY-MM-DD"),
  DataOfDate: null,
  selectedcameraname: null,
};
let DwellHourlyDetails = {
  camlist: [],
  selectedcam: null,
  selectedDate: dayjs().format("YYYY-MM-DD"),
  DataOfDate: null,
  selectedcameraname: null,
};
let weeklybouncedDetails = {
  SelectedOption: "Weekly",
  StartDate: dayjs().startOf("month").format("YYYY-MM-DD"),
  EndDate: dayjs().endOf("month").format("YYYY-MM-DD"),
  search: false,
};
let PeakHoursDetails = {
  SelectedOption: "Weekly",
  StartDate: dayjs().startOf("month").format("YYYY-MM-DD"),
  EndDate: dayjs().endOf("month"),
  search: false,
};

const Monthly = () => {
  const [RenderCompController, setRenderCompController] = useState(RenderComp);
  const [selectdwelltimedate, setselectdwelltimedate] = useState();
  const [dwelltimedata, setdwelltimedata] = useState("");
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [EngaedandBouncedController, setEngaedandBouncedController] = useState(
    []
  );
  const [UniqueHourlyChart, setUniqueHourlyChart] = useState({
    options: {
      chart: {
        id: "basic-bar",
        type: "bar",
        stacked: true,
        toolbar: { show: false },
        contextmenu: { enabled: false },
      },
      plotOptions: {
        bar: { horizontal: false },
      },
      xaxis: {
        categories: [],
        title: { text: "Hour ", offsetY: -10 },
      },
      yaxis: { title: { text: "count" } },
      legend: {
        position: "top",
        horizontalAlign: "center",
        offsetX: 0,
      },
      colors: ["#FF5733", "#C70039", "#900C3F", "#FFC300", "#DAF7A6"],
    },
    series: [],
  });

  const [EngagedandBouncedData, setEngagedandBouncedData] = useState(
    EngaedAndBouncedDetails
  );
  const [chartFootfallanalysis, setChartFootfallanalysis] = useState({
    series: [
      {
        name: "Footfall",
        data: [0, 0],
      },
    ],
    options: {
      chart: {
        id: "basic-bar",
        animations: {
          enabled: false,
        },
        toolbar: { show: false },
        contextmenu: { enabled: false },
      },
      xaxis: {
        type: "day",
        categories: [],
        title: {
          text: "Day",
          offsetY: -10,
          labels: {
            show: true,
            style: {
              colors: ["#a8a8a8"],
            },
          },
        },
      },
      stroke: {
        width: 1,
      },
      pattern: {
        strokeWidth: 0.1,
      },
      // yaxis: {
      //   labels: {
      //     show: false,
      //   },
      // },
      // tooltip: {
      //   enabled: true,
      // },

      // dataLabels: {
      //   enabled: true,
      //   background: {
      //     enabled: true,
      //     // borderRadius:2,
      //   }
      // }

      // tooltip: {
      //   enabled: false
      // }
      // xaxis: {
      //   categories:Day

      // [
      //   "Monday",
      //   "Tuesday",
      //   "Wednesday",
      //   "Thursday",
      //   "Friday",
      //   "Saturday",
      //   "Sunday",
      // ],
      // },
    },
  });
  const [UniqueHourlyController, setUniqueHourlyController] =
    useState(UniqueHourlyDetails);
  const [DwellHourlyController, setDwellHourlyController] =
    useState(DwellHourlyDetails);
  const [DwellHourly, setDwellHourly] = useState({
    options: {
      chart: {
        id: "basic-bar",
        type: "bar",
        stacked: true,
        toolbar: { show: false },
        contextmenu: { enabled: false },
      },
      plotOptions: {
        bar: { horizontal: false },
      },
      xaxis: {
        categories: [],
        title: { text: "Hour ", offsetY: -10 },
      },
      yaxis: { title: { text: "Seconds" } },
      legend: {
        position: "top",
        horizontalAlign: "center",
        offsetX: 0,
      },
      colors: ["#FF5733", "#C70039", "#900C3F", "#FFC300", "#DAF7A6"],
    },
    series: [],
  });

  const [WeeklyBouncedController, setWeeklyBouncedController] =
    useState(weeklybouncedDetails);

  const [selectedbounceMenu, setSelectedbounceMenu] = useState("Weekly");
  const [showRangePickerforBounce, setShowRangePickerforBounce] =
    useState(false);
  const [Engagersweeklychart, setEngagersweeklychart] = useState({
    options: {
      series: [
        {
          name: "Engagers",
          data: [],
          // data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
        },
      ],
      chart: {
        height: 350,
        type: "bar",
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },

      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: [],
      },
    },
  });
  const [Bouncedweeklychart, setBouncedweeklychart] = useState({
    options: {
      series: [
        {
          name: "Bounced",
          data: [],
          // data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
        },
      ],

      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      xaxis: {
        categories: [],
        // categories: [
        //   "Jan",
        //   "Feb",
        //   "Mar",
        //   "Apr",
        //   "May",
        //   "Jun",
        //   "Jul",
        //   "Aug",
        //   "Sep",
        // ],
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      colors: ["#00e396"],
    },
  });
  const [selecteddate, setselecteddate] = useState("");
  const [chartDatapieGender, setChartDatapieGender] = useState({
    series: [],

    options: {
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
            },
          },
        },
      },
      chart: {
        width: 380,
        type: "pie",
      },
      labels: ["Male", "Female"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  });
  const [chartDatapieAge, setChartDatapieAge] = useState({
    series: [],
    options: {
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
            },
          },
        },
      },
      chart: {
        width: 380,
        type: "pie",
      },
      colors: [
        "#F44336",
        "#E91E63",
        "#9C27B0",
        "#255aee",
        "#4eed9e",
        "#078f4b",
        "#6088d6",
        "#FFC107",
      ],
      labels: [
        "0-10",
        "11-20",
        "21-30",
        "31-40",
        "41-50",
        "51-60",
        "61-70",
        "71-100",
      ],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  });
  const [datedata, setdatedata] = useState("");
  const [loading, setloading] = useState(true);
  const [selectedpickhourMenu, setSelectedpickhourMenu] = useState("Weekly");
  const [showRangePickerforpeakHour, setshowRangePickerforpeakHourr] =
    useState(false);
  const [pickhoursweeklyData, setpickhoursweeklydata] = useState({
    options: {
      series: [
        {
          name: "Peak Hour",
          data: [],
          // data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
        },
      ],
      chart: {
        height: 350,
        type: "line",
        color: "#64e38a",
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
        width: 1,
      },
      pattern: {
        strokeWidth: 0.1,
      },
      // title: {
      //   text: "Weekly Data for Bounced",
      //   align: "left",
      // },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: [],
      },
      // yaxis: {
      //   min: 1,
      //   max: 24,
      //   tickAmount: 24,
      // },
      yaxis: {
        min: 2,
        max: 24,
        tickAmount: 12,
      },
    },
  });
  const [PeakHoursController, setPeakHoursController] =
    useState(PeakHoursDetails);

  //menu
  const menuforBounced = [
    {
      key: "Weekly",
      label: <Text>Weekly Data</Text>,
    },
    {
      key: "15Days",
      label: <Text>15 Days Data</Text>,
    },
    {
      key: "Monthly",
      label: <Text>Monthly Data</Text>,
    },
    {
      key: "search",
      label: (
        <Text>
          <Flex align="center" justify="space-evenly">
            search <IoIosSearch />
          </Flex>
        </Text>
      ),
    },
  ];
  const menuforpeakvalue = [
    {
      key: "Weekly",
      label: <Text>Weekly Data</Text>,
    },
    {
      key: "15Days",
      label: <Text>15 Days Data</Text>,
    },
    {
      key: "Monthly",
      label: <Text>Monthly Data</Text>,
    },
    {
      key: "search",
      label: (
        <Text>
          <Flex align="center" justify="space-evenly">
            search <IoIosSearch />
          </Flex>
        </Text>
      ),
    },
  ];

  //-----------------------
  const handleDateSelect = (date) => {
    if (date === null) {
      console.log("Date cleared!");
    } else {
      setselectdwelltimedate(date);

      const formattedDate = date.format("YYYY-MM-DD");

      axiosClient
        .get(`/dwelltime/getaveragebasedondate?date=${formattedDate}`)
        .then((response) => {
          if (response.data.issuccess === true) {
            setdwelltimedata(response.data.data.dwellTimeAvg);
          }
        })
        .catch((error) => {});

      setShowDatePicker(false);
    }
  };
  //-------------------------

  //Get Dwell Time  data
  useEffect(() => {
    axiosClient
      .get("/dwelltime/getaveragebasedondate?date=")
      .then((response) => {
        if (response) {
          setdwelltimedata(response.data.data.dwellTimeAvg);
        }
      })
      .catch((err) => {
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  }, []);
  //-------------------------------------
  useEffect(() => {
    setTimeout(() => {
      setRenderCompController((prev) => ({ ...prev, IngressEgress: true }));
    }, 1000);

    setTimeout(() => {
      setRenderCompController((prev) => ({ ...prev, DwellFootfall: true }));
    }, 1000);
    setTimeout(() => {
      setRenderCompController((prev) => ({ ...prev, EngagedBounced: true }));
    }, 1000);

    setTimeout(() => {
      setRenderCompController((prev) => ({ ...prev, AgeGender: true }));
    }, 1000);
    setTimeout(() => {
      setRenderCompController((prev) => ({ ...prev, PeakHours: true }));
    }, 1000);
  }, []);

  //-----------------------------------------

  // initial fetch of engagae &bounce
  useEffect(() => {
    axiosClient
      .get("/dwelltime/getdwelltimebasedoncameraid?cameraId=10")

      .then((response) => {
        if (response.data.issuccess === true) {
          if (response.data.data.length > 0) {
            const EBdataDefault = response.data.defaultData;
            const newMenuList = response.data.data.map((item, index) => {
              return {
                key: item.cameraId.toString(),
                label: item.cameraName,
                children:
                  item.zoneData.length > 0
                    ? item.zoneData.map((zone) => ({
                        key: `${item.cameraId}-${zone.id}`,
                        label: (
                          <Text
                            onClick={() => handleEngagedAndBounced(zone, item)}
                          >
                            {zone.Area}
                          </Text>
                        ),
                        item: { zone },
                      }))
                    : [],
              };
            });
            setEngaedandBouncedController(newMenuList);
            setEngagedandBouncedData((prev) => ({
              ...prev,
              CameraData: EBdataDefault,
              ZoneData:
                EBdataDefault.zoneData && EBdataDefault.zoneData.length > 0
                  ? EBdataDefault.zoneData[0]
                  : null,
            }));
          }
        } else {
          console.log("error engage data api call successfully");
        }
      })
      .catch((err) => {
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  }, []);

  //-----------------------------------------
  const DwellHourComp = () => {
    return (
      <Card
        styles={{
          body: { maxHeight: "100%" },
        }}
        title={
          <>
            <Row justify={"space-between"} align={"middle"}>
              <Col>
                <Title level={5}>Dwell time per hour</Title>
                <Tag>
                  {DwellHourlyController.DataOfDate &&
                    DwellHourlyController.DataOfDate}
                  {DwellHourlyController.selectedcameraname &&
                    " - " + DwellHourlyController.selectedcameraname}
                </Tag>
              </Col>
              <Col>
                <Row align="center" gutter={[10, 0]}>
                  <Col>
                    <DatePicker
                      onChange={onChangeDateDwellHourly}
                      format={"YYYY-MM-DD"}
                      disabledDate={(current) => {
                        return current && current > moment().endOf("day");
                      }}
                      defaultValue={dayjs()}
                    />
                  </Col>
                  <Col>
                    <Select
                      onChange={OnChangeCameraSelect}
                      defaultValue={DwellHourlyController.selectedcam}
                      style={{
                        width: 120,
                      }}
                      options={DwellHourlyController.camlist}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        }
      >
        <Chart
          options={DwellHourly.options}
          series={DwellHourly.series}
          type="bar"
          height={"190%"}
        />
      </Card>
    );
  };
  //----------------------------------------------

  const UniqueHourComp = () => {
    return (
      <Card
        styles={{
          body: { maxHeight: "100%" },
        }}
        title={
          <>
            <Row justify={"space-between"} align={"middle"}>
              <Col>
                <Title level={5}>Unique count per hour</Title>
                <Tag>
                  {UniqueHourlyController.DataOfDate &&
                    UniqueHourlyController.DataOfDate}
                  {UniqueHourlyController.selectedcameraname &&
                    " - " + UniqueHourlyController.selectedcameraname}
                </Tag>
              </Col>
              <Col>
                <Row align="center" gutter={[10, 0]}>
                  <Col>
                    <DatePicker
                      onChange={UniqueHourlyHandleDate}
                      format={"YYYY-MM-DD"}
                      disabledDate={(current) => {
                        return current && current > moment().endOf("day");
                      }}
                      defaultValue={dayjs()}
                    />
                  </Col>
                  <Col>
                    <Select
                      onChange={UniqueHourlyHandleSelectCamera}
                      defaultValue={UniqueHourlyController.selectedcam}
                      style={{
                        width: 120,
                      }}
                      options={UniqueHourlyController.camlist}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        }
      >
        <Chart
          options={UniqueHourlyChart.options}
          series={UniqueHourlyChart.series}
          type="bar"
          height={"190%"}
        />
      </Card>
    );
  };

  //Month picker for spider graph
  const onChange = (date, dateString) => {
    if (date === null) {
      axiosClient.get(`/livereport/spidergraphdata`).then((response) => {
        if (response.data.issuccess === true) {
          const responseData = response.data.data;

          const uniqueWeekwise = [
            ...new Set(
              responseData.spiderIngress[0].map((item) => item.weekwise)
            ),
          ];

          // Extracting data for the radar chart
          const seriesData = uniqueWeekwise.map((weekwise) => {
            return {
              name: weekwise,
              data: responseData.spiderIngress[0]
                .filter((item) => item.weekwise === weekwise)
                .map((item) => item.totalingress),
            };
          });

          // Setting up chart options
          const options = {
            chart: {
              height: 400,
              type: "radar",
            },
            xaxis: {
              categories: responseData.spiderIngress[0].map(
                (item) => item.week
              ),
              title: {
                text: "Week",
                offsetY: -10,
                labels: {
                  show: true,
                  style: {
                    colors: ["#a8a8a8"],
                  },
                },
              },
            },
            colors: [
              "#008FFB",
              "#00E396",
              "#FEB019",
              "#FF4560",
              "#775DD0",
              "#FF9F00",
            ],
          };

          setChartFootfallanalysis({
            series: seriesData,
            options: options,
          });
        }
      });
    } else {
      const formattedmonth = date.format("YYYY-MM");

      axiosClient
        .get(`/livereport/spidergraphdata?month=` + formattedmonth)
        .then((response) => {
          if (response.data.issuccess === true) {
            const responseData = response.data.data;

            const uniqueWeekwise = [
              ...new Set(responseData.newspider.map((item) => item.weekwise)),
            ];

            // Extracting data for the radar chart
            const seriesData = uniqueWeekwise.map((weekwise) => {
              return {
                name: weekwise,
                data: responseData.newspider
                  .filter((item) => item.weekwise === weekwise)
                  .map((item) => item.totalingress),
              };
            });

            // Setting up chart options
            const options = {
              chart: {
                height: 400,
                type: "radar",
              },
              xaxis: {
                categories: responseData.newspider.map((item) => item.week),
                title: {
                  text: "Week",
                  offsetY: -10,
                  labels: {
                    show: true,
                    style: {
                      colors: ["#a8a8a8"],
                    },
                  },
                },
              },
              colors: [
                "#008FFB",
                "#00E396",
                "#FEB019",
                "#FF4560",
                "#775DD0",
                "#FF9F00",
              ],
            };

            setChartFootfallanalysis({
              series: seriesData,
              options: options,
            });
          }
        })
        .catch((err) => {
          console.log("errr", err);
          if (err.status === 0) {
            message.error("Server error");
          } else {
            message.error(err.msg);
          }
        });
    }
  };
  //--------------------------------------------------
  const disabledFutureMonths = (current) => {
    return current && current > moment().endOf("month");
  };
  //------------------------------------------------------
  useEffect(() => {
    axiosClient
      .get(`/livereport/spidergraphdata`)
      .then((response) => {
        if (response.data.issuccess === true) {
          // Spiderchart
          const responseData =
            response.data.data.spiderIngress.length > 0
              ? response.data.data.spiderIngress[0]
              : [];

          const uniqueWeekwise = [
            ...new Set(responseData.map((item) => item.weekwise)),
          ];

          // Extracting data for the radar chart
          const seriesData = uniqueWeekwise.map((weekwise) => {
            return {
              name: weekwise,
              data: responseData
                .filter((item) => item.weekwise === weekwise)
                .map((item) => item.totalingress),
            };
          });

          // Setting up chart options
          const options = {
            chart: {
              height: 400,
              type: "radar",
            },
            xaxis: {
              categories: responseData.map((item) => item.week),
              title: {
                text: "Week",
                offsetY: -10,
                labels: {
                  show: true,
                  style: {
                    colors: ["#a8a8a8"],
                  },
                },
              },
            },
            colors: [
              "#008FFB",
              "#00E396",
              "#FEB019",
              "#FF4560",
              "#775DD0",
              "#FF9F00",
            ],
          };

          setChartFootfallanalysis({
            series: seriesData,
            options: options,
          });
        }
      })
      .catch((err) => {
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Network error");
        } else {
          message.error(err.message);
        }
      });
  }, []);

  //---------------------------------------------------
  const UniqueHourlyHandleSelectCamera = (selectedcam) => {
    if (selectedcam !== null && selectedcam !== undefined) {
      setUniqueHourlyController((prev) => ({ ...prev, selectedcam }));
    }
  };
  //---------------------------------------------------

  useEffect(() => {
    axiosClient
      .get(
        `/livereport/getuniquecount?date=${UniqueHourlyController.selectedDate}&camId=${UniqueHourlyController.selectedcam}`
      )
      .then((response) => {
        if (response.data.issuccess === true) {
          const data = response.data.data.data ? response.data.data.data : [];

          const selectedcamDetails = response.data.data.selectedCameraData;
          const CameraList = response.data.data.cameraData;
          if (selectedcamDetails) {
            setUniqueHourlyController((prev) => ({
              ...prev,
              selectedcam: selectedcamDetails.cameraId,
              selectedcameraname: selectedcamDetails.cameraName,
            }));
          }
          if (CameraList !== null && CameraList !== undefined) {
            const newCamList = CameraList.map((item) => ({
              value: item.cameraid,
              label: item.cameraname,
            }));

            setUniqueHourlyController((prev) => ({
              ...prev,
              camlist: [...newCamList],
            }));
          }

          if (data.length > 0 || data !== null || data !== undefined) {
            const xaxis = data.map((item) => item.hour);
            const series = data.map((item) => item.totalCount);

            setUniqueHourlyChart((prevState) => ({
              ...prevState,
              options: {
                ...prevState.options,
                xaxis: { ...prevState.options.xaxis, categories: xaxis },
              },
              series: [{ name: "Unique counts", data: series }],
            }));

            setUniqueHourlyController((prev) => ({
              ...prev,
              DataOfDate: UniqueHourlyController.selectedDate,
            }));
          }
        } else {
          console.log("error engage data api call successfully");
        }
      })
      .catch((err) => {
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  }, [UniqueHourlyController.selectedcam, UniqueHourlyController.selectedDate]);

  //----------------------------------------------
  const UniqueHourlyHandleDate = (date, dateString) => {
    if (dateString !== "") {
      setUniqueHourlyController((prev) => ({
        ...prev,
        selectedDate: dateString,
        selectedcam: "",
      }));
    } else {
      setUniqueHourlyController((prev) => ({
        ...prev,
        selectedDate: "",
        selectedcam: "",
      }));
    }
  };
  //-----------------------------------------
  useEffect(() => {
    axiosClient
      .get(
        `/dwelltime/getDwellTimeHourData?date=${DwellHourlyController.selectedDate}&camId=${DwellHourlyController.selectedcam}`
      )

      .then((response) => {
        if (response.data.issuccess === true) {
          const data = response.data.data.dwellData
            ? response.data.data.dwellData
            : [];

          const selectedcamDetails = response.data.data.selectedCameraDetails;
          const CameraList = response.data.data.cameraData;
          if (selectedcamDetails) {
            setDwellHourlyController((prev) => ({
              ...prev,
              selectedcam: selectedcamDetails.cameraId,
              selectedcameraname: selectedcamDetails.cameraName,
            }));
          }
          if (CameraList !== null && CameraList !== undefined) {
            const newCamList = CameraList.map((item) => ({
              value: item.cameraId,
              label: item.cameraName,
            }));

            setDwellHourlyController((prev) => ({
              ...prev,
              camlist: [...newCamList],
            }));
            // CameraList.map((item) => {
            //   let cam = {
            //     value: item.cameraId,
            //     label: item.cameraName,
            //   };

            //   setDwellHourlyController((prev) => ({
            //     ...prev,
            //     camlist: [...prev.camlist, cam],
            //   }));
            // });
          }

          if (data.length > 0 || data !== null || data !== undefined) {
            const zoneNames = extractZoneNames(data).sort();
            if (zoneNames.length > 0) {
              const { hours, series, seriesEngage, seriesBounce } =
                transformData(data, zoneNames);
              const colors = generateColorPalette(
                zoneNames ? zoneNames.length : 0
              );
              setDwellHourly((prevState) => ({
                ...prevState,
                options: {
                  ...prevState.options,
                  xaxis: {
                    ...prevState.options.xaxis,
                    categories: hours,
                  },
                  colors: colors,
                },
                series: series,
              }));

              setDwellHourlyController((prev) => ({
                ...prev,
                DataOfDate: DwellHourlyController.selectedDate,
              }));
            }
          }
        } else {
          console.log("error engage data api call successfully");
        }
      })
      .catch((err) => {
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  }, [DwellHourlyController.selectedcam, DwellHourlyController.selectedDate]);
  //--------------------------------------
  const generateColorPalette = (numberOfColors) => {
    const palette = [];
    if (
      numberOfColors > 0 &&
      numberOfColors !== null &&
      numberOfColors !== undefined
    ) {
      for (let j = 0; j < numberOfColors; j++) {
        palette.push(
          randomColor({
            luminosity: "dark",
            // hue: "blue",
          })
        );
      }
    }

    return palette;
  };
  //------------------------------------------------
  const transformData = (data, zoneNames) => {
    const hours = _.map(data, "hour");
    const series = _.map(zoneNames, (zone) => {
      const DwellTime_avg = _.map(data, zone + ".average_dwell_time");
      const Final_Dwell = DwellTime_avg.map((item) => Math.round(item));
      return {
        name: zone,
        data: Final_Dwell,
      };
    });

    return { hours, series };
  };
  //---------------------------

  const extractZoneNames = (data) => {
    return _(data)
      .flatMap(_.keys)
      .filter((key) => key.startsWith("Area"))
      .uniq()
      .value();
  };
  //---------------------------------
  const OnChangeCameraSelect = (selectedcam) => {
    if (selectedcam !== null && selectedcam !== undefined) {
      setDwellHourlyController((prev) => ({ ...prev, selectedcam }));
    }
  };

  // Onchange date function for Dwell hourly
  const onChangeDateDwellHourly = (date, dateString) => {
    if (dateString !== "") {
      setDwellHourlyController((prev) => ({
        ...prev,
        selectedDate: dateString,
        selectedcam: null,
      }));
    } else {
      setDwellHourlyController((prev) => ({
        ...prev,
        selectedDate: "",
        selectedcam: null,
      }));
    }
  };
  const handleEngagedAndBounced = (zone, item) => {
    setEngagedandBouncedData({ CameraData: item, ZoneData: zone });
  };

  //weekly
  const EngageAndBounce = () => {
    return (
      <Col
        xs={{ span: 24 / 1, offset: 0 }}
        sm={{ span: 24 / 1, offset: 0 }}
        md={{ span: 24 / 1, offset: 0 }}
        lg={{ span: 24 / 1, offset: 0 }}
        xl={{ span: 24 / 1, offset: 0 }}
      >
        <Card
          style={{
            userSelect: "none",
            boxShadow: "none",
          }}
          title={
            <>
              <Row justify={"space-between"} align={"middle"}>
                <Col>
                  <Title level={4}>
                    {WeeklyBouncedController.SelectedOption === "Weekly"
                      ? "Weekly Engaged and Bounced"
                      : selectedbounceMenu === "15Days"
                      ? "Last 15 Days Engaged and Bounced"
                      : "Monthly Engaged and Bounced"}
                  </Title>
                </Col>

                <Col>
                  <Flex gap={10} align="center">
                    {WeeklyBouncedController.search && (
                      <RangePicker
                        onChange={handleDateRangeChangeforBounce}
                        disabledDate={(current) => {
                          return current && current > moment().endOf("day");
                        }}
                        allowClear
                      />
                    )}
                    <Dropdown
                      menu={{
                        items: menuforBounced,
                        onClick: handleMenuClickWeeklyBounced,
                      }}
                      placement="bottomRight"
                    >
                      <Button>
                        <Flex gap={10} align="center">
                          Filters
                          <LuFilter />
                        </Flex>
                      </Button>
                    </Dropdown>
                  </Flex>
                </Col>
              </Row>
            </>
          }
        >
          <Row gutter={[1, 10]} justify={"space-between"} align={"middle"}>
            <Col
              xs={{ span: 23 / 1, offset: 0 }}
              sm={{ span: 24 / 1, offset: 0 }}
              md={{ span: 11 / 1, offset: 0 }}
              lg={{ span: 11 / 1, offset: 0 }}
              xl={{ span: 11, offset: 0 }}
            >
              <div style={{ height: "250px" }}>
                <ReactApexChart
                  type="bar"
                  options={Engagersweeklychart.options}
                  series={Engagersweeklychart.options.series}
                  height="100%"
                />
              </div>
            </Col>
            <Divider type="vertical" style={{ height: "250px" }} />
            <Col
              xs={{ span: 24 / 1, offset: 0 }}
              sm={{ span: 24 / 1, offset: 0 }}
              md={{ span: 11 / 1, offset: 0 }}
              lg={{ span: 11 / 1, offset: 0 }}
              xl={{ span: 11, offset: 0 }}
            >
              <div style={{ height: "250px" }}>
                <ReactApexChart
                  type="bar"
                  options={Bouncedweeklychart.options}
                  series={Bouncedweeklychart.options.series}
                  height="100%"
                />
              </div>
            </Col>
          </Row>
        </Card>
      </Col>
    );
  };

  //---------------------------
  const handleMenuClickWeeklyBounced = (e) => {
    const key = e.key;
    setSelectedbounceMenu(key);
    setWeeklyBouncedController((Prev) => ({ ...Prev, SelectedOption: key }));
    switch (key) {
      case "Weekly":
        handleweeklyDataBounced();
        break;
      case "15Days":
        handleFetch15DaysDataBounced();
        break;
      case "Monthly":
        handleBouncedMonthClick();
        break;
      case "search":
        setWeeklyBouncedController((prev) => ({ ...prev, search: true }));
        break;
      default:
        break;
    }
  };

  //-------------------------------
  const handleDateRangeChangeforBounce = (dates, dateStrings) => {
    if (dates == null) {
      setWeeklyBouncedController((prev) => ({
        ...prev,
        search: false,
        SelectedOption: "Weekly",
      }));
      handleweeklyDataBounced();
    } else {
      const startDate = dates[0].format("YYYY-MM-DD");
      const endDate = dates[1].format("YYYY-MM-DD");

      axiosClient
        .get(
          `dwelltime/getbounceandengagedforspecifieddates?startDate=${startDate}&endDate=${endDate}`
        )
        .then((response) => {
          setShowRangePickerforBounce(false);
          const apiData = response.data.data;
          const categories = apiData.map((item) => item.createdDate);
          const seriesData = apiData.map((item) => item.totalbounce);

          const BouncedData = apiData.map((item) => item.totalengaged);
          setEngagersweeklychart((prevState) => ({
            ...prevState,
            options: {
              ...prevState.options,
              series: [{ name: "Engagers", data: seriesData }],
              xaxis: { ...prevState.options.xaxis, categories: categories },
            },
          }));
          setBouncedweeklychart((prevState) => ({
            ...prevState,
            options: {
              ...prevState.options,
              series: [{ ...prevState.options.series, data: BouncedData }],
              xaxis: { ...prevState.options.xaxis, categories: categories },
            },
          }));
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  };

  //----------------------------------
  const handleweeklyDataBounced = () => {
    setShowRangePickerforBounce(false);
    axiosClient
      .get("dwelltime/getbounceandengagedperweek")
      .then((response) => {
        if (response.data.issuccess === true) {
          const apiData = response.data.data;
          const categories = apiData.map((item) => item.createdDate);
          const seriesData = apiData.map((item) => item.totalbounce);
          const BouncedData = apiData.map((item) => item.totalengaged);

          setEngagersweeklychart((prevState) => ({
            ...prevState,
            options: {
              ...prevState.options,
              series: [{ name: "Engagers", data: seriesData }],
              xaxis: { ...prevState.options.xaxis, categories: categories },
            },
          }));
          setBouncedweeklychart((prevState) => ({
            ...prevState,
            options: {
              ...prevState.options,
              series: [{ ...prevState.options.series, data: BouncedData }],
              xaxis: { ...prevState.options.xaxis, categories: categories },
            },
          }));
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  //Get bounced 15days data
  const handleFetch15DaysDataBounced = () => {
    setShowRangePickerforBounce(false);
    axiosClient
      .get("dwelltime/getbounceandengagedperfifteendays")
      .then((response) => {
        const apiData = response.data.data;
        const categories = apiData.map((item) => item.createdDate);
        const seriesData = apiData.map((item) => item.totalbounce);
        const BouncedData = apiData.map((item) => item.totalengaged);

        setEngagersweeklychart((prevState) => ({
          ...prevState,
          options: {
            ...prevState.options,
            series: [{ name: "Engagers", data: seriesData }],
            xaxis: { ...prevState.options.xaxis, categories: categories },
          },
        }));

        setBouncedweeklychart((prevState) => ({
          ...prevState,
          options: {
            ...prevState.options,
            series: [{ ...prevState.options.series, data: BouncedData }],
            xaxis: { ...prevState.options.xaxis, categories: categories },
          },
        }));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  //------------------
  const handleBouncedMonthClick = () => {
    axiosClient
      .get(
        `dwelltime/getbounceandengagedforspecifieddates?startDate=${WeeklyBouncedController.StartDate}&endDate=${WeeklyBouncedController.EndDate}`
      )
      .then((response) => {
        setShowRangePickerforBounce(false);
        const apiData = response.data.data;
        const categories = apiData.map((item) => item.createdDate);
        const seriesData = apiData.map((item) => item.totalengaged);
        const BouncedData = apiData.map((item) => item.totalbounce);

        setEngagersweeklychart((prevState) => ({
          ...prevState,
          options: {
            ...prevState.options,
            series: [{ name: "Engagers", data: seriesData }],
            xaxis: { ...prevState.options.xaxis, categories: categories },
          },
        }));

        setBouncedweeklychart((prevState) => ({
          ...prevState,
          options: {
            ...prevState.options,
            series: [{ ...prevState.options.series, data: BouncedData }],
            xaxis: { ...prevState.options.xaxis, categories: categories },
          },
        }));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  //--------------------------------
  const onChangeDate = (date, dateString) => {
    if (dateString === null || dateString === undefined || dateString === "") {
      setselecteddate("");
    } else {
      setselecteddate(dateString);
    }
  };
  //-----------------------------------
  //Get age & gender
  useEffect(() => {
    axiosClient
      .get(`/livereport/getageandgender?date=` + selecteddate + `&camid=`)
      .then((response) => {
        setloading(false);
        if (response.data.issuccess === true) {
          setdatedata(response.data.data.date);
          const ageDistributionData = response.data.data.age_distribution;
          const seriesData = ageDistributionData.map((item) =>
            parseInt(item.count)
          );

          const labelsData = ageDistributionData.map((item) => item.age);

          // setChartDatapieAge((prev) => ({
          //   ...prev,
          //   series: seriesData,
          //   labels:labelsData,
          // }));
          setChartDatapieAge((prev) => ({
            ...prev,
            series: seriesData,
            options: {
              ...prev.options,
              labels: labelsData,
            },
          }));

          // setChartDatapieGender((prev) => ({
          //   ...prev,
          //   series: [
          //     response.data.data.male_percentage,
          //     response.data.data.female_percentage,
          //   ],
          // }));

          if (
            response.data.data.male_percentage !== null &&
            response.data.data.male_percentage !== undefined &&
            response.data.data.female_percentage !== null &&
            response.data.data.female_percentage !== undefined
          ) {
            setChartDatapieGender((prev) => ({
              ...prev,
              series: [
                response.data.data.male_percentage,
                response.data.data.female_percentage,
              ],
            }));
          } else {
            setChartDatapieGender((prev) => ({
              ...prev,
              series: [],
            }));
          }
        }
      })
      .catch((err) => {
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  }, [selecteddate]);

  //------------weekly data
  const handleMenuClick2 = (e) => {
    const key = e.key;
    setSelectedpickhourMenu(key);

    switch (key) {
      case "Weekly":
        handleweeklyDataPeakHour();
        break;
      case "15Days":
        handleFetch15DaysDatapeakhour();
        break;
      case "Monthly":
        handleFetchMonthlyDataPeakHour();
        break;
      case "search":
        setPeakHoursController((prev) => ({ ...prev, search: true }));
        break;
      default:
        break;
    }
  };

  //-------------------------------
  const handleDateRangeChangeforPeakHours = (dates, dateStrings) => {
    if (dates === null || dates === undefined) {
      setPeakHoursController((prev) => ({ ...prev, search: false }));
      setSelectedpickhourMenu("Weekly");
      handleweeklyDataPeakHour();
    } else {
      const startDate = dates[0].format("YYYY-MM-DD");

      const endDate = dates[1].format("YYYY-MM-DD");

      axiosClient
        .get(
          `livereport/getpeakhourforspecifieddates?startDate=${startDate}&endDate=${endDate}`
        )
        .then((response) => {
          setshowRangePickerforpeakHourr(false);
          const apiData = response.data.data;

          const categories = apiData.map((item) => item.date);

          const seriesData = apiData.map((item) => item.peakHour);

          const yAxisLabels = apiData.map((item) => {
            if (typeof item.peakHour === "string") {
              return item.peakHour;
            } else {
              return "";
            }
          });

          setpickhoursweeklydata((prevState) => ({
            ...prevState,
            options: {
              ...prevState.options,
              series: [{ data: seriesData }],
              xaxis: { categories: categories },
              // yaxis: { categories: yAxisLabels },
            },
          }));
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  };
  //--------------------------------------------
  const handleweeklyDataPeakHour = () => {
    setshowRangePickerforpeakHourr(false);
    axiosClient
      .get("livereport/getpeakhourperweek")
      .then((response) => {
        const apiData = response.data.data;

        const categories = apiData.map((item) => item.date);

        const seriesData = apiData.map((item) => item.peakHour);

        const yAxisLabels = apiData.map((item) => {
          if (typeof item.peakHour === "string") {
            return item.peakHour;
          } else {
            return "";
          }
        });

        setpickhoursweeklydata((prevState) => ({
          ...prevState,
          options: {
            ...prevState.options,
            series: [{ data: seriesData }],
            xaxis: { categories: categories },
            // yaxis: { categories: yAxisLabels },
          },
        }));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  //----------------------------------
  const handleFetch15DaysDatapeakhour = () => {
    setshowRangePickerforpeakHourr(false);
    axiosClient
      .get("livereport/getpeakhourperfifteendays")
      .then((response) => {
        const apiData = response.data.data;

        const categories = apiData.map((item) => item.date);

        const seriesData = apiData.map((item) => item.peakHour);

        const yAxisLabels = apiData.map((item) => {
          if (typeof item.peakHour === "string") {
            return item.peakHour;
          } else {
            return "";
          }
        });

        setpickhoursweeklydata((prevState) => ({
          ...prevState,
          options: {
            ...prevState.options,
            series: [{ data: seriesData }],
            xaxis: { categories: categories },
            // yaxis: { categories: yAxisLabels },
          },
        }));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  //---------------------------------------
  const handleFetchMonthlyDataPeakHour = () => {
    setshowRangePickerforpeakHourr(true);
    const startDate = moment().startOf("month").format("YYYY-MM-DD");
    const endDate = moment().endOf("month").format("YYYY-MM-DD");
    axiosClient
      .get(
        `livereport/getpeakhourforspecifieddates?startDate=${startDate}&endDate=${endDate}`
      )
      .then((response) => {
        setshowRangePickerforpeakHourr(false);
        const apiData = response.data.data;

        const categories = apiData.map((item) => item.date);

        const seriesData = apiData.map((item) => item.peakHour);

        const yAxisLabels = apiData.map((item) => {
          if (typeof item.peakHour === "string") {
            return item.peakHour;
          } else {
            return "";
          }
        });

        setpickhoursweeklydata((prevState) => ({
          ...prevState,
          options: {
            ...prevState.options,
            series: [{ data: seriesData }],
            xaxis: { categories: categories },
            // yaxis: { categories: yAxisLabels },
          },
        }));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  //-------------------------

  //Pickhours weekly data
  useEffect(() => {
    axiosClient
      .get("livereport/getpeakhourperweek")
      .then((response) => {
        if (response.data.issuccess === true) {
          const apiData = response.data.data;

          const categories = apiData.map((item) => item.date);

          const seriesData = apiData.map((item) => item.peakHour);

          setpickhoursweeklydata((prevState) => ({
            ...prevState,
            options: {
              ...prevState.options,
              series: [
                { name: prevState.options.series.name, data: seriesData },
              ],
              xaxis: { categories: categories },
              // yaxis: { categories: yAxisLabels },
            },
          }));
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  return (
    <div style={{ margin: "1rem" }}>
      {RenderCompController.DwellFootfall && (
        <Col
          xs={{ span: 24 / 1, offset: 0 }}
          sm={{ span: 24 / 1, offset: 0 }}
          md={{ span: 24 / 1, offset: 0 }}
          lg={{ span: 24 / 1, offset: 0 }}
          xl={{ span: 24 / 1, offset: 0 }}
        >
          <Row gutter={[20, 30]}>
            <Col
              xs={{ span: 24 / 1, offset: 0 }}
              sm={{ span: 22 / 1, offset: 0 }}
              md={{ span: 22, offset: 0 }}
              lg={{ span: 22, offset: 0 }}
              xl={{ span: 16 / 1, offset: 0 }}
            >
              <Row
                align={"stretch"}
                justify={"space-between"}
                gutter={[20, 20]}
              >
                <Col
                  xs={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  md={{ span: 24, offset: 0 }}
                  lg={{ span: 24, offset: 0 }}
                  xl={{ span: 24 / 1, offset: 0 }}
                >
                  <Row gutter={[20, 30]}>
                    <Col
                      xs={{ span: 23, offset: 0 }}
                      sm={{ span: 18, offset: 0 }}
                      md={{ span: 12, offset: 0 }}
                      lg={{ span: 10, offset: 0 }}
                      xl={{ span: 10 / 1, offset: 0 }}
                    >
                      <Card
                        className="ingress-hover"
                        style={{
                          cursor: "pointer",
                          userSelect: "none",
                          boxShadow: "none",
                          minHeight: "150px",
                          // width: "280px",
                        }}
                        title={
                          <>
                            <Row justify={"space-between"} align={"middle"}>
                              <Col>
                                <Title level={4} type="secondary">
                                  DwellTime
                                </Title>
                              </Col>
                              <Col>
                                <DatePicker
                                  className="dwell_date"
                                  onChange={handleDateSelect}
                                  disabledDate={(current) =>
                                    current && current > moment().endOf("day")
                                  }
                                  defaultValue={dayjs().subtract(1, "day")}
                                />
                              </Col>
                            </Row>
                          </>
                        }
                        styles={{
                          body: { paddingTop: 0, paddingBottom: 0 },
                        }}
                      >
                        <div>
                          <Title level={1} style={{ fontWeight: "normal" }}>
                            {dwelltimedata !== null &&
                            dwelltimedata !== undefined
                              ? dwelltimedata
                              : 0}
                          </Title>
                        </div>
                      </Card>
                    </Col>
                    <Col
                      xs={{ span: 23, offset: 0 }}
                      sm={{ span: 20, offset: 0 }}
                      md={{ span: 14, offset: 0 }}
                      lg={{ span: 14, offset: 0 }}
                      xl={{ span: 14 / 1, offset: 0 }}
                    >
                      <Card
                        // className="ingress-hover"
                        style={{
                          cursor: "pointer",
                          userSelect: "none",
                          boxShadow: "none",
                          minHeight: "150px",
                        }}
                        title={
                          <>
                            <Row align={"center"} justify={"space-between"}>
                              <Col>
                                <Title level={4} type="secondary">
                                  Engaged & Bounced
                                </Title>
                              </Col>
                              <Col>
                                <Flex align="center" style={{ height: "100%" }}>
                                  <Dropdown
                                    menu={{
                                      items: EngaedandBouncedController,
                                    }}
                                  >
                                    <Button>
                                      <Space>
                                        <Text>Select camera</Text>
                                        <AiTwotoneEdit />
                                      </Space>
                                    </Button>
                                  </Dropdown>
                                </Flex>
                              </Col>
                            </Row>
                          </>
                        }
                        styles={{
                          body: { paddingTop: 0, paddingBottom: 0 },
                        }}
                      >
                        <Row>
                          <Col>
                            {EngagedandBouncedData.CameraData ? (
                              <Tag>
                                {EngagedandBouncedData.CameraData ? (
                                  EngagedandBouncedData.CameraData.cameraName
                                ) : (
                                  <></>
                                )}
                              </Tag>
                            ) : (
                              <></>
                            )}
                          </Col>
                          <Col>
                            {EngagedandBouncedData.ZoneData ? (
                              <Tag>
                                {EngagedandBouncedData.ZoneData ? (
                                  EngagedandBouncedData.ZoneData.Area
                                ) : (
                                  <></>
                                )}
                              </Tag>
                            ) : (
                              <></>
                            )}
                          </Col>
                        </Row>
                        <div style={{ margin: 0, padding: 0 }}>
                          <>
                            <Row justify={"space-between"}>
                              <Col>
                                <Title
                                  level={2}
                                  style={{ fontWeight: "normal" }}
                                >
                                  Engaged :{" "}
                                  {EngagedandBouncedData.ZoneData
                                    ? EngagedandBouncedData.ZoneData.engaged
                                    : 0}
                                </Title>
                              </Col>
                              <Col>
                                <Col>
                                  <Title
                                    level={2}
                                    style={{ fontWeight: "normal" }}
                                  >
                                    Bounced :{" "}
                                    {EngagedandBouncedData.ZoneData
                                      ? EngagedandBouncedData.ZoneData.bounced
                                      : 0}
                                  </Title>
                                </Col>
                              </Col>
                            </Row>
                          </>
                        </div>
                      </Card>
                    </Col>
                  </Row>
                </Col>
                <Col
                  xs={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  md={{ span: 24, offset: 0 }}
                  lg={{ span: 34, offset: 0 }}
                  xl={{ span: 24 / 1, offset: 0 }}
                >
                  <Row gutter={[20, 30]}>
                    {/* <Col
                                  xs={{ span: 22, offset: 0 }}
                                  sm={{ span: 20, offset: 0 }}
                                  md={{ span: 16, offset: 0 }}
                                  lg={{ span: 12, offset: 0 }}
                                  xl={{ span: 10, offset: 0 }}
                                >
                                  <Card
                                    style={{ minHeight: "100%" }}
                                    styles={{ body: { minHeight: "100%" } }}
                                    title={
                                      <>
                                        <Title level={5}>
                                          Dwell Time Heatmap
                                        </Title>
                                      </>
                                    }
                                  >
                                    <Row>
                                      <Col
                                        xs={{ span: 24, offset: 0 }}
                                        sm={{ span: 24, offset: 0 }}
                                        md={{ span: 24, offset: 0 }}
                                        lg={{ span: 24, offset: 0 }}
                                        xl={{ span: 24, offset: 0 }}
                                      >
                                        <div>
                                          {HeatmapController.cameralist &&
                                          HeatmapController.cameralist.length >
                                            0 ? (
                                            <Carousel showThumbs={false}>
                                              {HeatmapController.cameralist.map(
                                                (item) => {
                                                  return (
                                                    <Skeleton
                                                      loading={
                                                        HeatmapController.loading
                                                      }
                                                      active
                                                      round
                                                    >
                                                      <Image
                                                        src={
                                                          "data:image/png;base64," +
                                                          item.heatMapBase64
                                                        }
                                                        // style={{
                                                        //   maxHeight: "180px",
                                                        // }}
                                                      ></Image>
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          justifyContent:
                                                            "center",
                                                        }}
                                                      >
                                                        <Text className="label-heatmap">
                                                          Avg : {item.avg} sec
                                                          <br />
                                                          camera :{" "}
                                                          {item.cameraName}
                                                        </Text>
                                                      </div>
                                                    </Skeleton>
                                                  );
                                                }
                                              )}
                                            </Carousel>
                                          ) : (
                                            <Col
                                              xs={{ span: 24, offset: 0 }}
                                              sm={{ span: 24, offset: 0 }}
                                              md={{ span: 24, offset: 0 }}
                                              lg={{ span: 24, offset: 0 }}
                                              xl={{ span: 23, offset: 0 }}
                                              style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                marginTop: "60px",
                                              }}
                                            >
                                              <Empty />
                                            </Col>
                                          )}
                                        </div>
                                      </Col>
                                    </Row>
                                  </Card>
                                </Col> */}

                    <Col
                      // xs={{ span: 22, offset: 0 }}
                      // sm={{ span: 20, offset: 0 }}
                      // md={{ span: 16, offset: 0 }}
                      // lg={{ span: 24, offset: 0 }}
                      // xl={{ span: 14, offset: 0 }}
                      xs={22}
                      sm={20}
                      md={16}
                      lg={24}
                      xl={24}
                    >
                      <Row style={{ width: "100%" }}>
                        <Tabs
                          className="Engage-Bounce-tab"
                          style={{ width: "100%" }}
                          defaultActiveKey="dwell"
                          animated={false}
                          destroyInactiveTabPane={true}
                          items={[
                            {
                              key: "dwell",
                              label: (
                                <Flex justify="center" align="center" gap={7}>
                                  <Title level={5} color="black">
                                    Dwell per hour
                                  </Title>
                                  <RxLapTimer color="#00a5cf" size={18} />
                                </Flex>
                              ),
                              children: <DwellHourComp />,
                            },
                            {
                              key: "unique",
                              label: (
                                <Flex justify="center" align="center" gap={7}>
                                  <Title level={5} color="black">
                                    Unique per hour
                                  </Title>
                                  <MdOutlinePeopleAlt
                                    color="#00a5cf"
                                    size={20}
                                  />
                                </Flex>
                              ),
                              children: <UniqueHourComp />,
                            },
                          ]}
                        ></Tabs>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>

            <Col
              xs={{ span: 23, offset: 0 }}
              sm={{ span: 20 / 1, offset: 0 }}
              md={{ span: 18 / 1, offset: 0 }}
              lg={{ span: 14 / 1, offset: 3 }}
              xl={{ span: 8 / 1, offset: 0 }}
            >
              <Card
                // loading={loading}
                style={{
                  // width: "100%",
                  cursor: "pointer",
                  userSelect: "none",
                  height: "100%",
                  boxShadow: "none",
                  // marginRight: "30px",
                  // marginLeft: "18px",
                  //  height: "5px",
                  // marginBottom: "30px",
                }}
                styles={{
                  body: {
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingTop: 0,
                    margin: 0,
                    border: 0,
                  },
                  header: { border: 0 },
                }}
                title={
                  <>
                    <Row justify={"space-between"} align={"bottom"}>
                      <Col>
                        <Title level={5}>Footfall Analysis</Title>
                      </Col>
                      <Col>
                        <div style={{ display: "inline-flex" }}>
                          <div>
                            <DatePicker
                              onChange={onChange}
                              picker="month"
                              disabledDate={disabledFutureMonths}
                              allowClear={true}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </>
                }
              >
                {chartFootfallanalysis.series &&
                chartFootfallanalysis.series.length > 0 ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border: 0,
                    }}
                  >
                    <Chart
                      options={chartFootfallanalysis.options}
                      series={chartFootfallanalysis.series}
                      type="radar"
                      height={"200%"}
                    />
                  </div>
                ) : (
                  <div
                    style={{
                      textAlign: "center",
                      padding: "20px",
                    }}
                  >
                    <Text style={{ fontSize: "16px", color: "gray" }}>
                      No Data Available
                    </Text>
                  </div>
                )}
              </Card>
            </Col>
          </Row>
        </Col>
      )}
      {RenderCompController.EngagedBounced && (
        <Col
          xs={{ span: 24 / 1, offset: 0 }}
          sm={{ span: 24 / 1, offset: 0 }}
          md={{ span: 24 / 1, offset: 0 }}
          lg={{ span: 24 / 1, offset: 0 }}
          xl={{ span: 24 / 1, offset: 0 }}
        >
          <Row style={{ width: "100%", marginTop: "2rem" }}>
            <Tabs
              className="Engage-Bounce-tab"
              style={{ width: "100%" }}
              defaultActiveKey="live"
              animated={false}
              destroyInactiveTabPane={true}
              items={[
                {
                  key: "processed",
                  label: (
                    <Flex justify="center" align="center" gap={7}>
                      <Title level={5} color="black">
                        Processed
                      </Title>
                      <AiOutlineHistory color="#00a5cf" size={18} />
                    </Flex>
                  ),
                  children: <EngageAndBounce />,
                },
              ]}
            ></Tabs>
          </Row>
        </Col>
      )}

      {/* ---------- */}

      {/* {RenderCompController.AgeGender && ( */}
      <Col
        xs={{ span: 24 / 1, offset: 0 }}
        sm={{ span: 24 / 1, offset: 0 }}
        md={{ span: 24 / 1, offset: 0 }}
        lg={{ span: 24 / 1, offset: 0 }}
        xl={{ span: 24 / 1, offset: 0 }}
      >
        <Card
          style={{
            // borderRadius: "15px",
            // height: "360px",
            userSelect: "none",
            boxShadow: "none",
            marginTop: "2rem",
          }}
          title={
            <>
              <Row justify={"space-between"} align={"middle"}>
                <Col>
                  <Title level={5}>
                    Total detected Gender Distribution in %
                  </Title>
                </Col>
                <Col>
                  <DatePicker
                    onChange={onChangeDate}
                    format={"YYYY-MM-DD"}
                    disabledDate={(current) => {
                      return current && current > moment().endOf("day");
                    }}
                    defaultValue={dayjs().subtract(1, "day")}
                  />
                </Col>
                <Col>
                  <Title level={5}>Total detected Age Distribution in %</Title>
                </Col>
              </Row>
            </>
          }
        >
          <Row
            style={{ height: "100%" }}
            justify={"space-between"}
            align={"middle"}
          >
            <Col
              xs={{ span: 23 / 1, offset: 0 }}
              sm={{ span: 23 / 1, offset: 0 }}
              md={{ span: 11 / 1, offset: 0 }}
              lg={{ span: 11 / 1, offset: 0 }}
              xl={{ span: 11, offset: 0 }}
            >
              <div style={{ height: "250px" }}>
                <ReactApexChart
                  options={chartDatapieGender.options}
                  series={chartDatapieGender.series}
                  type="donut"
                  height="100%"
                />
              </div>
            </Col>
            <Divider type="vertical" style={{ height: "250px" }} />
            <Col
              xs={{ span: 23 / 1, offset: 0 }}
              sm={{ span: 23 / 1, offset: 0 }}
              md={{ span: 11 / 1, offset: 0 }}
              lg={{ span: 11 / 1, offset: 0 }}
              xl={{ span: 11, offset: 0 }}
              style={{ height: "100%" }}
            >
              <div style={{ height: "250px" }}>
                <ReactApexChart
                  options={chartDatapieAge.options}
                  series={chartDatapieAge.series}
                  type="donut"
                  height="100%"
                />
              </div>
            </Col>
          </Row>
        </Card>
      </Col>
      {/* -------------------------------------------- */}

      {RenderCompController.PeakHours && (
        <Col
          xs={{ span: 24 / 1, offset: 0 }}
          sm={{ span: 24 / 1, offset: 0 }}
          md={{ span: 24 / 1, offset: 0 }}
          lg={{ span: 24 / 1, offset: 0 }}
          xl={{ span: 24 / 1, offset: 0 }}
        >
          <Row>
            <Col
              xs={{ span: 23 / 1, offset: 0 }}
              sm={{ span: 20 / 1, offset: 0 }}
              md={{ span: 18 / 1, offset: 0 }}
              lg={{ span: 12 / 1, offset: 0 }}
              xl={{ span: 24, offset: 0 }}
            >
              <Card
                style={{
                  // borderRadius: "15px",
                  // height: "360px",
                  userSelect: "none",
                  boxShadow: "none",
                  marginTop: "2rem",
                }}
                title={
                  <>
                    <Row justify={"space-between"} align={"middle"}>
                      <Col>
                        <Title level={4}>
                          {selectedpickhourMenu === "Weekly"
                            ? "Weekly Peak Hour"
                            : selectedpickhourMenu === "15Days"
                            ? "Last 15 Days Peak Hour "
                            : "Monthly Peak Hour "}
                        </Title>
                      </Col>
                      <Col>
                        <Flex gap={10}>
                          {PeakHoursController.search && (
                            <RangePicker
                              onChange={handleDateRangeChangeforPeakHours}
                              style={{ marginBottom: "10px" }}
                              disabledDate={(current) =>
                                current && current > moment().endOf("day")
                              }
                              allowClear
                            />
                          )}

                          <Dropdown
                            // overlay={menuforpeakvalue}
                            menu={{
                              items: menuforpeakvalue,
                              onClick: handleMenuClick2,
                            }}
                            placement="bottomRight"
                          >
                            <Button>
                              <Flex gap={10} align="center">
                                Filters
                                <LuFilter />
                              </Flex>
                            </Button>
                          </Dropdown>
                        </Flex>
                      </Col>
                    </Row>
                  </>
                }
              >
                <div style={{ height: "250px" }}>
                  <ReactApexChart
                    options={pickhoursweeklyData.options}
                    series={pickhoursweeklyData.options.series}
                    height="100%"
                  />
                </div>
              </Card>
            </Col>
          </Row>
        </Col>
      )}
    </div>
  );
};

export default Monthly;
