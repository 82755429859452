import {
  Card,
  Col,
  Row,
  Select,
  Typography,
  Tabs,
  Modal,
  message,
  Table,
  Button,
  Spin,
  Alert,
  Result,
  Form,
  Radio,
  Space,
  Tag,
  Divider,
  notification,
  Progress,
  Avatar,
  List,
  Menu,
  Dropdown,
  Tooltip,
  Image,
  Skeleton,
  Empty,
  Flex,
  TimePicker,
  Drawer,
} from "antd";

import { DatePicker } from "antd";
import { CiImageOn } from "react-icons/ci";
import React from "react";
import { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Sparklines, SparklinesLine } from "react-sparklines";
import axiosClient from "../../Authentication/ApiCall";
import { FaCameraRetro, FaRegDotCircle } from "react-icons/fa";
import { useSelector } from "react-redux";
import moment from "moment/moment";
import Chart from "react-apexcharts";
import ReactApexChart from "react-apexcharts";
import { MdOutlineDirections, MdOutlinePeopleAlt } from "react-icons/md";
import { GrDirections } from "react-icons/gr";
import { IoIosSearch, IoMdCloseCircle } from "react-icons/io";
import { io } from "socket.io-client";
import {
  AiOutlineHistory,
  AiOutlineUserSwitch,
  AiTwotoneEdit,
} from "react-icons/ai";
import { PDFExport } from "@progress/kendo-react-pdf";
import { RxLapTimer } from "react-icons/rx";
import TabPane from "antd/lib/tabs/TabPane";
import authService from "../../Authentication/authService";
import _, { filter, find, flatMap, map, orderBy, values } from "lodash";
import {
  ArrowUpOutlined,
  ArrowDownOutlined,
  CalendarOutlined,
  TeamOutlined,
  CameraOutlined,
  DownOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
// import { IoIosCloseCircleOutline } from "react-icons/io";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { IoMdTime } from "react-icons/io";
import { FaCaretDown } from "react-icons/fa";
import { IoIosSend } from "react-icons/io";
import { FaChartSimple, FaRegCircleDot } from "react-icons/fa6";
import CheckableTag from "antd/lib/tag/CheckableTag";
import html2canvas from "html2canvas";
// import { DensityChart } from "./DensityChart";
import jsPDF from "jspdf";
import generatePDF from "react-to-pdf";
import { useScreenshot } from "use-react-screenshot";
import { Document, Page, View } from "@react-pdf/renderer";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import dayjs from "dayjs";
import randomColor from "randomcolor";
import { BiEdit } from "react-icons/bi";
import { IoFilter } from "react-icons/io5";
import { LuFilter } from "react-icons/lu";
import useInterval from "use-interval";
import Live from "./Mall/Live";
import { WeekNumberRoot } from "@fullcalendar/react";
import Weekly from "./Mall/Weekly";
import Monthly from "./Mall/Monthly";
import HeatMap from "./Mall/HeatMap";
const { Title, Link, Text } = Typography;
const { RangePicker } = DatePicker;

let DwellHourlyDetails = {
  camlist: [],
  selectedcam: null,
  selectedDate: dayjs().format("YYYY-MM-DD"),
  DataOfDate: null,
  selectedcameraname: null,
};

let UniqueHourlyDetails = {
  camlist: [],
  selectedcam: "",
  selectedDate: dayjs().format("YYYY-MM-DD"),
  DataOfDate: null,
  selectedcameraname: null,
};

let EngageAndBounceHourlyDetails = {
  camlist: [],
  selectedcam: null,
  selectedDate: dayjs().format("YYYY-MM-DD"),
  DataOfDate: null,
};

let EngaedAndBouncedDetails = {
  CameraData: null,
  ZoneData: null,
};

let weeklybouncedDetails = {
  SelectedOption: "Weekly",
  StartDate: dayjs().startOf("month").format("YYYY-MM-DD"),
  EndDate: dayjs().endOf("month").format("YYYY-MM-DD"),
  search: false,
};

let PeakHoursDetails = {
  SelectedOption: "Weekly",
  StartDate: dayjs().startOf("month").format("YYYY-MM-DD"),
  EndDate: dayjs().endOf("month"),
  search: false,
};

let IngressEgressDetails = {
  SelectedDate: "",
  ContinueFetch: true,
};

let FootfallDirectionHourlyDetails = {
  camlist: [],
  selectedcam: "",
  selectedcamName: "",
  selectedDate: dayjs().format("YYYY-MM-DD"),
  DataOfDate: "",
  DirectionImage: null,
  DirectionData: [],
  loading: false,
  Hour: "", // moment().hours(),
};

let RenderComp = {
  IngressEgress: false,
  DwellFootfall: false,
  EngagedBounced: false,
  AgeGender: false,
  PeakHours: false,
};
const NewFootfall = () => {
  const containerRef = useRef(null);
  const chartRef = useRef(null);
  const targetRef = useRef();
  const location = useLocation();

  const [cardData, setcardData] = useState([]);
  const [ingressloading, setingressloading] = useState(true);
  const [loading, setloading] = useState(true);
  const [downloadloading, setdownloadloading] = useState(false);
  const [DownloadDashboard, setDownloadDashboard] = useState(false);

  const [selecteddate, setselecteddate] = useState("");
  const [api, contextHolder] = notification.useNotification();
  const [YesterDayData, setyeaterdayData] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [totalingress, settotalingress] = useState();
  const [totalingrespercentage, settotalingresspercentage] = useState();
  const [totalegress, settotalegress] = useState();
  const [totalegresspercentage, settotalegresspercentage] = useState();
  const [progressbar, setprogressbar] = useState();
  const [selectdwelltimedate, setselectdwelltimedate] = useState();
  const [DwellHourlyController, setDwellHourlyController] =
    useState(DwellHourlyDetails);

  const [EngageBounceHourController, setEngageBounceHourController] = useState(
    EngageAndBounceHourlyDetails
  );

  const [UniqueHourlyController, setUniqueHourlyController] =
    useState(UniqueHourlyDetails);
  const [RenderCompController, setRenderCompController] = useState(RenderComp);
  const [
    FootfallDirectionHourlyController,
    setFootfallDirectionHourlyController,
  ] = useState(FootfallDirectionHourlyDetails);

  const [EngaedandBouncedController, setEngaedandBouncedController] = useState(
    []
  );

  const [HandleDateForIngressEgress, setHandleDateForIngressEgress] =
    useState(IngressEgressDetails);

  const [EngagedandBouncedData, setEngagedandBouncedData] = useState(
    EngaedAndBouncedDetails
  );
  const [totalactivecamera, settotalactivecamera] = useState();
  const [livedata, setlivedata] = useState({
    ingress: 0,
    egress: 0,
  });

  const [data, setdata] = useState([]);
  const [dwelltimedata, setdwelltimedata] = useState("");

  const [showDatePicker, setShowDatePicker] = useState(false);
  const [month, setmonth] = useState();
  const [selectedDate, setSelectedDate] = useState(null);
  const [cameraoption, setcameraoption] = useState([]);
  const [engagevalue, setengagevalue] = useState("");
  const [bouncevalue, setbouncevalue] = useState("");
  const [cameraname, setcameraname] = useState("");
  const [areaname, setareaname] = useState("Select area");
  const [bouncecameraname, setbouncecameraname] = useState();
  const [peakHour, setpeakHour] = useState();
  const [yesterdaypeakHour, setyesterdaypeakHour] = useState();
  const [label, setlabel] = useState([]);
  const [showRangePicker, setShowRangePicker] = useState(false);
  const [showRangePickerforBounce, setShowRangePickerforBounce] =
    useState(false);

  const [showRangePickerforpeakHour, setshowRangePickerforpeakHourr] =
    useState(false);

  const [WeeklyBouncedController, setWeeklyBouncedController] =
    useState(weeklybouncedDetails);

  const [PeakHoursController, setPeakHoursController] =
    useState(PeakHoursDetails);

  const [selectedbounceMenu, setSelectedbounceMenu] = useState("Weekly");
  const [selectedpickhourMenu, setSelectedpickhourMenu] = useState("Weekly");
  const [Totalcamera, setTotalcamera] = useState(0);
  const [footfallactivecamera, setfootfallactivecamera] = useState(0);
  const [ingessegresshour, setingressegresshour] = useState("");
  const [dwelltimeavg, setdwelltimeavg] = useState({});

  const [dwellRegion, setdwellRegion] = useState([]);

  const [datedata, setdatedata] = useState("");

  const [DwellHourly, setDwellHourly] = useState({
    options: {
      chart: {
        id: "basic-bar",
        type: "bar",
        stacked: true,
        toolbar: { show: false },
        contextmenu: { enabled: false },
      },
      plotOptions: {
        bar: { horizontal: false },
      },
      xaxis: {
        categories: [],
        title: { text: "Hour ", offsetY: -10 },
      },
      yaxis: { title: { text: "Seconds" } },
      legend: {
        position: "top",
        horizontalAlign: "center",
        offsetX: 0,
      },
      colors: ["#FF5733", "#C70039", "#900C3F", "#FFC300", "#DAF7A6"],
    },
    series: [],
  });

  const [EngagedHourlyChart, setEngagedHourlyChart] = useState({
    options: {
      chart: {
        id: "basic-bar",
        type: "bar",
        stacked: true,
        toolbar: { show: false },
        contextmenu: { enabled: false },
      },
      plotOptions: {
        bar: { horizontal: false },
      },
      xaxis: {
        categories: [],
        title: { text: "Hour ", offsetY: -10 },
      },
      yaxis: { title: { text: "Seconds" } },
      legend: {
        position: "top",
        horizontalAlign: "center",
        offsetX: 0,
      },
      colors: ["#FF5733", "#C70039", "#900C3F", "#FFC300", "#DAF7A6"],
    },
    series: [],
  });

  const [UniqueHourlyChart, setUniqueHourlyChart] = useState({
    options: {
      chart: {
        id: "basic-bar",
        type: "bar",
        stacked: true,
        toolbar: { show: false },
        contextmenu: { enabled: false },
      },
      plotOptions: {
        bar: { horizontal: false },
      },
      xaxis: {
        categories: [],
        title: { text: "Hour ", offsetY: -10 },
      },
      yaxis: { title: { text: "count" } },
      legend: {
        position: "top",
        horizontalAlign: "center",
        offsetX: 0,
      },
      colors: ["#FF5733", "#C70039", "#900C3F", "#FFC300", "#DAF7A6"],
    },
    series: [],
  });
  const [BouncedHourlyChart, setBouncedHourlyChart] = useState({
    options: {
      chart: {
        id: "basic-bar",
        type: "bar",
        stacked: true,
        toolbar: { show: false },
        contextmenu: { enabled: false },
      },
      plotOptions: {
        bar: { horizontal: false },
      },
      xaxis: {
        categories: [],
        title: { text: "Hour ", offsetY: -10 },
      },
      yaxis: { title: { text: "Seconds" } },
      legend: {
        position: "top",
        horizontalAlign: "center",
        offsetX: 0,
      },
      colors: ["#FF5733", "#C70039", "#900C3F", "#FFC300", "#DAF7A6"],
    },
    series: [],
  });

  //ingress&egress per hour chart
  const [chartData, setChartData] = useState({
    options: {
      chart: {
        id: "basic-bar",
        animations: {
          enabled: false,
        },
        toolbar: { show: false },
        contextmenu: { enabled: false },
      },
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      xaxis: {
        type: "category", // Change type to category for categorical data
        categories: [], // Initialize categories as empty array
        title: {
          text: "Hour",
          offsetY: -10,
        },
      },
      yaxis: {
        type: "count",
        title: {
          text: "count",
          offsetY: -10,
        },
      },
      colors: ["#00e396", "#008ffb"],
      dataLabels: {
        enabled: false,
        offsetY: -15,
        style: {
          colors: ["#615494", "#f27777"],
        },
      },
      stroke: {
        width: 1,
      },
      pattern: {
        strokeWidth: 0.1,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          dataLabels: {
            position: "top",
            offsetY: -20,
            width: 1,
            style: {
              fontSize: "12px",
              colors: [" #000"],
              offsetY: -20,
            },
          },
        },
        dataLabels: {
          enabled: true,
          hideOverflowingLabels: true,
          formatter: function () {
            return 25;
          },
          offsetY: -20,
        },
      },
    },
    series: [
      {
        name: "Ingress",
        data: [],
      },
      {
        name: "Egress",
        data: [],
      },
    ],
  });

  //Footfall analysis chart
  const [chartFootfallanalysis, setChartFootfallanalysis] = useState({
    series: [
      {
        name: "Footfall",
        data: [0, 0],
      },
    ],
    options: {
      chart: {
        id: "basic-bar",
        animations: {
          enabled: false,
        },
        toolbar: { show: false },
        contextmenu: { enabled: false },
      },
      xaxis: {
        type: "day",
        categories: [],
        title: {
          text: "Day",
          offsetY: -10,
          labels: {
            show: true,
            style: {
              colors: ["#a8a8a8"],
            },
          },
        },
      },
      stroke: {
        width: 1,
      },
      pattern: {
        strokeWidth: 0.1,
      },
      // yaxis: {
      //   labels: {
      //     show: false,
      //   },
      // },
      // tooltip: {
      //   enabled: true,
      // },

      // dataLabels: {
      //   enabled: true,
      //   background: {
      //     enabled: true,
      //     // borderRadius:2,
      //   }
      // }

      // tooltip: {
      //   enabled: false
      // }
      // xaxis: {
      //   categories:Day

      // [
      //   "Monday",
      //   "Tuesday",
      //   "Wednesday",
      //   "Thursday",
      //   "Friday",
      //   "Saturday",
      //   "Sunday",
      // ],
      // },
    },
  });

  const [chartDatapieGender, setChartDatapieGender] = useState({
    series: [],

    options: {
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
            },
          },
        },
      },
      chart: {
        width: 380,
        type: "pie",
      },
      labels: ["Male", "Female"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  });

  const [chartDatapieAge, setChartDatapieAge] = useState({
    series: [],
    options: {
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
            },
          },
        },
      },
      chart: {
        width: 380,
        type: "pie",
      },
      colors: [
        "#F44336",
        "#E91E63",
        "#9C27B0",
        "#255aee",
        "#4eed9e",
        "#078f4b",
        "#6088d6",
        "#FFC107",
      ],
      labels: [
        "0-10",
        "11-20",
        "21-30",
        "31-40",
        "41-50",
        "51-60",
        "61-70",
        "71-100",
      ],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  });

  const [HeatmapController, setHeatmapController] = useState({
    loading: false,
    cameralist: [],
  });

  const [HeatmapDetails, setHeatmapDetails] = useState({
    modalopen: false,
    details: null,
    imageloading: false,
  });

  const [Engagersweeklychart, setEngagersweeklychart] = useState({
    options: {
      series: [
        {
          name: "Engagers",
          data: [],
          // data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
        },
      ],
      chart: {
        height: 350,
        type: "bar",
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },

      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: [],
      },
    },
  });

  const [Bouncedweeklychart, setBouncedweeklychart] = useState({
    options: {
      series: [
        {
          name: "Bounced",
          data: [],
          // data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
        },
      ],

      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      xaxis: {
        categories: [],
        // categories: [
        //   "Jan",
        //   "Feb",
        //   "Mar",
        //   "Apr",
        //   "May",
        //   "Jun",
        //   "Jul",
        //   "Aug",
        //   "Sep",
        // ],
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      colors: ["#00e396"],
    },
  });

  const [pickhoursweeklyData, setpickhoursweeklydata] = useState({
    options: {
      series: [
        {
          name: "Peak Hour",
          data: [],
          // data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
        },
      ],
      chart: {
        height: 350,
        type: "line",
        color: "#64e38a",
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
        width: 1,
      },
      pattern: {
        strokeWidth: 0.1,
      },
      // title: {
      //   text: "Weekly Data for Bounced",
      //   align: "left",
      // },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: [],
      },
      // yaxis: {
      //   min: 1,
      //   max: 24,
      //   tickAmount: 24,
      // },
      yaxis: {
        min: 2,
        max: 24,
        tickAmount: 12,
      },
    },
  });

  const generatePDF = async () => {
    if (containerRef.current && targetRef.current) {
      setDownloadDashboard(true);
      console.log("container adding pdf");

      try {
        const pdfExportComponent = containerRef.current;
        await pdfExportComponent.save();
        setDownloadDashboard(false);
      } catch (error) {
        console.log("unable to save", error);
      }
      console.log("container save the pdf");
    }
  };

  useInterval(async () => {
    if (HandleDateForIngressEgress.ContinueFetch === true) {
      await axiosClient
        .get(`/livereport/getingressegress?date&camid=`)
        .then((response) => {
          if (response.data.issuccess === true) {
            setingressloading(false);

            settotalingress(response.data.data.total_ingress_egress);
            settotalingresspercentage(
              response.data.data.percentage_ingress_basedOnTime
            );
            const list_hour = map(
              response.data.data.total_records_hour,
              "hour"
            );

            const list_ingress = map(
              response.data.data.total_records_hour,
              "totalingress"
            );
            const list_egress = map(
              response.data.data.total_records_hour,
              "totalegress"
            );
            setChartData((prev) => ({
              ...prev,
              options: {
                ...prev.options,
                xaxis: {
                  // ...prev.options.xaxis,
                  categories: list_hour,
                },
              },
              series: [
                {
                  name: "Ingress",
                  data: list_ingress,
                },
                {
                  name: "Egress",
                  data: list_egress,
                },
              ],
            }));
          }
        })
        .catch((err) => {
          console.log("errr", err);
          if (err.status === 0) {
            message.error("Network error");
          } else {
            message.error(err.message);
          }
        });
    }
    try {
      const response = await axiosClient
        .get("crowdAnalysis/getCrowdAnalysisDetails")
        .then((response) => {
          if (response.data.issuccess === true) {
            setdata(response.data.data);
          }
        })
        .catch((err) => {
          console.log("errr", err);
          if (err.status === 0) {
            message.error("Server error");
          } else {
            message.error(err.message);
          }
        });
    } catch (err) {
      console.log("Error from try catch crowd analysis", err);
    }
  }, 20000);

  useEffect(() => {
    axiosClient
      .get(
        `/livereport/getingressegress?date=` +
          HandleDateForIngressEgress.SelectedDate +
          `&camid=` +
          ""
      )
      .then((response) => {
        if (response.data.issuccess === true) {
          setingressloading(false);
          settotalingress(response.data.data.total_ingress_egress);
          settotalingresspercentage(
            response.data.data.percentage_ingress_basedOnTime
          );

          setpeakHour(response.data.data.currentPeakHourRange);
          setyesterdaypeakHour(response.data.data.yesterdayPeakHourRange);
          const list_hour = map(response.data.data.total_records_hour, "hour");

          const list_ingress = map(
            response.data.data.total_records_hour,
            "totalingress"
          );
          const list_egress = map(
            response.data.data.total_records_hour,
            "totalegress"
          );
          setChartData((prev) => ({
            ...prev,
            options: {
              ...prev.options,
              xaxis: {
                // ...prev.options.xaxis,
                categories: list_hour,
              },
            },
            series: [
              {
                name: "Ingress",
                data: list_ingress,
              },
              {
                name: "Egress",
                data: list_egress,
              },
            ],
          }));
        }
      })
      .catch((err) => {
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Network error");
        } else {
          message.error(err.message);
        }
      });

    const FetchDataForCrowdAnalysis = async () => {
      try {
        await axiosClient
          .get("crowdAnalysis/getCrowdAnalysisDetails")
          .then((response) => {
            if (response.data.issuccess === true) {
              setdata(response.data.data);
            }
          })
          .catch((err) => {
            console.log("errr", err);
            if (err.status === 0) {
              message.error("Server error");
            } else {
              message.error(err.message);
            }
          });
      } catch (err) {
        console.log("Error from try catch crowd analysis", err);
      }
    };

    try {
      FetchDataForCrowdAnalysis();
    } catch (err) {
      console.log("Error from try catch crowd analysis", err);
    }
  }, [HandleDateForIngressEgress.SelectedDate]);

  useEffect(() => {
    axiosClient
      .get("/notification/getEventBasedActiveCamera?eventid=" + 13)

      .then((response) => {
        if (response.data.issuccess === true) {
          setTotalcamera(response.data.onboardCameraDetails.count);
          setfootfallactivecamera(response.data.eventBasedCameraDetails.count);
        } else {
          console.log("error");
        }
      })
      .catch((err) => {
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  }, []);

  // spider graph initial fetch
  useEffect(() => {
    axiosClient
      .get(`/livereport/spidergraphdata`)
      .then((response) => {
        if (response.data.issuccess === true) {
          // Spiderchart
          const responseData =
            response.data.data.spiderIngress.length > 0
              ? response.data.data.spiderIngress[0]
              : [];

          const uniqueWeekwise = [
            ...new Set(responseData.map((item) => item.weekwise)),
          ];

          // Extracting data for the radar chart
          const seriesData = uniqueWeekwise.map((weekwise) => {
            return {
              name: weekwise,
              data: responseData
                .filter((item) => item.weekwise === weekwise)
                .map((item) => item.totalingress),
            };
          });

          // Setting up chart options
          const options = {
            chart: {
              height: 400,
              type: "radar",
            },
            xaxis: {
              categories: responseData.map((item) => item.week),
              title: {
                text: "Week",
                offsetY: -10,
                labels: {
                  show: true,
                  style: {
                    colors: ["#a8a8a8"],
                  },
                },
              },
            },
            colors: [
              "#008FFB",
              "#00E396",
              "#FEB019",
              "#FF4560",
              "#775DD0",
              "#FF9F00",
            ],
          };

          setChartFootfallanalysis({
            series: seriesData,
            options: options,
          });
        }
      })
      .catch((err) => {
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Network error");
        } else {
          message.error(err.message);
        }
      });
  }, []);

  //Pickhours weekly data
  useEffect(() => {
    axiosClient
      .get("livereport/getpeakhourperweek")
      .then((response) => {
        if (response.data.issuccess === true) {
          const apiData = response.data.data;

          const categories = apiData.map((item) => item.date);

          const seriesData = apiData.map((item) => item.peakHour);

          setpickhoursweeklydata((prevState) => ({
            ...prevState,
            options: {
              ...prevState.options,
              series: [
                { name: prevState.options.series.name, data: seriesData },
              ],
              xaxis: { categories: categories },
              // yaxis: { categories: yAxisLabels },
            },
          }));
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  //Get age & gender
  useEffect(() => {
    axiosClient
      .get(`/livereport/getageandgender?date=` + selecteddate + `&camid=`)
      .then((response) => {
        setloading(false);
        if (response.data.issuccess === true) {
          setdatedata(response.data.data.date);
          const ageDistributionData = response.data.data.age_distribution;
          const seriesData = ageDistributionData.map((item) =>
            parseInt(item.count)
          );

          const labelsData = ageDistributionData.map((item) => item.age);

          // setChartDatapieAge((prev) => ({
          //   ...prev,
          //   series: seriesData,
          //   labels:labelsData,
          // }));
          setChartDatapieAge((prev) => ({
            ...prev,
            series: seriesData,
            options: {
              ...prev.options,
              labels: labelsData,
            },
          }));

          // setChartDatapieGender((prev) => ({
          //   ...prev,
          //   series: [
          //     response.data.data.male_percentage,
          //     response.data.data.female_percentage,
          //   ],
          // }));

          if (
            response.data.data.male_percentage !== null &&
            response.data.data.male_percentage !== undefined &&
            response.data.data.female_percentage !== null &&
            response.data.data.female_percentage !== undefined
          ) {
            setChartDatapieGender((prev) => ({
              ...prev,
              series: [
                response.data.data.male_percentage,
                response.data.data.female_percentage,
              ],
            }));
          } else {
            setChartDatapieGender((prev) => ({
              ...prev,
              series: [],
            }));
          }
        }
      })
      .catch((err) => {
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  }, [selecteddate]);

  //Get Engagers weekly data
  useEffect(() => {
    axiosClient
      .get("dwelltime/getbounceandengagedperweek")
      .then((response) => {
        if (response.data.issuccess === true) {
          const apiData = response.data.data;
          const categories = apiData.map((item) => item.createdDate);
          const seriesData = apiData.map((item) => item.totalengaged);
          const BouncedData = apiData.map((item) => item.totalbounce);

          setEngagersweeklychart((prevState) => ({
            ...prevState,
            options: {
              ...prevState.options,
              series: [{ name: "Engagers", data: seriesData }],
              xaxis: { ...prevState.options.xaxis, categories: categories },
            },
          }));
          setBouncedweeklychart((prevState) => ({
            ...prevState,
            options: {
              ...prevState.options,
              series: [{ data: BouncedData }],
              xaxis: { ...prevState.options.xaxis, categories: categories },
            },
          }));
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  //Dwell time data
  useEffect(() => {
    setHeatmapController((prev) => ({ ...prev, loading: true }));

    axiosClient
      .get("/dwelltime/getDwellTimeDetails?id=")
      .then((response) => {
        if (response.data.issuccess === true) {
          setHeatmapController((prev) => ({
            ...prev,
            cameralist: response.data.data,
          }));
          setHeatmapController((prev) => ({ ...prev, loading: false }));
        }
      })
      .catch((err) => {
        setHeatmapController((prev) => ({ ...prev, loading: false }));
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  }, []);

  //Get Dwell Time  data
  useEffect(() => {
    axiosClient
      .get("/dwelltime/getaveragebasedondate?date=")
      .then((response) => {
        if (response) {
          setdwelltimedata(response.data.data.dwellTimeAvg);
        }
      })
      .catch((err) => {
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  }, []);

  //get cameralist
  useEffect(() => {
    axiosClient
      .get("/dwelltime/getcamerabasedondwelltime")
      .then((response) => {
        if (response.data.issuccess === true) {
          // response.data.data.map((item, index) => {
          //   let camlist = {
          //     value: item.cameraId,
          //     label: item.cameraName,
          //   };
          //   setcameraoption((prev) => [...prev, camlist]);
          // });

          setcameraoption(response.data.data);
        }
      })
      .catch((err) => {
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  }, []);

  // initial fetch of engagae &bounce
  useEffect(() => {
    axiosClient
      .get("/dwelltime/getdwelltimebasedoncameraid?cameraId=10")

      .then((response) => {
        if (response.data.issuccess === true) {
          if (response.data.data.length > 0) {
            const EBdataDefault = response.data.defaultData;
            const newMenuList = response.data.data.map((item, index) => {
              return {
                key: item.cameraId.toString(),
                label: item.cameraName,
                children:
                  item.zoneData.length > 0
                    ? item.zoneData.map((zone) => ({
                        key: `${item.cameraId}-${zone.id}`,
                        label: (
                          <Text
                            onClick={() => handleEngagedAndBounced(zone, item)}
                          >
                            {zone.Area}
                          </Text>
                        ),
                        item: { zone },
                      }))
                    : [],
              };
            });
            setEngaedandBouncedController(newMenuList);
            setEngagedandBouncedData((prev) => ({
              ...prev,
              CameraData: EBdataDefault,
              ZoneData:
                EBdataDefault.zoneData && EBdataDefault.zoneData.length > 0
                  ? EBdataDefault.zoneData[0]
                  : null,
            }));
          }
        } else {
          console.log("error engage data api call successfully");
        }
      })
      .catch((err) => {
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  }, []);

  const extractZoneNames = (data) => {
    return _(data)
      .flatMap(_.keys)
      .filter((key) => key.startsWith("Area"))
      .uniq()
      .value();
  };
  const transformDataEngageAndBounce = (data, zoneNames) => {
    const hours = _.map(data, "hour");

    const seriesEngage = _.map(zoneNames, (zone) => {
      const Engage_avg = _.map(data, zone + ".engaged");
      const Final_Engage = Engage_avg.map((item) => Math.round(item));
      return {
        name: zone,
        data: Final_Engage,
      };
    });

    const seriesBounce = _.map(zoneNames, (zone) => {
      const Bounce_avg = _.map(data, zone + ".bounced");
      const Final_Bounce = Bounce_avg.map((item) => Math.round(item));
      return {
        name: zone,
        data: Final_Bounce,
      };
    });

    return { hours, seriesEngage, seriesBounce };
  };

  const transformData = (data, zoneNames) => {
    const hours = _.map(data, "hour");
    const series = _.map(zoneNames, (zone) => {
      const DwellTime_avg = _.map(data, zone + ".average_dwell_time");
      const Final_Dwell = DwellTime_avg.map((item) => Math.round(item));
      return {
        name: zone,
        data: Final_Dwell,
      };
    });

    return { hours, series };
  };

  useEffect(() => {
    axiosClient
      .get(
        `/dwelltime/getDwellTimeHourData?date=${DwellHourlyController.selectedDate}&camId=${DwellHourlyController.selectedcam}`
      )

      .then((response) => {
        if (response.data.issuccess === true) {
          const data = response.data.data.dwellData
            ? response.data.data.dwellData
            : [];

          const selectedcamDetails = response.data.data.selectedCameraDetails;
          const CameraList = response.data.data.cameraData;
          if (selectedcamDetails) {
            setDwellHourlyController((prev) => ({
              ...prev,
              selectedcam: selectedcamDetails.cameraId,
              selectedcameraname: selectedcamDetails.cameraName,
            }));
          }
          if (CameraList !== null && CameraList !== undefined) {
            const newCamList = CameraList.map((item) => ({
              value: item.cameraId,
              label: item.cameraName,
            }));

            setDwellHourlyController((prev) => ({
              ...prev,
              camlist: [...newCamList],
            }));
            // CameraList.map((item) => {
            //   let cam = {
            //     value: item.cameraId,
            //     label: item.cameraName,
            //   };

            //   setDwellHourlyController((prev) => ({
            //     ...prev,
            //     camlist: [...prev.camlist, cam],
            //   }));
            // });
          }

          if (data.length > 0 || data !== null || data !== undefined) {
            const zoneNames = extractZoneNames(data).sort();
            if (zoneNames.length > 0) {
              const { hours, series, seriesEngage, seriesBounce } =
                transformData(data, zoneNames);
              const colors = generateColorPalette(
                zoneNames ? zoneNames.length : 0
              );
              setDwellHourly((prevState) => ({
                ...prevState,
                options: {
                  ...prevState.options,
                  xaxis: {
                    ...prevState.options.xaxis,
                    categories: hours,
                  },
                  colors: colors,
                },
                series: series,
              }));

              setDwellHourlyController((prev) => ({
                ...prev,
                DataOfDate: DwellHourlyController.selectedDate,
              }));
            }
          }
        } else {
          console.log("error engage data api call successfully");
        }
      })
      .catch((err) => {
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  }, [DwellHourlyController.selectedcam, DwellHourlyController.selectedDate]);

  useEffect(() => {
    setFootfallDirectionHourlyController((prev) => ({
      ...prev,
      loading: true,
    }));
    axiosClient
      .get(
        `/livereport/getfootfalldirectionimage?date=${dayjs(
          FootfallDirectionHourlyController.selectedDate
        ).format("YYYY-MM-DD")}&camId=${
          FootfallDirectionHourlyController.selectedcam
        }&hour=${FootfallDirectionHourlyController.Hour}`
      )

      .then((response) => {
        if (response.data.issuccess === true) {
          const data = response.data.data.dwellData
            ? response.data.data.dwellData
            : [];

          const selectedcamDetails = response.data.data;
          const CameraList = response.data.data.cameraData;
          if (
            selectedcamDetails.selectedCameraData !== null &&
            selectedcamDetails.selectedCameraData !== undefined &&
            selectedcamDetails.selectedCameraData !== ""
          ) {
            setFootfallDirectionHourlyController((prev) => ({
              ...prev,
              selectedcam: selectedcamDetails.selectedCameraData.cameraId,

              selectedcamName: selectedcamDetails.selectedCameraData.cameraName,
            }));
          }

          if (
            selectedcamDetails.directionData !== null &&
            selectedcamDetails.directionData !== undefined &&
            selectedcamDetails.directionData !== ""
          ) {
            setFootfallDirectionHourlyController((prev) => ({
              ...prev,
              DirectionData: selectedcamDetails.directionData,
            }));
          }
          if (
            selectedcamDetails.directionImage !== null &&
            selectedcamDetails.directionImage !== undefined
          ) {
            setFootfallDirectionHourlyController((prev) => ({
              ...prev,
              DirectionImage: selectedcamDetails.directionImage,
            }));
          }

          if (
            selectedcamDetails.hour !== null &&
            selectedcamDetails.hour !== undefined &&
            selectedcamDetails.hour !== ""
          ) {
            setFootfallDirectionHourlyController((prev) => ({
              ...prev,
              Hour: selectedcamDetails.hour,
            }));
          }
          if (CameraList !== null && CameraList !== undefined) {
            const newCamList = CameraList.map((item) => ({
              value: item.cameraid,
              label: item.cameraname,
            }));

            setFootfallDirectionHourlyController((prev) => ({
              ...prev,
              camlist: [...newCamList],
            }));
          }
        } else {
          console.log("error engage data api call successfully");
        }
        setFootfallDirectionHourlyController((prev) => ({
          ...prev,
          loading: false,
        }));
      })
      .catch((err) => {
        setFootfallDirectionHourlyController((prev) => ({
          ...prev,
          loading: false,
        }));
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  }, [
    FootfallDirectionHourlyController.selectedcam,
    FootfallDirectionHourlyController.selectedDate,
    FootfallDirectionHourlyController.Hour,
  ]);

  useEffect(() => {
    axiosClient
      .get(
        `/dwelltime/getDwellTimeHourData?date=${dayjs(
          EngageBounceHourController.selectedDate
        ).format("YYYY-MM-DD")}&camId=${EngageBounceHourController.selectedcam}`
      )

      .then((response) => {
        if (response.data.issuccess === true) {
          const data = response.data.data.dwellData
            ? response.data.data.dwellData
            : [];

          const selectedcamDetails = response.data.data.selectedCameraDetails;
          const CameraList = response.data.data.cameraData;
          if (selectedcamDetails) {
            setEngageBounceHourController((prev) => ({
              ...prev,
              selectedcam: selectedcamDetails.cameraId,
            }));
          }
          if (CameraList !== null && CameraList !== undefined) {
            const newCamList = CameraList.map((item) => ({
              value: item.cameraId,
              label: item.cameraName,
            }));

            setEngageBounceHourController((prev) => ({
              ...prev,
              camlist: [...newCamList],
            }));
          }

          if (data.length > 0 || data !== null || data !== undefined) {
            const zoneNames = extractZoneNames(data).sort();
            if (zoneNames.length > 0) {
              const { hours, seriesEngage, seriesBounce } =
                transformDataEngageAndBounce(data, zoneNames);
              const colors = generateColorPalette(
                zoneNames ? zoneNames.length : 0
              );

              setEngagedHourlyChart((prevState) => ({
                ...prevState,
                options: {
                  ...prevState.options,
                  xaxis: {
                    ...prevState.options.xaxis,
                    categories: hours,
                  },
                  colors: colors,
                },
                series: seriesEngage,
              }));

              setBouncedHourlyChart((prevState) => ({
                ...prevState,
                options: {
                  ...prevState.options,
                  xaxis: {
                    ...prevState.options.xaxis,
                    categories: hours,
                  },
                  colors: colors,
                },
                series: seriesBounce,
              }));

              setEngageBounceHourController((prev) => ({
                ...prev,
                DataOfDate: EngageBounceHourController.selectedDate,
              }));
            }
          }
        } else {
          console.log("error engage data api call successfully");
        }
      })
      .catch((err) => {
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  }, [
    EngageBounceHourController.selectedcam,
    EngageBounceHourController.selectedDate,
  ]);

  useEffect(() => {
    axiosClient
      .get(
        `/livereport/getuniquecount?date=${UniqueHourlyController.selectedDate}&camId=${UniqueHourlyController.selectedcam}`
      )
      .then((response) => {
        if (response.data.issuccess === true) {
          const data = response.data.data.data ? response.data.data.data : [];

          const selectedcamDetails = response.data.data.selectedCameraData;
          const CameraList = response.data.data.cameraData;
          if (selectedcamDetails) {
            setUniqueHourlyController((prev) => ({
              ...prev,
              selectedcam: selectedcamDetails.cameraId,
              selectedcameraname: selectedcamDetails.cameraName,
            }));
          }
          if (CameraList !== null && CameraList !== undefined) {
            const newCamList = CameraList.map((item) => ({
              value: item.cameraid,
              label: item.cameraname,
            }));

            setUniqueHourlyController((prev) => ({
              ...prev,
              camlist: [...newCamList],
            }));
          }

          if (data.length > 0 || data !== null || data !== undefined) {
            const xaxis = data.map((item) => item.hour);
            const series = data.map((item) => item.totalCount);

            setUniqueHourlyChart((prevState) => ({
              ...prevState,
              options: {
                ...prevState.options,
                xaxis: { ...prevState.options.xaxis, categories: xaxis },
              },
              series: [{ name: "Unique counts", data: series }],
            }));

            setUniqueHourlyController((prev) => ({
              ...prev,
              DataOfDate: UniqueHourlyController.selectedDate,
            }));
          }
        } else {
          console.log("error engage data api call successfully");
        }
      })
      .catch((err) => {
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  }, [UniqueHourlyController.selectedcam, UniqueHourlyController.selectedDate]);

  // Function to generate a color palette dynamically
  const generateColorPalette = (numberOfColors) => {
    const palette = [];
    if (
      numberOfColors > 0 &&
      numberOfColors !== null &&
      numberOfColors !== undefined
    ) {
      for (let j = 0; j < numberOfColors; j++) {
        palette.push(
          randomColor({
            luminosity: "dark",
            // hue: "blue",
          })
        );
      }
    }

    return palette;
  };

  const OnChangeCameraSelect = (selectedcam) => {
    if (selectedcam !== null && selectedcam !== undefined) {
      setDwellHourlyController((prev) => ({ ...prev, selectedcam }));
    }
  };

  const UniqueHourlyHandleSelectCamera = (selectedcam) => {
    if (selectedcam !== null && selectedcam !== undefined) {
      setUniqueHourlyController((prev) => ({ ...prev, selectedcam }));
    }
  };

  const OnChangeCameraSelectEngageAndBounceHourly = (selectedcam) => {
    if (selectedcam !== null && selectedcam !== undefined) {
      setEngageBounceHourController((prev) => ({ ...prev, selectedcam }));
    }
  };

  const handleMenuClickWeeklyBounced = (e) => {
    const key = e.key;
    setSelectedbounceMenu(key);
    setWeeklyBouncedController((Prev) => ({ ...Prev, SelectedOption: key }));
    switch (key) {
      case "Weekly":
        handleweeklyDataBounced();
        break;
      case "15Days":
        handleFetch15DaysDataBounced();
        break;
      case "Monthly":
        handleBouncedMonthClick();
        break;
      case "search":
        setWeeklyBouncedController((prev) => ({ ...prev, search: true }));
        break;
      default:
        break;
    }
  };

  const handleMenuClick2 = (e) => {
    const key = e.key;
    setSelectedpickhourMenu(key);

    switch (key) {
      case "Weekly":
        handleweeklyDataPeakHour();
        break;
      case "15Days":
        handleFetch15DaysDatapeakhour();
        break;
      case "Monthly":
        handleFetchMonthlyDataPeakHour();
        break;
      case "search":
        setPeakHoursController((prev) => ({ ...prev, search: true }));
        break;
      default:
        break;
    }
  };

  const handleweeklyDataPeakHour = () => {
    setshowRangePickerforpeakHourr(false);
    axiosClient
      .get("livereport/getpeakhourperweek")
      .then((response) => {
        const apiData = response.data.data;

        const categories = apiData.map((item) => item.date);

        const seriesData = apiData.map((item) => item.peakHour);

        const yAxisLabels = apiData.map((item) => {
          if (typeof item.peakHour === "string") {
            return item.peakHour;
          } else {
            return "";
          }
        });

        setpickhoursweeklydata((prevState) => ({
          ...prevState,
          options: {
            ...prevState.options,
            series: [{ data: seriesData }],
            xaxis: { categories: categories },
            // yaxis: { categories: yAxisLabels },
          },
        }));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  //15days data for the peak Hours
  const handleFetch15DaysDatapeakhour = () => {
    setshowRangePickerforpeakHourr(false);
    axiosClient
      .get("livereport/getpeakhourperfifteendays")
      .then((response) => {
        const apiData = response.data.data;

        const categories = apiData.map((item) => item.date);

        const seriesData = apiData.map((item) => item.peakHour);

        const yAxisLabels = apiData.map((item) => {
          if (typeof item.peakHour === "string") {
            return item.peakHour;
          } else {
            return "";
          }
        });

        setpickhoursweeklydata((prevState) => ({
          ...prevState,
          options: {
            ...prevState.options,
            series: [{ data: seriesData }],
            xaxis: { categories: categories },
            // yaxis: { categories: yAxisLabels },
          },
        }));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  const handleFetchMonthlyDataPeakHour = () => {
    setshowRangePickerforpeakHourr(true);
    const startDate = moment().startOf("month").format("YYYY-MM-DD");
    const endDate = moment().endOf("month").format("YYYY-MM-DD");
    axiosClient
      .get(
        `livereport/getpeakhourforspecifieddates?startDate=${startDate}&endDate=${endDate}`
      )
      .then((response) => {
        setshowRangePickerforpeakHourr(false);
        const apiData = response.data.data;

        const categories = apiData.map((item) => item.date);

        const seriesData = apiData.map((item) => item.peakHour);

        const yAxisLabels = apiData.map((item) => {
          if (typeof item.peakHour === "string") {
            return item.peakHour;
          } else {
            return "";
          }
        });

        setpickhoursweeklydata((prevState) => ({
          ...prevState,
          options: {
            ...prevState.options,
            series: [{ data: seriesData }],
            xaxis: { categories: categories },
            // yaxis: { categories: yAxisLabels },
          },
        }));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handleDateRangeChangeforPeakHours = (dates, dateStrings) => {
    if (dates === null || dates === undefined) {
      setPeakHoursController((prev) => ({ ...prev, search: false }));
      setSelectedpickhourMenu("Weekly");
      handleweeklyDataPeakHour();
    } else {
      const startDate = dates[0].format("YYYY-MM-DD");

      const endDate = dates[1].format("YYYY-MM-DD");

      axiosClient
        .get(
          `livereport/getpeakhourforspecifieddates?startDate=${startDate}&endDate=${endDate}`
        )
        .then((response) => {
          setshowRangePickerforpeakHourr(false);
          const apiData = response.data.data;

          const categories = apiData.map((item) => item.date);

          const seriesData = apiData.map((item) => item.peakHour);

          const yAxisLabels = apiData.map((item) => {
            if (typeof item.peakHour === "string") {
              return item.peakHour;
            } else {
              return "";
            }
          });

          setpickhoursweeklydata((prevState) => ({
            ...prevState,
            options: {
              ...prevState.options,
              series: [{ data: seriesData }],
              xaxis: { categories: categories },
              // yaxis: { categories: yAxisLabels },
            },
          }));
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  };

  const handleBouncedMonthClick = () => {
    alert("HI")
    axiosClient
      .get(
        `dwelltime/getbounceandengagedforspecifieddates?startDate=${WeeklyBouncedController.StartDate}&endDate=${WeeklyBouncedController.EndDate}`
      )
      .then((response) => {
        setShowRangePickerforBounce(false);
        const apiData = response.data.data;
        const categories = apiData.map((item) => item.createdDate);
        const seriesData = apiData.map((item) => item.totalengaged);
        const BouncedData = apiData.map((item) => item.totalbounce);

        setEngagersweeklychart((prevState) => ({
          ...prevState,
          options: {
            ...prevState.options,
            series: [{ name: "Engagers", data: seriesData }],
            xaxis: { ...prevState.options.xaxis, categories: categories },
          },
        }));

        setBouncedweeklychart((prevState) => ({
          ...prevState,
          options: {
            ...prevState.options,
            series: [{ ...prevState.options.series, data: BouncedData }],
            xaxis: { ...prevState.options.xaxis, categories: categories },
          },
        }));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  //Get Bounce data based on selected start& end date
  const handleDateRangeChangeforBounce = (dates, dateStrings) => {
    if (dates == null) {
      setWeeklyBouncedController((prev) => ({
        ...prev,
        search: false,
        SelectedOption: "Weekly",
      }));
      handleweeklyDataBounced();
    } else {
      const startDate = dates[0].format("YYYY-MM-DD");
      const endDate = dates[1].format("YYYY-MM-DD");
      axiosClient
        .get(
          `dwelltime/getbounceandengagedforspecifieddates?startDate=${startDate}&endDate=${endDate}`
        )
        .then((response) => {
          alert("HII")
          setShowRangePickerforBounce(false);
          const apiData = response.data.data;
          const categories = apiData.map((item) => item.createdDate);
          const seriesData = apiData.map((item) => item.totalbounce);

          const BouncedData = apiData.map((item) => item.totalengaged);
          setEngagersweeklychart((prevState) => ({
            ...prevState,
            options: {
              ...prevState.options,
              series: [{ name: "Engagers", data: seriesData }],
              xaxis: { ...prevState.options.xaxis, categories: categories },
            },
          }));
          setBouncedweeklychart((prevState) => ({
            ...prevState,
            options: {
              ...prevState.options,
              series: [{ ...prevState.options.series, data: BouncedData }],
              xaxis: { ...prevState.options.xaxis, categories: categories },
            },
          }));
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  };

  const handleweeklyDataBounced = () => {
    setShowRangePickerforBounce(false);
    axiosClient
      .get("dwelltime/getbounceandengagedperweek")
      .then((response) => {
        if (response.data.issuccess === true) {
          const apiData = response.data.data;
          const categories = apiData.map((item) => item.createdDate);
          const seriesData = apiData.map((item) => item.totalbounce);
          const BouncedData = apiData.map((item) => item.totalengaged);

          setEngagersweeklychart((prevState) => ({
            ...prevState,
            options: {
              ...prevState.options,
              series: [{ name: "Engagers", data: seriesData }],
              xaxis: { ...prevState.options.xaxis, categories: categories },
            },
          }));
          setBouncedweeklychart((prevState) => ({
            ...prevState,
            options: {
              ...prevState.options,
              series: [{ ...prevState.options.series, data: BouncedData }],
              xaxis: { ...prevState.options.xaxis, categories: categories },
            },
          }));
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  //Get bounced 15days data
  const handleFetch15DaysDataBounced = () => {
    setShowRangePickerforBounce(false);
    axiosClient
      .get("dwelltime/getbounceandengagedperfifteendays")
      .then((response) => {
        const apiData = response.data.data;
        const categories = apiData.map((item) => item.createdDate);
        const seriesData = apiData.map((item) => item.totalbounce);
        const BouncedData = apiData.map((item) => item.totalengaged);

        setEngagersweeklychart((prevState) => ({
          ...prevState,
          options: {
            ...prevState.options,
            series: [{ name: "Engagers", data: seriesData }],
            xaxis: { ...prevState.options.xaxis, categories: categories },
          },
        }));

        setBouncedweeklychart((prevState) => ({
          ...prevState,
          options: {
            ...prevState.options,
            series: [{ ...prevState.options.series, data: BouncedData }],
            xaxis: { ...prevState.options.xaxis, categories: categories },
          },
        }));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  // const menuforBounced1 = (
  //   <Menu onClick={handleMenuClick}>
  //     <Menu.Item key="Weekly">Weekly Data</Menu.Item>
  //     <Menu.Item key="15Days">15 Days Data</Menu.Item>
  //     <Menu.Item key="Monthly">Monthly Data</Menu.Item>
  //   </Menu>
  // );

  const menuforBounced = [
    {
      key: "Weekly",
      label: <Text>Weekly Data</Text>,
    },
    {
      key: "15Days",
      label: <Text>15 Days Data</Text>,
    },
    {
      key: "Monthly",
      label: <Text>Monthly Data</Text>,
    },
    {
      key: "search",
      label: (
        <Text>
          <Flex align="center" justify="space-evenly">
            search <IoIosSearch />
          </Flex>
        </Text>
      ),
    },
  ];

  const menuforpeakvalue = [
    {
      key: "Weekly",
      label: <Text>Weekly Data</Text>,
    },
    {
      key: "15Days",
      label: <Text>15 Days Data</Text>,
    },
    {
      key: "Monthly",
      label: <Text>Monthly Data</Text>,
    },
    {
      key: "search",
      label: (
        <Text>
          <Flex align="center" justify="space-evenly">
            search <IoIosSearch />
          </Flex>
        </Text>
      ),
    },
  ];

  //Dwell time data based on date
  const handleDateSelect = (date) => {
    if (date === null) {
      console.log("Date cleared!");
    } else {
      setselectdwelltimedate(date);

      const formattedDate = date.format("YYYY-MM-DD");

      axiosClient
        .get(`/dwelltime/getaveragebasedondate?date=${formattedDate}`)
        .then((response) => {
          if (response.data.issuccess === true) {
            setdwelltimedata(response.data.data.dwellTimeAvg);
          }
        })
        .catch((error) => {});

      setShowDatePicker(false);
    }
  };

  const onChangeDate = (date, dateString) => {
    if (dateString === null || dateString === undefined || dateString === "") {
      setselecteddate("");
    } else {
      setselecteddate(dateString);
    }
  };

  // Onchange date function for Dwell hourly
  const onChangeDateDwellHourly = (date, dateString) => {
    if (dateString !== "") {
      setDwellHourlyController((prev) => ({
        ...prev,
        selectedDate: dateString,
        selectedcam: null,
      }));
    } else {
      setDwellHourlyController((prev) => ({
        ...prev,
        selectedDate: "",
        selectedcam: null,
      }));
    }
  };
  const UniqueHourlyHandleDate = (date, dateString) => {
    if (dateString !== "") {
      setUniqueHourlyController((prev) => ({
        ...prev,
        selectedDate: dateString,
        selectedcam: "",
      }));
    } else {
      setUniqueHourlyController((prev) => ({
        ...prev,
        selectedDate: "",
        selectedcam: "",
      }));
    }
  };

  // Onchange date function for engaged and bouncee hourly
  const onChangeDateEngageAndBounceHourly = (date, dateString) => {
    const datefrom_picker = moment(dateString, "YYYY-MM-DD");
    if (datefrom_picker._isValid) {
      setEngageBounceHourController((prev) => ({
        ...prev,
        selectedDate: dateString,
        selectedcam: null,
      }));
    }
  };

  //Month picker for spider graph
  const onChange = (date, dateString) => {
    if (date === null) {
      axiosClient.get(`/livereport/spidergraphdata`).then((response) => {
        if (response.data.issuccess === true) {
          const responseData = response.data.data;

          const uniqueWeekwise = [
            ...new Set(
              responseData.spiderIngress[0].map((item) => item.weekwise)
            ),
          ];

          // Extracting data for the radar chart
          const seriesData = uniqueWeekwise.map((weekwise) => {
            return {
              name: weekwise,
              data: responseData.spiderIngress[0]
                .filter((item) => item.weekwise === weekwise)
                .map((item) => item.totalingress),
            };
          });

          // Setting up chart options
          const options = {
            chart: {
              height: 400,
              type: "radar",
            },
            xaxis: {
              categories: responseData.spiderIngress[0].map(
                (item) => item.week
              ),
              title: {
                text: "Week",
                offsetY: -10,
                labels: {
                  show: true,
                  style: {
                    colors: ["#a8a8a8"],
                  },
                },
              },
            },
            colors: [
              "#008FFB",
              "#00E396",
              "#FEB019",
              "#FF4560",
              "#775DD0",
              "#FF9F00",
            ],
          };

          setChartFootfallanalysis({
            series: seriesData,
            options: options,
          });
        }
      });
    } else {
      const formattedmonth = date.format("YYYY-MM");

      axiosClient
        .get(`/livereport/spidergraphdata?month=` + formattedmonth)
        .then((response) => {
          if (response.data.issuccess === true) {
            const responseData = response.data.data;

            const uniqueWeekwise = [
              ...new Set(responseData.newspider.map((item) => item.weekwise)),
            ];

            // Extracting data for the radar chart
            const seriesData = uniqueWeekwise.map((weekwise) => {
              return {
                name: weekwise,
                data: responseData.newspider
                  .filter((item) => item.weekwise === weekwise)
                  .map((item) => item.totalingress),
              };
            });

            // Setting up chart options
            const options = {
              chart: {
                height: 400,
                type: "radar",
              },
              xaxis: {
                categories: responseData.newspider.map((item) => item.week),
                title: {
                  text: "Week",
                  offsetY: -10,
                  labels: {
                    show: true,
                    style: {
                      colors: ["#a8a8a8"],
                    },
                  },
                },
              },
              colors: [
                "#008FFB",
                "#00E396",
                "#FEB019",
                "#FF4560",
                "#775DD0",
                "#FF9F00",
              ],
            };

            setChartFootfallanalysis({
              series: seriesData,
              options: options,
            });
          }
        })
        .catch((err) => {
          console.log("errr", err);
          if (err.status === 0) {
            message.error("Server error");
          } else {
            message.error(err.msg);
          }
        });
    }
  };

  const disabledFutureMonths = (current) => {
    return current && current > moment().endOf("month");
  };

  const handleEngagedAndBounced = (zone, item) => {
    setEngagedandBouncedData({ CameraData: item, ZoneData: zone });
  };

  const HandleDateselectIngressEgress = (date, datestring) => {
    if (date === null || date === undefined) {
      setHandleDateForIngressEgress((prev) => ({
        ContinueFetch: true,
        SelectedDate: "",
      }));
    } else {
      setHandleDateForIngressEgress((prev) => ({
        ContinueFetch: false,
        SelectedDate: datestring,
      }));
    }
  };

  // Live tag for design
  const LiveTag = () => (
    <Tag color="green">
      <Flex gap={3} align="center">
        <Text
          style={{
            margin: "0px",
            fontSize: "13px",
            fontWeight: "initial",
          }}
        >
          Live
        </Text>
        <FaRegDotCircle />
      </Flex>
    </Tag>
  );

  const EngageAndBounceHourly = () => {
    return (
      <Col
        xs={{ span: 24 / 1, offset: 0 }}
        sm={{ span: 24 / 1, offset: 0 }}
        md={{ span: 24 / 1, offset: 0 }}
        lg={{ span: 24 / 1, offset: 0 }}
        xl={{ span: 24 / 1, offset: 0 }}
      >
        <Card
          style={{
            userSelect: "none",
            boxShadow: "none",
          }}
          title={
            <>
              <Row justify={"space-between"} align={"middle"}>
                <Col>
                  <Flex align="center" gap={10}>
                    <Title level={4}>Hourly Engaged and Bounced</Title>
                    <Tag>
                      {EngageBounceHourController.DataOfDate &&
                        EngageBounceHourController.DataOfDate}
                    </Tag>
                  </Flex>
                </Col>
                <Col>
                  <Row align="center" gutter={[10, 0]}>
                    <Col>
                      <DatePicker
                        onChange={onChangeDateEngageAndBounceHourly}
                        format={"YYYY-MM-DD"}
                        disabledDate={(current) => {
                          return current && current > moment().endOf("day");
                        }}
                        defaultValue={dayjs(
                          EngageBounceHourController.DataOfDate
                            ? EngageBounceHourController.DataOfDate
                            : EngageBounceHourController.selectedDate
                        )}
                      />
                    </Col>
                    <Col>
                      <Select
                        onChange={OnChangeCameraSelectEngageAndBounceHourly}
                        defaultValue={EngageBounceHourController.selectedcam}
                        style={{
                          width: 120,
                        }}
                        options={EngageBounceHourController.camlist}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>
          }
        >
          <Row gutter={[1, 10]} justify={"space-between"} align={"middle"}>
            <Col
              xs={{ span: 23 / 1, offset: 0 }}
              sm={{ span: 24 / 1, offset: 0 }}
              md={{ span: 11 / 1, offset: 0 }}
              lg={{ span: 11 / 1, offset: 0 }}
              xl={{ span: 11, offset: 0 }}
            >
              <div style={{ height: "250px" }}>
                <ReactApexChart
                  type="bar"
                  options={EngagedHourlyChart.options}
                  series={EngagedHourlyChart.series}
                  height="100%"
                  key={EngagedHourlyChart.series}
                />
              </div>
            </Col>
            <Divider type="vertical" style={{ height: "250px" }} />
            <Col
              xs={{ span: 24 / 1, offset: 0 }}
              sm={{ span: 24 / 1, offset: 0 }}
              md={{ span: 11 / 1, offset: 0 }}
              lg={{ span: 11 / 1, offset: 0 }}
              xl={{ span: 11, offset: 0 }}
            >
              <div style={{ height: "250px" }}>
                <ReactApexChart
                  type="bar"
                  options={BouncedHourlyChart.options}
                  series={BouncedHourlyChart.series}
                  height="100%"
                  key={BouncedHourlyChart.series}
                />
              </div>
            </Col>
          </Row>
        </Card>
      </Col>
    );
  };

  const EngageAndBounce = () => {
    return (
      <Col
        xs={{ span: 24 / 1, offset: 0 }}
        sm={{ span: 24 / 1, offset: 0 }}
        md={{ span: 24 / 1, offset: 0 }}
        lg={{ span: 24 / 1, offset: 0 }}
        xl={{ span: 24 / 1, offset: 0 }}
      >
        <Card
          style={{
            userSelect: "none",
            boxShadow: "none",
          }}
          title={
            <>
              <Row justify={"space-between"} align={"middle"}>
                <Col>
                  <Title level={4}>
                    {WeeklyBouncedController.SelectedOption === "Weekly"
                      ? "Weekly Engaged and Bounced"
                      : selectedbounceMenu === "15Days"
                      ? "Last 15 Days Engaged and Bounced"
                      : "Monthly Engaged and Bounced"}
                  </Title>
                </Col>

                <Col>
                  <Flex gap={10} align="center">
                    {WeeklyBouncedController.search && (
                      <RangePicker
                        onChange={handleDateRangeChangeforBounce}
                        disabledDate={(current) => {
                          return current && current > moment().endOf("day");
                        }}
                        allowClear
                      />
                    )}
                    <Dropdown
                      menu={{
                        items: menuforBounced,
                        onClick: handleMenuClickWeeklyBounced,
                      }}
                      placement="bottomRight"
                    >
                      <Button>
                        <Flex gap={10} align="center">
                          Filters
                          <LuFilter />
                        </Flex>
                      </Button>
                    </Dropdown>
                  </Flex>
                </Col>
              </Row>
            </>
          }
        >
          <Row gutter={[1, 10]} justify={"space-between"} align={"middle"}>
            <Col
              xs={{ span: 23 / 1, offset: 0 }}
              sm={{ span: 24 / 1, offset: 0 }}
              md={{ span: 11 / 1, offset: 0 }}
              lg={{ span: 11 / 1, offset: 0 }}
              xl={{ span: 11, offset: 0 }}
            >
              <div style={{ height: "250px" }}>
                <ReactApexChart
                  type="bar"
                  options={Engagersweeklychart.options}
                  series={Engagersweeklychart.options.series}
                  height="100%"
                />
              </div>
            </Col>
            <Divider type="vertical" style={{ height: "250px" }} />
            <Col
              xs={{ span: 24 / 1, offset: 0 }}
              sm={{ span: 24 / 1, offset: 0 }}
              md={{ span: 11 / 1, offset: 0 }}
              lg={{ span: 11 / 1, offset: 0 }}
              xl={{ span: 11, offset: 0 }}
            >
              <div style={{ height: "250px" }}>
                <ReactApexChart
                  type="bar"
                  options={Bouncedweeklychart.options}
                  series={Bouncedweeklychart.options.series}
                  height="100%"
                />
              </div>
            </Col>
          </Row>
        </Card>
      </Col>
    );
  };

  const HandleSelectCameraDirection = (id) => {
    setFootfallDirectionHourlyController((prev) => ({
      ...prev,
      selectedcam: id,
    }));
  };

  const onChangeTimeDirection = (date, datestring) => {
    if (datestring) {
      setFootfallDirectionHourlyController((prev) => ({
        ...prev,
        selectedDate: moment(datestring).format("YYYY-MM-DD"),
      }));
    } else {
      setFootfallDirectionHourlyController((prev) => ({
        ...prev,
        selectedDate: "",
      }));
    }
  };

  const handleHourChangeFootfalldirection = (time, hour) => {
    setFootfallDirectionHourlyController((prev) => ({
      ...prev,
      Hour: hour,
    }));
  };

  // disable hhours Footfall direction
  const disabledTime = () => {
    return {
      disabledHours: () => {
        const currentHour =
          moment().format("YYYY-MM-DD") ===
          FootfallDirectionHourlyController.selectedDate
            ? moment().hours()
            : 24;
        return [...Array(24).keys()].slice(currentHour);
      },
    };
  };
  const DwellHourComp = () => {
    return (
      <Card
        styles={{
          body: { maxHeight: "100%" },
        }}
        title={
          <>
            <Row justify={"space-between"} align={"middle"}>
              <Col>
                <Title level={5}>Dwell time per hour</Title>
                <Tag>
                  {DwellHourlyController.DataOfDate &&
                    DwellHourlyController.DataOfDate}
                  {DwellHourlyController.selectedcameraname &&
                    " - " + DwellHourlyController.selectedcameraname}
                </Tag>
              </Col>
              <Col>
                <Row align="center" gutter={[10, 0]}>
                  <Col>
                    <DatePicker
                      onChange={onChangeDateDwellHourly}
                      format={"YYYY-MM-DD"}
                      disabledDate={(current) => {
                        return current && current > moment().endOf("day");
                      }}
                      defaultValue={dayjs()}
                    />
                  </Col>
                  <Col>
                    <Select
                      onChange={OnChangeCameraSelect}
                      defaultValue={DwellHourlyController.selectedcam}
                      style={{
                        width: 120,
                      }}
                      options={DwellHourlyController.camlist}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        }
      >
        <Chart
          options={DwellHourly.options}
          series={DwellHourly.series}
          type="bar"
          height={"190%"}
        />
      </Card>
    );
  };

  const UniqueHourComp = () => {
    return (
      <Card
        styles={{
          body: { maxHeight: "100%" },
        }}
        title={
          <>
            <Row justify={"space-between"} align={"middle"}>
              <Col>
                <Title level={5}>Unique count per hour</Title>
                <Tag>
                  {UniqueHourlyController.DataOfDate &&
                    UniqueHourlyController.DataOfDate}
                  {UniqueHourlyController.selectedcameraname &&
                    " - " + UniqueHourlyController.selectedcameraname}
                </Tag>
              </Col>
              <Col>
                <Row align="center" gutter={[10, 0]}>
                  <Col>
                    <DatePicker
                      onChange={UniqueHourlyHandleDate}
                      format={"YYYY-MM-DD"}
                      disabledDate={(current) => {
                        return current && current > moment().endOf("day");
                      }}
                      defaultValue={dayjs()}
                    />
                  </Col>
                  <Col>
                    <Select
                      onChange={UniqueHourlyHandleSelectCamera}
                      defaultValue={UniqueHourlyController.selectedcam}
                      style={{
                        width: 120,
                      }}
                      options={UniqueHourlyController.camlist}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        }
      >
        <Chart
          options={UniqueHourlyChart.options}
          series={UniqueHourlyChart.series}
          type="bar"
          height={"190%"}
        />
      </Card>
    );
  };

  useEffect(() => {
    setTimeout(() => {
      setRenderCompController((prev) => ({ ...prev, IngressEgress: true }));
    }, 1000);

    setTimeout(() => {
      setRenderCompController((prev) => ({ ...prev, DwellFootfall: true }));
    }, 1000);
    setTimeout(() => {
      setRenderCompController((prev) => ({ ...prev, EngagedBounced: true }));
    }, 1000);

    setTimeout(() => {
      setRenderCompController((prev) => ({ ...prev, AgeGender: true }));
    }, 1000);
    setTimeout(() => {
      setRenderCompController((prev) => ({ ...prev, PeakHours: true }));
    }, 1000);
  }, []);

  //----------------------------vinoth-------------------------------//
  const [activeKey, setActiveKey] = useState("1");

  const handleTabChange = (key) => {
    setActiveKey(key);
  };
  //style={{ color: activeKey === "2" ? "#1677ff" : "black",textAlign:'center' }}
  //activeKey={activeKey} onChange={handleTabChange}

  const [drawerVisible, setDrawerVisible] = useState(false);

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
  };
  return (
    <div>
      <Tabs className="footfallstore">
        <TabPane
          className=""
          style={{ backgroundColor: "white" }}
          key="1"
          tab={<b>Store</b>}
        >
          <>
            <Row
              justify={"space-between"}
              gutter={[30, 30]}
              style={{ margin: "15px" }}
            >
              <Col
                xs={{ span: 18, offset: 0 }}
                sm={{ span: 14, offset: 0 }}
                md={{ span: 12, offset: 0 }}
                lg={{ span: 10, offset: 0 }}
                xl={{ span: 8 / 1, offset: 0 }}
              >
                <Row gutter={[20, 20]}>
                  <Col>
                    <Title level={3}>Dashboard</Title>
                  </Col>
                </Row>
              </Col>

              <Col
                xs={{ span: 4, offset: 0 }}
                sm={{ span: 6, offset: 0 }}
                md={{ span: 6, offset: 0 }}
                lg={{ span: 8, offset: 0 }}
                xl={{ span: 8 / 1, offset: 0 }}
                style={{ display: "flex", justifyContent: "end" }}
              >
                {/* {contextHolder} */}

                <Flex style={{ height: "100%" }} align="center">
                  <Button
                    title="Download"
                    type="default"
                    htmlType="submit"
                    loading={DownloadDashboard}
                    onClick={generatePDF}
                  >
                    Download
                  </Button>
                </Flex>
              </Col>
            </Row>

            <PDFExport
              margin={20}
              ref={containerRef}
              paperSize="auto"
              fileName={`footfall-report-${moment().format(
                "YYYY-MM-DD-HH-mm-ss"
              )}.pdf`}
              title="Footfall"
            >
              <div ref={targetRef}>
                <Row
                  className="Base-row"
                  gutter={[30, 20]}
                  style={{
                    margin: "10px",
                    backgroundColor: "white",
                    marginBottom: "30px",
                  }}
                >
                  {RenderCompController.IngressEgress && (
                    <Col
                      xs={{ span: 24 / 1, offset: 0 }}
                      sm={{ span: 24 / 1, offset: 0 }}
                      md={{ span: 24 / 1, offset: 0 }}
                      lg={{ span: 24 / 1, offset: 0 }}
                      xl={{ span: 24 / 1, offset: 0 }}
                    >
                      <Row gutter={[20, 20]}>
                        <Col
                          xs={{ span: 24, offset: 0 }}
                          sm={{ span: 24, offset: 0 }}
                          md={{ span: 20, offset: 0 }}
                          lg={{ span: 14, offset: 0 }}
                          xl={{ span: 16 / 1, offset: 0 }}
                        >
                          <Row gutter={[20, 20]}>
                            <Col
                              xs={{ span: 24, offset: 0 }}
                              sm={{ span: 24, offset: 0 }}
                              md={{ span: 24, offset: 0 }}
                              lg={{ span: 24, offset: 0 }}
                              xl={{ span: 24, offset: 0 }}
                            >
                              <Row gutter={[20, 20]}>
                                <Col
                                  xs={{ span: 18, offset: 0 }}
                                  sm={{ span: 12, offset: 0 }}
                                  md={{ span: 12, offset: 0 }}
                                  lg={{ span: 10, offset: 0 }}
                                  xl={{ span: 7 / 1, offset: 0 }}
                                >
                                  <Card
                                    className="ingress-hover"
                                    style={{
                                      cursor: "pointer",
                                      userSelect: "none",
                                      boxShadow: "none",
                                    }}
                                    title={
                                      <Row
                                        justify={"space-between"}
                                        align={"middle"}
                                      >
                                        <Col>
                                          <Title level={4} type="secondary">
                                            Footfall
                                          </Title>
                                        </Col>
                                        <Col>
                                          {HandleDateForIngressEgress.SelectedDate ? (
                                            <>
                                              <Tag color="green">
                                                {
                                                  HandleDateForIngressEgress.SelectedDate
                                                }
                                              </Tag>
                                            </>
                                          ) : (
                                            <LiveTag />
                                          )}
                                        </Col>
                                      </Row>
                                    }
                                    styles={{
                                      body: {
                                        paddingTop: "0px",
                                        // paddingBottom: "0px",
                                      },
                                    }}
                                  >
                                    <Title
                                      level={1}
                                      style={{
                                        fontWeight: "normal",
                                        margin: 0,
                                        padding: "5px",
                                      }}
                                    >
                                      {totalingress !== null &&
                                      totalingress !== undefined
                                        ? totalingress
                                        : 0}
                                    </Title>

                                    <Text
                                      style={{ backgroundColor: "#abd1b5" }}
                                    >
                                      {totalingrespercentage !== null &&
                                      totalingrespercentage !== undefined
                                        ? totalingrespercentage > 100
                                          ? "100"
                                          : totalingrespercentage
                                        : 0}
                                      %
                                    </Text>
                                    <Text> Vs.PreviousDay</Text>
                                  </Card>
                                </Col>

                                <Col
                                  xs={{ span: 18, offset: 0 }}
                                  sm={{ span: 18, offset: 0 }}
                                  md={{ span: 12, offset: 0 }}
                                  lg={{ span: 10, offset: 0 }}
                                  xl={{ span: 10 / 1, offset: 0 }}
                                >
                                  <Card
                                    className="ingress-hover"
                                    style={{
                                      cursor: "pointer",
                                      userSelect: "none",
                                      boxShadow: "none",
                                    }}
                                    title={
                                      <>
                                        <Row
                                          justify={"space-between"}
                                          align={"middle"}
                                        >
                                          <Col>
                                            <Title level={4} type="secondary">
                                              Peak Hours
                                            </Title>
                                          </Col>
                                          <Col>
                                            {HandleDateForIngressEgress.SelectedDate ? (
                                              <>
                                                <Tag color="green">
                                                  {
                                                    HandleDateForIngressEgress.SelectedDate
                                                  }
                                                </Tag>
                                              </>
                                            ) : (
                                              <LiveTag />
                                            )}
                                          </Col>
                                        </Row>
                                      </>
                                    }
                                    styles={{ body: { paddingTop: "0px" } }}
                                  >
                                    <Title
                                      style={{
                                        fontWeight: "normal",
                                        margin: 0,
                                        padding: "5px",
                                      }}
                                    >
                                      {peakHour !== null &&
                                      peakHour !== undefined &&
                                      peakHour !== "Invalid date - Invalid date"
                                        ? peakHour
                                        : "- -"}
                                    </Title>
                                    <Text>
                                      Yesterday's Peak Hours{" "}
                                      {yesterdaypeakHour !== null &&
                                      yesterdaypeakHour !== undefined &&
                                      yesterdaypeakHour !==
                                        "Invalid date - Invalid date"
                                        ? yesterdaypeakHour
                                        : "- -"}
                                    </Text>
                                  </Card>
                                </Col>
                                <Col
                                  xs={{ span: 18, offset: 0 }}
                                  sm={{ span: 10, offset: 0 }}
                                  md={{ span: 12, offset: 0 }}
                                  lg={{ span: 10, offset: 0 }}
                                  xl={{ span: 7 / 1, offset: 0 }}
                                >
                                  <Card
                                    className="ingress-hover"
                                    style={{
                                      cursor: "pointer",
                                      userSelect: "none",
                                      boxShadow: "none",
                                    }}
                                    title={
                                      <>
                                        <Row
                                          justify={"space-between"}
                                          align={"middle"}
                                        >
                                          <Col>
                                            <Title type="secondary" level={4}>
                                              Active Camera
                                            </Title>
                                          </Col>

                                          <Col>
                                            <LiveTag />
                                          </Col>
                                        </Row>
                                      </>
                                    }
                                    styles={{ body: { paddingTop: "0px" } }}
                                  >
                                    <Title
                                      style={{
                                        fontWeight: "normal",
                                        margin: 0,
                                        padding: "5px",
                                      }}
                                    >
                                      {footfallactivecamera !== null &&
                                      footfallactivecamera !== undefined
                                        ? footfallactivecamera
                                        : 0}
                                    </Title>
                                    <Text> Total Camera : {Totalcamera}</Text>
                                  </Card>
                                </Col>
                              </Row>
                            </Col>
                            <Col
                              xs={{ span: 24, offset: 0 }}
                              sm={{ span: 24, offset: 0 }}
                              md={{ span: 24, offset: 0 }}
                              lg={{ span: 24, offset: 0 }}
                              xl={{ span: 24, offset: 0 }}
                            >
                              <Row gutter={[30, 30]}>
                                <Col
                                  xs={{ span: 23, offset: 0 }}
                                  sm={{ span: 22, offset: 0 }}
                                  md={{ span: 20, offset: 0 }}
                                  lg={{ span: 22, offset: 0 }}
                                  xl={{ span: 24, offset: 0 }}
                                  // style={{ marginLeft: "1%" }}
                                >
                                  <Card
                                    loading={ingressloading}
                                    // hoverable

                                    style={{
                                      cursor: "pointer",
                                      userSelect: "none",
                                      boxShadow: "none",
                                    }}
                                    title={
                                      <Row
                                        justify={"space-between"}
                                        align={"middle"}
                                      >
                                        <Col>
                                          <Row gutter={5} align={"middle"}>
                                            <Col>
                                              <Title level={5}>
                                                Ingress and Egress Counts per
                                                Hour
                                              </Title>
                                            </Col>
                                            <Col>
                                              {HandleDateForIngressEgress.SelectedDate ? (
                                                <>
                                                  <Tag color="green">
                                                    {
                                                      HandleDateForIngressEgress.SelectedDate
                                                    }
                                                  </Tag>
                                                </>
                                              ) : (
                                                <LiveTag />
                                              )}
                                            </Col>
                                          </Row>
                                        </Col>
                                        <Col>
                                          <DatePicker
                                            className="dwell_date"
                                            onChange={
                                              HandleDateselectIngressEgress
                                            }
                                            disabledDate={(current) =>
                                              current &&
                                              current > moment().endOf("day")
                                            }
                                            // defaultValue={dayjs()}
                                          />
                                        </Col>
                                      </Row>
                                    }
                                  >
                                    {chartData.series &&
                                    chartData.series.length > 0 ? (
                                      <div className="app">
                                        <div className="row">
                                          <div className="mixed-chart">
                                            <ReactApexChart
                                              options={chartData.options}
                                              series={chartData.series}
                                              type="area"
                                              height={"200%"}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      <div
                                        style={{
                                          textAlign: "center",
                                          padding: "20px",
                                        }}
                                      >
                                        <Text
                                          style={{
                                            fontSize: "16px",
                                            color: "gray",
                                          }}
                                        >
                                          No Data Available
                                        </Text>
                                      </div>
                                    )}
                                  </Card>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>

                        <Col
                          xs={{ span: 23, offset: 0 }}
                          sm={{ span: 23, offset: 0 }}
                          md={{ span: 17 / 1, offset: 0 }}
                          lg={{ span: 10 / 1, offset: 0 }}
                          xl={{ span: 8 / 1, offset: 0 }}
                          // style={{ marginRight: "1px" }}
                        >
                          <Row
                            justify={"space-between"}
                            gutter={[20, 20]}
                            style={{ height: "100%" }}
                          >
                            <Col
                              xs={{ span: 24, offset: 0 }}
                              sm={{ span: 24, offset: 0 }}
                              md={{ span: 24, offset: 0 }}
                              lg={{ span: 24, offset: 0 }}
                              xl={{ span: 24, offset: 0 }}
                            >
                              <Card
                                style={{
                                  overflowY: "scroll",
                                  cursor: "pointer",
                                  userSelect: "none",
                                  boxShadow: "none",
                                  // marginTop: "20px",
                                }}
                                styles={{
                                  body: {
                                    // minHeight: "150px",
                                    maxHeight: "160px",
                                    overflowY: "scroll",
                                  },
                                }}
                                title={
                                  <Row
                                    justify={"space-between"}
                                    align={"middle"}
                                  >
                                    <Col>
                                      <Title type="secondary" level={5}>
                                        Crowd Analysis
                                      </Title>
                                    </Col>
                                    <Col>
                                      <LiveTag />
                                    </Col>
                                  </Row>
                                }
                              >
                                <List
                                  itemLayout="horizontal"
                                  dataSource={data}
                                  renderItem={(item, index) => (
                                    <List.Item>
                                      <List.Item.Meta
                                        avatar={
                                          <Avatar
                                            icon={
                                              <FaCameraRetro
                                                style={{ color: "blue" }}
                                              />
                                            }
                                          />
                                        }
                                        title={
                                          <Row>
                                            <Col span={19}>
                                              <Text
                                                strong
                                                style={{
                                                  fontSize: "17px",
                                                  marginRight: "72px",
                                                }}
                                              >
                                                {item.cameraName}
                                              </Text>
                                            </Col>
                                            <Col span={3}>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  justifyContent:
                                                    "space-between",
                                                }}
                                              >
                                                {item.trent >= 0 ? (
                                                  <Tag color="blue">
                                                    <ArrowUpOutlined />{" "}
                                                    {Math.abs(item.trent)}%
                                                  </Tag>
                                                ) : (
                                                  <Tag color="red">
                                                    <ArrowDownOutlined />{" "}
                                                    {Math.abs(item.trent)}%
                                                  </Tag>
                                                )}
                                              </div>
                                            </Col>
                                          </Row>
                                        }
                                        description={
                                          <div>
                                            {item.count} people Captured in{" "}
                                            {item.cameraName}
                                          </div>
                                        }
                                      />
                                    </List.Item>
                                  )}
                                />
                              </Card>
                            </Col>

                            <Col
                              xs={{ span: 24, offset: 0 }}
                              sm={{ span: 24, offset: 0 }}
                              md={{ span: 24, offset: 0 }}
                              lg={{ span: 24, offset: 0 }}
                              xl={{ span: 24, offset: 0 }}
                            >
                              <Card
                                style={{ height: "100%" }}
                                styles={{ body: { minHeight: "100%" } }}
                                title={
                                  <>
                                    <Row
                                      justify={"space-between"}
                                      align={"middle"}
                                    >
                                      <Col span={6}>
                                        <Flex gap={5} align="center">
                                          <Title level={5}>
                                            Footfall Direction <GrDirections />
                                          </Title>
                                        </Flex>

                                        <Tag>
                                          {FootfallDirectionHourlyController.selectedcamName ? (
                                            FootfallDirectionHourlyController.selectedcamName +
                                            " - "
                                          ) : (
                                            <></>
                                          )}
                                          {FootfallDirectionHourlyController.selectedDate ? (
                                            FootfallDirectionHourlyController.selectedDate +
                                            " - "
                                          ) : (
                                            <></>
                                          )}
                                          {FootfallDirectionHourlyController.Hour ? (
                                            FootfallDirectionHourlyController.Hour +
                                            " hr"
                                          ) : (
                                            <></>
                                          )}
                                        </Tag>
                                      </Col>
                                      <Col>
                                        <Flex gap={5}>
                                          <Select
                                            placeholder={
                                              <Text type="secondary">
                                                camera
                                              </Text>
                                            }
                                            onChange={
                                              HandleSelectCameraDirection
                                            }
                                            style={{
                                              width: 120,
                                            }}
                                            options={
                                              FootfallDirectionHourlyController.camlist
                                            }
                                          />

                                          <DatePicker
                                            style={{ width: "100px" }}
                                            placeholder="Date"
                                            onChange={onChangeTimeDirection}
                                            disabledDate={(current) =>
                                              current &&
                                              current > moment().endOf("day")
                                            }
                                          />
                                          <TimePicker
                                            style={{ width: "100px" }}
                                            placeholder="Hour"
                                            disabledTime={disabledTime}
                                            format={"HH"}
                                            onChange={
                                              handleHourChangeFootfalldirection
                                            }
                                          />
                                        </Flex>
                                      </Col>
                                    </Row>
                                  </>
                                }
                              >
                                <div style={{ maxHeight: "250px" }}>
                                  {FootfallDirectionHourlyController.DirectionImage &&
                                  FootfallDirectionHourlyController.DirectionImage ? (
                                    <Skeleton
                                      style={{
                                        maxHeight: "250px",
                                        minHeight: "250px",
                                      }}
                                      loading={
                                        FootfallDirectionHourlyController.loading
                                      }
                                      active
                                      round
                                    >
                                      <Image
                                        src={
                                          "data:image/png;base64," +
                                          FootfallDirectionHourlyController.DirectionImage
                                        }
                                        style={{
                                          maxHeight: "250px",
                                          minHeight: "250px",
                                        }}
                                      ></Image>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                        }}
                                      >
                                        {FootfallDirectionHourlyController.DirectionData !==
                                        "No data" ? (
                                          // FootfallDirectionHourlyController.DirectionData.map(
                                          //   (item) => (
                                          //     <Text className="label-Direction">
                                          //       {item.directionname} :{" "}
                                          //       {item.count}
                                          //     </Text>
                                          //   )
                                          // )
                                          <></>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </Skeleton>
                                  ) : (
                                    <Col
                                      xs={{ span: 24, offset: 0 }}
                                      sm={{ span: 24, offset: 0 }}
                                      md={{ span: 24, offset: 0 }}
                                      lg={{ span: 24, offset: 0 }}
                                      xl={{ span: 23, offset: 0 }}
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        // marginTop: "60px",
                                      }}
                                    >
                                      <Empty />
                                    </Col>
                                  )}
                                </div>
                              </Card>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  )}
                  <Divider style={{ margin: 0 }}></Divider>

                  {/* Dwell && Engaged and Bounced && dwell heatmap && dwell Hour chart && Footfall analysis */}
                  {RenderCompController.DwellFootfall && (
                    <Col
                      xs={{ span: 24 / 1, offset: 0 }}
                      sm={{ span: 24 / 1, offset: 0 }}
                      md={{ span: 24 / 1, offset: 0 }}
                      lg={{ span: 24 / 1, offset: 0 }}
                      xl={{ span: 24 / 1, offset: 0 }}
                    >
                      <Row gutter={[20, 30]}>
                        <Col
                          xs={{ span: 24 / 1, offset: 0 }}
                          sm={{ span: 22 / 1, offset: 0 }}
                          md={{ span: 22, offset: 0 }}
                          lg={{ span: 22, offset: 0 }}
                          xl={{ span: 16 / 1, offset: 0 }}
                        >
                          <Row
                            align={"stretch"}
                            justify={"space-between"}
                            gutter={[20, 20]}
                          >
                            <Col
                              xs={{ span: 24, offset: 0 }}
                              sm={{ span: 24, offset: 0 }}
                              md={{ span: 24, offset: 0 }}
                              lg={{ span: 24, offset: 0 }}
                              xl={{ span: 24 / 1, offset: 0 }}
                            >
                              <Row gutter={[20, 30]}>
                                <Col
                                  xs={{ span: 23, offset: 0 }}
                                  sm={{ span: 18, offset: 0 }}
                                  md={{ span: 12, offset: 0 }}
                                  lg={{ span: 10, offset: 0 }}
                                  xl={{ span: 10 / 1, offset: 0 }}
                                >
                                  <Card
                                    className="ingress-hover"
                                    style={{
                                      cursor: "pointer",
                                      userSelect: "none",
                                      boxShadow: "none",
                                      minHeight: "150px",
                                      // width: "280px",
                                    }}
                                    title={
                                      <>
                                        <Row
                                          justify={"space-between"}
                                          align={"middle"}
                                        >
                                          <Col>
                                            <Title level={4} type="secondary">
                                              DwellTime
                                            </Title>
                                          </Col>
                                          <Col>
                                            <DatePicker
                                              className="dwell_date"
                                              onChange={handleDateSelect}
                                              disabledDate={(current) =>
                                                current &&
                                                current > moment().endOf("day")
                                              }
                                              defaultValue={dayjs().subtract(
                                                1,
                                                "day"
                                              )}
                                            />
                                          </Col>
                                        </Row>
                                      </>
                                    }
                                    styles={{
                                      body: { paddingTop: 0, paddingBottom: 0 },
                                    }}
                                  >
                                    <div>
                                      <Title
                                        level={1}
                                        style={{ fontWeight: "normal" }}
                                      >
                                        {dwelltimedata !== null &&
                                        dwelltimedata !== undefined
                                          ? dwelltimedata
                                          : 0}
                                      </Title>
                                    </div>
                                  </Card>
                                </Col>
                                <Col
                                  xs={{ span: 23, offset: 0 }}
                                  sm={{ span: 20, offset: 0 }}
                                  md={{ span: 14, offset: 0 }}
                                  lg={{ span: 14, offset: 0 }}
                                  xl={{ span: 14 / 1, offset: 0 }}
                                >
                                  <Card
                                    // className="ingress-hover"
                                    style={{
                                      cursor: "pointer",
                                      userSelect: "none",
                                      boxShadow: "none",
                                      minHeight: "150px",
                                    }}
                                    title={
                                      <>
                                        <Row
                                          align={"center"}
                                          justify={"space-between"}
                                        >
                                          <Col>
                                            <Title level={4} type="secondary">
                                              Engaged & Bounced
                                            </Title>
                                          </Col>
                                          <Col>
                                            <Flex
                                              align="center"
                                              style={{ height: "100%" }}
                                            >
                                              <Dropdown
                                                menu={{
                                                  items:
                                                    EngaedandBouncedController,
                                                }}
                                              >
                                                <Button>
                                                  <Space>
                                                    <Text>Select camera</Text>
                                                    <AiTwotoneEdit />
                                                  </Space>
                                                </Button>
                                              </Dropdown>
                                            </Flex>
                                          </Col>
                                        </Row>
                                      </>
                                    }
                                    styles={{
                                      body: { paddingTop: 0, paddingBottom: 0 },
                                    }}
                                  >
                                    <Row>
                                      <Col>
                                        {EngagedandBouncedData.CameraData ? (
                                          <Tag>
                                            {EngagedandBouncedData.CameraData ? (
                                              EngagedandBouncedData.CameraData
                                                .cameraName
                                            ) : (
                                              <></>
                                            )}
                                          </Tag>
                                        ) : (
                                          <></>
                                        )}
                                      </Col>
                                      <Col>
                                        {EngagedandBouncedData.ZoneData ? (
                                          <Tag>
                                            {EngagedandBouncedData.ZoneData ? (
                                              EngagedandBouncedData.ZoneData
                                                .Area
                                            ) : (
                                              <></>
                                            )}
                                          </Tag>
                                        ) : (
                                          <></>
                                        )}
                                      </Col>
                                    </Row>
                                    <div style={{ margin: 0, padding: 0 }}>
                                      <>
                                        <Row justify={"space-between"}>
                                          <Col>
                                            <Title
                                              level={2}
                                              style={{ fontWeight: "normal" }}
                                            >
                                              Engaged :{" "}
                                              {EngagedandBouncedData.ZoneData
                                                ? EngagedandBouncedData.ZoneData
                                                    .engaged
                                                : 0}
                                            </Title>
                                          </Col>
                                          <Col>
                                            <Col>
                                              <Title
                                                level={2}
                                                style={{ fontWeight: "normal" }}
                                              >
                                                Bounced :{" "}
                                                {EngagedandBouncedData.ZoneData
                                                  ? EngagedandBouncedData
                                                      .ZoneData.bounced
                                                  : 0}
                                              </Title>
                                            </Col>
                                          </Col>
                                        </Row>
                                      </>
                                    </div>
                                  </Card>
                                </Col>
                              </Row>
                            </Col>
                            <Col
                              xs={{ span: 24, offset: 0 }}
                              sm={{ span: 24, offset: 0 }}
                              md={{ span: 24, offset: 0 }}
                              lg={{ span: 24, offset: 0 }}
                              xl={{ span: 24 / 1, offset: 0 }}
                            >
                              <Row gutter={[20, 30]}>
                                <Col
                                  xs={{ span: 22, offset: 0 }}
                                  sm={{ span: 20, offset: 0 }}
                                  md={{ span: 16, offset: 0 }}
                                  lg={{ span: 12, offset: 0 }}
                                  xl={{ span: 10, offset: 0 }}
                                >
                                  <Card
                                    style={{ minHeight: "100%" }}
                                    styles={{ body: { minHeight: "100%" } }}
                                    title={
                                      <>
                                        <Title level={5}>
                                          Dwell Time Heatmap
                                        </Title>
                                      </>
                                    }
                                  >
                                    <Row>
                                      <Col
                                        xs={{ span: 24, offset: 0 }}
                                        sm={{ span: 24, offset: 0 }}
                                        md={{ span: 24, offset: 0 }}
                                        lg={{ span: 24, offset: 0 }}
                                        xl={{ span: 24, offset: 0 }}
                                      >
                                        <div>
                                          {HeatmapController.cameralist &&
                                          HeatmapController.cameralist.length >
                                            0 ? (
                                            <Carousel showThumbs={false}>
                                              {HeatmapController.cameralist.map(
                                                (item) => {
                                                  return (
                                                    <Skeleton
                                                      loading={
                                                        HeatmapController.loading
                                                      }
                                                      active
                                                      round
                                                    >
                                                      <Image
                                                        src={
                                                          "data:image/png;base64," +
                                                          item.heatMapBase64
                                                        }
                                                        // style={{
                                                        //   maxHeight: "180px",
                                                        // }}
                                                      ></Image>
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          justifyContent:
                                                            "center",
                                                        }}
                                                      >
                                                        <Text className="label-heatmap">
                                                          Avg : {item.avg} sec
                                                          <br />
                                                          camera :{" "}
                                                          {item.cameraName}
                                                        </Text>
                                                      </div>
                                                    </Skeleton>
                                                  );
                                                }
                                              )}
                                            </Carousel>
                                          ) : (
                                            <Col
                                              xs={{ span: 24, offset: 0 }}
                                              sm={{ span: 24, offset: 0 }}
                                              md={{ span: 24, offset: 0 }}
                                              lg={{ span: 24, offset: 0 }}
                                              xl={{ span: 23, offset: 0 }}
                                              style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                marginTop: "60px",
                                              }}
                                            >
                                              <Empty />
                                            </Col>
                                          )}
                                        </div>
                                      </Col>
                                    </Row>
                                  </Card>
                                </Col>

                                <Col
                                  xs={{ span: 22, offset: 0 }}
                                  sm={{ span: 20, offset: 0 }}
                                  md={{ span: 16, offset: 0 }}
                                  lg={{ span: 12, offset: 0 }}
                                  xl={{ span: 14, offset: 0 }}
                                >
                                  <Row style={{ width: "100%" }}>
                                    <Tabs
                                      className="Engage-Bounce-tab"
                                      style={{ width: "100%" }}
                                      defaultActiveKey="dwell"
                                      animated={false}
                                      destroyInactiveTabPane={true}
                                      items={[
                                        {
                                          key: "dwell",
                                          label: (
                                            <Flex
                                              justify="center"
                                              align="center"
                                              gap={7}
                                            >
                                              <Title level={5} color="black">
                                                Dwell per hour
                                              </Title>
                                              <RxLapTimer
                                                color="#00a5cf"
                                                size={18}
                                              />
                                            </Flex>
                                          ),
                                          children: <DwellHourComp />,
                                        },
                                        {
                                          key: "unique",
                                          label: (
                                            <Flex
                                              justify="center"
                                              align="center"
                                              gap={7}
                                            >
                                              <Title level={5} color="black">
                                                Unique per hour
                                              </Title>
                                              <MdOutlinePeopleAlt
                                                color="#00a5cf"
                                                size={20}
                                              />
                                            </Flex>
                                          ),
                                          children: <UniqueHourComp />,
                                        },
                                      ]}
                                    ></Tabs>
                                  </Row>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>

                        <Col
                          xs={{ span: 23, offset: 0 }}
                          sm={{ span: 20 / 1, offset: 0 }}
                          md={{ span: 18 / 1, offset: 0 }}
                          lg={{ span: 14 / 1, offset: 3 }}
                          xl={{ span: 8 / 1, offset: 0 }}
                        >
                          <Card
                            // loading={loading}
                            style={{
                              // width: "100%",
                              cursor: "pointer",
                              userSelect: "none",
                              height: "100%",
                              boxShadow: "none",
                              // marginRight: "30px",
                              // marginLeft: "18px",
                              //  height: "5px",
                              // marginBottom: "30px",
                            }}
                            styles={{
                              body: {
                                height: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                paddingTop: 0,
                                margin: 0,
                                border: 0,
                              },
                              header: { border: 0 },
                            }}
                            title={
                              <>
                                <Row justify={"space-between"} align={"bottom"}>
                                  <Col>
                                    <Title level={5}>Footfall Analysis</Title>
                                  </Col>
                                  <Col>
                                    <div style={{ display: "inline-flex" }}>
                                      <div>
                                        <DatePicker
                                          onChange={onChange}
                                          picker="month"
                                          disabledDate={disabledFutureMonths}
                                          allowClear={true}
                                        />
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              </>
                            }
                          >
                            {chartFootfallanalysis.series &&
                            chartFootfallanalysis.series.length > 0 ? (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  border: 0,
                                }}
                              >
                                <Chart
                                  options={chartFootfallanalysis.options}
                                  series={chartFootfallanalysis.series}
                                  type="radar"
                                  height={"200%"}
                                />
                              </div>
                            ) : (
                              <div
                                style={{
                                  textAlign: "center",
                                  padding: "20px",
                                }}
                              >
                                <Text
                                  style={{ fontSize: "16px", color: "gray" }}
                                >
                                  No Data Available
                                </Text>
                              </div>
                            )}
                          </Card>
                        </Col>
                      </Row>
                    </Col>
                  )}

                  {/* //Weeklydata for the bounced & Engagers */}
                  {RenderCompController.EngagedBounced && (
                    <Col
                      xs={{ span: 24 / 1, offset: 0 }}
                      sm={{ span: 24 / 1, offset: 0 }}
                      md={{ span: 24 / 1, offset: 0 }}
                      lg={{ span: 24 / 1, offset: 0 }}
                      xl={{ span: 24 / 1, offset: 0 }}
                    >
                      <Row style={{ width: "100%" }}>
                        <Tabs
                          className="Engage-Bounce-tab"
                          style={{ width: "100%" }}
                          defaultActiveKey="live"
                          animated={false}
                          destroyInactiveTabPane={true}
                          items={[
                            {
                              key: "live",
                              label: (
                                <Flex justify="center" align="center" gap={7}>
                                  <Title level={5} color="black">
                                    Live
                                  </Title>
                                  <FaRegCircleDot color="#00a5cf" size={15} />
                                </Flex>
                              ),
                              children: <EngageAndBounceHourly />,
                            },
                            {
                              key: "processed",
                              label: (
                                <Flex justify="center" align="center" gap={7}>
                                  <Title level={5} color="black">
                                    Processed
                                  </Title>
                                  <AiOutlineHistory color="#00a5cf" size={18} />
                                </Flex>
                              ),
                              children: <EngageAndBounce />,
                            },
                          ]}
                        ></Tabs>
                      </Row>
                    </Col>
                  )}

                  {/* {RenderCompController.AgeGender && ( */}
                  <Col
                    xs={{ span: 24 / 1, offset: 0 }}
                    sm={{ span: 24 / 1, offset: 0 }}
                    md={{ span: 24 / 1, offset: 0 }}
                    lg={{ span: 24 / 1, offset: 0 }}
                    xl={{ span: 24 / 1, offset: 0 }}
                  >
                    <Card
                      style={{
                        // borderRadius: "15px",
                        // height: "360px",
                        userSelect: "none",
                        boxShadow: "none",
                      }}
                      title={
                        <>
                          <Row justify={"space-between"} align={"middle"}>
                            <Col>
                              <Title level={5}>
                                Total detected Gender Distribution in %
                              </Title>
                            </Col>
                            <Col>
                              <DatePicker
                                onChange={onChangeDate}
                                format={"YYYY-MM-DD"}
                                disabledDate={(current) => {
                                  return (
                                    current && current > moment().endOf("day")
                                  );
                                }}
                                defaultValue={dayjs().subtract(1, "day")}
                              />
                            </Col>
                            <Col>
                              <Title level={5}>
                                Total detected Age Distribution in %
                              </Title>
                            </Col>
                          </Row>
                        </>
                      }
                    >
                      <Row
                        style={{ height: "100%" }}
                        justify={"space-between"}
                        align={"middle"}
                      >
                        <Col
                          xs={{ span: 23 / 1, offset: 0 }}
                          sm={{ span: 23 / 1, offset: 0 }}
                          md={{ span: 11 / 1, offset: 0 }}
                          lg={{ span: 11 / 1, offset: 0 }}
                          xl={{ span: 11, offset: 0 }}
                        >
                          <div style={{ height: "250px" }}>
                            <ReactApexChart
                              options={chartDatapieGender.options}
                              series={chartDatapieGender.series}
                              type="donut"
                              height="100%"
                            />
                          </div>
                        </Col>
                        <Divider type="vertical" style={{ height: "250px" }} />
                        <Col
                          xs={{ span: 23 / 1, offset: 0 }}
                          sm={{ span: 23 / 1, offset: 0 }}
                          md={{ span: 11 / 1, offset: 0 }}
                          lg={{ span: 11 / 1, offset: 0 }}
                          xl={{ span: 11, offset: 0 }}
                          style={{ height: "100%" }}
                        >
                          <div style={{ height: "250px" }}>
                            <ReactApexChart
                              options={chartDatapieAge.options}
                              series={chartDatapieAge.series}
                              type="donut"
                              height="100%"
                            />
                          </div>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                  {/* )} */}

                  {RenderCompController.PeakHours && (
                    <Col
                      xs={{ span: 24 / 1, offset: 0 }}
                      sm={{ span: 24 / 1, offset: 0 }}
                      md={{ span: 24 / 1, offset: 0 }}
                      lg={{ span: 24 / 1, offset: 0 }}
                      xl={{ span: 24 / 1, offset: 0 }}
                    >
                      <Row>
                        <Col
                          xs={{ span: 23 / 1, offset: 0 }}
                          sm={{ span: 20 / 1, offset: 0 }}
                          md={{ span: 18 / 1, offset: 0 }}
                          lg={{ span: 12 / 1, offset: 0 }}
                          xl={{ span: 24, offset: 0 }}
                        >
                          <Card
                            style={{
                              // borderRadius: "15px",
                              // height: "360px",
                              userSelect: "none",
                              boxShadow: "none",
                            }}
                            title={
                              <>
                                <Row justify={"space-between"} align={"middle"}>
                                  <Col>
                                    <Title level={4}>
                                      {selectedpickhourMenu === "Weekly"
                                        ? "Weekly Peak Hour"
                                        : selectedpickhourMenu === "15Days"
                                        ? "Last 15 Days Peak Hour "
                                        : "Monthly Peak Hour "}
                                    </Title>
                                  </Col>
                                  <Col>
                                    <Flex gap={10}>
                                      {PeakHoursController.search && (
                                        <RangePicker
                                          onChange={
                                            handleDateRangeChangeforPeakHours
                                          }
                                          style={{ marginBottom: "10px" }}
                                          disabledDate={(current) =>
                                            current &&
                                            current > moment().endOf("day")
                                          }
                                          allowClear
                                        />
                                      )}

                                      <Dropdown
                                        // overlay={menuforpeakvalue}
                                        menu={{
                                          items: menuforpeakvalue,
                                          onClick: handleMenuClick2,
                                        }}
                                        placement="bottomRight"
                                      >
                                        <Button>
                                          <Flex gap={10} align="center">
                                            Filters
                                            <LuFilter />
                                          </Flex>
                                        </Button>
                                      </Dropdown>
                                    </Flex>
                                  </Col>
                                </Row>
                              </>
                            }
                          >
                            <div style={{ height: "250px" }}>
                              <ReactApexChart
                                options={pickhoursweeklyData.options}
                                series={pickhoursweeklyData.options.series}
                                height="100%"
                              />
                            </div>
                          </Card>
                        </Col>
                      </Row>
                    </Col>
                  )}

                  <Modal
                    open={HeatmapDetails.modalopen}
                    title={
                      <>
                        <Row
                          align={"middle"}
                          justify={"center"}
                          style={{ marginBottom: "15px" }}
                        >
                          <Col>
                            <Text className="Modal-Header">Heatmap </Text>
                          </Col>
                        </Row>
                      </>
                    }
                    destroyOnClose
                    onOk={() =>
                      setHeatmapDetails((prev) => ({
                        ...prev,
                        modalopen: false,
                      }))
                    }
                    onCancel={() =>
                      setHeatmapDetails((prev) => ({
                        ...prev,
                        modalopen: false,
                      }))
                    }
                    cancelButtonProps={{ style: { display: "none" } }}
                  >
                    <Skeleton
                      loading={HeatmapDetails.imageloading}
                      active
                      round
                    >
                      {HeatmapDetails.details && HeatmapDetails.details ? (
                        <Row justify={"center"} gutter={[20, 20]}>
                          <Col
                            xs={{ span: 24, offset: 0 }}
                            sm={{ span: 24, offset: 0 }}
                            md={{ span: 24, offset: 0 }}
                            lg={{ span: 24, offset: 0 }}
                            xl={{ span: 23, offset: 0 }}
                          >
                            {HeatmapDetails.details.heatMapImage && (
                              <Image
                                alt={
                                  "camera name " +
                                  HeatmapDetails.details.location
                                }
                                // style={{ maxHeight: "100vh" }}
                                // className="image-property"
                                src={
                                  "data:image/png;base64," +
                                  HeatmapDetails.details.heatMapImage
                                }
                              />
                            )}
                          </Col>
                          <Col
                            xs={{ span: 24, offset: 0 }}
                            sm={{ span: 24, offset: 0 }}
                            md={{ span: 24, offset: 0 }}
                            lg={{ span: 24, offset: 0 }}
                            xl={{ span: 23, offset: 0 }}
                          >
                            <Row justify={"center"}>
                              <Col>
                                <Title level={5}>
                                  {HeatmapDetails.details.location}
                                </Title>
                                {Object.entries(dwelltimeavg).map(
                                  ([area, avg]) => (
                                    <div key={area}>
                                      <Text>
                                        <span style={{ fontWeight: "bold" }}>
                                          {area}
                                        </span>
                                        :{avg}sec
                                      </Text>
                                    </div>
                                  )
                                )}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      ) : (
                        <Space
                          direction="vertical"
                          style={{ width: "100%" }}
                          styles={{
                            item: {
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            },
                          }}
                        >
                          <CiImageOn title="Image unavailable" size={150} />
                          <Text strong>Image unavailable</Text>
                        </Space>
                      )}
                    </Skeleton>
                  </Modal>
                </Row>
              </div>
            </PDFExport>
          </>
        </TabPane>
      </Tabs>
    </div>
  );
};
export default NewFootfall;
