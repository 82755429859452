import React, { useState, useEffect } from "react";
import {
  UserOutlined,
  LockOutlined,
  DownOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { Progress } from "antd";
import authService from "../../Authentication/authService";
import {
  Button,
  Col,
  DatePicker,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Switch,
  Tooltip,
  Form,
  Typography,
  Flex,
  Modal,
  message,
  Table,
  Tag,
  Tree,
  Descriptions,
} from "antd";
import { Collapse, Card } from "antd";

import axiosClient from "../../Authentication/ApiCall";
const { Option } = Select;
const { Text, Link, Title } = Typography;
const ServerOnboarding = () => {
  const { Panel } = Collapse;
  const [RegForm] = Form.useForm();
  const [editRegForm] = Form.useForm();
  const [modal, setModal] = useState(false);
  const [getserverdata, setgetserverdata] = useState([]);
  const [AddRefresh, setAddRefresh] = useState(1);
  const [editModal, seteditModal] = useState(false);
  const [serverid, setserverid] = useState("");
  const onclickmodal = () => {
    setModal(true);
    RegForm.resetFields();
    // editRegForm.resetFields();
  };

  const handlecreateeventclose = () => {
    setModal(false);
  };

  const oncancelcard = () => {
    setModal(false);
    RegForm.resetFields();
    // editRegForm.resetFields();
  };
  const onFinish = (values) => {
    console.log("onfinish values", values);

    axiosClient
      .post("/serverdetails/addserverdetails", {
        serverName: values.servername,
        serverIp: values.ipadress,
      })
      .then((response) => {
        if (response.data.issuccess === true) {
          message.success(response.data.msg);

          setAddRefresh(AddRefresh + 1);
          setModal(false);
          // setcount(count + 1);
          RegForm.resetFields();
        }
      })
      .catch((err) => {
        setModal(false);
        RegForm.resetFields();

        console.log("errr", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  };
  useEffect(() => {
    axiosClient
      .get("/serverdetails/getserverdetails")
      .then((response) => {
        if (response.data.issuccess === true) {
          console.log("server response", response);
          setgetserverdata(response.data.data);
        }
      })
      .catch((err) => {
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  }, [AddRefresh]);
  const generateTreeData = (server) => [
    {
      title: `Server Name: ${server.serverName}`,
      key: `serverName-${server.id}`,
      children: [
        {
          title: `Server IP: ${server.serverIp}`,
          key: `serverIp-${server.id}`,
        },
        {
          title: `Created Date: ${new Date(
            server.createddate
          ).toLocaleString()}`,
          key: `createddate-${server.id}`,
        },
      ],
    },
  ];
  const handleclose = () => {
    seteditModal(false);
  };
  const onEdit = (server) => {
    console.log("onedit serverdetails", server);
    setserverid(server.id);
    seteditModal(true);
    editRegForm.setFieldsValue({
      servername: server.serverName,
      ipadress: server.serverIp,
    });
  };
  const onFinishedit = (values) => {
    axiosClient
      .put("/serverdetails/editserverdetails", {
        serverName: values.servername,
        serverIp: values.ipadress,
        id: serverid,
      })
      .then((response) => {
        if (response.data.issuccess === true) {
          message.success(response.data.msg);

          setAddRefresh(AddRefresh + 1);
          seteditModal(false);
        } else {
          setAddRefresh(AddRefresh + 1);
          seteditModal(false);
        }
      })
      .catch((err) => {
        setAddRefresh(AddRefresh + 1);
        seteditModal(false);
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  };
  const onDelete = (server) => {
    console.log("server555555", server);
    Modal.confirm({
      title: "Confirm Delete?",
      content: "Event will be deleted",

      okText: "Confirm",
      okType: "danger",
      onOk: () => {
        axiosClient
          .delete("/serverdetails/deleteserverdetails?id=" + server.id)
          .then((response) => {
            if (response.data.issuccess === true) {
              message.success(response.data.msg);
              // setDeleteRefresh(DeleteRefresh + 1);
              setAddRefresh(AddRefresh + 1);
            } else {
              console.log("error");
            }
          })
          .catch((err) => {
            console.log("errr", err);
            if (err.status === 0) {
              message.error("Server error");
            } else {
              message.error(err.msg);
            }
          });
      },
    });
  };
  return (
    <div style={{ width: "100%", overflowX: "hidden" }}>
      {" "}
      <div
        className="report-content"
        style={{
          // height: "120px",
          backgroundColor: "#000816",
          width: "100%",
          // marginLeft: "9px",
          margin: 0,
          // display: "flex",
          // flexDirection: "column",
          // justifyContent: "center",
        }}
      >
        {/* First Row */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginLeft: "3%",
            padding: "10px",
          }}
        >
          <p
            style={{
              color: "white",
              fontSize: "21px",
              fontFamily: "revert-layer",
              marginTop: "10px",
            }}
          >
            Server Onboarding
          </p>
          <div style={{ display: "flex", marginRight: "3%" }}>
            <Button className="adduser" onClick={onclickmodal}>
              + New
            </Button>
          </div>
        </div>
      </div>
      <div>
        {" "}
        {/* <Row
          gutter={[16, 16]}
          style={{ marginTop: "30px", marginLeft: "30px" }}
        >
          {getserverdata.map((server) => (
            <Col xs={24} sm={12} md={8} lg={6} key={server.id}>
              <Collapse accordion>
                <Card title={`Server: ${server.serverName}`} bordered={true}>
                  <Collapse accordion>
                    <Panel header={server.serverName} key={server.id}>
                      <Card bordered={false}>
                        <p>
                          <strong>Server Name:</strong> {server.serverName}
                        </p>
                        <p>
                          <strong>Server IP:</strong> {server.serverIp}
                        </p>
                        <p>
                          <strong>Created Date:</strong>{" "}
                          {new Date(server.createddate).toLocaleString()}
                        </p>
                      </Card>
                    </Panel>
                  </Collapse>
                </Card>
              </Collapse>
            </Col>
          ))}
        </Row> */}
        {/* <Row
          gutter={[16, 16]}
          style={{ marginTop: "30px", marginLeft: "30px" }}
        >
          {getserverdata.map((server) => (
            <Col xs={24} sm={12} md={8} lg={6} key={server.id}>
              <Card
                title={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {` ${server.serverName}`}
                    <Tooltip title="Delete Server">
                      <DeleteOutlined
                        onClick={() => onDelete(server)}
                        style={{
                          fontSize: "18px",
                          cursor: "pointer",
                          color: "red",
                        }}
                      />
                    </Tooltip>
                  </div>
                }
                bordered={true}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Tree defaultExpandAll treeData={generateTreeData(server)} />
                  <Tooltip title="Edit Server">
                    <EditOutlined
                      onClick={() => onEdit(server)}
                      style={{ fontSize: "18px", cursor: "pointer" }}
                    />
                  </Tooltip>
                </div>
              </Card>
            </Col>
          ))}
        </Row> */}
        <Row
          gutter={[16, 16]}
          style={{ marginTop: "20px", marginLeft: "30px", marginRight: "10px" }}
        >
          {getserverdata.map((server) => (
            <Col xs={20} sm={11} md={12} lg={8} xl={8} key={server.id}>
              <Card
                title={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      height: "60px",
                    }}
                  >
                    {/* Left: Server Name */}
                    <span style={{ fontSize: "20px" }}>
                      {server.serverName}
                    </span>

                    {/*                   
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "250px",
                      }}
                    >
                      {/* CPU */}
                    {/* <div style={{ textAlign: "center" }}>
                        <div>
                          <strong>CPU</strong>
                        </div>
                        <div>{server.CPU ? server.CPU : "--"}</div>
                      </div> */}

                    {/* GPU */}
                    {/* <div
                        style={{
                          textAlign: "center",
                          display: "flex",
                          flexWrap: "wrap",
                          justifyContent: "center",
                        }}
                      >
                        <div>
                          <strong>GPU</strong>
                        </div>
                        {Array.isArray(server.GPU) && server.GPU.length > 0 ? (
                          server.GPU.map((gpu, index) => (
                            <div key={index} style={{ margin: "0 5px" }}>
                              {Object.values(gpu)[0]} %{" "}
                              {index > 0 ? ` (GPU ${index + 1})` : ""}
                            </div>
                          ))
                        ) : (
                          <div>--</div>
                        )}
                      </div> */}

                    {/* Memory */}
                    {/* <div style={{ textAlign: "center" }}>
                        <div>
                          <strong>Memory</strong>
                        </div>
                        <div>{server.diskSpace ? server.diskSpace : "--"}</div>
                      </div>
                    </div> */}

                    {/* Right: Delete Icon */}
                    <Tooltip title="Delete Server">
                      <DeleteOutlined
                        onClick={() => onDelete(server)}
                        style={{
                          fontSize: "18px",
                          cursor: "pointer",
                          color: "red",
                        }}
                      />
                    </Tooltip>
                  </div>
                }
                bordered={true}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    textAlign: "center",
                  }}
                >
                  {/* CPU */}
                  <div>
                    <strong>CPU</strong>
                    <div>{server.CPU ? server.CPU : "0"}</div>
                  </div>

                  {/* GPU */}
                  <div>
                    <strong>GPU</strong>
                    <div>
                      {Array.isArray(server.GPU) &&
                      server.GPU.length > 0 &&
                      server.GPU[0]?.gpu1 != null
                        ? server.GPU[0]?.gpu1
                        : "0"}
                    </div>
                  </div>

                  <div>
                    <strong>GPU2</strong>
                    <div>
                      {Array.isArray(server.GPU) &&
                      server.GPU.length > 1 &&
                      server.GPU[1]?.gpu2 != null
                        ? server.GPU[1]?.gpu2
                        : "0"}
                    </div>
                  </div>

                  {/* Memory */}
                  <div>
                    <strong>Memory</strong>
                    <div>{server.diskSpace ? server.diskSpace : "0"}</div>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginLeft: "20px",
                    marginTop: "10px",
                  }}
                >
                  <Descriptions
                    column={1}
                    bordered
                    labelStyle={{
                      backgroundColor: "#f0f0f0",
                      fontWeight: "bold",
                      width: "200px",
                      textAlign: "left",
                    }}
                    contentStyle={{
                      textAlign: "right",
                      width: "250px",
                    }}
                  >
                    <Descriptions.Item label="Server Name">
                      {server.serverName}
                    </Descriptions.Item>
                    <Descriptions.Item label="IP">
                      {server.serverIp}
                    </Descriptions.Item>
                    <Descriptions.Item label="Date">
                      {server.createddate}
                    </Descriptions.Item>
                  </Descriptions>
                </div>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
      <Modal
        destroyOnClose
        centered
        open={modal}
        onCancel={() => oncancelcard()}
        title={
          <>
            <Row
              align={"middle"}
              justify={"center"}
              style={{ marginBottom: "30px" }}
            >
              <Col>
                <Text className="Modal-Header">Server Onboarding</Text>
              </Col>
            </Row>
          </>
        }
        footer={
          <>
            <Row justify={"space-around"}>
              <Col>
                {authService.getCurrentUserReadOnly() ? (
                  <Tooltip title="you need permission to submit">
                    <Button type="primary" block disabled>
                      Submit
                    </Button>
                  </Tooltip>
                ) : (
                  <Button
                    className="primary-button"
                    form="submitAdduserForm"
                    type="primary"
                    block
                    htmlType="submit"
                  >
                    Submit
                  </Button>
                )}
              </Col>
              <Col>
                <Button onClick={() => handlecreateeventclose()}>Cancel</Button>
              </Col>
            </Row>
          </>
        }
      >
        <Form
          id="submitAdduserForm"
          form={RegForm}
          name="basic"
          onFinish={onFinish}
          autoComplete={"off"}
          labelCol={{
            xs: { span: 24 },
            sm: { span: 7, offset: 0 },
            md: { span: 7, offset: 0 },
            lg: { span: 8, offset: 0 },
            xl: { span: 9, offset: 0 },
          }}
          wrapperCol={{
            xs: { span: 21 },
            sm: { span: 11, offset: 0 },
            md: { span: 11, offset: 0 },
            lg: { span: 8, offset: 0 },
            xl: { span: 14, offset: 0 },
          }}
        >
          <Form.Item
            label="Server Name"
            name="servername"
            rules={[
              { required: true, message: "Please enter your Server Name!" },
            ]}
          >
            <Input
              placeholder="server Name"
              prefix={<UserOutlined className="site-form-item-icon" />}
            />
          </Form.Item>
          <Form.Item
            label="Ip adress"
            name="ipadress"
            rules={[
              { required: true, message: "Please enter your IP address!" },
              {
                pattern: /^(\d{1,3})\.(\d{1,3})\.(\d{1,3})\.(\d{1,5})$/,
                message: "Please enter a valid IP address ",
              },
            ]}
          >
            <Input
              placeholder="Ip adress"
              prefix={<UserOutlined className="site-form-item-icon" />}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        centered
        open={editModal}
        title={
          <>
            <Row
              align={"middle"}
              justify={"center"}
              style={{ marginBottom: "30px" }}
            >
              <Col>
                <Text className="Modal-Header"> Edit Server Onboarding</Text>
              </Col>
            </Row>
          </>
        }
        footer={
          <>
            <Row justify={"space-around"}>
              <Col>
                {authService.getCurrentUserReadOnly() ? (
                  <Tooltip title="you need permission to submit">
                    <Button type="primary" block disabled>
                      Submit
                    </Button>
                  </Tooltip>
                ) : (
                  <Button
                    className="primary-button"
                    form="submiteditform"
                    type="primary"
                    block
                    htmlType="submit"
                  >
                    Submit
                  </Button>
                )}
              </Col>
              <Col>
                <Button onClick={() => handleclose()}>Cancel</Button>
              </Col>
            </Row>
          </>
        }
        onCancel={() => seteditModal(false)}
      >
        {" "}
        <Form
          id="submiteditform"
          form={editRegForm}
          name="basic"
          onFinish={onFinishedit}
          autoComplete={"off"}
          labelCol={{
            xs: { span: 24 },
            sm: { span: 7, offset: 0 },
            md: { span: 7, offset: 0 },
            lg: { span: 8, offset: 0 },
            xl: { span: 9, offset: 0 },
          }}
          wrapperCol={{
            xs: { span: 21 },
            sm: { span: 11, offset: 0 },
            md: { span: 11, offset: 0 },
            lg: { span: 8, offset: 0 },
            xl: { span: 14, offset: 0 },
          }}
        >
          <Form.Item
            label="Server Name"
            name="servername"
            rules={[
              { required: true, message: "Please enter your Server Name!" },
            ]}
          >
            <Input
              placeholder="server Name"
              prefix={<UserOutlined className="site-form-item-icon" />}
            />
          </Form.Item>
          <Form.Item
            label="Ip adress"
            name="ipadress"
            rules={[
              { required: true, message: "Please enter your IP address!" },
              {
                pattern: /^(\d{1,3})\.(\d{1,3})\.(\d{1,3})\.(\d{1,5})$/,
                message: "Please enter a valid IP address ",
              },
            ]}
          >
            <Input
              placeholder="Ip adress"
              prefix={<UserOutlined className="site-form-item-icon" />}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};
export default ServerOnboarding;
