import React, { useEffect, useState } from "react";
import authService from "../../Authentication/authService";
import { useAtom } from "jotai";
import ClipLoader from "react-spinners/ClipLoader";
import {
  Button,
  Row,
  Typography,
  Select,
  Table,
  Image,
  Col,
  message,
  Tooltip,
  Spin,
  Alert,
  Tag,
  Empty,
} from "antd";
// import { eventListAtom, selectedEventIdAtom } from './atoms';
import {
  eventListAtom,
  selectedEventIdAtom,
  DashboardNavData,
} from "../Utils/StateExports";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { DatePicker, Modal } from "antd";
import moment, { invalid } from "moment";
import styled from "styled-components";
import axiosClient from "../../Authentication/ApiCall";
import * as FileSaver from "file-saver";
import { FaAngleDown, FaCaretDown } from "react-icons/fa";
import { PulseLoader } from "react-spinners";
import socket from "../../../Socket";
import dayjs from "dayjs";
const { Option } = Select;
const { RangePicker } = DatePicker;
const { Text } = Typography;

const Container = styled.div`
  .header {
    padding: 1rem 0;
  }

  .filters {
    padding: 0 1rem;
  }

  .table-container {
    padding: 1rem 2rem;
  }
`;

const OtherReports = () => {
  const [dashboardNavData] = useAtom(DashboardNavData);

  const [data, setData] = useState([]);

  const [EventList, setEventList] = useState([]);
  const [CameraList, setCameraList] = useState([]);

  const [eventId, seteventId] = useState(null);
  const [cameraId, setCameraId] = useState(null);
  const [status, SetStatus] = useState(null);

  const [Model, setModel] = useState(false);
  // const data1 = JSON.parse(localStorage.getItem("reportData"));
  // console.log("data received 33333344", data1.id);
  // const id = data1.id;
  // // seteventId(id);

  const [currentImage, setCurrentImage] = useState("");
  const [modelVisible, setModelVisible] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [previndex, setprevindex] = useState(0);
  const [records, setRecords] = useState([]);

  const [date, setdate] = useState(false);
  const [nextId, setNextId] = useState(null);
  const [prevId, setPrevId] = useState(null);
  const [reportloading, setreportLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [StartDate, setstartDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [EndDate, setendDate] = useState("");
  const [nextandprev, setnextandprev] = useState(false);
  const [cameraName, setCameraName] = useState("");
  const [eventName, setEventName] = useState("");
  const [reportData, setreportData] = useState([]);
  const [previousRecord, setPreviousRecord] = useState({});
  const [nextRecord, setNextRecord] = useState({});
  const [imageRecordsid, setimageRecordsid] = useState([]);
  const [dateRange, setDateRange] = useState(null);
  const [image, setImage] = useState("");
  const [ImageLoad, setImageLoad] = useState({
    id: "",
    Load: false,
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [currentImageId, setCurrentImageId] = useState(null);
  const [imageRecords, setImageRecords] = useState([]);
  const [event, setevent] = useState(false);
  // const formattedStartDate =
  //   startDate && moment.isMoment(startDate)
  //     ? startDate.format("YYYY-MM-DD")
  //     : "";
  // const formattedEndDate =
  //   endDate && moment.isMoment(endDate) ? endDate.format("YYYY-MM-DD") : "";
  const [previousId, setPreviousId] = useState(null);

  //disable date logic
  const disabledDate = (current) => {
    const createdDate = authService.getCreatedDate();
    const today = dayjs();

    return createdDate
      ? current < dayjs(createdDate).startOf("day") ||
          current > today.endOf("day")
      : false;
  };
  //column for report
  const columns = [
    {
      title: <span style={{ color: "#000816" }}>S.No</span>,
      dataIndex: "sno",
      key: "sno",
      align: "center",
    },
    {
      title: <span style={{ color: "#000816" }}> Camera Name</span>,
      dataIndex: "cameraName",
      key: "cameraName",
      align: "center",
    },
    {
      title: <span style={{ color: "#000816" }}>Event Name</span>,
      dataIndex: "eventName",
      key: "event",
      align: "center",
    },
    {
      title: <span style={{ color: "#000816" }}>Location</span>,
      dataIndex: "location",
      key: "location",
      align: "center",
    },
    // {
    //   title: (
    //     <span style={{ color: "#000816", textAlign: "center" }}>Details</span>
    //   ),
    //   dataIndex: "details",
    //   key: "details",
    //   align: "center",
    // },
    {
      title: <span style={{ color: "#000816" }}>Date/Time</span>,
      dataIndex: "createddate",
      key: "datetime",
      align: "center",
    },
    {
      title: <span style={{ color: "#000816" }}>Image/Video</span>,
      dataIndex: "media",
      key: "media",
      align: "center",
      render: (text, record) => (
        <span>
          {record.id == ImageLoad.id && ImageLoad.Load === true ? (
            <Button type="primary">
              <PulseLoader size={2} color="white" />
            </Button>
          ) : (
            <Button
              type="primary"
              onClick={() => {
                openModel(record);
                //imageSetter(console.log("RECORD-----------------",record))
              }}
            >
              View
            </Button>
          )}
        </span>
      ),
    },
    {
      title: <span style={{ color: "#000816" }}>Status</span>,
      dataIndex: "status",
      key: "status",
      align: "center",
      width: 200,
      render: (text, record) => (
        <span>
          {record.status == true ? "Issue solved" : "Issue Not solved"}
        </span>
      ),
    },
    {
      title: <span style={{ color: "#000816" }}>Action</span>,
      dataIndex: "action",
      key: "action",
      align: "center",
      render: (text, record) => (
        <Select
          className="custom-select"
          style={{ width: 160, color: "grey" }}
          placeholder="Action"
          dropdownStyle={{ backgroundColor: "#fffff", color: "#fffff" }}
          suffixIcon={<FaCaretDown color="white" size={20} />}
          value={status == null ? null : null}
          onChange={(value) => {
            SetStatus(value);
            updateAction(record.id, record.eventName, value);
          }}
        >
          <Option value={true}>Issue Solved</Option>;
          <Option value={false}>Issue not Solved</Option>;
        </Select>
      ),
    },
  ];

  // calling api for event list based on cameraid & camera list based on eventid
  useEffect(() => {
    // allReports();
    // getAllEvents();
    getAllCameraList();
    if (eventId != null || eventId != undefined) {
      getCameraListBasedId();
    }
    if (cameraId != null || cameraId != undefined) {
      getEventListBasedId();
    }
  }, [startDate, endDate, eventId, cameraId, currentPage]);

  //initially calling the eventlst
  useEffect(() => {
    getAllEvents();
  }, []);

  //trigering api for get all report data if any changes of date,camera& event
  useEffect(() => {
    // setreportLoading(true);
    allReports(startDate, endDate, eventId, cameraId, currentPage);
  }, [startDate, endDate, eventId, cameraId, currentPage]);
  useEffect(() => {
    setCurrentPage(1);
  }, [startDate, endDate, eventId, cameraId, 1]);
  // calling the eventlst based on dashboardNavData
  useEffect(() => {
    if (dashboardNavData) {
      console.log("Received ID:", dashboardNavData.id);
      getAllEvents();
    }
  }, [dashboardNavData]);

  //this api for fetching image through api
  const fetchImage = (id, eventId, StartDate, EndDate, cameraId) => {
    setLoading(true);

    axiosClient
      .get(
        `/report1/getothereventsimagebyid?id=${id}&eventId=${eventId}&event=${event}&startDate=${StartDate}&endDate=${EndDate}&cameraId=${cameraId}`
      )
      .then((response) => {
        if (response.data.issuccess) {
          const {
            image,
            currentData,
            previousData,
            nextData,
            startDate,
            endDate,
          } = response.data.data;
          setImageRecords(currentData);
          setCurrentImage(image);
          setstartDate(startDate);
          setendDate(endDate);

          // Ensure these records include eventId
          setPreviousRecord(previousData || {});
          setNextRecord(nextData || {});

          // Store previous and next IDs
          setPreviousId(previousData?.id || null);
          setNextId(nextData?.id || null);
        } else {
          message.error("Image unavailable");
        }
      })
      .catch((err) => {
        console.error("Error fetching image:", err);
        message.error("Image unavailable");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  //onclick of previous button of image
  const handlePrevious = () => {
    if (previousId) {
      const previousEventId = previousRecord.eventId;
      fetchImage(previousId, previousEventId, startDate, endDate, cameraId);
      setCurrentIndex(currentIndex - 1);
    }
  };
  //onclick of Next button of image
  const handleNext = () => {
    if (nextId) {
      const nextEventId = nextRecord.eventId;
      fetchImage(nextId, nextEventId, startDate, endDate, cameraId);
      setCurrentIndex(currentIndex + 1);
    }
  };
  //this is modal for displaying image
  const openModel = (record) => {
    const index = imageRecordsid.findIndex((r) => r.id === record.id);
    setCurrentIndex(index);

    const StartDate =
      Array.isArray(dateRange) && dateRange[0]
        ? dateRange[0].format("YYYY-MM-DD")
        : "";
    const EndDate =
      Array.isArray(dateRange) && dateRange[1]
        ? dateRange[1].format("YYYY-MM-DD")
        : "";

    fetchImage(record.id, record.eventId, StartDate, EndDate, cameraId);

    setModelVisible(true);
  };

  const imageSetter = (record) => {
    console.log("RECORD-----------------", record);
  };

  const handleOk = () => {
    setModel(false);
    setImage("");
  };

  const handleCancel = () => {
    setModel(false);
    setImage("");
  };

  const onClickDownload = () => {
    axiosClient
      .get(
        "/report1/getreportforothereventsexceldownload?startDate=" +
          startDate +
          "&endDate=" +
          endDate +
          "&eventId=" +
          eventId +
          "&cameraId=" +
          cameraId,
        { responseType: "blob" }
      )
      .then((response) => {
        if (response.status === 200) {
          var blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          FileSaver.saveAs(blob, "report.xlsx");
        }
      })
      .catch((err) => {
        console.error("Error fetching overall events:", err);
      });
  };

  // useEffect(() => {
  //   // allReports();
  //   //getAllEvents();
  //   //getAllCameraList();
  //   if (eventId != null || eventId != undefined) {
  //     getCameraListBasedId();
  //   }
  //   if (cameraId != null || cameraId != undefined) {
  //     getEventListBasedId();
  //   }
  // }, [StartDate, EndDate, eventId, cameraId]);

  // useEffect(() => {
  //   allReports(StartDate, EndDate, eventId, cameraId);
  //   getAllEvents();
  //   getAllCameraList();
  //   // getImageBasedId();
  // }, []);

  //: http://localhost:4001/api/report1/geteventlistbasedoncameraid

  // useEffect(() => {
  //   axiosClient
  //     .get("/report1/geteventlistbasedoncameraid")
  //     .then((response) => {
  //       if (response.data.issuccess === true) {
  //         console.log("Events---------------------------:", response.data);

  //       } else {
  //         console.log("Something went wrong");
  //       }
  //     })
  //     .catch((err) => {
  //       console.error("Error fetching overall events:", err);
  //     });
  // }, []);

  const Clear = () => {
    // Clear local storage
    // localStorage.removeItem("reportData");
    getAllEvents();
    // Reset state variables
    setDateRange(null);
    seteventId(null);
    setCameraId(null);
    setCurrentPage(1);
    setevent(false);
    allReports("", "", null, null, 1);
  };

  //http://localhost:4001/api/report1/getreportdetailsbasedoneventId
  function allReports(startDate, endDate, eventId, cameraId, currentPage) {
    // setreportLoading(true);
    console.log("api is triggering");
    return axiosClient
      .get(
        `/report1/getreportforotherevents?startDate=${startDate}&endDate=${endDate}&eventId=${eventId}&cameraId=${cameraId}&page=${currentPage}&download=false`
      )
      .then((response) => {
        if (response.data.issuccess === true) {
          setreportLoading(false);
          console.log("api is triggering1111");
          const responseData = response.data.data.list.map((item, index) => ({
            ...item,
            sno: (currentPage - 1) * 10 + index + 1,
            details: "-",
          }));
          console.log("responseData222222", responseData);
          setData(responseData);
          const idsAndeventIds = responseData.map((item) => ({
            id: item.id,
            eventId: item.eventId,
            cameraName: item.cameraName,
            location: item.location,
            eventName: item.eventName,
          }));

          setimageRecordsid(idsAndeventIds);
          setTotalPages(response.data.data.totalpages);
        } else {
          setreportLoading(false);
          console.log(
            "Something went wrong:",
            response.data.message || "Unknown error"
          );
        }
      })
      .catch((err) => {
        setreportLoading(false);
        console.error("Error fetching overall events:", err);
      });
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // function allReports(StartDate, EndDate, eventId, cameraId, page = 1) {
  //   axiosClient
  //     .get(
  //       `/report1/getreportforotherevents?startDate=${StartDate}&endDate=${EndDate}&eventId=${eventId}&cameraId=${cameraId}&page=${page}&download=false`
  //     )
  //     .then((response) => {
  //       if (response.data.issuccess === true) {
  //         console.log("api is triggering1111");
  //         const responseData = response.data.data.list.map((item, index) => ({
  //           ...item,
  //           sno: (page - 1) * 10 + index + 1,
  //           details: "-",
  //         }));
  //         console.log("responseData222222", responseData);
  //         setData(responseData);
  //         const idsAndeventIds = responseData.map((item) => ({
  //           id: item.id,
  //           eventId: item.eventId,
  //           cameraName: item.cameraName,
  //           location: item.location,
  //           eventName: item.eventName,
  //         }));
  //         setImageRecords(idsAndeventIds);
  //         setData(responseData);
  //         setRecords(response.data.data);
  //         setCurrentImageId(idsAndeventIds[0]?.id);
  //       } else {
  //         console.log("Something went wrong");
  //       }
  //     })
  //     .catch((err) => {
  //       console.error("Error fetching overall events:", err);
  //     });
  // }

  const getAllEvents = () => {
    axiosClient
      .get("/report1/geteventlistforreport")
      .then((response) => {
        if (response.data.issuccess) {
          setEventList(response.data.data);

          const id = dashboardNavData?.id;
          console.log("id6666", id);

          // Check if the ID matches any eventId from the response
          const matchedEvent = response.data.data.find(
            (item) => item.eventId === id
          );

          if (matchedEvent) {
            seteventId(matchedEvent.eventId); // Update selected event ID
          } else {
            seteventId(null); // Reset if no match found
          }
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((err) => {
        console.error("Error fetching overall events:", err);
      });
  };
  // const getAllEvents = () => {
  //   axiosClient
  //     .get("/report1/geteventlistforreport")
  //     .then((response) => {
  //       if (response.data.issuccess) {
  //         setEventList(response.data.data);

  //         const data1 = JSON.parse(localStorage.getItem("reportData"));
  //         const id = data1.id;
  //         // Check if the ID matches any eventId from the response
  //         const matchedEvent = response.data.data.find(
  //           (item) => item.eventId === id
  //         );
  //         if (matchedEvent) {
  //           seteventId(matchedEvent.eventId);
  //         } else {
  //           seteventId(null);
  //         }
  //       } else {
  //         console.log("Something went wrong");
  //       }
  //     })
  //     .catch((err) => {
  //       console.error("Error fetching overall events:", err);
  //     });
  // };

  const handleImageLoadStart = () => {
    setLoading(true);
  };

  const handleImageLoadEnd = () => {
    setLoading(false);
  };

  function getAllCameraList() {
    axiosClient
      // .get("/report1/getcameralist")
      .get("/report1/getcameralistforreport?eventId=" + "")
      .then((response) => {
        if (response.data.issuccess === true) {
          console.log(
            "Overall Camera First-----------------------------------:",
            response.data.data
          );
          setCameraList(response.data.data);
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((err) => {
        console.error("Error fetching overall events:", err);
      });
  }
  // http://localhost:4001/api/report1/geteventlistbasedoncameraid?cameraId=1

  function getEventListBasedId() {
    axiosClient
      .get("/report1/geteventlistbasedoncameraid?cameraId=" + cameraId)
      .then((response) => {
        if (response.data.issuccess === true) {
          console.log(
            "Overall Based on Camera Event-----------------------------------:",
            response.data.data
          );
          setEventList(response.data.data);
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((err) => {
        console.error("Error fetching overall events:", err);
      });
  }
  // console.log("Event list %55%%%%%%%",EventList)

  // http://localhost:4001/api/report1/getcameralistbasedoneventId?eventId=1
  function getCameraListBasedId() {
    axiosClient
      .get("/report1/getcameralistbasedoneventId?eventId=" + eventId)
      .then((response) => {
        if (response.data.issuccess === true) {
          console.log(
            "Overall Based on Event Camera TEST-----------------------------------:",
            response.data.data
          );
          setCameraList(response.data.data);
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((err) => {
        console.error("Error fetching overall events:", err);
      });
  }

  // http://localhost:4001/api/report1/getEventImageByID?id=1&eventId=5

  function getImageBasedId(id, eventId) {
    setImageLoad((prev) => ({
      id: id,
      Load: true,
    }));
    axiosClient
      .get(`/report1/getEventImageByID?id=${id}&eventId=${eventId}`)
      .then((response) => {
        if (response.data.issuccess === true) {
          console.log(
            "IMAGE-----------------------------------:",
            response.data.data
          );
          //setEventList(response.data.data);
          setImage(response.data.data);
          setModel(true);
          setImageLoad({
            id: "",
            Load: false,
          });
        } else {
          console.log("Something went wrong");
          message.error("Image unavailable");
          setImageLoad((prev) => ({
            ...prev,
            Load: false,
          }));
        }
      })
      .catch((err) => {
        console.error("Error fetching overall events:", err);
        message.error("Image unavailable");
        setImageLoad((prev) => ({
          ...prev,
          Load: false,
        }));
      });
  }

  const onDateChange = (date) => {
    if (date) {
      const [startDate, endDate] = date;
      if (date) {
        setdate(true);
      }
      setStartDate(startDate.format("YYYY-MM-DD"));
      setEndDate(endDate.format("YYYY-MM-DD"));
    } else {
      setStartDate("");
      setEndDate("");
    }
  };

  // http://localhost:4001/api/report1/updateaction?id=2&eventName=Trespassing&status=false
  function updateAction(id, eventName, value) {
    axiosClient
      .put(
        `/report1/updateaction?id=${id}&eventName=${eventName}&status=${value}`
      )
      .then((response) => {
        if (response.data.issuccess === true) {
          allReports(startDate, endDate, eventId, cameraId, currentPage);
          SetStatus(null);
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((err) => {
        console.error("Error fetching overall events:", err);
      });
  }
  // const currentRecord = imageRecords.find(
  //   (record) => record.id === currentImageId
  // );
  // const uniqueCameras = CameraList.filter(
  //   (item, index, self) =>
  //     index === self.findIndex((t) => t.id === item.id)
  // );

  // const emitData = () => {
  //   const dataToSend = { message: "Trespassing" };
  //   // Emit a custom event to the server
  //   socket.emit("Trespassing", dataToSend);
  //   console.log("Data sent to server:", dataToSend);
  // };
  // useEffect(() => {
  //   emitData();
  // }, []);
  return (
    <div style={{ marginTop: "15px" }}>
      {/* <Row
          xs={24}
          md={12}
          style={{
            alignItems: "center",
            justifyContent: "space-between",
            padding: "1rem 1rem",
          }}
        >
          <Text
            xs={24}
            md={12}
            style={{ color: "white", fontWeight: "700", fontSize: "2rem" }}
          >
            Report
          </Text>
          <Button
            xs={24}
            md={12}
            style={{ display: "flex", alignItems: "center", gap: "5px" }}
            onClick={onClickDownload}
          >
            Export to xlsx <FaCloudDownloadAlt size={22} />
          </Button>
        </Row> */}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Row
          style={{
            padding: "0rem 1rem",
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            marginLeft: "1%",
            gap: "1rem",
            flexWrap: "wrap",
          }}
        >
          <Col>
            <RangePicker
              value={dateRange}
              onChange={(date) => {
                setDateRange(date);
                onDateChange(date);
              }}
              disabledDate={disabledDate}
            />
            {/* <RangePicker
              //style={{width:260}}
              value={dateRange}
              onChange={(date) => {
                setDateRange(date);
                onDateChange(date);
              }}
              disabledDate={disabledDate}
            /> */}
          </Col>
          <Col>
            <Select
              style={{ color: "grey" }}
              placeholder="Select Camera"
              value={cameraId}
              onChange={(value) => {
                setCameraId(value);
              }}
            >
              {CameraList.map((item) => {
                return <Option value={item.cameraId}>{item.cameraName}</Option>;
              })}
            </Select>
          </Col>
          <Col>
            {/* <Select
              style={{ color: "grey" }}
              placeholder="Select Event"
              value={eventId}
              onChange={(value) => {
                seteventId(value);
              }}
            >
              {EventList.map((item) => {
                return <Option value={item.eventId}>{item.eventName}</Option>;
              })}
            </Select> */}
            <Select
              style={{ color: "grey" }}
              placeholder="Select Event"
              value={eventId}
              onChange={(value) => {
                seteventId(value);
                setevent(true);
              }}
            >
              {EventList.map((item) => (
                <Option key={item.eventId} value={item.eventId}>
                  {item.eventName}
                </Option>
              ))}
            </Select>
          </Col>
          <Col>
            <Button onClick={Clear}>Clear</Button>
          </Col>
        </Row>
        <Row>
          <Button
            xs={24}
            md={12}
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
              marginRight: "50px",
            }}
            onClick={onClickDownload}
          >
            Export to xlsx <FaCloudDownloadAlt size={22} />
          </Button>
        </Row>
      </div>

      <Table
        columns={columns}
        dataSource={data}
        style={{ padding: "1rem 2rem", width: "100%" }}
        pagination={{
          current: currentPage,
          total: totalPages * 10,
          pageSize: 10,
          showSizeChanger: false,
          onChange: handlePageChange, // Handle page change
        }}
        rowKey="sno"
        scroll={{ x: true }}
      />

      <Modal
        width={"50vw"}
        open={modelVisible}
        onCancel={() => setModelVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setModelVisible(false)}>
            Cancel
          </Button>,
        ]}
        title={
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span
              style={{
                fontSize: "22px",
                fontWeight: "bold",
                color: "#3d3c3c",
              }}
            >
              {imageRecords.eventName} Detected
            </span>
            <div style={{ textAlign: "center" }}>
              <span
                style={{ fontWeight: "bold", color: "grey", fontSize: "18px" }}
              >
                Date:{" "}
              </span>
              <span style={{ color: "#3d3c3c", fontSize: "16px" }}>
                {imageRecords.createddate || "N/A"}
              </span>
            </div>
          </div>
        }
      >
        {/* <div className="image-container" style={{ position: "relative", height: "400px" }}>
    {loading && (
      <div className="loading-overlay" style={{ top: "18%", marginLeft: "10%" }}>
        <Spin size="large" />
      </div>
    )}

    {currentImage && currentImage !== "file not found!" ? (
      <Image
        src={`data:image/jpeg;base64,${currentImage}`}
        style={{ width: "100%", height: "auto" }}
      />
    ) : (
      <div style={{ display: "flex", marginLeft: "220px", justifyContent: "center", alignItems: "center", height: "100%" }}>
        <Empty description="No Image Available" />
      </div>
    )}
  </div> */}
        <div
          className="image-container"
          style={{
            position: "relative",
            minHeight: "380px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {loading ? (
            <div
              className="loading-overlay"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spin size="large" />
            </div>
          ) : currentImage && currentImage !== "file not found!" ? (
            <Image
              src={`data:image/jpeg;base64,${currentImage}`}
              style={{ width: "100%", height: "auto" }}
            />
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Empty description="No Image Available" />
            </div>
          )}
        </div>
        <div
          style={{
            marginTop: 16,
            display: "flex",
            justifyContent: "space-evenly",
            width: "100%",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <span
              style={{ fontWeight: "bold", color: "grey", fontSize: "18px" }}
            >
              Camera Name:{" "}
            </span>
            <span style={{ color: "#3d3c3c", fontSize: "18px" }}>
              {imageRecords.cameraName}
            </span>
          </div>
          <div style={{ textAlign: "center" }}>
            <span
              style={{ fontWeight: "bold", color: "grey", fontSize: "18px" }}
            >
              Location:{" "}
            </span>
            <span style={{ color: "#3d3c3c", fontSize: "18px" }}>
              {imageRecords.location || "N/A"}
            </span>
          </div>
          {/* <div style={{ textAlign: "center" }}>
            <span
              style={{ fontWeight: "bold", color: "grey", fontSize: "18px" }}
            >
              Date:{" "}
            </span>
            <span style={{ color: "#3d3c3c", fontSize: "16px" }}>
              {imageRecords.createddate || "N/A"}
            </span>
          </div> */}
        </div>

        <div
          style={{
            marginTop: 16,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Tooltip title="Previous Image">
            <Button
              icon={<LeftOutlined />}
              onClick={handlePrevious}
              disabled={!previousId}
            />
          </Tooltip>
          <Tooltip title="Next Image">
            <Button
              icon={<RightOutlined />}
              onClick={handleNext}
              disabled={!nextId}
            />
          </Tooltip>
        </div>
      </Modal>

      {/* <Modal
        width={"50vw"}
        open={modelVisible}
        // onOk={() => setModelVisible(false)}
        onCancel={() => setModelVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setModelVisible(false)}>
            Cancel
          </Button>,
        ]}
        title={
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {imageRecords.length > 0 && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div>
                  <span
                    style={{
                      fontSize: "20px",
                      fontWeight: "bold",
                      color: "#3d3c3c",
                    }}
                  >
                    {imageRecords[currentIndex].eventName} Detected
                  </span>
                </div>
                <div>
                  <span
                    style={{
                      fontSize: "17px",
                      fontWeight: "bold",
                      color: "grey",
                    }}
                  >
                    Camera Name:
                  </span>
                  <span style={{ color: "#3d3c3c" }}>
                    {imageRecords[currentIndex].cameraName}
                  </span>
                </div>
                <div style={{ marginTop: "4px" }}>
                  <span
                    style={{
                      fontSize: "17px",
                      fontWeight: "bold",
                      color: "grey",
                    }}
                  >
                    Location:
                  </span>
                  <span style={{ color: "#3d3c3c" }}>
                    {imageRecords[currentIndex].location}
                  </span>
                </div>
              </div>
            )}
          </div>
        }
      >
        <div style={{ position: "relative" }}>
          {currentImage ? (
            <div>
              {loading && (
                <div className="loading-overlay">
                  <Spin size="large" />
                </div>
              )}
              <Image
                src={`data:image/jpeg;base64,${currentImage}`}
                alt="Image Not Found"
                style={{ width: "100%", height: "auto" }}
              />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                marginTop: "50px",
              }}
            >
              <Empty description="No Image Available" />
            </div>
          )}
        </div>

        <div
          style={{
            marginTop: 16,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Tooltip title="Previous Image">
            <Button
              icon={<LeftOutlined />}
              onClick={handlePrevious}
              disabled={previndex === 0 && currentPage === 1}
            />
          </Tooltip>

          <Tooltip title="Next Image">
            <Button
              icon={<RightOutlined />}
              onClick={handleNext}
              // disabled={currentIndex === imageRecords.length - 1}
            />
          </Tooltip>
        </div>
      </Modal> */}
      <Modal
        className="LoadinModal"
        open={reportloading}
        style={{ background: "none", textAlign: "center" }}
        footer={false}
        closable={false}
        centered
      >
        <ClipLoader
          color="#23234d"
          // loading={reportloading}
          cssOverride={{
            display: "block",
            margin: "0 auto",

            borderWidth: "5px",
            marginBottom: "10px",
          }}
          size={100}
          aria-label="Loading Spinner"
          data-testid="loader"
          title="Loading..."
        />
        <Text style={{ color: "white", fontSize: "1.2em" }}>Loading ...</Text>
      </Modal>
    </div>
  );
};

const StyledSelect = styled(Select)`
  .ant-select-selection-placeholder {
    color: red; /* Change this to your desired color */
  }
`;

export default OtherReports;
