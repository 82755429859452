import { createSlice } from "@reduxjs/toolkit";
import { notification } from "antd";

export const notificationSlice = createSlice({
  name: "notification",
  initialState: {
    value: { count: 0, unread: 0 },
    usage: { totalmemory: 0, freememory: 0, cpu: 0, gpu: 0 },
  },
  reducers: {
    count: (state, action) => {
      state.value = action.payload;
    },
    cpu_usage: (state, action) => {
      state.usage = action.payload;
    },
  },
});

export const { count, cpu_usage } = notificationSlice.actions;

export default notificationSlice.reducer;
