import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Row,
  Typography,
  Select,
  Table,
  Image,
  Col,
  Menu,
  Dropdown,
  Tag,
  Input,
  Space,
  Empty,
  Pagination,
} from "antd";
import Highlighter from "react-highlight-words";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { DatePicker, Modal } from "antd";
import moment from "moment";
import styled from "styled-components";
import axiosClient from "../../Authentication/ApiCall";
import * as FileSaver from "file-saver";
import { FaAngleDown, FaCaretDown } from "react-icons/fa";
import {
  UserOutlined,
  LockOutlined,
  DownOutlined,
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
} from "@ant-design/icons";
// import "./ReportStyle.css";
import { IoCameraReverse } from "react-icons/io5";
const { Option } = Select;
const { RangePicker } = DatePicker;
const { Text } = Typography;

const Container = styled.div`
  .header {
    padding: 1rem 0;
  }

  .filters {
    padding: 0 1rem;
  }

  .table-container {
    padding: 1rem 2rem;
  }
`;

const FootfallReport = () => {
  function disabledDate(current) {
    // Disable dates after today
    return current && current > moment().endOf("day");
  }
  const [data, setData] = useState([]);
  const [EventList, setEventList] = useState([]);
  const [CameraList, setCameraList] = useState([]);
  const [StartDate, setStartDate] = useState("");
  const [EndDate, setEndDate] = useState("");
  const [EventId, setEventId] = useState(null);
  const [cameraId, setCameraId] = useState(null);
  const [status, SetStatus] = useState(null);
  const [eventName, setEventName] = useState("");
  const [flagEvent, setflagEvent] = useState(false);
  const [Model, setModel] = useState(false);
  const [AnprModal, setAnprModal] = useState(false);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [imagedata, setimagedata] = useState("");
  const [currentPage, setcurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  console.log("totalCount", totalCount);
  const [pageSize, setPageSize] = useState(10);
  const [previousData, setPreviousData] = useState([]);
  function allReports(StartDate, EndDate, currentPage, pageSize) {
    axiosClient
      .get(
        `/report1/getreportforfootfallmall?startDate=${StartDate}&endDate=${EndDate}&page=${currentPage}&pageSize=${pageSize}`
      )
      .then((response) => {
        console.log("API Response:", response.data);

        if (response.data.issuccess) {
          const responseData = response.data.data.map((item, index) => ({
            ...item,
            sno: index + 1 + (currentPage - 1) * pageSize,
            details: "-", // Additional fields if necessary
          }));
          if (StartDate && EndDate) {
            setPreviousData(responseData);
          }
          setData(responseData);

          // Calculate totalItems
          const totalItems = response.data.totalPages;
          console.log("Total Items Calculated:", totalItems);
          setTotalCount(totalItems * 10);

          if (responseData.length > 0) {
            setEventName(responseData[0].eventName);
          }
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((err) => {
        console.error("Error fetching overall events:", err);
      });
  }

  const handleTableChange = (currentPage, pageSize) => {
    setcurrentPage(currentPage);
    setPageSize(pageSize);
    // allReports(currentPage, pageSize);
  };

  //getting  all reports
  // function allReports(StartDate, EndDate, EventId, cameraId) {
  //   axiosClient
  //     .get(
  //       "/report1/getreoptforfootfallmall?startDate=" +
  //         StartDate +
  //         "&endDate=" +
  //         EndDate
  //     )
  //     .then((response) => {
  //       if (response.data.issuccess === true) {
  //         // if (EventId == null)
  //         //   console.log(
  //         //     "Overall Events for STATUS-----------------------------------:",
  //         //     response.data.data
  //         //   );
  //         // const responseData = response.data.data;

  //         if (EventId == null) {
  //           setflagEvent(true);
  //           console.log("setflagEvent", flagEvent);
  //         } else {
  //           setflagEvent(false);
  //           console.log("setflagEvent", flagEvent);
  //         }

  //         const responseData = response.data.data
  //           .sort((a, b) => new Date(b.createddate) - new Date(a.createddate))
  //           .map((item, index) => ({
  //             ...item,
  //             sno: index + 1,
  //             details: "-",
  //           }));
  //         setData(responseData);
  //         if (responseData.length > 0) {
  //           setEventName(responseData[0].eventName);
  //         }
  //       } else {
  //         console.log("Something went wrong");
  //       }
  //     })

  //     .catch((err) => {
  //       console.error("Error fetching overall events:", err);
  //     });
  // }
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters);
              // Add functionality to close dropdown if needed
              // Optionally call confirm to keep the dropdown open or closed
              confirm({ closeDropdown: true });
            }}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: <span style={{ color: "#000816" }}>Sl No</span>,
      dataIndex: "serialNo",
      align: "center",
      render: (text, record, index) => (currentPage - 1) * pageSize + index + 1,
    },
    // {
    //   title: <span style={{ color: "#000816" }}>camera Name</span>,

    //   dataIndex: "cameraName",
    //   key: Math.random(),
    //   align: "center",
    //   render: (text) => (
    //     <p
    //       justify="center"
    //       align="center"
    //       // style={{
    //       //   backgroundColor: "#564592",
    //       //   color: "white",
    //       //   borderRadius: "5px",
    //       // }}
    //     >
    //       <Text style={{ color: "black" }}>{text}</Text>
    //     </p>
    //   ),
    //   // filters: cameraFilters,
    //   filterSearch: true,
    //   onFilter: (value, record) => record.cameraName.indexOf(value) === 0,
    // },
    {
      title: <span style={{ color: "#000816" }}>Total Ingress</span>,

      render: (text) => <a>{text}</a>,
      dataIndex: "totalIngress",
      render: (text) => <Text>{text}</Text>,
      key: Math.random(),
      align: "center",
    },
    {
      title: <span style={{ color: "#000816" }}>Total Egress</span>,

      dataIndex: "totalEgress",
      render: (text) => <Text>{text}</Text>,
      key: Math.random(),
      align: "center",
    },
    {
      title: <span style={{ color: "#000816" }}>Average DwellTime</span>,
      // title: "Average DwellTime",
      dataIndex: "averageDwellTime",
      key: Math.random(),
      render: (text) => <Text>{text}</Text>,
      align: "center",
    },
    {
      title: <span style={{ color: "#000816" }}>Total Male</span>,

      dataIndex: "totalMale",
      key: Math.random(),
      render: (text) => <Text>{text}</Text>,
      align: "center",
    },
    {
      title: <span style={{ color: "#000816" }}>Total Female</span>,

      dataIndex: "totalFemale",
      key: Math.random(),
      render: (text) => <Text>{text}</Text>,
      align: "center",
    },
    // {
    //   title: <span style={{ color: "#000816" }}>Location</span>,
    //   dataIndex: "location",
    //   key: Math.random(),
    //   align: "center",
    //   render: (text) => <Text>{text}</Text>,
    //   // filters: location.map((item) => item),
    //   filterSearch: true,
    //   onFilter: (value, record) => record.location.indexOf(value) === 0,
    // },

    {
      title: <span style={{ color: "#000816" }}>Created Date</span>,
      render: (text) => <Text>{text}</Text>,
      dataIndex: "date",
      align: "center",
      key: "initialDate",
    },
  ];
  const OpenModel = (record) => {
    setAnprModal(true);
    console.log("RECORD-----------------", record.eventId);
    getImageBasednumberplate(record.id, record.eventId);
  };

  const OpenModeltrespassing = (record) => {
    setModel(true);
    console.log("RECORD-----------------", record.eventId);
    getImageBasedId(record.id, record.eventId);
  };
  const imageSetter = (record) => {
    console.log("RECORD-----------------", record);
  };

  const handleOk = () => {
    setModel(false);
    setImage("");
  };

  const handleCancel = () => {
    setModel(false);
    setImage("");
  };

  const onClickDownload = () => {
    axiosClient
      .get(
        "/report1/getpdfreportforfootfall?startDate=" +
          StartDate +
          "&endDate=" +
          EndDate +
          "&eventId=" +
          13 +
          "&cameraId=" +
          cameraId +
          "&download=" +
          true,
        { responseType: "blob" }
      )
      .then((response) => {
        if (response.status === 200) {
          var blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          FileSaver.saveAs(blob, "Footfallreport.pdf");
        }
      })
      .catch((err) => {
        console.error("Error fetching overall events:", err);
      });
  };

  //http://localhost:4001/api/report1/getreportdetailsbasedoneventid

  useEffect(() => {
    // Reset to page 1 when StartDate or EndDate changes
    setcurrentPage(1);
    allReports(StartDate, EndDate, 1, pageSize);
  }, [StartDate, EndDate]);

  useEffect(() => {
    allReports(StartDate, EndDate, currentPage, pageSize);
  }, [currentPage, pageSize]);

  const [dateRange, setDateRange] = useState(null);
  const Clear = () => {
    //window.location.reload();
    setDateRange(null);
    setData(previousData);
    setcurrentPage(1);
    allReports("", "", currentPage, pageSize);
  };

  function getAllCameraList() {
    axiosClient
      // .get("/report1/getcameralist")
      .get("/report1/getcameralistforreport?eventId=" + 13)
      .then((response) => {
        if (response.data.issuccess === true) {
          console.log(
            "Overall Camera First-----------------------------------:",
            response.data.data
          );
          setCameraList(response.data.data);
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((err) => {
        console.error("Error fetching overall events:", err);
      });
  }

  // http://localhost:4001/api/report1/getEventImageByID?id=1&eventId=5
  const [image, setImage] = useState("");
  console.log("imagw4444444");

  function getImageBasedId(id, eventId) {
    axiosClient
      .get(`/report1/getEventImageByID?id=${id}&eventId=${eventId}`)
      .then((response) => {
        if (response.data.issuccess === true) {
          console.log(
            "IMAGE-----------------------------------:",
            response.data.data
          );
          //setEventList(response.data.data);
          const imageData = response.data.data;
          console.log("IMAGE DATA TO SET:", imageData);
          setImage(imageData);
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((err) => {
        console.error("Error fetching overall events:", err);
      });
  }

  useEffect(() => {
    console.log("Updated image state:", image);
  }, [image]);

  function getImageBasednumberplate(id, eventId) {
    axiosClient
      .get(`/report1/getEventImageByID?id=${id}&eventId=${eventId}`)
      .then((response) => {
        if (response.data.issuccess === true) {
          console.log(
            "IMAGE-----------------------------------:",
            response.data.data
          );
          const imageData = response.data.data;
          console.log("IMAGE DATA TO SET444445555:", imageData.inTimeImage);
          console.log("IMAGE DATA TO outtimeimge:", imageData.outTimeImage);
          setimagedata(imageData);
          // setImage(response.data.data);
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((err) => {
        console.error("Error fetching overall events:", err);
      });
  }

  const onDateChange = (date) => {
    if (date) {
      const [startDate, endDate] = date;
      setStartDate(startDate.format("YYYY-MM-DD"));
      setEndDate(endDate.format("YYYY-MM-DD"));
    } else {
      setStartDate("");
      setEndDate("");
    }
  };

  // http://localhost:4001/api/report1/updateaction?id=2&eventName=Trespassing&status=false
  function updateAction(id, eventName, value) {
    axiosClient
      .put(
        `/report1/updateaction?id=${id}&eventName=${eventName}&status=${value}`
      )
      .then((response) => {
        if (response.data.issuccess === true) {
          // allReports(StartDate, EndDate, EventId, cameraId);
          SetStatus(null);
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((err) => {
        console.error("Error fetching overall events:", err);
      });
  }

  // const uniqueCameras = CameraList.filter(
  //   (item, index, self) =>
  //     index === self.findIndex((t) => t.id === item.id)
  // );
  const onClose = () => {
    setAnprModal(false);
  };
  const opencamera = () => {
    setopencameramodal(true);
    fetchData(StartDate, EndDate);
  };
  const onClosecameramodal = () => {
    setopencameramodal(false);
  };
  const cameracolumns = [
    {
      title: (
        <span style={{ color: "#000816", fontSize: "17px" }}>camera Name</span>
      ),

      dataIndex: "cameraName",
      key: Math.random(),
      align: "center",
      render: (text) => (
        <p
          justify="center"
          align="center"
          // style={{
          //   backgroundColor: "#564592",
          //   color: "white",
          //   borderRadius: "5px",
          // }}
        >
          <Text style={{ color: "black" }}>{text}</Text>
        </p>
      ),
      // filters: cameraFilters,
      filterSearch: true,
      onFilter: (value, record) => record.cameraName.indexOf(value) === 0,
    },
    {
      title: (
        <span style={{ color: "#000816", fontSize: "17px" }}>Location</span>
      ),
      dataIndex: "location",
      key: Math.random(),
      align: "center",
      render: (text) => <Text>{text}</Text>,
      // filters: location.map((item) => item),
      filterSearch: true,
      onFilter: (value, record) => record.location.indexOf(value) === 0,
    },
  ];
  const [opencameramodal, setopencameramodal] = useState(false);
  const [cameradata, setcameradata] = useState([]);

  //: http://localhost:4001/api/report1/getreoptforfootfallmall?startDate=2024-09-01&endDate=2024-10-10

  const fetchData = async (StartDate, EndDate) => {
    try {
      const response = await axiosClient.get(
        "/report1/getcameralistforfootfallreport"
      );

      if (response.data.issuccess) {
        setcameradata(response.data.data);
      } else {
        console.log("Something went wrong");
      }
    } catch (err) {
      console.error("Error fetching overall events:", err);
    }
  };
  return (
    <div style={{ marginTop: "15px" }}>
      {/* <Row
        xs={24}
        md={12}
        style={{
          alignItems: "center",
          justifyContent: "space-between",
          padding: "1rem 1rem",
        }}
      >
        <Text
          xs={24}
          md={12}
          style={{ color: "white", fontWeight: "700", fontSize: "2rem" }}
        >
          Report
        </Text>
        <Button
          xs={24}
          md={12}
          style={{ display: "flex", alignItems: "center", gap: "5px" }}
          onClick={onClickDownload}
        >
          Export to xlsx <FaCloudDownloadAlt size={22} />
        </Button>
      </Row> */}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Row
          style={{
            padding: "0rem 1rem",
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            marginLeft: "1%",
            gap: "1rem",
            flexWrap: "wrap",
          }}
        >
          <Col>
            <RangePicker
              //style={{width:260}}
              value={dateRange}
              onChange={(date) => {
                setDateRange(date);
                onDateChange(date);
              }}
              disabledDate={disabledDate}
            />
          </Col>
          {/* <Col>
            <Select
              style={{ color: "grey" }}
              placeholder="Select Camera"
              value={cameraId}
              onChange={(value) => {
                setCameraId(value);
              }}
            >
              {CameraList.map((item) => {
                return <Option value={item.cameraId}>{item.cameraName}</Option>;
              })}
            </Select>
          </Col> */}
          {/* <Col>
            <Select
              style={{ color: "grey" }}
              placeholder="Select Event"
              value={EventId}
              onChange={(value) => {
                setEventId(value);
              }}
            >
              {EventList.map((item) => {
                return <Option value={item.eventId}>{item.eventName}</Option>;
              })}
            </Select>
          </Col> */}
          <Col>
            <Button onClick={Clear}>Clear</Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button onClick={opencamera} style={{ marginRight: "20px" }}>
              {" "}
              View Camera
            </Button>
          </Col>
          <Button
            xs={24}
            md={12}
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
              marginRight: "50px",
            }}
            onClick={onClickDownload}
          >
            Export to xlsx <FaCloudDownloadAlt size={22} />
          </Button>
        </Row>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100vw",
        }}
      >
        <Table
          columns={columns}
          dataSource={data}
          style={{ padding: "1rem 2rem", width: "100%" }}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            total: totalCount,
            onChange: handleTableChange,
          }}
          rowKey="sno"
        />
      </div>
      {/* <Pagination
        current={pagination.current}
        pageSize={pagination.pageSize}
        total={pagination.total}
        onChange={(page, pageSize) =>
          setPagination({ current: page, pageSize })
        }
        style={{ display: "flex", justifyContent: "flex-end" }}
      /> */}
      <div>
        <Modal
          width={"50vw"}
          title="Basic Modal"
          open={Model}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          {/* <Image
            src={"data:image/png;base64," + image}
            // src={`data:image/jpeg;base64,${image}`}
            alt="Image Not Found"
            preview={false}
            height={500}
          /> */}
          <img
            src={`data:image/png;base64,${image}`}
            alt="Image Not Found"
            style={{ width: "100%", height: "auto" }}
          />
        </Modal>
        <Modal
          open={AnprModal}
          onCancel={onClose}
          footer={null}
          width={1000} // Adjust width as needed
        >
          <Row>
            <Col span={11}>
              {" "}
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",

                  marginBottom: "9px",
                  marginLeft: "30px",
                }}
              >
                <Tag
                  style={{
                    padding: "5px",
                    fontSize: "18px",
                    fontWeight: "bold",
                  }}
                >
                  {" "}
                  In Time Image
                </Tag>
              </span>
              {imagedata.inTimeImage ? (
                <Image
                  src={`data:image/jpeg;base64,${imagedata.inTimeImage}`}
                  alt="In Time"
                  style={{ height: "560px" }}
                  className="modal-image"
                />
              ) : (
                <div style={{ textAlign: "center", marginTop: "130px" }}>
                  <Empty />
                </div>
              )}
            </Col>
            <Col span={11} offset={1}>
              {" "}
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",

                  marginBottom: "9px",
                  marginLeft: "40px",
                }}
              >
                <Tag
                  style={{
                    padding: "5px",
                    fontSize: "18px",
                    fontWeight: "bold",
                  }}
                >
                  Out Time Image
                </Tag>
              </span>
              {imagedata.outTimeImage ? (
                <Image
                  src={`data:image/jpeg;base64,${imagedata.outTimeImage}`}
                  alt="Out Time"
                  style={{ height: "560px" }}
                  className="modal-image"
                />
              ) : (
                <div style={{ textAlign: "center", marginTop: "130px" }}>
                  {" "}
                  <Empty />
                </div>
              )}
            </Col>
          </Row>
        </Modal>
        <Modal
          open={opencameramodal}
          onCancel={onClosecameramodal}
          footer={null}
        >
          <Table
            columns={cameracolumns}
            dataSource={cameradata}
            pagination={{ pageSize: 5 }}
          />
        </Modal>
      </div>
    </div>
  );
};

const StyledSelect = styled(Select)`
  .ant-select-selection-placeholder {
    color: red; /* Change this to your desired color */
  }
`;

export default FootfallReport;
