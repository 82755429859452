import {
  Card,
  Col,
  Row,
  Select,
  Typography,
  Tabs,
  Modal,
  message,
  Table,
  Button,
  Spin,
  Alert,
  Result,
  Form,
  Radio,
  Space,
  Tag,
  Divider,
  notification,
  Progress,
} from "antd";
import ANPR from "./ANPR";
import Footfall from "./Footfall";
import Trespassing from "./Trespassing";
import OverallDashboard from "./OverallDashboard";
import "./Dashboard.css";
import { DatePicker } from "antd";
import DashboardMain from "./DashboardMain";
import React from "react";
import { useEffect, useState, useRef } from "react";

import TabPane from "antd/lib/tabs/TabPane";
import { useAtom } from "jotai";
import {
  EventListJotai,
  PolicyDetailsJotai,
  Role,
} from "../Utils/StateExports";
import NewFootfall from "./NewFootFall";
import FootfallMall from "./Footfall-Mall/FootfallMall";

const { Title, Link, Text } = Typography;
const { RangePicker } = DatePicker;

function Dashboard() {
  document.title = "KEN - Dashboard";

  // const [EventList, setEventList] = useAtom(EventListJotai);

  // jotai test
  // const [Rolecheck, setRoletest] = useAtom(Role);

  const DashboardItems = [
    {
      key: 1,
      label: "FootFall Store",
      children: <Footfall />,
    },
    {
      key: 2,
      label: "FootFall Mall",
      children: <FootfallMall />,
    },
    // {
    //   key: 14,
    //   label: "Dashboard",
    //   children: <DashboardMain />,
    // },
    // {
    //   key: 5,
    //   label: "Trespassing",
    //   children: <Trespassing />,
    // },
    // {
    //   key: 3,
    //   label: "ANPR",
    //   children: <ANPR />,
    // },
  ];
  const [activeKey, setActiveKey] = useState(1);

  const handleTabChange = (key) => {
    setActiveKey(key);
  };

  const getTabStyle = () => {
    if (activeKey == 1|| activeKey == 2) {
      return { height: "100%", position: "fixed", width: "100%" }; // FootFall Store and FootFall Mall
    }
    return { height: "100vh", width: "100%"  }; // Other tabs (ANPR, Dashboard, Trespassing)
  };

  return (
    <div style={getTabStyle()}>
      <Tabs
        className="dash"
        activeKey={activeKey}
        onChange={handleTabChange}
        items={DashboardItems}
        tabPosition="top"
        //style={getTabStyle()}
      />
    </div>
  );
}

export default Dashboard;
