import React, { useState, useEffect ,useRef} from "react";

import { MdNotificationsNone, MdOutlineMarkEmailRead } from "react-icons/md";

import { useNavigate } from "react-router-dom";
import {
  Layout,
  Menu,
  Row,
  Col,
  Tag,
  Space,
  Typography,
  Drawer,
  Button,
  message,
  Card,
  List,
  Image,
  Badge,
  Flex,
  Divider,
  Popover,
  notification,
  Alert,
  Pagination,
  Empty,
} from "antd";
import {
  UserOutlined,
  UserAddOutlined,
  LogoutOutlined,
  DashboardOutlined,
  CalendarOutlined,
  FileDoneOutlined,
  DesktopOutlined,
} from "@ant-design/icons";
import {
  BrowserRouter as Router,
  Link,
  Outlet,
  useLocation,
} from "react-router-dom";

import authService from "../Authentication/authService";

import { useDispatch, useSelector } from "react-redux";
import axiosClient from "../Authentication/ApiCall";
import { count as cnt } from "../../features/Notification";
import { MdAccessTime } from "react-icons/md";
import moment from "moment/moment";
import socket from "../../Socket";

import { IoMdNotificationsOutline } from "react-icons/io";

import { MdArrowDropDown } from "react-icons/md";

import { GrConfigure } from "react-icons/gr";

import { CgController } from "react-icons/cg";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { IoMdAppstore } from "react-icons/io";

import { MdOutlineAccessTime } from "react-icons/md";
import ReactTimeAgo from "react-time-ago";
import { BsShieldExclamation } from "react-icons/bs";
import { atom, useAtom } from "jotai";
import { PolicyDetailsJotai, Role } from "../Menu/Utils/StateExports";
import { MdAddAPhoto } from "react-icons/md";
import { FaCalendar } from "react-icons/fa6";
const { Title, Text } = Typography;

const { Header, Sider, Content } = Layout;

function MainDashboard() {
  const dispatch = useDispatch();
  const count = useSelector((state) => state.count.value);
  const usage = useSelector((state) => state.cpu_usage.usage);

  const location = useLocation();
  const navigate = useNavigate();
  const [OpenDrawer, setOpenDrawer] = useState(false);
  const [NotificationData, setNotificationData] = useState([]);
  const [newnotificationcount, setnewnotificationcount] = useState("");
  const [TotalCount, setTotalCount] = useState();

  // const [selectedKey, setSelectedKey] = useState(
  //   location.state === null ? "1" : location.state.data
  // );

  // Set initial selectedKey based on location.state or default to "1"
  // const initialKey = location.state?.data || "1";
  const [selectedKey, setSelectedKey] = useState(1);
  // useEffect(() => {
  //   // Create a mapping of routes to keys
  //   const pathKeyMap = {
  //     "/Admin/Dashboard": "1",
  //      "/Admin/Reports": "4",
  //     "/Admin/EventSetup": "8",
  //       "/Admin/Configuration": "5"

  //   };

  //   // Update selectedKey based on the current pathname
  //   const currentKey = pathKeyMap[location.pathname] || "1";
  //   setSelectedKey(currentKey);
  // }, [location.pathname]);

  const [refresh, setrefresh] = useState(0);
  const [clicked, setclicked] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  //   const [page, setpage] = useState(2);
  const [Listloading, setListloading] = useState(true);

  const [Profile, setProfile] = useState([]);

  const [Breadcrumbs, setBreadcrumbs] = useState({ parent: null, child: null });

  //   const [currentPage, setCurrentPage] = useState(1);
  //   const pageSize = 5;

  const [PolicyDetails, setPolicyDetails] = useAtom(PolicyDetailsJotai);
  const [notificationCount, setnotificationCount] = useState("");
  // jotai test
  const [Rolecheck, setRoletest] = useAtom(Role);

  // console.log(
  //   "Location from maindashboard",
  //   location.pathname.split("/").at(-1)
  // );

  const Configuration = [
    {
      key: "5-1",
      label: (
        <Link to="PolicyConfig" state={{ data: "5-1" }}>
          PolicyConfiguration
        </Link>
      ),
      icon: <FileDoneOutlined />,
      path: "Policy Configuration",
    },
    {
      key: "5-2",
      label: (
        <Link to="StoreOnboarding" state={{ data: "5-2" }}>
          Store Onboarding
        </Link>
      ),
      icon: <IoMdAppstore />,
      path: "OnBoarding Location",
    },
    {
      key: "5-3",
      label: (
        <Link to="UserMaster" state={{ data: "5-3" }}>
          UserMaster
        </Link>
      ),
      icon: <UserAddOutlined />,
      path: "UserMaster",
    },
    {
      key: "5-4",
      label: (
        <Link to="Asset_onboarding" state={{ data: "5-4" }}>
          Asset Onboarding
        </Link>
      ),
      icon: <MdAddAPhoto />,
      path: "Asset Onboarding",
    },
    {
      key: "5-5",
      label: (
        <Link to="ServerOnboarding" state={{ data: "5-5" }}>
          Server Onboarding
        </Link>
      ),
      icon: <DesktopOutlined />,
      path: "ServerOnboarding",
    },
  ].filter((child) =>
    Rolecheck === "Admin" ? true : PolicyDetails.includes(child.path)
  );

  // Admin Menu
  const items = [
    {
      key: "1",
      label: (
        <Link to="Dashboard" state={{ data: "1" }}>
          Dashboard
        </Link>
      ),
      icon: <DashboardOutlined />,
      path: "Dashboard",
    },

    {
      key: "4",
      icon: <HiOutlineDocumentReport />,
      label: (
        <Link to="Reports" state={{ data: "4" }}>
          Reports
        </Link>
      ),
      path: "Reports",
    },
    {
      key: "8",
      icon: <CgController />,
      label: (
        <Link to="EventSetup" state={{ data: "8" }}>
          Event Addition
        </Link>
      ),
      path: "Event Addition",
    },

    Configuration.length > 0 && {
      key: "5",
      icon: <GrConfigure />,
      label: (
        <>
          Configuration
          <MdArrowDropDown />
        </>
      ),
      // path: "Configuration",

      children: Configuration,
    },
  ];

  // filters based on Policy config
  const filteredItems = items.filter((item) => {
    if (item.children) {
      const filteredChildren = item.children.filter((child) =>
        // PolicyDetails.includes(child.path)
        Rolecheck === "Admin" ? true : PolicyDetails.includes(child.path)
      );

      return filteredChildren;
    } else {
      return Rolecheck === "Admin" ? true : PolicyDetails.includes(item.path);
    }
  });
  const previousSelectedKeyRef = useRef("");

  useEffect(() => {
    const currentPath = location.pathname;
    let newSelectedKey = "";

    if (currentPath.includes("/Admin/Dashboard")) {
      newSelectedKey = "1";
    } else if (currentPath.includes("/Admin/Reports")) {
      newSelectedKey = "4";
    } else if (currentPath.includes("/Admin/EventSetup")) {
      newSelectedKey = "8";
    } else if (currentPath.includes("/Admin/Configuration")) {
      newSelectedKey = "5";
    }

    if (newSelectedKey !== selectedKey) {
      setSelectedKey(newSelectedKey);
      previousSelectedKeyRef.current = newSelectedKey;

      const newBreadcrumbs = filteredItems.reduce((acc, item) => {
        if (item.key === newSelectedKey) {
          return { parent: item.path };
        }
        if (item.children) {
          const foundChild = item.children.find(
            (val) => val.key === newSelectedKey
          );
          if (foundChild) {
            return { parent: item.path, child: foundChild.path };
          }
        }
        return acc;
      }, {});
      setBreadcrumbs(newBreadcrumbs);
    }

    if (!authService.isAuthenticateduser()) {
      TimeOut();
    }
  }, [location.pathname, filteredItems, authService, selectedKey]);

  // useEffect(() => {
  //   const currentPath = location.pathname;
  //   let newSelectedKey;

  //   //  the new selected key based on the current path
  //   if (currentPath.includes("/Admin/Dashboard")) {
  //     newSelectedKey = "1";
  //   } else if (currentPath.includes("/Admin/Reports")) {
  //     newSelectedKey = "4";
  //   } else if (currentPath.includes("/Admin/EventSetup")) {
  //     newSelectedKey = "8";
  //   } else if (currentPath.includes("/Admin/Configuration")) {
  //     newSelectedKey = "5";
  //   }

  //   // Only update selectedKey if it has changed
  //   if (newSelectedKey !== selectedKey) {
  //     setSelectedKey(newSelectedKey);
  //   }

  //   // Update breadcrumbs based on the selected key
  //   filteredItems.forEach((item) => {
  //     if (item.key === newSelectedKey) {
  //       setBreadcrumbs({ parent: item.path });
  //     } else if (item.children) {
  //       item.children.forEach((val) => {
  //         if (val.key === newSelectedKey) {
  //           setBreadcrumbs({ parent: item.path, child: val.path });
  //         }
  //       });
  //     }
  //   });

  //   if (!authService.isAuthenticateduser()) {
  //     TimeOut();
  //   }
  // }, [location.pathname, selectedKey, filteredItems, authService]);
  // Notification
  const openNotification = () => {
    notification.open({
      message: "Trespassing",
      description: (
        <>
          <Flex vertical>
            <Text>Detected at camera1</Text>
            <Text type="secondary">
              <Flex align="flex-end" gap={5}>
                <MdOutlineAccessTime size={"1.3rem"} />
                <Text type="secondary">
                  <ReactTimeAgo date={moment()}></ReactTimeAgo>
                </Text>
              </Flex>
            </Text>
          </Flex>
        </>
      ),
      icon: (
        <BsShieldExclamation
          style={{
            color: "#108ee9",
          }}
        />
      ),
      placement: "bottomRight",
      duration: 7,
    });
  };

  // to check the JWT token timeout
  const TimeOut = () => {
    console.log("time out called");
    navigate("/Login");

    message.info("session timed out");
    authService.logout();
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  useEffect(() => {
    console.log("session timed out ");
    authService.isAuthenticateduser() ? <></> : TimeOut();
  }, []);

  // assigning Selected menu tab by location data, renders only when location has changed
  useEffect(() => {
    const LocationData = location.state === null ? "1" : location.state.data;
    setSelectedKey(LocationData);
    filteredItems.map((item) => {
      if (item.key === LocationData) {
        setBreadcrumbs({ ...Breadcrumbs.child, parent: item.path });
      } else if (item.children) {
        item.children &&
          item.children.map((val) => {
            if (val.key === LocationData) {
              setBreadcrumbs({ parent: item.path, child: val.path });
            }
          });
      }
    });
    authService.isAuthenticateduser() ? <></> : TimeOut();
  }, [location]);

  useEffect(() => {
    socket.on("Trespassing", (data) => {
      const alarmAudio = new Audio("/alarm.mp3");
      alarmAudio.play().catch((error) => {
        console.warn("Audio play was prevented:", error);
      });
      console.log("socket data ", data);
      notification.open({
        message: "Trespassing Alert",
        description: "Trespassing notification received",
        placement: "topRight",
        duration: 3,
        onClick: () => {
          console.log("Notification Clicked!");
        },
      });
      HandleNotification(currentPage, pageSize);
    });
    socket.on("Number_Plate_Recognition", (data) => {
      const alarmAudio = new Audio("/alarm.mp3");
      alarmAudio.play().catch((error) => {
        console.warn("Audio play was prevented:", error);
      });
      console.log("socket data ", data);
      notification.open({
        message: "Numberplate Alert",
        description: "Numberplate notification received",
        placement: "topRight",
        duration: 3,
        onClick: () => {
          console.log("Notification Clicked!");
        },
      });
      HandleNotification(currentPage, pageSize);
    });
    socket.on("Human_Zone_Crossing", (data) => {
      // const alarmAudio = new Audio("/alarm.mp3");
      // alarmAudio.play().catch((error) => {
      //   console.warn("Audio play was prevented:", error);
      // });
      const alarmAudio = new Audio("/alarm.mp3");

      alarmAudio.play().catch((error) => {
        console.warn("Audio play was prevented:", error);
      });

      // Function to stop the audio
      function stopAudio() {
        alarmAudio.pause();
        alarmAudio.currentTime = 0;
      }

      // Set a timeout to stop the audio after 5 seconds
      setTimeout(stopAudio, 5000);

      console.log("socket human ", data);
      notification.open({
        message: "Human_Zone_Crossing Alert",
        description: "Human_Zone_Crossing notification received",
        placement: "topRight",
        duration: 3,
        onClick: () => {
          console.log("Notification Clicked!");
        },
      });
      HandleNotification(currentPage, pageSize);
    });

    socket.on("Safety_Equipments_Detection", (data) => {
      // const alarmAudio = new Audio("/alarm.mp3");
      // alarmAudio.play().catch((error) => {
      //   console.warn("Audio play was prevented:", error);
      // });
      const alarmAudio = new Audio("/alarm.mp3");

      alarmAudio.play().catch((error) => {
        console.warn("Audio play was prevented:", error);
      });

      // Function to stop the audio
      function stopAudio() {
        alarmAudio.pause();
        alarmAudio.currentTime = 0;
      }

      // Set a timeout to stop the audio after 5 seconds
      setTimeout(stopAudio, 5000);

      console.log("socket ppe ", data);
      notification.open({
        message: "Safety_Equipments_Detection Alert",
        description: "Safety_Equipments_Detection notification received",
        placement: "topRight",
        duration: 2,
        onClick: () => {
          console.log("Notification Clicked!");
        },
      });
      HandleNotification(currentPage, pageSize);
    });
    return () => {
      socket.off("Trespassing");
      socket.off("Human_Zone_Crossing");
      socket.off("Safety_Equipments_Detection");
    };
  }, [currentPage, pageSize]);
  useEffect(() => {
    setTimeout(() => TimeOut(), 110 * 60 * 1000);

    // get notification count
    axiosClient
      .get("/userNotification/getNotificationcount")
      .then((response) => {
        if (response.data.issuccess === true) {
          // store.dispatch(count({ count: response.data.unseencount }));

          dispatch(
            cnt({
              count: response.data.newMSG,
              unread: response.data.unseencount,
            })
          );
          setTotalCount(response.data.unseencount);
          // setcameralist(response.data.data);
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((err) => {
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });

    // API to get User details for profile
    axiosClient
      .get("/registration/getUserProfile")
      .then((response) => {
        if (response.data.issuccess === true) {
          setProfile(response.data.data);
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((err) => {
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  }, []);
  // useEffect(() => {
  //   socket.on("Human_Zone_Crossing", (data) => {
  //     console.log("socket data000000 ", data);
  //     notification.open({
  //       message: "Human_Zone_Crossing Alert",
  //       description: "Human_Zone_Crossing notification received",
  //       placement: "topRight",
  //       duration: 3,
  //       onClick: () => {
  //         console.log("Notification Clicked!");
  //       },
  //     });
  //     HandleNotification(currentPage, pageSize);
  //   });

  //   return () => {
  //     socket.off("Human_Zone_Crossing");
  //   };
  // }, [currentPage, pageSize]);

  // INitial Fetch
  useEffect(() => {
    HandleNotification(currentPage, pageSize);
  }, [refresh, clicked, currentPage, pageSize]);

  // // to get updated notification list
  useEffect(() => {
    setListloading(true);
    clicked
      ? axiosClient
          .get("/notification/getallnotificationdetails?page=1&&size=10")
          .then((response) => {
            if (response.data.issuccess === true) {
              // response.data.data.map((item, index) => {
              //   setNotificationData((Datas) => [...Datas, item]);
              // });
              setNotificationData(response.data.data.notificationDetails);
              console.log(
                " setNotificationData11111",
                response.data.data.notificationDetails
              );
              setListloading(false);
              // let j = response.data.data;

              // setData((Datas) => [...Data, response.data.data]);
              // setlocation((location) => [...location, tosaveloc]);
            } else {
              console.log("Something went wrong");
            }
          })
          .catch((err) => {
            console.log("errr", err);
            if (err.status === 0) {
              message.error("Server error");
            } else {
              message.error(err.msg);
            }
          })
      : axiosClient
          .get("/notification/getallnotificationdetails?page=1&&size=10")
          .then((response) => {
            if (response.data.issuccess === true) {
              // setData((data) => [...data, response.data.data]);
              // setNotificationData(response.data.data);
              setNotificationData(response.data.data.notificationDetails);
              console.log(
                " setNotificationData22222",
                response.data.data.notificationDetails
              );
              setListloading(false);
            } else {
              console.log("Something went wrong");
            }
          })
          .catch((err) => {
            console.log("errr", err);
            if (err.status === 0) {
              message.error("Server error");
            } else {
              message.error(err.msg);
            }
          });
  }, [refresh]);

  // Api to send details of viewed notification
  const HandleClick = (id) => {
    setListloading(true);

    axiosClient
      .put("notification/updatenotificationdetailsisseen?id=" + id)
      .then((response) => {
        if (response.data.issuccess === true) {
          // setclicked(true);

          if (NotificationData.length > 10) {
            setNotificationData(NotificationData.slice(0, -5));
          }
          setrefresh(refresh + 1);
          // setListloading(false);
        }
      })
      .catch((err) => {
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  };

  // Notification all read
  const AllRead = () => {
    setclicked(false);
    setOpenDrawer(false);
    axiosClient
      .put("notification/updatenotificationdetailsisseen?id=" + 0)
      .then((response) => {
        if (response.data.issuccess === true) {
          dispatch(cnt({ count: 0, unread: 0 }));
          setrefresh(refresh + 1);
          message.success("Marked all as read");
        }
      })
      .catch((err) => {
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  };

  // API to get Notification list
  const HandleNotification = (page = 1, size = 10) => {
    setListloading(true);
    axiosClient
      .get(`/notification/getallnotificationdetails?page=${page}&size=${size}`)
      .then((response) => {
        if (response.data.issuccess === true) {
          setNotificationData(response.data.data.notificationDetails);
          console.log(response.data.data.count);
          setnewnotificationcount(response.data.data.count);

          setCurrentPage(page);
          setPageSize(size);
          setTotalPages(response.data.data.totalPages);
          console.log(
            "notificationdata",
            response.data.data.notificationDetails
          );
          setListloading(false);
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((err) => {
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  };

  const Logout = () => {
    authService.logout();
    navigate("/");
  };

  const renderCardsForPage = () => {
    return NotificationData.map((item, index) => (
      <Card
        key={index}
        className={item.isseen ? "read-notification" : "Unread-notification"}
        bordered
        hoverable
        onClick={() => {
          if (!item.isseen) HandleClick(item.id);
        }}
        bodyStyle={{ padding: "0px 10px" }}
      >
        <List.Item
          className="Notification-list-item"
          extra={
            <Col span={8}>
              {item.imageBase64 ? (
                <Image
                  src={`data:image/jpeg;base64,${item.imageBase64}`}
                  alt={item.imagename}
                  preview={true}
                />
              ) : (
                <div
                  style={{
                    width: "130px",
                    height: "100px",
                    backgroundColor: "#f0f0f0",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  No Image
                </div>
              )}
            </Col>
          }
        >
          <List.Item.Meta
            title={item.eventName}
            description={
              <Row gutter={[5, 10]}>
                <Col>
                  <Typography.Text type="secondary">
                    {item.eventName} Detected at {item.location} in{" "}
                    {item.cameraName}
                  </Typography.Text>
                </Col>
                <Col className="Notification-Time">
                  <Typography.Text
                    type="secondary"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <MdAccessTime size={"1rem"} />{" "}
                    {moment(item.createddate).format("YYYY MM DD HH:mm:ss")}
                  </Typography.Text>
                </Col>
              </Row>
            }
          />
        </List.Item>
      </Card>
    ));
  };
  const contents = (
    <div className="" style={{width:"300px"}}>
      <div className="profile">
        {" "}
        <Image
          src="/profile.jpg"
          preview={false}
          style={{
            borderRadius: "50%",
            width: "10%",
            height: "15%",
          }}
        />
        <List
          footer={
            <>
              <Flex
                onClick={Logout}
                style={{ cursor: "pointer" }}
                align="center"
                justify="center"
                gap={5}
              >
                <Title level={5}>
                  <Space>
                    <LogoutOutlined size={"20px"} />
                    <Text strong>Logout</Text>
                  </Space>
                </Title>
              </Flex>
            </>
          }
        >
          <List.Item>
            {/* {useremail} */}
            <List.Item.Meta
              style={{ fontWeight: "bold" }}
              // description="SuperAdmin"
            />
            {Profile.username}
          </List.Item>
          <List.Item>
            {/* {useremail} */}
            <List.Item.Meta
              style={{ fontWeight: "bold" }}
              // description="SuperAdmin"
            />
            {Profile.email}
          </List.Item>
          <List.Item>
            {/* {useremail} */}
            <List.Item.Meta
              style={{ fontWeight: "bold" }}
              // description="SuperAdmin"
            />
            <Text
              type="secondary"
              className="textrole"
              style={{ marginRight: "38%" }}
            >
              Roles: <Text>{Profile.roles}</Text>
            </Text>
          </List.Item>
          <List.Item>
            {/* {useremail} */}
            <List.Item.Meta
              style={{ fontWeight: "bold" }}
              // description="SuperAdmin"
            />
            <Text type="secondary" style={{ marginRight: "35%" }}>
              Contact: <Text>{Profile.mobile_number}</Text>
            </Text>
          </List.Item>

          <List.Item>
            <List.Item.Meta
              style={{ fontWeight: "bold" }}
              // description="SuperAdmin"
            />
            <Text type="secondary" style={{ marginRight: "18%" }}>
              Mobile Access :{" "}
              <Text>
                {Profile.mobile_access ? (
                  <Tag color="green"> YES</Tag>
                ) : (
                  <Tag color="red"> NO</Tag>
                )}
              </Text>
            </Text>
          </List.Item>
          {/* <List.Item> */}
          {/* {useremail} */}
          {/* <List.Item.Meta
              style={{ fontWeight: "bold" }}
              // description="SuperAdmin"
            />

            {Profile.VerificationCode != null &&
            Profile.VerificationCode != undefined &&
            Profile.VerificationCode != "" ? (
              <Text type="secondary">
                Verification ID : <Text>{Profile.VerificationCode}</Text>
              </Text>
            ) : (
              <></>
            )}
          </List.Item> */}
          {/* <h4 style={{marginBottom:'40px'}}> SuperAdmin </h4> */}
        </List>
      </div>
    </div>
  );

  return (
    <Layout
      style={{ height: "100%", backgroundColor: "white" }}
    >
      <Header className="master-header">
        <Row className="logo-and-menu" justify={"space-between"}>
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 8, offset: 0 }}
            md={{ span: 12, offset: 0 }}
            lg={{ span: 14, offset: 0 }}
            xl={{ span: 14, offset: 0 }}
            xxl={{ span: 14, offset: 0 }}
          >
            <Row style={{ width: "100%" }}>
              <Col
                xs={{ span: 6, offset: 0 }}
                sm={{ span: 8, offset: 0 }}
                md={{ span: 13, offset: 0 }}
                lg={{ span: 9, offset: 0 }}
                xl={{ span: 6, offset: 0 }}
                xxl={{ span: 6, offset: 0 }}
                className="center-vertical"
              >
                <Row style={{ width: "100%" }}>
                  <Col
                    xs={{ span: 24, offset: 0 }}
                    sm={{ span: 24, offset: 0 }}
                    md={{ span: 6, offset: 0 }}
                    lg={{ span: 8, offset: 0 }}
                    xl={{ span: 8, offset: 0 }}
                    xxl={{ span: 6, offset: 0 }}
                    className="center-vertical"
                  >
                    <img
                      src="../../Ken_icon.svg"
                      style={{ backgroundColor: "none", borderRadius: "50%" }}
                      height={35}
                      alt="logo"
                    />
                  </Col>
                  <Col
                    xs={{ span: 0, offset: 0 }}
                    sm={{ span: 0, offset: 0 }}
                    md={{ span: 18, offset: 0 }}
                    lg={{ span: 12, offset: 0 }}
                    xl={{ span: 16, offset: 0 }}
                    xxl={{ span: 18, offset: 0 }}
                  >
                    <Text level={5} className="App-name">
                      KENVISION
                    </Text>
                  </Col>
                </Row>
              </Col>

              <Col
                xs={{ span: 18, offset: 0 }}
                sm={{ span: 16, offset: 0 }}
                md={{ span: 11, offset: 0 }}
                lg={{ span: 14, offset: 0 }}
                xl={{ span: 18, offset: 0 }}
                xxl={{ span: 18, offset: 0 }}
              >
                {/* <Menu
                  className="Menu"
                  theme="dark"
                  mode="horizontal"
                  // defaultSelectedKeys={["2"]}
                  items={filteredItems}
                  selectedKeys={selectedKey}
                  
                  style={{
                    flex: 1,
                    minWidth: 0,
                  }}
                  onClick={(e) => {
                    if (e.key === "13") {
                      authService.removeuser();
                    }
                  }}
                /> */}
                <Menu
                  theme="dark"
                  mode="horizontal"
                  selectedKeys={[selectedKey]}
                >
                  {items.map((item) =>
                    item.children ? (
                      <Menu.SubMenu
                        key={item.key}
                        title={item.label}
                        icon={item.icon}
                      >
                        {item.children.map((child) => (
                          <Menu.Item key={child.key}>{child.label}</Menu.Item>
                        ))}
                      </Menu.SubMenu>
                    ) : (
                      <Menu.Item key={item.key} icon={item.icon}>
                        {item.label}
                      </Menu.Item>
                    )
                  )}
                </Menu>
              </Col>
            </Row>
          </Col>

          <Col
            xs={{ span: 0, offset: 0 }}
            sm={{ span: 16, offset: 0 }}
            md={{ span: 12, offset: 0 }}
            lg={{ span: 10, offset: 0 }}
            xl={{ span: 10, offset: 0 }}
            xxl={{ span: 10, offset: 0 }}
          >
            <Flex style={{ display: "flex", justifyContent: "end" }}>
              <Space align="center" className="header-right-block">
                {Rolecheck === "Admin" && (
                  <>
                    <Badge
                      count={newnotificationcount}
                      className="center-current"
                      onClick={(e) => {
                        HandleNotification();
                        setOpenDrawer(true);
                      }}
                    >
                      <IoMdNotificationsOutline
                        style={{ fontSize: "23px" }}
                        onClick={(e) => {
                          HandleNotification();
                          setOpenDrawer(true);
                          // openNotification();
                        }}
                        className="header-icons"
                      />
                    </Badge>
                    <Divider type="vertical" className="header-divider" />
                    <Link to={"Calendar"} state={{ data: "0" }}>
                      {location.pathname.split("/").at(-1) &&
                      location.pathname.split("/").at(-1) === "Calendar" ? (
                        <FaCalendar className="header-icons" />
                      ) : (
                        <CalendarOutlined className="header-icons" />
                      )}
                    </Link>{" "}
                    <Divider type="vertical" className="header-divider" />
                  </>
                )}
                {Rolecheck == "user" &&
                  PolicyDetails.includes("Notification") && (
                    <>
                      <Badge
                        count={newnotificationcount}
                        className="center-current"
                        onClick={(e) => {
                          HandleNotification();
                          setOpenDrawer(true);
                        }}
                      >
                        <IoMdNotificationsOutline
                          style={{ fontSize: "23px" }}
                          onClick={(e) => {
                            HandleNotification();
                            setOpenDrawer(true);
                            // openNotification();
                          }}
                          className="header-icons"
                        />
                      </Badge>
                      <Divider type="vertical" className="header-divider" />
                    </>
                  )}
                {Rolecheck == "user" &&
                  PolicyDetails.includes("Event Calendar") && (
                    <>
                      <Link to={"Calendar"} state={{ data: "0" }}>
                        {location.pathname.split("/").at(-1) &&
                        location.pathname.split("/").at(-1) === "Calendar" ? (
                          <FaCalendar color="blue" className="header-icons" />
                        ) : (
                          <CalendarOutlined className="header-icons" />
                        )}
                      </Link>
                      <Divider type="vertical" className="header-divider" />
                    </>
                  )}
                <Space align="center">
                  <div
                    style={{ display: "flex", justifyContent: "space-evenly" }}
                  >
                    <div
                      style={{
                        width: "100px",
                      }}
                    >
                      <Popover content={contents} >
                        <UserOutlined style={{ fontSize: "17px" }} />{" "}
                        {authService.getUserName()}
                      </Popover>
                    </div>
                  </div>
                </Space>
              </Space>
            </Flex>
            {/* <Flex style={{ display: "flex", justifyContent: "end" }}>
              <Space align="center">
                <div
                  style={{ display: "flex", justifyContent: "space-evenly" }}
                >
                  <div
                    style={{
                      width: "100px",
                    }}
                  >
                    <Popover content={contents}>
                      <UserOutlined style={{ fontSize: "17px" }} />{" "}
                      {authService.getUserName()}
                    </Popover>
                  </div>
                </div>
              </Space>
            </Flex> */}
          </Col>
        </Row>
      </Header>

      {/* For notification list */}
      <Drawer
        open={OpenDrawer}
        width={window.innerWidth > 700 ? 450 : "auto"}
        title={
          <Row
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Col>
              {newnotificationcount >= 1
                ? `Notifications(${newnotificationcount})`
                : "Notifications"}
            </Col>
            <Col span={10}>
              <Typography.Link
                onClick={AllRead}
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                }}
              >
                Mark All as Read{" "}
                <MdOutlineMarkEmailRead color="#40A9FF" size={"1.2em"} />
              </Typography.Link>
            </Col>
          </Row>
        }
        placement="right"
        onClose={() => {
          setOpenDrawer(false);
        }}
        // footer={
        //   <Row justify={"center"}>
        //     <Col>
        //       <Button
        //         onClick={() => {
        //           setOpenDrawer(false);
        //           navigate("Reports", { state: { data: "4" } });
        //         }}
        //       >
        //         {/* View All */}
        //       </Button>
        //     </Col>
        //   </Row>
        // }
      >
        <List loading={Listloading} itemLayout="horizontal">
          {NotificationData && NotificationData.length > 0 ? (
            renderCardsForPage()
          ) : (
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "160px",
              }}
            >
              <Empty />
            </span>
          )}
        </List>
        <Pagination
          current={currentPage}
          pageSize={pageSize}
          total={totalPages * pageSize}
          onChange={(page) => setCurrentPage(page)}
          style={{ marginTop: "20px", textAlign: "center" }}
          showSizeChanger={false}
        />
      </Drawer>
      {/* EVery pages renders here, inside the content, outlet=children modules */}
      <Content
        className="site-layout-background space-bottom "
        style={{
          backgroundColor: "white",
          // margin: "1em",
          overflowX: "hidden",
          // overflow: "initial",
          padding: 0,
          margin: 0,
        }}
      >
        <Outlet />
      </Content>
    </Layout>
  );
}

export default MainDashboard;
