import React, { useState, useEffect } from "react";
import { Component } from "react";
import {
  UserOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from "@ant-design/icons";
import { PlusOutlined } from "@ant-design/icons";
import { MinusOutlined } from "@ant-design/icons";
import {
  Label,
  Input,
  Select,
  Space,
  Cascader,
  Form,
  Checkbox,
  message,
  Typography,
  TreeSelect,
  Tooltip,
} from "antd";
import { SettingOutlined } from "@ant-design/icons";
import { InputNumber } from "antd";
import { Button, Radio } from "antd";
import { Row, Col, Card, Switch, DatePicker } from "antd";
import { createFromIconfontCN } from "@ant-design/icons";
import { Layout, Menu, Breadcrumb } from "antd";
import { PageHeader } from "antd";
import { Image } from "antd";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import axiosClient from "../../Authentication/ApiCall";
import authService from "../../Authentication/authService";
import moment from "moment/moment";
import CameraAccessList from "./CameraAccessList";
import { all } from "axios";
import { MultiSelect } from "react-multi-select-component";

const { Header, Footer, Sider, Content } = Layout;
const { Option } = Select;
const { Title, Text } = Typography;

function CameraAccess() {
  document.title = "KEN - Access Control";

  const [AddRefresh, setAddRefresh] = useState(1);
  const [UserList, setUserList] = useState([]);
  const [cameralist, setcameralist] = useState([]);

  const [selectedids, setselectedids] = useState([]);
  const allids = selectedids.map(({ value }) => value);

  useEffect(() => {
    axiosClient
      .get("/camera/get_cameraList")
      .then((response) => {
        if (response.status === 200) {
       
          response.data.data.map((item, index) => {
            let camname = item.cameraName;
            let camdata = {
              label: item.cameraName,
              value: item.id,
            };
            
            setcameralist((cam) => [...cam, camdata]);
          });
        } else {
          console.log("something went wrong");
        }
      })
      .catch((err) => {
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });

    axiosClient
      .get("/cameraaccess/getUSerList")
      .then((response) => {
        if (response.status === 200) {
          setUserList(response.data.data);
        } else {
        }
      })
      .catch((err) => {
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  }, []);

  const onFinish = (values) => {
 

  
    axiosClient
      .post("/cameraaccess/addUserCameraAccess", {
        camera_list: allids,
        id: values.username,
      })
      .then((response) => {
        if (response.data.issuccess == true) {
          message.success(response.data.msg);
          setAddRefresh(AddRefresh + 1);
        }
      })
      .catch((err) => {
        console.log("errr", err);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  };

  return (
    <Row className="top-space8 " gutter={[30, 10]}>
      <Col
        xs={{ span: 22, offset: 2 }}
        lg={{ span: 11, offset: 1 }}
        xl={{ span: 8, offset: 0 }}
      >
        <Card hoverable bordered={true} title="Provide Access to the user">
          <Form
            name="basic"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 22,
            }}
            initialValues={
              {
                // remember: true,
              }
            }
            onFinish={onFinish}
            // onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              className="top-space"
              label="Username"
              name="username"
              rules={[
                {
                  type: "number",
                  required: true,
                  message: "Please select!",
                },
              ]}
            >
              <Select
                allowClear
                style={{
                  width: "100%",
                }}
                placeholder="Please select a user"
              >
                {UserList.map((option) => (
                  <Option key={option.id} value={option.id}>
                    {/* <h4 style={{ color: "red" }}></h4> */}
                    {/* <span style={{ color: "blue" }}></span> */}

                    <Title style={{ color: "#23234D" }} level={5}>
                      {option.username}
                    </Title>
                    <Text type="secondary">{option.email}</Text>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              // key={Math.random()}
              className="top-space"
              label="Camera List"
              name="cameralist"
              // validateStatus="success"
              rules={[
                {
                  // type: "",
                  required: allids.length >= 1 ? false : true,
                  message: "Please select",
                },
              ]}
            >
              {/* <TreeSelect
                treeCheckable
                allowClear
                value={selectedids}
                
                style={{
                  width: "100%",
                }}
                onChange={(ids) => setselectedids(ids)}
                placeholder="Please select Camera's to give access"
                treeData={[
                  {
                    title:
                      selectedids.length > 0 ? (
                        <span
                          onClick={() => setselectedids([])}
                          style={{
                            display: "inline-block",
                            color: "#286FBE",
                            cursor: "pointer",
                          }}
                        >
                          Unselect all
                        </span>
                      ) : (
                        <span
                          onClick={() => setselectedids(allids)}
                          style={{
                            display: "inline-block",
                            color: "#286FBE",
                            cursor: "pointer",
                          }}
                        >
                          Select all
                        </span>
                      ),
                    value: "xxx",
                    disableCheckbox: true,
                    disabled: true,
                  },
                  ...cameralist,
                ]}
              /> */}
              {/* <MultiSelect
                labelledBy="Select"
                value={selectedids}
                options={options}
              /> */}
              <div>
                <MultiSelect
                  options={cameralist}
                  value={selectedids}
                  onChange={setselectedids}
                  labelledBy="Select"
                />
              </div>

              {/* {cameralist.map((option) => (
                  <Option key={option.id} value={option.id}>
                    <Text> {option.cameraName}</Text>
                  </Option>
                ))}
              </TreeSelect> */}
            </Form.Item>

            <Form.Item
              wrapperCol={{
                xs: { span: 24, offset: 0 },
                sm: { span: 12, offset: 8 },
                md: { span: 12, offset: 8 },
                lg: { span: 12, offset: 8 },
                xl: { span: 8, offset: 8 },
              }}
            >
              {authService.getCurrentUserReadOnly() ? (
                <Tooltip title="you need access to submit">
                  <Button disabled type="primary" block>
                    Submit
                  </Button>
                </Tooltip>
              ) : (
                <Button
                  style={{ backgroundColor: "#3e3a75", border: "none" }}
                  type="primary"
                  block
                  htmlType="submit"
                >
                  Submit
                </Button>
              )}
            </Form.Item>
          </Form>
        </Card>
      </Col>

      <Col
        xs={{ span: 22, offset: 2 }}
        lg={{ span: 11, offset: 1 }}
        xl={{ span: 16, offset: 0 }}
      >
        <Card hoverable bordered={true} title="Camera access List for a user">
          <CameraAccessList props={AddRefresh} />
        </Card>
      </Col>
    </Row>
  );
}

export default CameraAccess;
